import schedule from 'node-schedule';
import { AfterViewChecked, AfterViewInit, Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { Howl, Howler } from 'howler';

import { SettingsService } from './core/settings/settings.service';
import { Refresh } from './shared/state/auth/auth-state.actions';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { ConsoleState } from './shared/state/console/console.state';

import { ConsoleService } from './shared/services/console.service';
import { MatSnackBar } from '@angular/material/snack-bar';

const SOUND_FILE_CODE_WHITE = '/assets/sound/code_white.wav';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  @HostBinding('class.layout-fixed') get isFixed() {
    return this.settings.getLayoutSetting('isFixed');
  }
  @HostBinding('class.aside-collapsed') get isCollapsed() {
    return this.settings.getLayoutSetting('isCollapsed');
  }
  @HostBinding('class.layout-boxed') get isBoxed() {
    return this.settings.getLayoutSetting('isBoxed');
  }
  @HostBinding('class.layout-fs') get useFullLayout() {
    return this.settings.getLayoutSetting('useFullLayout');
  }
  @HostBinding('class.hidden-footer') get hiddenFooter() {
    return this.settings.getLayoutSetting('hiddenFooter');
  }
  @HostBinding('class.aside-float') get isFloat() {
    return this.settings.getLayoutSetting('isFloat');
  }
  @HostBinding('class.aside-toggled') get asideToggled() {
    return this.settings.getLayoutSetting('asideToggled');
  }
  @HostBinding('class.aside-collapsed-text') get isCollapsedText() {
    return this.settings.getLayoutSetting('isCollapsedText');
  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    if (!document.hidden) {
      this.store.dispatch(new Refresh());
    }
  }

  constructor(
    public settings: SettingsService, 
    public consoleService: ConsoleService,
    private afMessaging: AngularFireMessaging,
    private snackBar: MatSnackBar,
    public store: Store) {}

  codeWhiteSubscription: Subscription;
  tokenSubscription: Subscription;
  unitChangedSubscription: Subscription;
  errorSubscription: Subscription;
  codeWhiteIds: string[] = [];
  notificationSubscribed: boolean = false;

  sound: Howl;

  ngOnInit() {
    schedule.scheduleJob('0 2 * * *', function() {
      window.location.reload();
    });

    document.addEventListener('click', e => {
      const target = e.target as HTMLElement;
      if (target.tagName === 'A') {
        e.preventDefault();
      }
    });

    this.notificationSubscribed = localStorage.getItem('notificationUnitTopicsSubscription') === 'true';
    if (this.notificationSubscribed) {
      this.consoleService.listenForMessages();
      this.errorSubscription = this.store.select(ConsoleState.pushNotificationError).subscribe(val => {
        if (val) {
          this.snackBar.open(val, 'Okay', {
            duration: 6000,
            panelClass: ['error-snackbar']
          });
        }
      });
    } else {
      this.unitChangedSubscription = this.store.select(ConsoleState.selectedUnits).subscribe(units => {
        if (units?.length > 0 && this.store.selectSnapshot(ConsoleState.isPushNotificationUser)) {
          this.requestPermission();
        }
      });
    }
  }

  /*
  * Request notification permission for the browser and subscribe to the selected unit
  */
  async requestPermission() {
    
      let isBrowserSafari = false;
      // if messaging supported and the token exists
      // vendor is deprecated, but still the best way to check for now
      if(navigator.vendor.match(/apple/i) || 
        (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1)) {
        isBrowserSafari = true;
      }
      if (!isBrowserSafari) {
        this.tokenSubscription = this.afMessaging.requestToken
          .subscribe(
            async (token) => {
              if (token) {
                // subscribe to the selected unit
                localStorage.setItem('notificationUnitTopicsSubscription', 'true');
                const organization = this.store.selectSnapshot(ConsoleState.selectedOrganization);
                const units = this.store.selectSnapshot(ConsoleState.selectedUnits);
                let selectedUnits = this.store.selectSnapshot(ConsoleState.selectedAndSharedUnitIds);
                const errorMessage = await this.consoleService.subscribeToUnits(organization, token, selectedUnits);
                if (errorMessage) {
                  this.snackBar.open('Failed to subscribe to push notifications', 'Okay', {
                    duration: 6000,
                    panelClass: ['error-snackbar']
                  });
                  localStorage.removeItem('notificationUnitTopicsSubscription');
                }
                this.consoleService.listenForMessages();
                this.notificationSubscribed = true;
              } else {
                localStorage.removeItem('notificationUnitTopicsSubscription');
              }
            },
            (error) => { console.error(error); },  
          );
        }
        this.unitChangedSubscription.unsubscribe();
      
  }

  ngAfterViewInit(): void {
    this.codeWhiteSubscription = this.store.select(ConsoleState.addedCodeWhiteNotifications).subscribe(notifications => {
      
      if (notifications && notifications.length > 0) {
        const notifications_sorted = [...notifications].sort((a, b) => b.time - a.time);
        const notification = notifications_sorted.filter(notif => !notif.acceptedBy);
        if (notification.length > 0 && !this.codeWhiteIds.includes(notification[0].id)) {

          this.sound = new Howl({
            src: [SOUND_FILE_CODE_WHITE],
            autoplay: false,
            onloaderror: (error) => {console.log('load error', error)},
            onplayerror: (error) => {console.log('play error', error)},
          });

          // this is a new code white notification that has not been accepted
          this.sound.play();

          // if the sound doesn't play within ten seconds, stop it
          setTimeout(() => {
            if (this.sound) {
              this.sound.stop();
            }
          }, 10000);

          this.codeWhiteIds.push(...notification.map(notif => notif.id));
        }
      }
    })
  }

  ngOnDestroy(): void {
      if (this.codeWhiteSubscription) {
        this.codeWhiteSubscription.unsubscribe();
      }
      if (this.tokenSubscription) {
        this.tokenSubscription.unsubscribe();
      }
      if (this.unitChangedSubscription) {
        this.unitChangedSubscription.unsubscribe();
      }
      if (this.errorSubscription) {
        this.errorSubscription.unsubscribe();
      }
  }
}
