<div class="wrapper">
  <div class="block-center mt-4 wd-custom">
    <h2 id="brand" class="text-center">Tenera Care</h2>

    <!-- START card-->
    <div class="card card-flat">
      <div class="card-body">
        <p class="text-center py-2">RESET PASSWORD</p>

        <mat-spinner *ngIf="isLoading"></mat-spinner>

        <alert *ngIf="accountError" type="danger" class="text-left">
          <div *ngIf="accountError.list">
            Password must:
            <ul>
              <ng-container *ngFor="let error of accountError.list">
                <li>{{error}}</li>
              </ng-container>    
            </ul>
          </div>
          <div *ngIf="!accountError.list">
            {{accountError.error}}
          </div>
        </alert>

        <div *ngIf="!isLoading && passwordReset">
          <alert type="success" class="text-center">
            <div>Your password has been reset.</div>
          </alert>
          <div class="login"><a [routerLink]="'/login'">Return to Login</a></div>
        </div>

        <form *ngIf="!isLoading && !invalidCode && !passwordReset" [formGroup]="resetForm" class="form-validate mb-3" role="form" name="resetForm" novalidate="" (submit)="reset(resetForm.value)">
          <div class="reset-message">Reset password for: <strong>{{ email }}</strong></div>

          <div class="form-group">
            <div class="input-group with-focus">
              <input class="form-control" type="password" placeholder="Enter New Password" autocomplete="off" formControlName="newPassword1" (keypress)="resetMessages()"/>
            </div>
            <div class="text-danger text-center"
              *ngIf="resetForm.controls['newPassword1'].hasError('required') && (resetForm.controls['newPassword1'].dirty || resetForm.controls['newPassword1'].touched)">
              This field is required
            </div>
          </div>

          <div class="form-group">
            <div class="input-group with-focus">
              <input class="form-control" type="password" placeholder="Re-enter New Password" autocomplete="off" formControlName="newPassword2" (keypress)="resetMessages()"/>
            </div>
            <div class="text-danger text-center"
              *ngIf="resetForm.controls['newPassword2'].hasError('required') && (resetForm.controls['newPassword2'].dirty || resetForm.controls['newPassword2'].touched)">
              This field is required
            </div>
          </div>

          <button class="btn btn-block btn-primary btn-lg mt-3" type="submit" [disabled]="!resetForm.valid">
            Reset Password
          </button>
        </form>
      </div>
    </div>
    <!-- END card-->
    <div class="p-3 text-center credit">
      <span>&copy;</span> <span>{{ getAppSetting("year") }}</span>
      <span> - </span> <span>{{ getAppSetting("name") }}</span>
    </div>
    <div class="privacy"><a [routerLink]="'/privacy'">Privacy Policy</a></div>
  </div>
</div>
