import { MenuService } from '../core/menu/menu.service';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { TranslatorService } from '../core/translator/translator.service';

import { AuthGuard } from '../shared/services/auth.guard';
import { AuthComponent } from './auth/auth.component';
import { CanDeactivateGuard } from '../shared/services/deactivate.guard';
import { LoginComponent } from './login/login.component';
import { menu } from './menu';
import { PrivacyComponent } from './privacy/privacy.component';
import { PasswordChangeComponent } from './password/change/password-change.component';
import { PasswordRecoverComponent } from './password/recover/password-recover.component';
import { PasswordResetComponent } from './password/reset/password-reset.component';
import { routes } from './routes';

@NgModule({
  declarations: [
    LoginComponent,
    AuthComponent,
    PrivacyComponent,
    PasswordChangeComponent,
    PasswordRecoverComponent,
    PasswordResetComponent
  ],
  imports: [
    SharedModule,
    RouterModule.forRoot(routes, {})
  ],
  exports: [
    RouterModule
  ],
  providers: [
    AuthGuard,
    CanDeactivateGuard]
})

export class RoutesModule {
  constructor(public menuService: MenuService, tr: TranslatorService) {
  }
}
