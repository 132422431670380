import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

import * as ProfileListActions from './profile-list-state.actions';
import { ProfileListStateModel } from './profile-list-state.model';
import { Profile } from '../../models/profile.model';

/**
 * State class for profile profile list
 */
@State<ProfileListStateModel>({
  name: 'profileList',
  defaults: {
    profileList: []
  }
})
@Injectable()
export class ProfileListState {

  /**
   * Get the list of profiles
   *
   * @returns ProfileListState.profileList
   */
  @Selector()
  static profileList(state: ProfileListStateModel): Profile[] {
    return state.profileList;
  }


  /**
   * Action handler - set the profile list
   */
  @Action(ProfileListActions.SetProfileList)
  onSetProfileList(ctx: StateContext<ProfileListStateModel>, action: ProfileListActions.SetProfileList) {
    // sort the list alphabetically by first name
    action.profileList.sort(function(a, b) {
      if (a.profileData.firstName > b.profileData.firstName) {
        return 1;
      } else if (b.profileData.firstName > a.profileData.firstName) {
        return -1;
      } else {
        if (a.profileData.lastName > b.profileData.lastName) {
          return 1;
        } else if (b.profileData.lastName > a.profileData.lastName) {
          return -1;
        }
        return 0;
      }
    });

    ctx.patchState({
      profileList: action.profileList
    });
  }

  /**
   * Action handler - reset the profile list state to default
   */
  @Action(ProfileListActions.ResetProfileListState)
  onResetProfileListState(ctx: StateContext<ProfileListStateModel>) {
    ctx.setState({
      profileList: []
    });
  }


}
