import { ProfileUpdateItem } from '../../models/profile-update-item.model';
import { ProfileRule } from '../../../../shared/models/rules/profile-rule.model';
import { Profile } from '../../models/profile.model';
import { ProfileType } from '../../models/profile-type/profile-type.model';
import { Unit } from '../../../../shared/models/unit.model';
import { Floor } from '../../../../routes/dashboard/map/models/floor.model';
import { Geofence } from '../../../../shared/models/geofence.model';

// Events - Actions that result from an event

/**
 * Action dispatched to clear the photo
 */
export class ClearPhoto {
  static type = '[ProfileDetail] ClearPhoto';
}

/**
 * Action dispatched to clear the photo error message
 */
export class ClearPhotoErrorMessage {
  static type = '[ProfileDetail] ClearPhotoErrorMessage';
}

/**
 * Action dispatched to clear the photo file type
 */
export class ClearPhotoFileType {
  static type = '[ProfileDetail] ClearPhotoFileType';
}

/**
 * Action dispatched to clear the profile error message
 */
export class ClearProfileErrorMessage {
  static type = '[ProfileDetail] ClearProfileErrorMessage';
}

/**
 * Action dispatched to clear the profile rule error message
 */
export class ClearProfileRuleErrorMessage {
  static type = '[ProfileDetail] ClearProfileRuleErrorMessage';
}

/**
 * Action dispatched to clear the profile update message
 */
export class ClearProfileUpdateMessage {
  static type = '[ProfileDetail] ClearProfileUpdateMessage';
}

/**
 * Action dispatched to clear the profile update message
 */
export class ClearProfileRuleUpdateMessage {
  static type = '[ProfileDetail] ClearProfileRuleUpdateMessage';
}

/**
 * Action dispatched to set the mode
 */
export class SetProfileType {
  static type = '[ProfileDetail] SetProfileType';
  constructor(public profileType: ProfileType) {}
}

/**
 * Action dispatched to set the mode
 */
export class SetMode {
  static type = '[ProfileDetail] SetMode';
  constructor(public mode: string) {}
}

/**
 * Action dispatched to set the photo
 */
export class SetPhoto {
  static type = '[ProfileDetail] SetPhoto';
  constructor(public photo: string) {}
}

/**
 * Action dispatched to set the photo error message
 */
export class SetPhotoErrorMessage {
  static type = '[ProfileDetail] SetPhotoErrorMessage';
  constructor(public message: string) {}
}

/**
 * Action dispatched to set the photo file type
 */
export class SetPhotoFileType {
  static type = '[ProfileDetail] SetPhotoFileType';
  constructor(public fileType: string) {}
}

/**
 * Action dispatched to set the profile error message
 */
export class SetProfileErrorMessage {
  static type = '[ProfileDetail] SetProfileErrorMessage';
  constructor(public message: string) {}
}

/**
 * Action dispatched to set the profile rule error message
 */
export class SetProfileRuleErrorMessage {
  static type = '[ProfileDetail] SetProfileRuleErrorMessage';
  constructor(public message: string) {}
}

/**
 * Action dispatched to set the profile update message
 */
export class SetProfileUpdateMessage {
  static type = '[ProfileDetail] SetProfileUpdateMessage';
  constructor(public message: string) {}
}

/**
 * Action dispatched to set the profile update message
 */
export class SetProfileRuleUpdateMessage {
  static type = '[ProfileDetail] SetProfileRuleUpdateMessage';
  constructor(public message: string) {}
}

/**
 * Action dispatched to add a pending profile update
 */
export class AddProfileUpdateItem {
  static type = '[ProfileDetail] AddProfileUpdateItem';
  constructor(public profileUpdateItem: ProfileUpdateItem) {}
}

/**
 * Action dispatched to clear pending updates
 */
export class ClearPendingUpdates {
  static type = '[ProfileDetail] ClearPendingUpdates';
}

/**
 * Action dispatched to set isNameChanged
 */
export class SetIsNameChanged {
  static type = '[ProfileDetail] SetIsNameChanged';
  constructor(public isNameChanged: boolean) {}
}

/**
 * Action dispatched to set isRoomChanged
 */
export class SetIsRoomChanged {
  static type = '[ProfileDetail] SetIsRoomChanged';
  constructor(public isRoomChanged: boolean) {}
}

/**
 * Action dispatched to set isBedChanged
 */
export class SetIsBedChanged {
  static type = '[ProfileDetail] SetIsBedChanged';
  constructor(public isBedChanged: boolean) {}
}

/**
 * Action dispatched to set isUnitChanged
 */
export class SetIsUnitChanged {
  static type = '[ProfileDetail] SetIsUnitChanged';
  constructor(public isUnitChanged: boolean) {}
}

/**
 * Action dispatched to set assignedUnit
 */
export class SetAssignedUnit {
  static type = '[ProfileDetail] SetAssignedUnit';
  constructor(public assignedUnit: Unit) {}
}

/**
 * Action dispatched to set selectedUnitGeofenceId
 */
export class SetSelectedUnitGeofenceId {
  static type = '[ProfileDetail] SetSelectedUnitGeofenceId';
  constructor(public selectedUnitGeofenceId: string) {}
}

/**
 * Action dispatched to set isBathroomChanged
 */
export class SetIsBathroomChanged {
  static type = '[ProfileDetail] SetIsBathroomChanged';
  constructor(public isBathroomChanged: boolean) {}
}

/**
 * Action dispatched to set isOnpassChanged
 */
export class SetIsOnpassChanged {
  static type = '[ProfileDetail] SetIsOnpassChanged';
  constructor(public isOnpassChanged: boolean) {}
}

/**
 * Action dispatched to set isNewSensorAssigned
 */
export class SetIsNewSensorAssigned {
  static type = '[ProfileDetail] SetIsNewSensorAssigned';
  constructor(public isNewSensorAssigned: boolean) {}
}

/**
 * Action dispatched to set isTimeChanged
 */
export class SetIsTimeChanged {
  static type = '[ProfileDetail] SetIsTimeChanged';
  constructor(public isTimeChanged: boolean) {}
}

/**
 * Action dispatched to set isReadOnly
 */
 export class SetIsReadOnly {
  static type = '[ProfileDetail] SetIsReadOnly';
  constructor(public isReadOnly: boolean) {}
}

/**
 * Action dispatched to set removeMapIcon
 */
export class SetRemoveMapIcon {
  static type = '[ProfileDetail] SetRemoveMapIcon';
  constructor(public removeMapIcon: boolean) {}
}

/**
 * Action dispatched to set the profile detail
 */
export class SetProfileDetail {
  static type = '[ProfileDetail] SetProfileDetail';
  constructor(public profileDetail: Profile) {}
}

/**
 * Action dispatched to set the floor data
 */
export class SetFloorData {
  static type = '[ProfileDetail] SetFloorData';
  constructor(public floorData: Floor) {}
}

/**
 * Action dispatched to set the floor position coorinates
 */
export class SetCoordinates {
  static type = '[ProfileDetail] SetCoordinates';
  constructor(public coordinates: number[]) {}
}

/**
 * Action dispatched to set the floor position coorinates
 */
export class SetFixturePendingCoordinates {
  static type = '[ProfileDetail] SetFixturePendingCoordinates';
  constructor(public fixturePendingCoordinates: number[]) {}
}

/**
 * Action dispatched to set the floor position coorinates
 */
export class SetFloorGeofences {
  static type = '[ProfileDetail] SetFloorGeofences';
  constructor(public floorGeofences: Geofence[]) {}
}

/**
 * Action dispatched to set the fixture selected flooor ID
 */
export class SetSelectedRoomId {
  static type = '[ProfileDetail] SetSelectedRoomId';
  constructor(public selectedRoomId: string) {}
}

/**
 * Action dispatched to set the list of saved rules
 */
export class SetSavedRules {
  static type = '[ProfileDetail] SetSavedRules';
  constructor(public rules: ProfileRule[]) {}
}

/**
 * Action dispatched to set the list of unsaved rules
 */
export class SetUnsavedRules {
  static type = '[ProfileDetail] SetUnsavedRules';
  constructor(public rules: ProfileRule[]) {}
}

/**
 * Action dispatched to add a rule to unsaved rules
 */
export class AddUnsavedRule {
  static type = '[ProfileDetail] AddUnsavedRule';
  constructor(public rule: ProfileRule) {}
}

/**
 * Action dispatched to update the room geofence in all
 *  unsaved rules where updateRoomOnChange = true
 */
export class UpdateRoomInUnsavedRules {
  static type = '[ProfileDetail] UpdateRoomInUnsavedRules';
  constructor(public payload: { roomId: string, roomName: string } ) {}
}

/**
 * Action dispatched to delete all unsaved
 *  rules where updateRoomOnChange = true
 */
export class DeleteUnsavedRoomRules {
  static type = '[ProfileDetail] DeleteUnsavedRoomRules';
}

/**
 * Action dispatched to update the bed geofence in all
 *  unsaved rules where updateBedOnChange = true
 */
export class UpdateBedInUnsavedRules {
  static type = '[ProfileDetail] UpdateBedInUnsavedRules';
  constructor(public payload: { triggerValue: string, geofenceIdName: string } ) {}
}

/**
 * Action dispatched to delete all unsaved
 *  rules where updateBedOnChange = true
 */
export class DeleteUnsavedBedRules {
  static type = '[ProfileDetail] DeleteUnsavedBedRules';
}

/**
 * Action dispatched to update the bathroom geofence in all
 *  unsaved rules where updateBathroomOnChange = true
 */
export class UpdateBathroomInUnsavedRules {
  static type = '[ProfileDetail] UpdateBathroomInUnsavedRules';
  constructor(public payload: { triggerValue: string, geofenceIdName: string } ) {}
}

/**
 * Action dispatched to delete all unsaved
 *  rules where updateBathroomOnChange = true
 */
export class DeleteUnsavedBathroomRules {
  static type = '[ProfileDetail] DeleteUnsavedBathroomRules';
}

/**
 * Action dispatched to update rule start and end time in all
 *  unsaved rules where updateTime is not set the NEVER
 */
export class UpdateTimeInUnsavedRules {
  static type = '[ProfileDetail] UpdateTimeInUnsavedRules';
  constructor(public payload: { riseTime: number, sleepTime: number } ) {}
}

/**
 * Action dispatched to update all unsaved
 *  rules with the specified properties
 */
export class UpdateAllUnsavedRules {
  static type = '[ProfileDetail] UpdateAllUnsavedRules';
  constructor(public updatedProperties: {} ) {}
}

/**
 * Action dispatched to update a rule in unsaved rules
 */
export class UpdateUnsavedRule {
  static type = '[ProfileDetail] UpdateUnsavedRule';
  constructor(public payload: { index: number, updatedProperties: {} } ) {}
}

/**
 * Action dispatched to update firmware version
 */
export class SetFirmwareVersion {
  static type = '[ProfileDetail] SetFirmwareVersion';
  constructor(public firmwareVersion: string ) {}
}

/**
 * Action dispatched to remove a rule from unsaved rules
 */
export class DeleteUnsavedRule {
  static type = '[ProfileDetail] DeleteUnsavedRule';
  constructor(public index: number) {}
}

/**
 * Action dispatched to reset the profile detail state to default
 */
export class ResetProfileDetailState {
  static type = '[ProfileDetail] ResetProfileDetailState';
}
