import { SetNotificationDetail } from '../../routes/notifications/notification-detail/state/notification-detail-state.actions';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Injectable, OnDestroy } from '@angular/core';
import { map } from 'rxjs/operators';
import { Subscription, Subject } from 'rxjs';
import { Navigate } from '@ngxs/router-plugin';
import { HttpClient } from '@angular/common/http';

import {
  AddCodeWhiteNotifications,
  DecrementActiveNotificationsCodeWhite,
  DecrementActiveNotificationsCount,
  IncrementActiveNotificationsCodeWhite,
  IncrementActiveNotificationsCount, 
  RemoveCodeWhiteNotifications, 
  UpdateAddedCodeWhiteNotifications} from '../state/console/console-state.actions';
import { Logout } from '../state/auth/auth-state.actions';
import { Notification } from './../models/notification.model';
import { NotificationListItem } from '../../routes/notifications/models/notification-list-item.model';
import { AddNotification, DeleteNotification,
  UpdateNotification } from '../../routes/notifications/notification-list/state/notification-list-state.actions';
import { environment } from '../../../environments/environment';
import { NotificationListState } from '../../routes/notifications/notification-list/state/notification-list.state';

const QUERY_ARRAY_LIMIT = 10;

@Injectable({providedIn: 'root'})
export class NotificationsService implements OnDestroy {

  // Subscriptions to Actions being dispateched
  private logoutSubscription: Subscription;

  // Subscription used by Dashboard component
  private activeNotificationsSubscriptions: Subscription[] = [];

 // Subscription used by notification detail component
 private notificationDetailSubscription: Subscription;

  // Subscription used by Notification component
  private notificationListSubscriptions: Subscription[] = [];

  // Listener for activeNotificationsSubscription
  private activeNotificationsListener = new Subject<any>();

  // Subscription to notification count used by entire application
  private activeNotificationsCountSubscriptions: Subscription[] = [];

  // Subscription to code white notification used by entire application
  private activeNotificationsCodeWhiteSubscriptions: Subscription[] = [];

  constructor(
    private actions: Actions,
    private afs: AngularFirestore,
    private http: HttpClient,
    private store: Store) {

    // set up subscription to Logout action
    this.logoutSubscription = this.actions.pipe(ofActionDispatched(Logout)).subscribe(() => {
      this.cancelFirebaseSubscriptions();
    });
  }

  /**
   * Return the activeNotificationsListener as an observable
   */
  getActiveNotificationsListener() {
    return this.activeNotificationsListener.asObservable();
  }

  /**
   * Subscribe to active notifications for a unit
   *
   * @param organization The selected organization
   * @param venueId The ID of the venue containing the selected unit
   * @param unitIds The IDs of the selected units
   */
  async fetchActiveNotificationsCount(organization: string, venueId: string, unitIds: string[], isHomeUnitNotifier: boolean) {
    await this.cancelActiveNotificationsCountSubscriptions();
    const notificationsUrl = 'organizations/' + organization + '/venues/' + venueId + '/notifications';
    for (let i=0; i<unitIds.length; i+=QUERY_ARRAY_LIMIT) {
      const currentUnitIds = unitIds.slice(i, i + QUERY_ARRAY_LIMIT);
      this.subscribeToActiveNotificationCount(notificationsUrl, 'unitId', currentUnitIds, unitIds);
      if (isHomeUnitNotifier) {
        this.subscribeToActiveNotificationCount(notificationsUrl, 'homeUnits', currentUnitIds, unitIds);
      }
    }
  }

  subscribeToActiveNotificationCount(notificationsUrl: string, unitsType: string, currentUnitIds: string[], allUnitIds: string[]) {
    let activeNotificationsCollection: AngularFirestoreCollection<Notification>;
    if (unitsType === 'unitId') {
      activeNotificationsCollection = this.afs.collection<Notification>
      (notificationsUrl, ref => ref.where('clearedTime', '==', null).where(unitsType, 'in', currentUnitIds));
    } else {
      activeNotificationsCollection = this.afs.collection<Notification>
        (notificationsUrl, ref => ref.where('clearedTime', '==', null).where(unitsType, 'array-contains-any', currentUnitIds));
    }
    
      this.activeNotificationsCountSubscriptions.push(activeNotificationsCollection.stateChanges()
      .pipe( map(activeNotifications => activeNotifications.map (currentNotification => {
        return currentNotification;
      }))).subscribe( (notifications) => {
        notifications.forEach(notification => {
          const action = notification.type;
          const unit = notification.payload.doc.data().unitId;
          // avoid duplicating counts for home unit and unit id
          if (unitsType != 'homeUnits' || !allUnitIds.includes(unit)) {
            if (action === 'added') {
              this.store.dispatch(new IncrementActiveNotificationsCount());
            } else if (action === 'removed') {
              this.store.dispatch(new DecrementActiveNotificationsCount());
            }
          }
        });
      }, (error) => {
        console.error(error);
      }));

      this.activeNotificationsCodeWhiteSubscriptions.push(activeNotificationsCollection.stateChanges()
      .pipe( map(activeNotifications => activeNotifications.map (currentNotification => {
        const data = currentNotification.payload.doc.data() as Notification;
        const action = currentNotification.type;
        const id = currentNotification.payload.doc.id;
        return { id, action, ...data };
      }))).subscribe( (activeNotifications: Notification[]) => {
        const codeWhites = activeNotifications ? activeNotifications.filter(notif => notif.priority === 0) : [];
        let added = [];
        for (const cw of codeWhites) {
          if (cw.action === 'added') {
            this.store.dispatch(new IncrementActiveNotificationsCodeWhite());
            this.store.dispatch(new AddCodeWhiteNotifications(cw));
            // If there are multiple at once, get the latest so only one sound plays
            // added = added && cw.time < added.time ? added : cw;
            added.push(cw);
          } else if (cw.action === 'removed') {
            this.store.dispatch(new DecrementActiveNotificationsCodeWhite());
            this.store.dispatch(new RemoveCodeWhiteNotifications(cw));
          } else if (cw.action === 'modified') {
            this.store.dispatch(new RemoveCodeWhiteNotifications(cw));
            this.store.dispatch(new AddCodeWhiteNotifications(cw));
            added.push(cw);
          }
        }
        this.store.dispatch(new UpdateAddedCodeWhiteNotifications(added));
        
      }, (error) => {
        console.error(error);
      }));
  }

  /**
   * Subscribe to active notifications for one floor of a unit
   *  and emit values to subscribers of activeNotificationsListener
   *
   * @param organization The selected organization
   * @param venueId The ID of the venue containing the selected unit
   * @param unitIds The IDs of the selected units
   */
  fetchActiveNotifications(organization: string, venueId: string,  unitIds: string[]) {
    const notificationsUrl = 'organizations/' + organization + '/venues/' + venueId + '/notifications';
    for (let i=0; i<unitIds.length; i+=QUERY_ARRAY_LIMIT) {
      const currentUnitIds = unitIds.slice(i, i + QUERY_ARRAY_LIMIT);
      const activeNotificationsCollection = this.afs.collection<Notification>
        (notificationsUrl, ref => ref.where('clearedTime', '==', null).where('unitId', 'in', currentUnitIds));
      this.activeNotificationsSubscriptions.push(activeNotificationsCollection.stateChanges()
      .pipe( map(activeNotifications => activeNotifications.map (currentNotification => {
        const data = currentNotification.payload.doc.data() as Notification;
        const action = currentNotification.type;
        const id = currentNotification.payload.doc.id;
        return { id, action, ...data };
      }))).subscribe( (activeNotifications: Notification[]) => {
        this.activeNotificationsListener.next([...activeNotifications]);
      }, (error) => {
        console.error(error);
        this.activeNotificationsListener.next(null);
      }));
    }
  }

  /**
   * Subscribe to active notifications for one floor of a unit
   *  and emit values to subscribers of activeNotificationsListener
   *
   * @param organization The selected organization
   * @param venueId The ID of the venue containing the selected unit
   * @param unitIds The IDs of the selected units
   */
  fetchActiveHomeUnitNotifications(organization: string, venueId: string,  unitIds: string[]) {
    const notificationsUrl = 'organizations/' + organization + '/venues/' + venueId + '/notifications';
    for (let i=0; i<unitIds.length; i+=QUERY_ARRAY_LIMIT) {
      const currentUnitIds = unitIds.slice(i, i + QUERY_ARRAY_LIMIT);
      const activeNotificationsCollection = this.afs.collection<Notification>
        (notificationsUrl, ref => ref.where('clearedTime', '==', null).where('homeUnits', 'array-contains-any', currentUnitIds));
      this.activeNotificationsSubscriptions.push(activeNotificationsCollection.stateChanges()
      .pipe( map(activeNotifications => activeNotifications.map (currentNotification => {
        const data = currentNotification.payload.doc.data() as Notification;
        const action = currentNotification.type;
        const id = currentNotification.payload.doc.id;
        return { id, action, ...data };
      }))).subscribe( (activeNotifications: Notification[]) => {
        this.activeNotificationsListener.next([...activeNotifications.filter(n => !unitIds.includes(n.unitId))]);
      }, (error) => {
        console.error(error);
        this.activeNotificationsListener.next(null);
      }));
    }
  }

  /**
   * Subscribe to active notifications for a unit
   *  and update notifications list in state on change
   *
   * @param organization The selected organization
   * @param venueId The ID of the venue containing the selected unit
   * @param unitIds The IDs of the selected units
   */
  fetchActiveNotificationsList(organization: string, venueId: string, unitIds: string[]) {
    // TODO:
    const notificationsUrl = 'organizations/' + organization + '/venues/' + venueId + '/notifications';
    for (let i=0; i<unitIds.length; i+=QUERY_ARRAY_LIMIT) {
      const currentUnitIds = unitIds.slice(i, i + QUERY_ARRAY_LIMIT);
      const notificationsCollection = this.afs.collection<Notification>
        (notificationsUrl, ref => ref.where('clearedTime', '==', null).where('unitId', 'in', currentUnitIds));
      this.notificationListSubscriptions.push(notificationsCollection.stateChanges()
      .pipe( map (notifications => {
        return notifications.map(notification => {
          return ({
            action: notification.type,
            clearedReason: notification.payload.doc.data().clearedReason,
            date: notification.payload.doc.data().time,
            eventType: notification.payload.doc.data().subject,
            id: notification.payload.doc.id,
            message: notification.payload.doc.data().message,
            name: notification.payload.doc.data().name,
            priority: notification.payload.doc.data().priority,
            profileType: notification.payload.doc.data().profileType,
            unitId: notification.payload.doc.data().unitId
          });
        });
      })).subscribe( (notifications: NotificationListItem[]) => {
        notifications.forEach(notification => {
          if (notification.action === 'added') {
            const notifList = this.store.selectSnapshot(NotificationListState.notificationList);
            const found = notifList.find(n => n.id === notification.id);
            if (!found) {
                this.store.dispatch(new AddNotification(notification));
            }
          } else if (notification.action === 'removed') {
            this.store.dispatch(new DeleteNotification(notification.id));
          } else if (notification.action === 'modified') {
            this.store.dispatch(new UpdateNotification(notification));
          }
        });
      }, (error) => {
        console.error(error);
        // TODO dispatch error and display error in cnnsole
      }));
    }
  }

  /**
   * Subscribe to active notifications for a unit
   *  and update notifications list in state on change
   *
   * @param organization The selected organization
   * @param venueId The ID of the venue containing the selected unit
   * @param unitIds The IDs of the selected units
   */
  fetchActiveHomeUnitNotificationsList(organization: string, venueId: string, unitIds: string[]) {
    // TODO:
    const notificationsUrl = 'organizations/' + organization + '/venues/' + venueId + '/notifications';
    for (let i=0; i<unitIds.length; i+=QUERY_ARRAY_LIMIT) {
      const currentUnitIds = unitIds.slice(i, i + QUERY_ARRAY_LIMIT);
      const notificationsCollection = this.afs.collection<Notification>
        (notificationsUrl, ref => ref.where('clearedTime', '==', null).where('homeUnits', 'array-contains-any', currentUnitIds));
      this.notificationListSubscriptions.push(notificationsCollection.stateChanges()
      .pipe( map (notifications => {
        return notifications.map(notification => {
          return ({
            action: notification.type,
            clearedReason: notification.payload.doc.data().clearedReason,
            date: notification.payload.doc.data().time,
            eventType: notification.payload.doc.data().subject,
            id: notification.payload.doc.id,
            message: notification.payload.doc.data().message,
            name: notification.payload.doc.data().name,
            priority: notification.payload.doc.data().priority,
            profileType: notification.payload.doc.data().profileType,
            unitId: notification.payload.doc.data().unitId
          });
        });
      })).subscribe( (notifications: NotificationListItem[]) => {
        notifications.forEach(notification => {
            if (notification.action === 'added') {
              const notifList = this.store.selectSnapshot(NotificationListState.notificationList);
              const found = notifList.find(n => n.id === notification.id);
              if (!found) {
                  this.store.dispatch(new AddNotification(notification));
              }
            } else if (notification.action === 'removed') {
              this.store.dispatch(new DeleteNotification(notification.id));
            } else if (notification.action === 'modified') {
              this.store.dispatch(new UpdateNotification(notification));
            }
        });
      }, (error) => {
        console.error(error);
        // TODO dispatch error and display error in cnnsole
      }));
    }
  }

  /**
   * Subscribe to notifications for a unit within a specified time
   *  interval and update notifications list in state on change
   *
   * @param organization The selected organization
   * @param venueId The ID of the venue containing the selected unit
   * @param unitIds The ID of the selected unit
   * @param minTime The minimum time value to report
   */
  fetchNotificationListForInterval(organization: string, venueId: string, unitIds: string[], minTime: number) {
    const notificationsUrl = 'organizations/' + organization + '/venues/' + venueId + '/notifications';
    for (let i=0; i<unitIds.length; i+=QUERY_ARRAY_LIMIT) {
      const currentUnitIds = unitIds.slice(i, i + QUERY_ARRAY_LIMIT);
      const notificationsCollection = this.afs.collection<Notification>
        (notificationsUrl, ref => ref.where('time', '>=', minTime).where('unitId', 'in', currentUnitIds));
      this.notificationListSubscriptions.push(notificationsCollection.stateChanges()
      .pipe( map (notifications => {
        return notifications.map(notification => {
          return ({
            action: notification.type,
            clearedReason: notification.payload.doc.data().clearedReason,
            date: notification.payload.doc.data().time,
            eventType: notification.payload.doc.data().subject,
            id: notification.payload.doc.id,
            message: notification.payload.doc.data().message,
            name: notification.payload.doc.data().name,
            priority: notification.payload.doc.data().priority,
            profileType: notification.payload.doc.data().profileType,
            unitId: notification.payload.doc.data().unitId
          });
        });
      })).subscribe( (notifications: NotificationListItem[]) => {
        notifications.forEach(notification => {
          if (notification.action === 'added') {
            const notifList = this.store.selectSnapshot(NotificationListState.notificationList);
            const found = notifList.find(n => n.id === notification.id);
            if (!found) {
                this.store.dispatch(new AddNotification(notification));
            }
          } else if (notification.action === 'removed') {
            this.store.dispatch(new DeleteNotification(notification.id));
          } else if (notification.action === 'modified') {
            this.store.dispatch(new UpdateNotification(notification));
          }
        });
      }, (error) => {
        console.error(error);
        // TODO dispatch error and display error in cnnsole
      }));
    }
  }

  /**
   * Subscribe to notifications for a unit within a specified time
   *  interval and update notifications list in state on change
   *
   * @param organization The selected organization
   * @param venueId The ID of the venue containing the selected unit
   * @param unitIds The ID of the selected unit
   * @param minTime The minimum time value to report
   */
  fetchHomeNotificationListForInterval(organization: string, venueId: string, unitIds: string[], minTime: number) {
    const notificationsUrl = 'organizations/' + organization + '/venues/' + venueId + '/notifications';
    for (let i=0; i<unitIds.length; i+=QUERY_ARRAY_LIMIT) {
      const currentUnitIds = unitIds.slice(i, i + QUERY_ARRAY_LIMIT);
      const notificationsCollection = this.afs.collection<Notification>
        (notificationsUrl, ref => ref.where('time', '>=', minTime).where('homeUnits', 'array-contains-any', currentUnitIds));
      this.notificationListSubscriptions.push(notificationsCollection.stateChanges()
      .pipe( map (notifications => {
        return notifications.map(notification => {
          return ({
            action: notification.type,
            clearedReason: notification.payload.doc.data().clearedReason,
            date: notification.payload.doc.data().time,
            eventType: notification.payload.doc.data().subject,
            id: notification.payload.doc.id,
            message: notification.payload.doc.data().message,
            name: notification.payload.doc.data().name,
            priority: notification.payload.doc.data().priority,
            profileType: notification.payload.doc.data().profileType,
            unitId: notification.payload.doc.data().unitId
          });
        });
      })).subscribe( (notifications: NotificationListItem[]) => {
        notifications.forEach(notification => {
          if (notification.action === 'added') {
            const notifList = this.store.selectSnapshot(NotificationListState.notificationList);
            const found = notifList.find(n => n.id === notification.id);
            if (!found) {
                this.store.dispatch(new AddNotification(notification));
            }
          } else if (notification.action === 'removed') {
            this.store.dispatch(new DeleteNotification(notification.id));
          } else if (notification.action === 'modified') {
            this.store.dispatch(new UpdateNotification(notification));
          }
        });
      }, (error) => {
        console.error(error);
        // TODO dispatch error and display error in cnnsole
      }));
    }
  }

  /**
   * Subscribe to a notification document and update notification
   *  detail in state on change
   *
   * @param organization The selected organization
   * @param venueId The ID of the venue containing the selected unit
   * @param docId The firestore document identifier
   */
  fetchNotificationDetail(organization: string, venueId: string, docId: string): void {
    const notificationsUrl = 'organizations/' + organization + '/venues/' + venueId + '/notifications';
    const notificationsCollection = this.afs.collection<Notification>(notificationsUrl).doc(docId);
    this.notificationDetailSubscription = notificationsCollection.snapshotChanges()
    .pipe(map (notification => {
      if (notification.payload.data()) {
        const data = notification.payload.data() as Notification;
        const id = notification.payload.id;
        return {id, ...data};
      } else { // document does not exist, so return user to dashboard
        this.store.dispatch(new Navigate(['/dashboard']));
        return null;
      }
    })).subscribe( (notification: Notification) => {
      this.store.dispatch(new SetNotificationDetail(notification));
    }, error => {
      console.error(error);
    });
  }

  /**
   * Update a notification in Firestore by setting the accepted by and accepted time fields
   *
   * @param organization The selected organization
   * @param venueId The ID of the venue containing the selected unit
   * @param notificationId The document ID of the notification
   * @param name The name of the user accepting the notification
   */
  async acceptNotification(organization: string, venueId: string, notificationId: string, name: string) {
   try {
    const notificationsUrl = environment.apiUrl + '/organizations/' + organization + '/venues/' + venueId + '/notifications/' + notificationId + '/accept';
    const data = { acceptedBy: name };
    const res = await this.http.put<any>(notificationsUrl, data).toPromise();
    return null;
   } catch (error) {
     console.log(error);
   }
    
  }

  /**
   * Update a notification in Firestore by setting the fields related to notification clearing
   *
   * @param organization The selected organization
   * @param venueId The ID of the venue containing the selected unit
   * @param notificationId The document ID of the notification
   * @param reason The reason for clearing the notification
   * @param memo Additional notes provided for the notification
   * @param name The name of the user clearing the notification
   */
  async clearNotification(organization: string, venueId: string, notificationId: string, reason: string, memo: string, name: string) {
    try {
      const notificationsUrl = environment.apiUrl + '/organizations/' + organization + '/venues/' + venueId + '/notifications/' + notificationId + '/clear';
      const data = {
        clearedBy: name,
        clearedMemo: memo,
        clearedReason: reason };
      const res = await this.http.put<any>(notificationsUrl, data).toPromise();
      return null;
     } catch (error) {
       console.log(error);
       return error;
     }
  }

  /**
   * Unsubscribe from changes to active notification count
   */
  cancelActiveNotificationsCountSubscriptions() {
    return new Promise((resolve) => {
      this.activeNotificationsCountSubscriptions.forEach(sub => sub.unsubscribe());
      this.activeNotificationsCountSubscriptions = [];
      resolve(true);
    });
  }

  /**
   * Unsubscribe from changes to active notification count
   */
  cancelActiveNotificationsCodeWhiteSubscriptions() {
    return new Promise((resolve) => {
      this.activeNotificationsCodeWhiteSubscriptions.forEach(sub => sub.unsubscribe());
      this.activeNotificationsCodeWhiteSubscriptions = [];
      resolve(true);
    });
  }

  /**
   * Unsubscribe from changes to active notifications
   */
  cancelActiveNotificationsSubscriptions() {
    return new Promise((resolve) => {
      this.activeNotificationsSubscriptions.forEach(sub => sub.unsubscribe());
      this.activeNotificationsSubscriptions = [];
      resolve(true);
    });
  }

  /**
   * Unsubscribe to notification list changes
   */
  cancelNotificationListSubscriptions(): Promise<boolean> {
    return new Promise((resolve) => {
      this.notificationListSubscriptions.forEach(sub => sub.unsubscribe());
      this.notificationListSubscriptions = [];
      resolve(true);
    });
  }

  /**
   * Unsubscribe from a notification document
   */
  cancelNotificationDetailSubscription(): void {
    if (this.notificationDetailSubscription && !this.notificationDetailSubscription.closed) {
      this.notificationDetailSubscription.unsubscribe();
    }
  }

  /**
   * Unsubscribe from all Firebase subscriptions
   */
  cancelFirebaseSubscriptions(): void {
    this.cancelActiveNotificationsCountSubscriptions();
    this.cancelActiveNotificationsCodeWhiteSubscriptions();
    this.cancelActiveNotificationsSubscriptions();
    this.cancelNotificationListSubscriptions();
    this.cancelNotificationDetailSubscription();
  }

  /**
   * Unsubscribe from all service subscriptions
   */
  ngOnDestroy() {
    this.cancelFirebaseSubscriptions();
    if (this.logoutSubscription) {
      this.logoutSubscription.unsubscribe();
    }
  }


}
