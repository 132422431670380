import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { Geofence } from '../models/geofence.model';

const URL_PREFIX = environment.apiUrl + '/organizations/';

@Injectable()
export class GeofencesService {

  constructor(private http: HttpClient) {}

  /**
   * Fetch geofences by type for a unit and floor - promise
   *  resolves to an array of geofence id, name pairs
   *
   * @param organization The ID of the organization
   * @param venueId The ID of the venue
   * @param unitId The ID of the unit
   * @param floorId The ID of the floor
   * @param type The type of geofences to fecth
   */
  getGeofencesByType(organization: string, venueId: string, unitId: string, floorId: string, type: string): Promise<Geofence[]> {
    const geofencesUrl = URL_PREFIX + organization + '/venues/' + venueId + '/units/' + unitId +
      '/floors/' + floorId + '/geofences/type/' + type;
    return new Promise((resolve, reject) => {
      this.http.get<{geofences: Geofence[]}>(geofencesUrl).subscribe((response) => {
        resolve(response.geofences);
      }, (error) => {
        console.log(error);
        reject();
      });
    });
  }
  /**
   * Fetch geofences by type for selected units on a floor
   *
   * @param organization The ID of the organization
   * @param venueId The ID of the venue
   * @param unitIds The ID of the units
   * @param floorId The ID of the floor
   * @param types The types of geofences to fecth
   */
  getFilteredFloorGeofences(organization: string, venueId: string, unitIds: string[], floorId: string, types: string[]): Promise<Geofence[]> {
    const geofencesUrl = URL_PREFIX + organization + '/venues/' + venueId + '/units/' + unitIds[0] +
      '/floors/' + floorId + '/geofences/full/floor';
    return new Promise((resolve, reject) => {
      this.http.get<{geofences: Geofence[]}>(geofencesUrl).subscribe((response) => {
        const unitFilter = response.geofences.filter(g => unitIds.includes(g.unitId));
        const filtered = unitFilter.filter(g => types.includes(g.type))
        resolve(filtered);
      }, (error) => {
        console.log(error);
        reject();
      });
    });
  }

  /**
   * Fetch all geofences for a unit and floor - promise
   *  resolves to an array of geofence objects
   *
   * @param organization The ID of the organization
   * @param venueId The ID of the venue
   * @param unitId The ID of the unit
   * @param floorId The ID of the floor
   */
  getGeofences(organization: string, venueId: string, unitId: string, floorId: string): Promise<Geofence[]> {
    const geofencesUrl = URL_PREFIX + organization + '/venues/' + venueId + '/units/' + unitId + '/floors/' + floorId + '/geofences';
    return new Promise((resolve, reject) => {
      this.http.get<{geofences: Geofence[]}>(geofencesUrl).subscribe((response) => {
        resolve(response.geofences);
      }, (error) => {
        console.log(error);
        reject();
      });
    });
  }

  /**
   * Fetch all geofences for a full floor - promise
   *  resolves to an array of geofence objects
   *
   * @param organization The ID of the organization
   * @param venueId The ID of the venue
   * @param unitId The ID of the unit
   * @param floorId The ID of the floor
   */
  getGeofencesForFloor(organization: string, venueId: string, unitId: string, floorId: string): Promise<Geofence[]> {
    const geofencesUrl = URL_PREFIX + organization + '/venues/' + venueId + '/units/' + unitId + '/floors/' + floorId + '/geofences/full/floor';
    return new Promise((resolve, reject) => {
      this.http.get<{geofences: Geofence[]}>(geofencesUrl).subscribe((response) => {
        resolve(response.geofences);
      }, (error) => {
        console.log(error);
        reject();
      });
    });
  }

  /**
   * Fetch all geofences for a unit and floor - promise
   *  resolves to an array of geofence objects
   *
   * @param organization The ID of the organization
   * @param venueId The ID of the venue
   */
  getVenueGeofences(organization: string, venueId: string): Promise<Geofence[]> {
    const geofencesUrl = URL_PREFIX + organization + '/venues/' + venueId + '/geofences';
    return new Promise((resolve, reject) => {
      this.http.get<{geofences: Geofence[]}>(geofencesUrl).subscribe((response) => {
        resolve(response.geofences);
      }, (error) => {
        console.log(error);
        reject();
      });
    });
  }


}
