import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store, Select } from '@ngxs/store';

import * as ProfileActions from '../../state/profile-detail-state.actions';
import { ConsoleState } from '../../../../../shared/state/console/console.state';
import { EventType } from '../../../../../shared/models/rules/enums/event-type.enum';
import { GeofenceAction } from '../../../../../shared/models/rules/enums/geofence-action.enum';
import { GeofenceType } from '../../../../../shared/models/rules/enums/geofence-type.enum';
import { GeofencesService } from '../../../../../shared/services/geofences.service';
import { NotifyOnTime } from '../../../../../shared/models/rules/enums/notify-on-time.enum';
import { OptionItem } from '../../../../../shared/models/option-item.model';
import { ProfileDetailState } from '../../state/profile-detail.state';
import { ProfileFormService } from '../../profile-form.service';
import { ProfileRule } from '../../../../../shared/models/rules/profile-rule.model';
import { ProfileRuleUtils } from '../profile-rule.utils';
import { ProfilesService } from '../../../profiles.service';
import { ProfileUtils } from '../../../profile.utils';
import { RuleFormService } from './rule-form.service';
import { RulesService } from '../../../../../shared/services/rules.service';
import { ProfileType } from '../../../models/profile-type/profile-type.model';
import { Observable, Subscription } from 'rxjs';
import { DeleteConfirmDialogComponent } from '../../../../../shared/components/delete-confirm-dialog/delete-confirm-dialog.component';
import { take } from 'rxjs/operators';
import { ProfileListState } from '../../../profile-list/state/profile-list.state';
import { Unit } from '../../../../../shared/models/unit.model';

@Component({
  selector: 'app-rule-detail-dialog',
  templateUrl: './profile-rule-detail-dialog.component.html',
  styleUrls: ['./profile-rule-detail-dialog.component.scss'],
  providers: [RuleFormService]
})
export class ProfileRuleDetailDialogComponent implements OnInit, OnDestroy {
  ruleForm: UntypedFormGroup;
  errorMessage: string;

  // option lists for form controls
  eventTypes: OptionItem[];
  actions: string[];
  proximityDistances: number[];
  geofenceTypes: string[];
  geofences: OptionItem[];
  existingGeofenceTypes: string[];
  geofenceWarnText: boolean = false;
  proximityNames: OptionItem[];
  profileType: ProfileType;
  unit: Unit;
  eventSubscriptions: Subscription[] = [];

  submitButtonLabel: string;
  reasonLabel: string;
  requestorTypes: Array<{ value: string, label: string }>;

  floorName: string;

  // Profiles type configuration
  @Select(ProfileDetailState.profileType) profileType$: Observable<ProfileType>;
  @Select(ConsoleState.isRuleEditor) isRuleEditor$: Observable<boolean>;
  @Select(ConsoleState.units) units$: Observable<Unit[]>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    rule: ProfileRule, ruleMode: string,
    profileId: string, unsavedRuleIndex: number
  },
    private geofencesService: GeofencesService,
    private dialog: MatDialog,
    private profileFormService: ProfileFormService,
    private profilesService: ProfilesService,
    private ruleFormService: RuleFormService,
    private rulesService: RulesService,
    private store: Store,
    private dialogRef: MatDialogRef<ProfileRuleDetailDialogComponent>) { }

  ngOnInit() {
    this.submitButtonLabel = this.store.selectSnapshot(ConsoleState.isRuleEditor) ? this.data.ruleMode : 'Submit Request';
    if (this.store.selectSnapshot(ConsoleState.isRuleEditor)) {
      if (this.data.ruleMode === 'Delete') {
        this.reasonLabel = 'Reason for Delete';
      } else if (this.data.ruleMode === 'Update') {
        this.reasonLabel = 'Reason for Rule Update';
      } else {
        this.reasonLabel = 'Reason for Rule';
      }
    } else {
      this.reasonLabel = 'Reason for Request';
    }
    this.ruleForm = this.ruleFormService.ruleForm;
    this.populateOptions();
    if (this.data.rule) {
      this.ruleFormService.populateFormFromRule(this.data.rule);
    } else {
      this.initializeForm();
    }
  }

  /**
   * Populate the option lists for rule parameters
   */
  async populateOptions() {
    let floorId: string;
    const pendingFloorChange = this.store.selectSnapshot(ProfileDetailState.profileUpdates).find(update => update.key === 'floorId');
    
    if (this.data.profileId && !pendingFloorChange) { // if saved profile, get floor ID from state
      floorId = this.store.selectSnapshot(ProfileDetailState.profileDetail).profileData.floorId;
    } else { // if in create mode, get floor ID from create profile form
      floorId = this.profileFormService.getFloorId();
    }
    
    const organization = this.store.selectSnapshot(ConsoleState.selectedOrganization);
    const venueId = this.store.selectSnapshot(ConsoleState.selectedVenueId);
    
    this.unit = this.store.selectSnapshot(ProfileDetailState.assingedUnit) ?? this.store.selectSnapshot(ConsoleState.selectedUnits)[0];
    this.populateEventTypes();
    this.populateGeofenceActions(false);
    this.requestorTypes = ProfileRuleUtils.getRuleRequestorTypes();
    this.proximityDistances = ProfileRuleUtils.getProximityDistancesInMeters();
    this.geofenceTypes = ProfileRuleUtils.getEnumsAsArray(GeofenceType);
    this.populateGeofences(organization, venueId, this.unit.unitId, this.unit.includeShared, floorId);
    this.proximityNames = await this.profilesService.fetchProximityNames(organization, venueId, this.unit.unitId, this.data.profileId);
    this.floorName = this.unit.floors.find(floor => floor.floorId === floorId)?.floorName;
  }

  populateGeofences(organization: string, venueId: string, unitId: string, sharedUnitIds: string[], floorId: string) {
    if (!floorId) {
      this.geofences = null;
    } else {
      this.geofences = [];
      // get geofences for whole floor
      this.geofencesService.getGeofencesForFloor(organization, venueId, unitId, floorId).then(geofences => {
        if (geofences.length > 0) {
          const newGeofences = geofences.map(geofence => {
            return { 
              label: geofence.name, 
              value: geofence.id, 
              unitId: sharedUnitIds?.includes(geofence.unitId) ? unitId : geofence.unitId
            };
          });
          this.geofences = [...this.geofences, ...newGeofences];
          this.geofences.sort((a, b) => a.label.localeCompare(b.label));
        }
      });
    }
    this.geofencesService.getVenueGeofences(organization, venueId).then(geofences => {
      this.existingGeofenceTypes = null;
      if(geofences.length > 0) {
        this.existingGeofenceTypes = Array.from(new Set(geofences.map(geo => geo.type)));
      }
    });
  }

  /**
   * Populate the list of possible event types
   *  from the EventType enum
   */
  populateEventTypes() {
    this.eventSubscriptions.push(this.profileType$.subscribe(profileType => {
      const labels = [
        { value: EventType.GEOFENCE, label: 'Zone' },
        { value: EventType.NOT_OWN_ROOM, label: 'Not Own Room' },
        { value: EventType.PROXIMITY, label: 'Distancing' },
        { value: EventType.ON_FLOOR, label: 'On Floor' },
      ];
      const optionList: string[] = [];
      // in update mode, do not allow different event type to be selected
      if (this.data.ruleMode === 'Delete') {
        optionList.push(this.data.rule.eventType);
      }
      else if (this.data.ruleMode === 'Update') {
        optionList.push(this.data.rule.eventType);
      } else {
        let roomId: number;
        // if saved profile, get room ID from state
        if (this.data.profileId && this.store.selectSnapshot(ProfileDetailState.profileDetail)) {
          roomId = this.store.selectSnapshot(ProfileDetailState.profileDetail).profileData.roomId;
        } else { // if in create mode, get room ID from create profile form
          roomId = this.profileFormService.getRoomId();
        }
        if (profileType) {
          profileType.ruleSettings.eventTypes.forEach(eventType => {
            // only add Not Own Room event type if room ID is set
            if (eventType === EventType.NOT_OWN_ROOM && roomId !== null) {
              optionList.push(eventType);
            } else if (eventType !== EventType.NOT_OWN_ROOM) {
              // add all other event types
              optionList.push(eventType);
            }
          });
        }
      }

      this.eventTypes = [];
      optionList.forEach(option => {
        const fw = this.store.selectSnapshot(ProfileDetailState.firmwareVersion);
        const unit = this.store.selectSnapshot(ProfileDetailState.assingedUnit);
        const isOnFloorEnabled = unit?.featureConfig?.isOnFloorEnabled ?? false;
        const isUserSuperAdmin = this.store.selectSnapshot(ConsoleState.role) === 'SuperAdmin';
        if (option !== EventType.ON_FLOOR || (['wearable-fd-main', 'wearable-fd-debug'].includes(fw) && isOnFloorEnabled && isUserSuperAdmin)) {
          this.eventTypes.push(labels.find(({ value }) => value === option));
        }
      });
    }));
  }

  /**
   * Populate the list of possible geofence
   *  actions from the GeofenceAction enum
   */
  populateGeofenceActions(enableExit: boolean): void {
    let bedId: number;
    if (this.data.profileId && this.store.selectSnapshot(ProfileDetailState.profileDetail)) { // if saved profile, get bed ID from state
      bedId = this.store.selectSnapshot(ProfileDetailState.profileDetail).profileData.bedId;
    } else { // if in create mode, get bed ID from create profile form
      bedId = this.profileFormService.getBedId();
    }
    let bathroomId: number;
    if (this.data.profileId && this.store.selectSnapshot(ProfileDetailState.profileDetail)) { // if saved profile, get bed ID from state
      bathroomId = this.store.selectSnapshot(ProfileDetailState.profileDetail).profileData.bathroomId;
    } else { // if in create mode, get bed ID from create profile form
      bathroomId = this.profileFormService.getBathroomId();
    }
    const optionList: string[] = [];
    for (const n in GeofenceAction) {
      if (typeof GeofenceAction[n] === 'string') {
        // always add Enter
        // add exit assigned bed if a bed is assigned and trigger type = Geofence ID
        // add exit if trigger type = Geofence ID
        if (GeofenceAction[n] === 'Enter' ||
          (GeofenceAction[n] === 'Exit Assigned Bed' && bedId) ||
          (GeofenceAction[n] === 'Near') ||
          (GeofenceAction[n] === 'Enter Assigned Bathroom' && bathroomId) ||
          (GeofenceAction[n] === 'Exit' && enableExit)) {
          optionList.push(GeofenceAction[n]);
        }
      }
    }
    this.actions = optionList;
  }

  /**
   * Called by default when in create rule mode -
   *  set default form values for creating a Geofence rule
   */
  initializeForm(): void {
    this.eventSubscriptions.push(this.profileType$.subscribe(profileType => {
      // setup form for a geofence rule by default
      if (profileType) {
        this.profileType = profileType;
        const eventType = profileType.ruleSettings.defaultEventType;
        this.ruleForm.patchValue({ eventType: eventType });
        if (eventType === EventType.GEOFENCE) {
          this.initializeGeofenceRule();
        } else if (eventType === EventType.NOT_OWN_ROOM) {
          this.initializeNotOwnRoomRule();
        } else if (eventType === EventType.PROXIMITY) {
          this.initializeProximityRule();
        } else if (eventType === EventType.ON_FLOOR) {
          this.initializeOnFloorRule();
        }
      }
    }));
  }

  /**
   * Set default form values for creating a Geofence rule
   */
  initializeGeofenceRule(): void {
    this.ruleFormService.setupGeofenceRule();
    this.populateGeofenceActions(false);
  }

  /**
   * Set default form values for creating a Not Own Room rule
   */
  initializeNotOwnRoomRule(): void {
    let roomId: number;
    let roomName: string;
    if (this.data.profileId) { // if saved profile, get values from state
      const profileData = this.store.selectSnapshot(ProfileDetailState.profileDetail).profileData;
      if (profileData.visitResident) {
        const profileList = this.store.selectSnapshot(ProfileListState.profileList);
        const resident = profileList.find(item => item.profileType === 'resident' && item.id === profileData.visitResident)
        roomId = resident.profileData.roomId;
        roomName = resident.profileData.roomName;
      } else {
        roomId = profileData.roomId;
        roomName = profileData.roomName;
      }
      
    } else { // if in create mode, get values from create profile form
      roomId = this.profileFormService.getRoomId();
      roomName = this.profileFormService.getRoomName();
    }
    this.ruleFormService.setupNotOwnRoomRule(roomId, roomName);
  }

  /**
   * Set default form values for creating a Proximity rule
   */
  initializeProximityRule(): void {
    this.ruleFormService.setupProximityRule(this.profileType);
  }

  /**
   * Set default form values for creating an On Floor rule
   */
  initializeOnFloorRule(): void {
    this.ruleFormService.setupOnFloorRule(this.profileType);
  }

  // Respond to form selections

  /**
   * Respond to eventType selection
   */
  onEventTypeSelected(): void {
    // clear any previously set parameters
    this.ruleFormService.resetRuleParameters();

    const eventType = this.ruleForm.get('eventType').value;
    if (eventType === EventType.GEOFENCE) {
      this.initializeGeofenceRule();
      this.ruleForm.get('disableOnPass').setValue(true);
    } else if (eventType === EventType.NOT_OWN_ROOM) {
      this.initializeNotOwnRoomRule();
      this.ruleForm.get('disableOnPass').setValue(true);
    } else if (eventType === EventType.PROXIMITY) {
      this.initializeProximityRule();
      this.ruleForm.get('disableOnPass').setValue(false);
    } else if (eventType === EventType.ON_FLOOR) {
      this.initializeOnFloorRule();
      this.ruleForm.get('disableOnPass').setValue(false);
    }
  }

  // Respond to Geofence parameter selections

  /**
   * Respond to geofenceTrigger selection
   */
  onTriggerTypeSelected(geofenceTrigger: string): void {
    const enableExit = geofenceTrigger === 'Geofence ID';
    this.ruleFormService.clearGeofence();
    this.populateGeofenceActions(enableExit);
  }

  onTriggerSelected() {
    const trigger = this.ruleForm.get('triggerValue').value;
    if (trigger === GeofenceType.RESTRICTED) {
      this.ruleForm.get('disableOnPass').setValue(false);
    }
    // if geofence type trigger is not in existingGeofenceTypes warn
    if (this.ruleForm.get('geofenceTrigger').value === 'Geofence Type' && this.existingGeofenceTypes) {
      this.geofenceWarnText = !this.existingGeofenceTypes.includes(trigger);
    } else {
      this.geofenceWarnText = false;
    }
  }

  /**
   * Respond to action selection
   */
  onActionSelected(): void {
    const action = this.ruleForm.get('action').value;
    if (action === 'Exit Assigned Bed') {
      let bedId: number;
      let bedName: string;
      if (this.data.profileId) { // if saved profile, get values from state
        bedId = this.store.selectSnapshot(ProfileDetailState.profileDetail).profileData.bedId;
        bedName = this.store.selectSnapshot(ProfileDetailState.profileDetail).profileData.bedName;
      } else { // if in create mode, get values from create profile form
        bedId = this.profileFormService.getBedId();
        bedName = this.profileFormService.getBedName();
      }
      // set geofence parameters from profile form Bed geofence values
      this.ruleForm.patchValue({ geofenceTrigger: 'Geofence ID' });
      this.ruleForm.patchValue({ triggerValue: bedId });
      this.ruleForm.patchValue({ geofence: bedName });
    } else if (action === 'Enter Assigned Bathroom') {
      let bathroomId: number;
      let bathroomName: string;
      if (this.data.profileId) { // if saved profile, get values from state
        bathroomId = this.store.selectSnapshot(ProfileDetailState.profileDetail).profileData.bathroomId;
        bathroomName = this.store.selectSnapshot(ProfileDetailState.profileDetail).profileData.bathroomName;
      } else { // if in create mode, get values from create profile form
        bathroomId = this.profileFormService.getBathroomId();
        bathroomName = this.profileFormService.getBathroomName();
      }
      // set geofence parameters from profile form Bed geofence values
      this.ruleForm.patchValue({ geofenceTrigger: 'Geofence ID' });
      this.ruleForm.patchValue({ triggerValue: bathroomId });
      this.ruleForm.patchValue({ geofence: bathroomName });
    }
    this.ruleFormService.setEventCategoryFromAction(action);
  }

  /**
   * Check form values according to eventType selected
   */
  isFormValid(): boolean {
    if (!this.ruleForm.get('reason').value) {
      return false;
    }
    if (!this.ruleForm.get('requestorType').value && this.data.ruleMode != 'Delete') {
      return false;
    }
    return this.ruleFormService.isFormValid();
  }

  /**
   * Set all form values to their default
   */
  resetForm(): void {
    this.ruleFormService.resetForm();
  }

  /**
   * Create a new rule or update an existing rule
   */
  async submit() {

    this.store.dispatch(new ProfileActions.ClearProfileRuleErrorMessage);
    this.store.dispatch(new ProfileActions.ClearProfileRuleUpdateMessage);
    // delete only requires the reason 
    if (this.data.ruleMode === 'Delete') {
      this.submitDelete();
      return;
    }

    this.eventSubscriptions.push(this.profileType$.subscribe(profileType => {
      this.profileType = profileType;
    }));

    const eventType = this.ruleForm.get('eventType').value;
    let rule: ProfileRule;
    // get the values from the form according to event type
    if (eventType === EventType.GEOFENCE) {
      let roomId: number;
      let bedId: number;
      let bathroomId: number;
      if (this.data.profileId) { // if saved profile, get values from state
        roomId = this.store.selectSnapshot(ProfileDetailState.profileDetail).profileData.roomId;
        bedId = this.store.selectSnapshot(ProfileDetailState.profileDetail).profileData.bedId;
        bathroomId = this.store.selectSnapshot(ProfileDetailState.profileDetail).profileData.bathroomId;
      } else { // if in create mode, get values from create profile form
        roomId = this.profileFormService.getRoomId();
        bedId = this.profileFormService.getBedId();
        bathroomId = this.profileFormService.getBathroomId();
      }
      rule = this.ruleFormService.getFormAsGeofenceRule(this.geofences, roomId, bedId, bathroomId);
    } else if (eventType === EventType.NOT_OWN_ROOM) {
      rule = this.ruleFormService.getFormAsNotOwnRoomRule();
    } else if (eventType === EventType.PROXIMITY) {
      rule = this.ruleFormService.getFormAsProximityRule(this.proximityNames);
    } else if (eventType === EventType.ON_FLOOR) {
      rule = this.ruleFormService.getFormAsOnFloorRule();
    }

    // TODO
    // check to see if this rule exists - if exists, display an error message in the popup
    // if create profile mode - check for this rule in unsaved rules
    // if edit profile mode - check for this rule in database AND unsaved rules
    // END TODO

    // set rule start time and end time based on value for updateTime
    if (rule.properties.updateTime === NotifyOnTime.RISE_TO_SLEEP) {
      rule.startTime = this.getRiseTimeAsDate().getTime();
      rule.endTime = this.getSleepTimeAsDate().getTime();
    } else if (rule.properties.updateTime === NotifyOnTime.SLEEP_TO_RISE) {
      rule.startTime = this.getSleepTimeAsDate().getTime();
      rule.endTime = this.getRiseTimeAsDate().getTime();
    } else { // always on so no start time and end time
      rule.startTime = null;
      rule.endTime = null;
    }
    const reason = this.ruleForm.get('reason').value;
    const requestorType = this.ruleForm.get('requestorType').value;
    // set the level based on Profile Type
    rule.level = this.profileType.ruleSettings.level;
    // set profile ID
    rule.profileId = this.data.profileId;
    // create or update saved or unsaved rule
    if (this.data.unsavedRuleIndex !== null) { // editing unsaved rule, so just delete existing rule and add new
      rule.ruleDetails = { reason: reason, requestorType: requestorType };
      this.store.dispatch(new ProfileActions.UpdateUnsavedRule({ index: this.data.unsavedRuleIndex, updatedProperties: rule }));
    } else if (rule.profileId) { // create new or edit saved rule for existing profile
      const organization = this.store.selectSnapshot(ConsoleState.selectedOrganization);
      const venueId = this.store.selectSnapshot(ConsoleState.selectedVenueId);
      const editor = this.store.selectSnapshot(ConsoleState.isRuleEditor);
      
      // ensure disableOnPass and active status are correct
      if (rule.properties.triggerValue === 'Restricted' || rule.eventType === 'Proximity' || rule.eventType === 'On Floor') {
        rule.properties.disableOnPass = false;
      } else {
        rule.properties.disableOnPass = true;
      }

      const profileData = this.store.selectSnapshot(ProfileDetailState.profileDetail).profileData;
      const onpassstatus = profileData ? profileData.onpassstatus : 'NOT ON PASS';
      if (this.profileType.id != "resident") {
        rule.active = true;
      } else if (rule.active && rule.properties.disableOnPass && onpassstatus != 'NOT ON PASS') {
        rule.active = false;
      } else if ((!rule.active && rule.properties.disableOnPass && onpassstatus == 'NOT ON PASS') || (!rule.properties.disableOnPass)) {
        rule.active = true;
      } 

      if (this.data.rule && editor) { // rule editor updating existing rule
        try {
          await this.rulesService.updateRule(organization, venueId, this.data.rule.id, rule, reason, requestorType);
          this.store.dispatch(new ProfileActions.SetProfileRuleUpdateMessage('Rule Updated.'));
        } catch (error) {
          console.log(error);
          this.store.dispatch(new ProfileActions.SetProfileRuleErrorMessage('An error occurred updating this rule. ' + error));
          this.errorMessage = 'An error occurred updating this rule';
        }
      } else if (editor) { // rule editor creating new rule
        try {
          await this.rulesService.addRule(organization, venueId, rule, reason, requestorType);
          this.store.dispatch(new ProfileActions.SetProfileRuleUpdateMessage('Rule Created.'));
        } catch (error) {
          console.log(error);
          this.store.dispatch(new ProfileActions.SetProfileRuleErrorMessage('An error occurred adding this rule. ' + error));
          // this.errorMessage = 'An error occurred adding this rule. ' + error;
        }
      } else if (this.data.rule) { // requesting edit for existing rule
        try {
          await this.rulesService.requestUpdateRule(organization, venueId, this.data.rule.id, rule, this.data.profileId, reason, requestorType);
          this.store.dispatch(new ProfileActions.SetProfileRuleUpdateMessage('Rule update requested.'));
        } catch (error) {
          console.log(error);
          this.store.dispatch(new ProfileActions.SetProfileRuleErrorMessage('An error occurred requesting an update for this rule. ' + error));
          // this.errorMessage = 'An error occurred requesting an update for this rule. ' + error;
        }
      } else { // new rule requested
        try {
          await this.rulesService.requestAddRule(organization, venueId, rule, reason, requestorType);
          this.store.dispatch(new ProfileActions.SetProfileRuleUpdateMessage('New rule requested.'));
        } catch (error) {
          console.log(error);
          this.store.dispatch(new ProfileActions.SetProfileRuleErrorMessage('An error occurred requesting this rule. ' + error));
          // this.errorMessage = 'An error occurred requesting this rule';
        }
      }
    } else { // new rule for unsaved profile
      rule.ruleDetails = { reason: reason, requestorType: requestorType };
      this.store.dispatch(new ProfileActions.AddUnsavedRule(rule));
    }

    this.dialogRef.close();
    this.resetForm();
  }

  /**
   * Dialog to confirm delete or delete request
   */
  submitDelete() {
    const editor = this.store.selectSnapshot(ConsoleState.isRuleEditor);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    const reason = this.ruleForm.get('reason').value;
    if (this.data.ruleMode === 'Delete' && editor) {

      dialogConfig.data = {
        title: 'Delete Saved Rule',
        message: 'This action will permanently delete this rule. It cannot be recovered.'
      };

      const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, dialogConfig);
      dialogRef.afterClosed().pipe(take(1)).subscribe(async result => {
        if (result) {

          try {
            const organization = this.store.selectSnapshot(ConsoleState.selectedOrganization);
            const venueId = this.store.selectSnapshot(ConsoleState.selectedVenueId);
            await this.rulesService.deleteRule(organization, venueId, this.data.rule.id, reason);

          } catch (error) {
            console.log(error);
            this.errorMessage = 'An error occurred deleting this rule';
          }
        }
        this.dialogRef.close();
        this.resetForm();
      });
    } else if (this.data.ruleMode === 'Delete') {
      dialogConfig.data = {
        title: 'Request to Delete Saved Rule',
        message: 'Are you sure you want to request that this rule is deleted?'
      };
      const dialogRef = this.dialog.open(DeleteConfirmDialogComponent, dialogConfig);
      dialogRef.afterClosed().pipe(take(1)).subscribe(async result => {
        if (result) {

          try {
            const organization = this.store.selectSnapshot(ConsoleState.selectedOrganization);
            const venueId = this.store.selectSnapshot(ConsoleState.selectedVenueId);
            const profileId = this.store.selectSnapshot(ProfileDetailState.profileDetail).id;
            await this.rulesService.requestDeleteRule(organization, venueId, this.data.rule.id, profileId, reason);

          } catch (error) {
            console.log(error);
            this.errorMessage = 'An error occurred requesting a delete of this rule';
          }
        }
        this.dialogRef.close();
        this.resetForm();
      });
    }
  }

  /**
   * return profile rise time as a date object
   */
  getRiseTimeAsDate(): Date {
    if (this.data.profileId) { // if saved profile, get values from state
      return ProfileUtils.convertTimeToDate(
        this.store.selectSnapshot(ProfileDetailState.profileDetail).profileData.riseHour,
        this.store.selectSnapshot(ProfileDetailState.profileDetail).profileData.riseMinute,
        this.store.selectSnapshot(ProfileDetailState.profileDetail).profileData.riseMeridian);
    } else { // if in create mode, get rise time from profile form
      return this.profileFormService.getRiseTimeAsDate();
    }
  }

  /**
   * return profile sleep time as a date object
   */
  getSleepTimeAsDate(): Date {
    if (this.data.profileId) { // if saved profile, get values from state
      return ProfileUtils.convertTimeToDate(
        this.store.selectSnapshot(ProfileDetailState.profileDetail).profileData.sleepHour,
        this.store.selectSnapshot(ProfileDetailState.profileDetail).profileData.sleepMinute,
        this.store.selectSnapshot(ProfileDetailState.profileDetail).profileData.sleepMeridian);
    } else { // if in create mode, get sleep time from profile form
      return this.profileFormService.getSleepTimeAsDate();
    }
  }

  ngOnDestroy(): void {
    this.eventSubscriptions.forEach(sub => {
      sub?.unsubscribe();
    })
  }

}
