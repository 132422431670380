import { UntypedFormGroup } from "@angular/forms";
import { RuleSettings } from "./rule-settings.model";
import { FormLayout } from "./form-layout.model";
import { CallStationClearMethod } from "../enums/call-station-clear-method.enum";

export abstract class ProfileType {
  readonly id: string;
  readonly name: string;
  readonly namePlural: string;
  readonly icon: string;
  readonly listReturningOption: boolean;
  readonly checkForDuplicateName: boolean;
  readonly listColumns: string[];
  readonly downloadColumns: string[];
  readonly secureColumns: { column: string; permissions: string[]; }[];
  readonly formLayout: FormLayout;
  readonly ruleSettings: RuleSettings;
  readonly isFixture: boolean;

  abstract mapLabel(form: UntypedFormGroup): string;

  hasListColumn(column) {
    return (this.listColumns.includes(column));
  }

  hasFormSection(section) {
    return (this.formLayout[section] && this.formLayout[section].length>0);
  }

  hasFormField(field) {
    for (let fields of Object.values(this.formLayout)) {
      if (fields.includes(field)) return true;
    }
    return false;
  }

  hasFixtureField(field: string, fixtureType: string) {
    switch (fixtureType) {
      case 'Nurse Call Device':
        return (this.formLayout.fixtureNurseCall?.includes(field));
      case 'Sensor Device':
        return (this.formLayout.fixtureSensor?.includes(field));
      default:
        return false;
    }
  }

  hasFixtureClearDevice(field: string, hardwareClearMethod: string) {
    switch (hardwareClearMethod) {
      case CallStationClearMethod.SENSOR:
        return (this.formLayout.fixtureClearSensor?.includes(field));
      case CallStationClearMethod.CHANNEL:
        return (this.formLayout.fixtureClearChannel?.includes(field));
      case CallStationClearMethod.SELF_CLEAR_LATCH:
      default:
        return false;
    }
  }

  getAllFields() {
    let fieldList = [];
    for (let fields of Object.values(this.formLayout)) {
      fields.forEach(field => {
        fieldList.push(field);
      });
    }
    fieldList.sort();
    return fieldList;
  }
}
