import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { ConsoleState } from './../state/console/console.state';
import { Logout } from '../state/auth/auth-state.actions';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { NcsChannel } from '../models/ncsChannel.model';
import { AddActivatedNcsChannel, DeleteActivatedNcsChannel, UpdateActivatedNcsChannel } from '../state/ncsChannels/ncsChannels-state.actions';

const API_URL_PREFIX = environment.apiUrl;

@Injectable()
export class NcsChannelsService implements OnDestroy {

  // Subscription to Logout Action being dispateched
  private logoutSubscription: Subscription;

  // Data subscription used by profile detail components
  private activatedNcsChannelsSubscription: Subscription;

  constructor(
    private actions: Actions,
    private afs: AngularFirestore,
    private http: HttpClient,
    private store: Store) {
      // set up subscription to Logout action
    this.logoutSubscription = this.actions.pipe(ofActionDispatched(Logout)).subscribe(() => {
      this.cancelActivatedNcsChannelsSubscription();
    });
  }

  /**
   * Subscribe to activated ncschannels for a venue
   *
   * @param organization The organization ID used to query ncschannels
   * @param venueId The venue ID used to query ncschannels
   */
  fetchActivatedNcsChannels(organization: string, venueId: string, clearingProfiles: Array<{beaconId: string, channelId: number}>) {
    if (!this.activatedNcsChannelsSubscription || this.activatedNcsChannelsSubscription.closed) {
      const channelsCollection = `organizations/${organization}/venues/${venueId}/ncsChannels`;
      const currentTime = new Date().getTime();
      const provisioningTimeLimit = currentTime - 15 * 60 * 1000;
      const collection = this.afs.collection<NcsChannel>(channelsCollection,
      ref => ref
        .where('profileId', '==', null)
        .where('provisionTime', '>=', provisioningTimeLimit)
      );
    this.activatedNcsChannelsSubscription = collection.stateChanges()
    .pipe( map( ncschannels => ncschannels.map (currentNcsChannel => {
      const data = currentNcsChannel.payload.doc.data() as NcsChannel;
      const action = currentNcsChannel.type;
      const id = currentNcsChannel.payload.doc.id;
      return { id, action, ...data } ;
    }))).subscribe( (ncschannels: NcsChannel[]) => {
      ncschannels.forEach(ncsChannel => {
        const clearDevice = clearingProfiles.find(dev => dev.beaconId === ncsChannel.beaconId && dev.channelId === ncsChannel.channelId);
        if (!clearDevice) {
          if (ncsChannel.action === 'added') {
            this.store.dispatch(new AddActivatedNcsChannel(ncsChannel));
          } else if (ncsChannel.action === 'removed') {
            this.store.dispatch(new DeleteActivatedNcsChannel(ncsChannel.id));
          } else if (ncsChannel.action === 'modified') {
            this.store.dispatch(new UpdateActivatedNcsChannel(ncsChannel));
          }
        }
      });
    }, error => {
      console.error(error);
    });
    }
  }
  
  /**
   * 
   * @param organization the org id
   * @param venueId the venue id
   * @param beaconId the document beacon id
   * @param channelId the document channel id
   */
  async resetNcsChannel(organization: string, venueId: string, beaconId: string, channelId: number) {
    if (venueId !== 'Playground') {
      try {
        const resetUrl = API_URL_PREFIX + '/organizations/' + organization + '/venues/' + venueId +'/ncsChannels/' + beaconId + '/' + channelId + '/reset';
        await this.http.put(resetUrl, {}).toPromise();
      } catch (error) {
        console.error('Failed to reset ncs Channel document:', error);
      }
    }
  }

  /**
   * Get a ncsChannel document from the root tags collection
   *
   * @param organization The organization ID of the ncsChannel document
   * @param venueId The venue ID of the ncsChannel document
   * @param docId The ID of the ncsChannel document
   */
  fetchNcsChannelDocument(organization: string, venueId: string, docId: string) {
    const channelsCollection = `organizations/${organization}/venues/${venueId}/ncsChannels`;
    return this.afs.collection(channelsCollection).doc(docId).get();
  }

  /**
   * Unsubscribe from changes to activated ncschannels
   */
  cancelActivatedNcsChannelsSubscription() {
    return new Promise((resolve) => {
      if (this.activatedNcsChannelsSubscription && !this.activatedNcsChannelsSubscription.closed) {
        this.activatedNcsChannelsSubscription.unsubscribe();
      }
      resolve(true);
    });
  }

  /**
   * Unsubscribe from all service subscriptions
   */
  ngOnDestroy() {
    this.cancelActivatedNcsChannelsSubscription();
    if (this.logoutSubscription) {
      this.logoutSubscription.unsubscribe();
    }
  }
}

