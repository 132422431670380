<div class="wrapper">
<div class="unwrap">
  <div class="bg-cover">
      <div class="container container-md pv-lg">
          <div class="text-center mb-lg pb-lg">
              <div class="h1 text-bold">Tenera Care Privacy Policy</div>
          </div>
      </div>
  </div>
</div>
<div class="row">
  <div class="privacy">

            <h3>Overview</h3>
            <p>
              Your privacy is extremely important to us. This privacy policy explains the personal data GeoNavo processes, how GeoNavo processes it and for what purposes. This includes all information collected in our platform; as well as the privacy of information entrusted to us by business partners, prospects and others we seek information from and/or contact us through our websites,
              <a href="http://www.tenera.care" target="_blank">www.tenera.care</a> & <a href="http://www.geonavo.com">www.geonavo.com</a>.
            </p><p>
              <b>As privacy laws and practices evolve, we will amend this Policy from time to time. While we will endeavour to give reasonable notice of such changes, we do reserve the right, where necessary, to do so without prior notice.</b>
            </p>
            <br>
            <h3>What is considered private?</h3>
            <p>
              Information that is used by a government authority, financial institution or insurance carrier to
              distinguish a person from other individuals (e.g., social security number, social insurance
              number, credit card information, or insurance policy number) is private. Such information can
              be used to identify an individual (e.g., a person who works at a healthcare facility, or a resident
              or patient in a healthcare facility). Certain information may be used to contact a person directly
              (e.g., an email address, home mailing address or telephone number). Depending on the
              jurisdiction, the above identifiers are considered to be Personal Information (“PI”), Personally
              Identifiable Information (“PII”), Sensitive Personal Information (“SPI”) or a similar term, and it is
              private. An individual’s business contact information and business title generally are exempt
              from privacy laws. Information about an individual’s health, including insurance and billing
              information, is also considered – depending on the jurisdiction – to be PI, Protected Health
              Information (“PHI”), Personal Health Information (also known as “PHI”), Individually Identifiable
              Health Information (“IIHI”) or a similar term, and it also is private
            </p><p>
              For the remainder of this Policy, we will refer to all PI, PII, SPI, PHI, IIHI, and “Health Information” as “Personal Information” unless we specifically note otherwise. If we wish to refer only to information about a specific individual’s health but not to other forms of Personal Information, we will refer to “PHI.”
            </p><p>
              This Policy also will apply to non-personal information if such information can be used in combination with other Personal Information or non-personal information to identify an individual.
            </p><p>
              Please be aware that this Policy only covers information manually submitted to, or automatically collected by, us through use of the Site and/or the Services. If you contact or exchange information with another GeoNavo customer or business partner in person or through a means other than through the Site or Services, such activity is not covered by this Policy. Additionally, if you are not a customer or a business partner of GeoNavo by way of written agreement, and are contacting us out of interest in the Services, a business partnership or a job opportunity, please be aware that the information that you share with us is not covered by this Policy, unless required by law.
            </p>
            <br>
            <h3>What Personal Information do we collect from users?</h3>
            <p>For use with the Tenera Care Platform, we may request your first name, last name, email address, phone number and password.
              We collect Personal Information when a user account is created and when users sign in to the Tenera Care Console or the Care
              Assistant mobile app. The information that we request will be retained by us and used as described in this Privacy Policy.
            </p>
            <br>
            <h3>Purpose of Collecting Data</h3>
            <p>GeoNavo's Tenera Care Platform is a cloud-based Software-as-a-Service (SaaS) platform designed to provide seniors a safer
              environment to live. We primarily collect Personal Information as necessary to communicate with you and/or to provide the
              Services, support the platform and for research and development purposes to improve the overall performance and features of
              the platform.
            </p>
            <br>
            <h3>Security, Threats & Breach Notification</h3>
              <p>Our Services have physical, administrative and technical security measures in place to protect
                against the loss, misuse, unauthorized access and alteration of data and Personal Information
                under our direct control. When the Services are accessed using current browser technology,
                Secure Socket Layer (“SSL”) technology protects information using both server authentication
                and data encryption to help ensure that data is safe, secure, and available only to you.
              </p><p>
                We are committed to educating our staff about the protection of Personal Information, and the
                importance of compliance with relevant privacy legislation and company policies. Employees
                and contractors are required to sign confidentiality agreements.
              </p><p>
                These safeguards help prevent unauthorized access, maintain data accuracy, and ensure the appropriate use of Personal Information; however, it is important to remember that no system can guarantee 100% security at all times. In the event that we detect a threat to security or a security vulnerability, we may attempt to contact you to recommend protective measures.
              </p>
              <br>
            <h3>Retention & Deletion</h3>
              <p>
                GeoNavo will retain Personal Information: as necessary for the purposes outlined in this Policy;
                for as long as a customer account remains active; as required to manage and administer the
                Services; as required to carry out legal responsibilities (e.g., legal holds and other legal
                procedures); to resolve a dispute (including enforcement of a contract); or, as communicated to
                you at the time of collection. After all applicable retention periods have expired, we will delete
                or destroy your Personal Information in a manner designed to ensure that it cannot be
                reconstructed or read. If, at any time, it is not feasible for us to delete or destroy your Personal
                Information, we will continue using the same safeguards of protection and security outlined in
                this Policy and related subordinate policies, for as long as it cannot be destroyed.
              </p>
              <br>
            <h3>Contact us</h3>
            <p>
              If you believe your Personal Information has been used in a way that is inconsistent with this
              Policy or your preferences, or if you have questions please contact us by emailing
              <a href="mailto:support@tenera.care">support@tenera.care</a> or by mailing us:
            </p>
              26 Oxer Close<br/>
              Hammonds Plains, Nova Scotia<br/>
              Canada<br/>
              B4B 2B8<br/>
            </div>
    </div>
  </div>
