import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Injectable, OnDestroy } from '@angular/core';
import { map } from 'rxjs/operators';
import { Navigate } from '@ngxs/router-plugin';
import { Observable, Subscription } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Logout } from '../../shared/state/auth/auth-state.actions';
import { OptionItem } from '../../shared/models/option-item.model';
import { SetFloorData, SetFloorGeofences, SetProfileDetail } from './profile-detail/state/profile-detail-state.actions';
import { SetProfileList } from './profile-list/state/profile-list-state.actions';
import { ProfileType } from './models/profile-type/profile-type.model';
import { Profile } from './models/profile.model';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { ResidentProfileType } from './models/profile-type/resident-profile-type.model';
import { AssetProfileType } from './models/profile-type/asset-profile-type.model';
import { VisitorProfileType } from './models/profile-type/visitor-profile-type.model';
import { StaffProfileType } from './models/profile-type/staff-profile-type.model';
import { Venue } from '../../shared/models/venue.model';
import { VisitReason } from '../../shared/models/rules/enums/visit-reason.enum';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';
import { ProfileIncidentReport } from './models/profile-incident-report.model';
import { SetProfileIncidentReport } from './profile-incident-report/state/profile-incident-report-state.actions';
import { Geofence } from '../../shared/models/geofence.model';
import { ProfileChart } from './models/profile-chart.model';
import { Adl } from '../../shared/models/adl.model';
import { SetAdls, SetProfileChart } from './profile-chart/state/profile-chart-state.actions';
import { ProximityOptionItem } from './models/profile-type/proximity-option-item.model';
import { AuthState } from '../../shared/state/auth/auth.state';
import { ProfileListState } from './profile-list/state/profile-list.state';
import { LatestPosition } from '../../shared/models/position.model';
import { FixtureProfileType } from './models/profile-type/fixture-profile-type.model';
import { Floor } from '../dashboard/map/models/floor.model';

const API_URL_PREFIX = environment.apiUrl;
const FLOORS_URL_PREFIX = environment.apiUrl + '/organizations/';
const GLOBAL_ORG = 'Tenera';
const QUERY_ARRAY_LIMIT = 10;

@Injectable()
export class ProfilesService implements OnDestroy {

  // Subscriptions to Actions being dispateched
  private logoutSubscription: Subscription;

  // Subscription used by profile list component
  private profileListSubscription: Subscription[] = [];

  // Subscription used by profile detail component
  private profileDetailSubscription: Subscription;

  // Subscription for profile chart data
  private incidentReportSubscription: Subscription;
  private profileChartSubscription: Subscription;

  // Subscription to ADLs
  private adlSubscription: Subscription;

  private profileTypes: ProfileType[];
  
  constructor(
    private actions: Actions,
    private afs: AngularFirestore,
    private storage: AngularFireStorage,
    private http: HttpClient,
    private store: Store) {

    // set up subscription to Logout action
    this.logoutSubscription = this.actions.pipe(ofActionDispatched(Logout)).subscribe(() => {
      this.cancelFirebaseSubscriptions();
    });
  }

  /**
   * Lookup a specific profile type
   *
   * @param typeId id of profile type to fetch
   */
  getProfileType(typeId: string) {
    const profileTypes = this.getProfileTypes();

    // find the profile type based on the url param
    return profileTypes.find(({id}) => id === typeId);
  }

  /**
   * Get all supported profile types
   */
  getProfileTypes() {
    if (!this.profileTypes) {
      this.profileTypes = [
        AssetProfileType.getInstance(),
        ResidentProfileType.getInstance(),
        StaffProfileType.getInstance(),
        VisitorProfileType.getInstance(),
        FixtureProfileType.getInstance(),
      ];
    }

    // find the profile type based on the url param
    return this.profileTypes;
  }

  /**
   * Subscribe to profiles firestore collection changes
   *  and update profiles list in state on change
   *
   * @param organization The selected organization
   * @param unitIds The IDs of the selected units
   * @param profileType The type of profiles to fetch
   */
  fetchProfileList(organization: string, unitIds: string[], profileType: string, profileStatus: string): void {
    for (let i=0; i<unitIds.length; i+=QUERY_ARRAY_LIMIT) {
      const currentUnitIds = unitIds.slice(i, i + QUERY_ARRAY_LIMIT);
      const profilesCollection = this.afs.collection<Profile>('organizations/' + organization + '/profiles',
        ref => ref.where('profileType', '==', profileType)
                  .where('active', '==', true)
                  .where('profileStatus', '==', profileStatus)
                  .where('units', 'array-contains-any', currentUnitIds));
      this.profileListSubscription.push(profilesCollection.snapshotChanges()
        .pipe(map (profiles => {
          return profiles.map(profileDoc => {
            const data = profileDoc.payload.doc.data() as Profile;
            const id = profileDoc.payload.doc.id;
            return {id, ...data};
          });
        })).subscribe( (profiles: Profile[]) => {
          const existing = this.store.selectSnapshot(ProfileListState.profileList);
          const previouslyAdded = existing.filter(p => {
            return !profiles.find(prof => {
              return prof.id === p.id;
            }) && !p.units.find(u => currentUnitIds.includes(u));
          });
          this.store.dispatch(new SetProfileList([...profiles, ...previouslyAdded]));
        }, error => {
          console.error(error);
          // TODO dispatch error and display error in cnnsole
        }));
      }
  }

  /**
   * Subscribe to profiles firestore collection changes
   *  and update profiles list in state on change
   *
   * @param organization The selected organization
   * @param venueId The IDs of the selected venue
   * @param profileType The type of profiles to fetch
   */
  fetchUnitlessFixtureList(organization: string, venueId: string,  profileStatus: string): void {

      const profilesCollection = this.afs.collection<Profile>('organizations/' + organization + '/profiles',
        ref => ref.where('profileType', '==', 'fixture')
                  .where('active', '==', true)
                  .where('profileStatus', '==', profileStatus)
                  .where('venueId', '==', venueId)
                  .where('units', '==', []));
      this.profileListSubscription.push(profilesCollection.snapshotChanges()
        .pipe(map (profiles => {
          return profiles.map(profileDoc => {
            const data = profileDoc.payload.doc.data() as Profile;
            const id = profileDoc.payload.doc.id;
            return {id, ...data};
          });
        })).subscribe( (profiles: Profile[]) => {
          const existing = this.store.selectSnapshot(ProfileListState.profileList);
          const previouslyAdded = existing.filter(p => {
            return !profiles.find(prof => {
              return prof.id === p.id;
            }) && p.units.length > 0;
          });
          this.store.dispatch(new SetProfileList([...profiles, ...previouslyAdded]));
        }, error => {
          console.error(error);
          // TODO dispatch error and display error in cnnsole
        }));
      
  }

  /**
   * Subscribe to positions firestore collection changes
   *
   * @param organization The selected organization
   */
  fetchPositionList(organization: string): Observable<OptionItem[]> {
    const positionsCollection = this.afs.collection<{name: string}>('organizations/' + organization + '/positions');
    return positionsCollection.snapshotChanges().pipe(map (positionDocs => {
      return positionDocs.map(profileDoc => {
        const doc = profileDoc.payload.doc;
        return {
          label: doc.data().name,
          value: doc.id,
        } as OptionItem;
      });
    }));
  }

  /**
   *  Get a list of resident IDs and names to populate the
   *  target resident for the proximity rule
   *
   * @param organization The selected organization
   * @param venueId The ID of the venue containing the selected unit
   * @param unitId The ID of the selected unit
   * @param profileId The ID of the profile for which the list is being populated
   */
  fetchProximityNames(organization: string, venueId: string, unitId: string, profileId: string): Promise<OptionItem[]> {
    return new Promise(async (resolve, reject) => {
      const profiles: ProximityOptionItem[] = [];
      profiles.push({ label: '* All Residents *', value: 'ALL_RESIDENTS', unitId: '*' });
      profiles.push({ label: '* All Visitors *', value: 'ALL_VISITORS', unitId: '*' });
      profiles.push({ label: '* All Residents / Staff / Visitors *', value: 'ALL_RESIDENTS_STAFF_VISITORS', unitId: '*' });
      this.afs.collection<Profile>('organizations/' + organization + '/profiles',
      ref => ref.where('venueId', '==', venueId)
                .where('profileStatus', '==', 'enabled')
                .where('active', '==', true)
                .where('profileType', '==', 'resident'))
      .get().subscribe(querySnapshot => {
        querySnapshot.forEach(document => {
          if (document.id.toString() !== profileId) {
            const name = document.data().profileData.firstName + ' ' + document.data().profileData.lastName;
            const profileUnit = document.data().units[0];
            profiles.push({ value: document.id, label: name, unitId: profileUnit });
          }
        });
        profiles.sort((a, b) => (a.label.indexOf('*') > -1 || a.label > b.label) ? 1 : -1);
        resolve(profiles);
      }, error => {
        reject(error);
      });
    });
  }

  /**
   * Subscribe to changes to a profile document
   *  and update profile detail in state on change
   *
   * @param organization The organization associated with the venue
   * @param docId The firestore document identifier
   */
  fetchProfileDetail(organization: string, docId: string): void {
    const profilesCollection = this.afs.collection<Profile>('organizations/' + organization + '/profiles').doc(docId);
    this.profileDetailSubscription = profilesCollection.snapshotChanges()
    .pipe(map (profile => {
      if (profile.payload.data()) {
        const data = profile.payload.data() as Profile;
        const id = profile.payload.id;
        if (!data.profileData.reasonType) {
          data.profileData.reasonType = VisitReason.OTHER;
        }
        return {id, ...data};
      } else { // document does not exist, so return user to profile list
        this.store.dispatch(new Navigate(['/profiles']));
        return null;
      }
    })).subscribe( (profile: Profile) => {
      this.store.dispatch(new SetProfileDetail(profile));
    }, error => {
      console.error(error);
    });
  }


  /**
   * Fetch a floor
   *
   * @param organization The organization associated with the floor
   * @param venueId The venue associated with the floor
   * @param floorId The floor identifier
   */
  fetchFloor(organization: string, venueId: string, floorId: string): void {
    const floorUrl = FLOORS_URL_PREFIX + organization + '/venues/' + venueId + '/floors/' + floorId;
    this.http.get<Floor>(floorUrl).subscribe(floor => {
      this.store.dispatch(new SetFloorData(floor));
    });
  }

  /**
   * Fetch floor geofences
   *
   * @param organization The organization associated with the floor
   * @param venueId The venue associated with the floor
   * @param unitId The unit identifier
   * @param floorId The floor identifier
   */
  fetchUnitGeofences(organization: string, venueId: string, unitId: string, floorId: string): void {
    const geofencesUrl = API_URL_PREFIX + '/organizations/' + organization + '/venues/' + venueId + '/units/' + unitId + '/floors/' + floorId + '/geofences';
    try {
      this.http.get<{geofences: Geofence[]}>(geofencesUrl).subscribe(geoData => {
        this.store.dispatch(new SetFloorGeofences(geoData.geofences));
      });
    } catch (error) {
      console.log(error);
    }
  }

/**
   * Fetch the chart for a given profile and date
   *
   * @param organization The organization associated with the profile
   * @param venueId The selected venue ID
   * @param profileId The profile ID
   * @param chartDate the date of the chart in YYYY-MM-DD format
   */
  fetchProfileChart(organization: string, venueId: string, profileId: string, chartDate: string): void {
    const chartCollection = this.afs.collection<ProfileChart>('organizations/' + organization + '/venues/' + venueId + '/adlCharts/',
      ref => ref.where('profileId', '==', profileId)
                .where('date', '==', chartDate));
    this.profileChartSubscription = chartCollection.snapshotChanges()
      .pipe(map (charts => {
        return charts.map(profileDoc => {
          const data = profileDoc.payload.doc.data() as ProfileChart;
          const id = profileDoc.payload.doc.id;
          return {id, ...data};
        });
      })).subscribe( (charts: ProfileChart[]) => {
        if (charts.length > 0) {
          this.store.dispatch(new SetProfileChart(charts[0]));
        }
      }, error => {
        console.error(error);
      });
  }

  /**
   * Fetch the incident reports for a given profile and date
   *
   * @param organization The organization associated with the profile
   * @param venueId The selected venue ID
   * @param profileId The profile ID
   * @param reportDate the date of the chart in YYYY-MM-DD format
   */
fetchIncidentReports(organization: string, venueId: string, profileId: string, reportDateToday: string, reportDateYesterday: string): void {
  const startDate = moment(reportDateYesterday + ' 00:00:00').valueOf();
  const endDate = moment(reportDateToday + ' 23:59:59').valueOf();
  const chartCollection = this.afs.collection<ProfileIncidentReport>('organizations/' + organization + '/venues/' + venueId + '/incidentReports/',
    ref => ref.where('profileId', '==', profileId)
              .where('reportedTime', '>=', startDate)
              .where('reportedTime', '<=', endDate)
              .orderBy('reportedTime', 'desc'));
  this.incidentReportSubscription = chartCollection.snapshotChanges()
    .pipe(map (charts => {
      return charts.filter(chart => !chart.payload.doc.data().deleted).map(profileDoc => {
        const data = profileDoc.payload.doc.data() as ProfileIncidentReport;
        const id = profileDoc.payload.doc.id;
        return {id, ...data};
      });
    })).subscribe( (reports: ProfileIncidentReport[]) => {
      this.store.dispatch(new SetProfileIncidentReport(reports));
    }, error => {
      console.error(error);
    });
}

  /**
   * Retrieve a staff list
   *  and update profiles list in state on change
   *
   * @param organization The selected organization
   * @param unitId The ID of the selected units
   */
   async fetchAssistingStaffList(organization: string, units: string[]): Promise<{ id: string; displayName: string; }[]> {
    const results = [];
    for (let i=0; i<units.length; i+=QUERY_ARRAY_LIMIT) {
      const currentUnitIds = units.slice(i, i + QUERY_ARRAY_LIMIT);
      const profilesCollection = this.afs.collection<Profile>('organizations/' + organization + '/profiles',
        ref => ref.where('profileType', '==', 'staff')
                  .where('active', '==', true)
                  .where('profileStatus', '==', 'enabled')
                  .where('units', 'array-contains-any', currentUnitIds));
      const profileList = await profilesCollection.get().toPromise();
      const profileDocs = profileList.docs.sort((a, b) => (a.data().profileData.lastName > b.data().profileData.lastName) ? 1 : ((b.data().profileData.lastName > a.data().profileData.lastName) ? -1 : 0));
      results.push(...profileDocs.map(profile => {
        const displayName = `${profile.data().profileData.firstName} ${profile.data().profileData.lastName}`;
        return {
          id: profile.id, displayName: displayName
        }
      }));
    }
    return results;
  }

  /**
   * add an incident report to firestore
   *
   * @param organization The organization associated with the profile
   * @param venueId The venue Id
   * @param profileId The profile associated with the report
   * @param incidentReport The report document
   */
  async addIncidentReport(organization: string, venueId: string, profileId: string, incidentReport: ProfileIncidentReport) {
    const updateUrl = API_URL_PREFIX + '/organizations/' + organization + '/venues/' + venueId + '/profile/' + profileId + '/incident-reports';
    try {
      const response = await this.http.post<{ message: string, chartId: string }>(updateUrl, incidentReport).toPromise();
      return null;
    } catch (error) {
      console.log(error);
      const message = error.error && error.error.message ? error.error.message : 'Chart update failed';
      return message;
    }
  }

  /**
   * Update the existing incident report
   *
   * @param organization The organization associated with the profile
   * @param venueId The venue Id
   * @param profileId The profile associated with the report
   * @param incidentReportId report id to update
   * @param incidentReport The update document
   */
  async updateIncidentReport(organization: string, venueId: string, profileId: string, incidentReportId: string, incidentReport: ProfileIncidentReport) {
    const updateUrl = API_URL_PREFIX + '/organizations/' + organization + '/venues/' + venueId + '/profile/' + profileId + '/incident-reports/' + incidentReportId;
    try {
      const response = await this.http.put<{ message: string, chartId: string }>(updateUrl, incidentReport).toPromise();
      return null;
    } catch (error) {
      console.log(error);
      const message = error.error && error.error.message ? error.error.message : 'Chart update failed';
      return message;
    }
  }

  /**
   * delete the existing incident report
   *
   * @param organization The organization associated with the profile
   * @param venueId The venue Id
   * @param profileId The profile associated with the report
   * @param incidentReportId report id to update
   */
  async deleteIncidentReport(organization: string, venueId: string, profileId: string, incidentReportId: string) {
    const updateUrl = API_URL_PREFIX + '/organizations/' + organization + '/venues/' + venueId + '/profile/' + profileId + '/incident-reports/' + incidentReportId;
    try {
      const response = await this.http.delete<{ message: string, chartId: string }>(updateUrl).toPromise();
      return null;
    } catch (error) {
      console.log(error);
      const message = error.error && error.error.message ? error.error.message : 'Chart update failed';
      return message;
    }
  }

  /* Update the adl chart values
   *
   * @param organization The organization associated with the profile
   * @param venueId The selected venue ID
   * @param profileId The profile ID
   * @param chartDate the date of the chart in YYYY-MM-DD format
   */
  async updateProfileChart(organization: string, venueId: string, profileId: string, chartDate: string, docUpdate: ProfileChart): Promise<any> {
    const updateUrl = API_URL_PREFIX + '/organizations/' + organization + '/venues/' + venueId + '/adl-charts/profile/' + profileId + '/date/' + chartDate;
    try {
      const response = await this.http.put<{ message: string, chartId: string }>(updateUrl, docUpdate).toPromise();
      return null;
    } catch (error) {
      console.log(error);
      const message = error.error && error.error.message ? error.error.message : 'Chart update failed';
      return message;
    }
  }

  /**
   * Update the onpassstatus field for a profile profile
   *
   * @param organization The organization associated with the profile
   * @param docId The profile document ID
   * @param status The status to set
   */
  async updateOnPassStatus(organization: string, docId: string, status: string): Promise<any> {
    const updateUrl = API_URL_PREFIX + '/organizations/' + organization + '/profiles/' + docId + '/onpass';
      try {
        const response = await this.http.put<{ message: string, profileId: string }>(updateUrl, {onpassstatus: status}).toPromise();
        return null;
      } catch (error) {
        console.log(error);
        const message = error.error && error.error.message ? error.error.message : 'Profile creation failed';
        return message;
      }
  }

  /**
   * Archive a lastestPosition document
   *
   * @param organization The organization associated with the profile
   * @param profileId The profile document ID
   */
  archiveRecord(organization: string, profileId: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const archiveUrl = API_URL_PREFIX + '/organizations/' + organization + '/profiles/' + profileId + '/archive';
      try {
        const response = await this.http.put<any>(archiveUrl, {}).toPromise();
        resolve(response.message);
      } catch (error) {
        console.log(error);
        const message = error.error && error.error.message ? error.error.message : 'Profile creation failed';
        reject(message);
      }
    });
  }

  /**
   * Determine whether a sensor is available to be assigned to a profile -
   *  promise resolves to true if the sensor is available, false otherwise
   *
   * @param organization The organization associated with the profiles collection
   * @param sensorId The sensor to be assigned
   */
  isSensorAvailable(organization: string, sensorId: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      this.afs.collection<Profile>('organizations/' + organization + '/profiles',
        ref => ref.where('sensorId', '==', sensorId))
      .get().subscribe(querySnapshot => {
        resolve(querySnapshot.empty);
      }, error => {
        reject(error);
      });
    });
  }

  /**
   * 
   * @returns a list of accessible units from the user access endpoint
   */
  async fetchAccessibleUnits(): Promise<string[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const userId = this.store.selectSnapshot(AuthState.userId);
        const getUrl = API_URL_PREFIX + '/users/access/' + userId;
        const response = await this.http.get<{ orgs: Array<any> }>(getUrl).toPromise();
        const units = [];
        for (const org of response.orgs) {
          for (const ven of org.venues) {
            for (const unit of ven.units) {
              units.push(unit.id);
            }
          }
        }
        resolve(units);
      } catch (error) {
        console.log(error);
        const message = error.error && error.error.message ? error.error.message : 'Profile creation failed';
        reject(message);
      }
    });
  }

  /**
   * Add a profile to firestore - promise resolves to the profile document ID
   *
   * @param organization The organization associated with the profiles collection
   * @param profile The profile to add
   */
  addProfile(organization: string, profile: Profile): Promise<string> {
    return new Promise(async (resolve, reject) => {
      const adddUrl = API_URL_PREFIX + '/organizations/' + organization + '/profiles';
      try {
        const response = await this.http.post<{ message: string, profileId: string }>(adddUrl, profile).toPromise();
        resolve(response.profileId);
      } catch (error) {
        console.log(error);
        const message = error.error && error.error.message ? error.error.message : 'Profile creation failed';
        reject(message);
      }
    });
  }

  /**
   * Save an profile photo - promise resolves to the file URL
   *
   * @param organization The organization associated with the profile
   * @param profileId The identifier of the profile represented by the photo
   * @param profilePhoto The image to store as profile photo
   * @param imageType Image type metadata
   * @param route If provided, the view to navigate to when complete
   */
  storeProfilePhoto(organization: string, profileId: string, profilePhoto: string, imageType: string): Promise<string> {
    return new Promise(async (resolve, reject) => {
      const base = await fetch(profilePhoto);
      const blob = await base.blob();

      const photoFile = new File([blob], profileId, {type: imageType});
      const {imagePost, options} = this.photoToMultiPartForm(photoFile);

      const photoUploadUrl = API_URL_PREFIX + '/organizations/' + organization + '/profiles/' + profileId + '/photo';
      try {
        const response = await this.http.put<{ message: string, photoUrl: string }>(photoUploadUrl, imagePost, options).toPromise();
        resolve(response.photoUrl);
      } catch (error) {
        console.log(error);
        const message = error.error && error.error.message ? error.error.message : 'Image upload failed';
        reject(message);
      }
    });
  }

  /**
   * Convert a photo to a multipart form object for json and binary transfer
   *
   * @param photo The Floor object
   */
   photoToMultiPartForm(photo: File) {
    const httpHeaders = new HttpHeaders({
      "enctype": "multipart/form-data",
      'Content-Type': 'multipart/form-data'
    })
    const imagePost = new FormData();

    if (photo) {
      imagePost.append('photoToUpload', photo);
    }

    const options = {
      headers: httpHeaders,
    };

    return {imagePost, options};
  }

  /**
   * Update the photoUrl field for a profile
   *
   * @param organization The organization associated with the profiles collection
   * @param docId The profile document ID
   * @param photoUrl The profile photo URL to set
   */
  async updatePhotoUrl(organization: string, docId: string, photoUrl: string): Promise<void> {
    return this.afs.collection('organizations/' + organization + '/profiles').doc(docId).update({'photoUrl': photoUrl});
  }

  /**
   * Update a profile in a firestore collection
   *
   * @param organization The organization associated with the profiles collection
   * @param docId The profile document ID
   * @param updates An object of properties and updated values
   */
  async updateProfile(organization: string, docId: string, updates: {}): Promise<any> {
    const updateUrl = API_URL_PREFIX + '/organizations/' + organization + '/profiles/' + docId;
      try {
        const response = await this.http.put<any>(updateUrl, updates).toPromise();
        return null;
      } catch (error) {
        console.log(error);
        const message = error.error && error.error.message ? error.error.message : 'Profile creation failed';
        return message;
      }
  }

  /**
   * Update a profile and linked user information in a firestore collection
   *
   * @param organization The organization associated with the profiles collection
   * @param docId The profile document ID
   * @param updates An object of properties and updated values
   */
  async updateLinkedProfile(organization: string, docId: string, updates: {}, locationAccess: string[]): Promise<any> {
    // return this.afs.collection('organizations/' + organization + '/profiles').doc(docId).update(updates);
    const updateUrl = API_URL_PREFIX + '/organizations/' + organization + '/profiles/' + docId + '/user';
      try {
        const response = await this.http.put<any>(updateUrl, {profile: updates, locationAccess: locationAccess}).toPromise();
        return null;
      } catch (error) {
        console.log(error);
        const message = error.error && error.error.message ? error.error.message : 'Profile creation failed';
        return message;
      }
  }

  /**
   * Delete a file from Firebase Storage
   *
   * @param organization The organization associated with the profiles collection
   * @param profileId The identifier of the photo to delete
   */
  deleteProfilePhoto(organization: string, profileId: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      const photoUploadUrl = API_URL_PREFIX + '/organizations/' + organization + '/profiles/' + profileId + '/photo';
      try {
        const response = await this.http.delete<any>(photoUploadUrl).toPromise();
        resolve();
      } catch (error) {
        console.log(error);
        const message = error.error && error.error.message ? error.error.message : 'Image delete failed';
        reject(message);
      }
    });
  }

  // DEPRECATED
  // /**
  //  * Delete a profile from a firestore collection
  //  *
  //  * @param organization The organization associated with the profiles collection
  //  * @param docId The sensor ID assigned to the profile
  //  */
  // async deleteProfileLatestPosition(organization: string, venueId: string, docId: string): Promise<void> {
  //   return this.afs.collection( 'organizations/' + organization + '/venues/' + venueId + '/latestPositions')
  //     .doc(docId).delete();
  // }

  /**
   * Delete a profile from a firestore collection
   *
   * @param organization The organization associated with the profiles collection
   * @param docId The profile document ID
   */
  async deleteProfile(organization: string, docId: string): Promise<any> {
    const deleteUrl = API_URL_PREFIX + '/organizations/' + organization + '/profiles/' + docId;
      try {
        const response = await this.http.delete<any>(deleteUrl).toPromise();
        return null;
      } catch (error) {
        console.log(error);
        const message = error.error && error.error.message ? error.error.message : 'Profile delete failed';
        return message;
      }
  }

  /**
   * Log out visitor
   *
   * @param organization The organization associated with the profiles collection
   * @param docId The profile document ID
   */
  async disableProfile(organization: string, docId: string): Promise<any> {
    const disableUrl = API_URL_PREFIX + '/organizations/' + organization + '/profiles/' + docId + '/disable';
    try {
      const response = await this.http.put<any>(disableUrl, {}).toPromise();
      return null;
    } catch (error) {
      console.log(error);
      const message = error.error && error.error.message ? error.error.message : 'Profile disable failed';
      return message;
    }
  }

  /**
   * Retrieve a profile from a firestore collection by custom id
   *
   * @param organization The organization associated with the profiles collection
   * @param idName The unique id field name
   * @param idVal The id value
   */
  fetchProfileByCustomId(organization: string, idName: string, idVal: string) {
    return this.afs.collection<Profile>('organizations/' + organization + '/profiles',
      ref => ref.where('profileData.' + idName, '==', idVal)).get();
  }

  /**
   * Retrieve a profile match based on firstname, last name and profile type
   *
   * @param organization The organization associated with the profiles collection
   * @param firstName the profile first name
   * @param lastName the profile last name
   * @param profileType the type of profile to check against
   */
  fetchProfilesByName(organization: string, firstName: string, lastName: string, profileType: string): Promise<Profile[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const matchingVisitors:Profile[] = [];
        const profileDocs = await this.afs.collection<Profile>('organizations/' + organization + '/profiles',
        ref => ref.where('profileType', '==', profileType)
        .where('active', '==', true)
        .where('profileData.firstName', '==', firstName)
        .where('profileData.lastName', '==', lastName)).get().toPromise();
        for (const profile of profileDocs.docs) {
          matchingVisitors.push({id: profile.id, ...profile.data()} as Profile)
        }
        resolve(matchingVisitors);
      } catch (error) {
        reject(error);
      }
    })
  }

  /**
   * Query venues for an organization
   *
   * @param organization The specified organization
   */
  fetchVenues(organization: string): Promise<Venue[]> {
    return new Promise(async (resolve, reject) => {

      // fetch deleted venues for this organization
      const deletedVenueIds = [];
      const venuesUrl = 'organizations/' + organization + '/venues';
      const deletedVenues = await this.afs.collection<any>(venuesUrl, ref => ref.where('status', '==', 'deleted')).get().toPromise();
      for (const deletedVenue of deletedVenues.docs) {
        deletedVenueIds.push(deletedVenue.id);
      }

      // fetch venue location documents for this organization
      const venueList: Venue[] = [];
      const locationsUrl = 'organizations/' + organization + '/locations';
      const venueLocations = this.afs.collection<any>(locationsUrl, ref => ref.where('type', '==', 'venue'));
      venueLocations.get().subscribe(async querySnapshot => {
        for (const currentVenue of querySnapshot.docs) {
          // if venue is not deleted, add it to the venue list
          if (!deletedVenueIds.includes(currentVenue.id)) {
            const venueName = currentVenue.data().name;
            const venueId = currentVenue.id;
            venueList.push({venueName, venueId});
          }
        }
        resolve(venueList);
      }, error => {
        reject(error);
      });
    });
  }

  /**
   * Query units for a venue
   *
   * @param organization The specified organizationId
   * @param venueId The specified venueId
   */
  async fetchUnits(organization: string, venueId: string): Promise<{name: string, id: string}[]> {
    try {
      const unitsUrl = 'organizations/' + organization + '/venues/' + venueId + '/units';
      const unitPositionsCollection = await this.afs.collection<any>(unitsUrl).get().toPromise();
      return unitPositionsCollection.docs.map(doc => {
        return {
          id: doc.id,
          name: doc.data().name,
          isShared: doc.data().isShared
        }
      }).filter(unit => !unit.isShared);
    } catch (error) {
      throw Error;
    }
  }
  
  /**
   * Query geofences for the selected unit
   *
   * @param organization The specified organizationId
   * @param venueId The specified venueId
   */
  async fetchGeofences(organization: string, venueId: string) {
    const geofences = [];
    const geofenceUrl = API_URL_PREFIX + '/organizations/' + organization + '/venues/' + venueId +  '/geofences';
    try {
      const response = await this.http.get<{geofences: Geofence[]}>(geofenceUrl).toPromise();
      geofences.push(...response.geofences);
    } catch (error) {
      console.log(error);
      return [];
    }
    return geofences.filter(geo => !['Bed', 'Toilet', 'Chair'].includes(geo.type)).sort((a, b) => {
      if (a.type === b.type) {
        return (a.name > b.name) ? 1 : -1;
      }
      return (a.type > b.type) ? -1 : 1;
    });
  }

  fetchAdls(): void {
    const adlsCollection = this.afs.collection<Adl>('adls');
    this.adlSubscription = adlsCollection.get().subscribe(adlDocs => {
      const adls: Adl[] = adlDocs.docs.map(adlDoc => {
        const data = adlDoc.data() as Adl;
        const id = adlDoc.id;
        return {id: id, ...data};
      });
      this.store.dispatch(new SetAdls(adls));
    });
  }

  /**
   * Subscribe to active position changes in a Firestore collection
   *  and emit the values to subscribers of positionsListener
   *
   * @param organization The selected organization
   * @param venueId The ID of the venue containing the selected unit
   * @param profileType The profile type
   */
  async fetchLatestPositionSnapshot(organization: string, venueId: string, profileType: string) {
    // subscribe to the position for the current profiles
    const unitPositionsCollection = await this.afs.collection<LatestPosition>
        ('organizations/' + organization + '/venues/' + venueId + '/latestPositions',
        ref => ref
          .where('profileType', '==', profileType)
        ).get().toPromise();

    return unitPositionsCollection.docs.map(pos => {return {id: pos.id, ...pos.data()}});
  }

  /**
   * checks if a beacon/channel id is assigned for clearing
   * @param organization org id
   * @param beaconId clear beacon id
   * @param channelId clear channel id
   * @param profileId profile id
   * @returns boolean
   */
  async getClearDeviceInUse(organization: string, beaconId: string, channelId: number, profileId: string) {
    if (!beaconId || channelId === null) {
      return false;
    }
    // subscribe to the position for the current profiles
    const profileRef = await this.afs.collection<LatestPosition>
        ('organizations/' + organization + '/profiles',
        ref => ref
          .where('profileData.clearBeaconId', '==', beaconId)
          .where('profileData.clearChannelId', '==', channelId)
          .where('profileStatus', '==', 'enabled')
          .where('active', '==', true)
        ).get().toPromise();

    return profileRef.docs.map(doc => doc.id != profileId).length > 0;
  }

  /**
   * checks if a sensor id is assigned for clearing
   * @param organization org id
   * @param sensorId clear sensor id
   * @param profileId profile id
   * @returns boolean
   */
  async getClearSensorInUse(organization: string, sensorId: string, profileId: string) {
    if (!sensorId) {
      return false;
    }
    // subscribe to the position for the current profiles
    const profileRef = await this.afs.collection<LatestPosition>
        ('organizations/' + organization + '/profiles',
        ref => ref
          .where('profileData.clearSensorId', '==', sensorId)
          .where('profileStatus', '==', 'enabled')
          .where('active', '==', true)
        ).get().toPromise();

    return profileRef.docs.map(doc => doc.id != profileId).length > 0;
  }

  /**
   * gets all sensors assigned for clearing
   * @param organization org id
   * @param venueId venue id
   * @returns string[]
   */
  async getAllClearSensorsInUse(organization: string, venueId: string) {
    // subscribe to the position for the current profiles
    try {
      const profileRef = await this.afs.collection<LatestPosition>
        ('organizations/' + organization + '/profiles',
          ref => ref
            .where('profileType', '==', 'fixture')
            .where('profileStatus', '==', 'enabled')
            .where('venueId', '==', venueId)
            .where('active', '==', true)
          ).get().toPromise();

      return profileRef.docs.filter(prof => prof.data().profileData?.clearSensorId).map(doc => {
        return doc.data().profileData.clearSensorId;
      });
    } catch (error) {
      return [];
    }
  }

  /**
   * gets all beacon/channel ids assigned for clearing
   * @param organization org id
   * @param venueId venue id
   * @returns array
   */
  async getAllClearDevicesInUse(organization: string, venueId: string) {
    // subscribe to the position for the current profiles
    try {
      const profileRef = await this.afs.collection<LatestPosition>
        ('organizations/' + organization + '/profiles',
          ref => ref
            .where('profileType', '==', 'fixture')
            .where('profileStatus', '==', 'enabled')
            .where('venueId', '==', venueId)
            .where('active', '==', true)
          ).get().toPromise();

      return profileRef.docs.filter(prof => prof.data().profileData?.clearBeaconId && prof.data().profileData?.clearChannelId != null).map(doc => {
        return {
          beaconId: doc.data().profileData?.clearBeaconId, 
          channelId: doc.data().profileData?.clearChannelId
        };
      });
    } catch (error) {
      return [];
    }
  }

  /**
   * 
   * @param fileName the file name
   * @param data the data to store to a file
   */
  downloadFile(fileName: string, data: any) {
    // wrapped special chars in quotes
    data.forEach((row, i) => {
      row.forEach((el, j) => {
        if (el && (el.indexOf(",") > -1 || el.indexOf("\n") > -1)) {
          data[i][j] = '"' + el + '"';
        }
      });
    });
    const a = document.createElement('a');
    const blob = new Blob([data.map(e => e.join(",")).join("\n")], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  /**
   * Unsubscribe to all profile profile changes
   */
  cancelProfileListSubscription(): Promise<boolean> {
    return new Promise((resolve) => {
      // call unsubscribe if the subscription has been initialized and is not yet unsubscribed
      for (let i=0; i<this.profileListSubscription.length; i++) {
        if (this.profileListSubscription[i] && !this.profileListSubscription[i].closed) {
          this.profileListSubscription[i].unsubscribe();
        }
      }
      this.profileListSubscription = [];
      resolve(true);
    });
  }

  /**
   * Unsubscribe to changes to a single profile profile
   */
  cancelProfileDetailSubscription(): void {
    if (this.profileDetailSubscription && !this.profileDetailSubscription.closed) {
      this.profileDetailSubscription.unsubscribe();
    }
  }

  /**
   * Unsubscribe to changes to a incident reports
   */
  cancelIncidentReportSubscription(): void {
    if (this.incidentReportSubscription && !this.incidentReportSubscription.closed) {
      this.incidentReportSubscription.unsubscribe();
    }
  }

  /**
   * Unsubscribe to changes to a profile chart
   */
  cancelProfileChartSubscription(): void {
    if (this.profileChartSubscription && !this.profileChartSubscription.closed) {
      this.profileChartSubscription.unsubscribe();
    }
  }

  /**
   * Unsubscribe to adls
   */
  cancelAdlSubscription(): void {
    if (this.adlSubscription && !this.adlSubscription.closed) {
      this.adlSubscription.unsubscribe();
    }
  }

  /**
   * Unsubscribe from all Firebase subscriptions
   */
  cancelFirebaseSubscriptions(): void {
    this.cancelProfileListSubscription();
    this.cancelProfileDetailSubscription();
    this.cancelIncidentReportSubscription();
    this.cancelAdlSubscription();
    this.cancelProfileChartSubscription();
  }

  /**
   * Unsubscribe from all service subscriptions
   */
  ngOnDestroy(): void {
    this.cancelFirebaseSubscriptions();
    if (this.logoutSubscription) {
      this.logoutSubscription.unsubscribe();
    }
  }


}
