import { SortObj } from '../listview-state.model';

/**
 * Action dispatched to set search filter
 */
export class SetProfileFilter {
  static type = '[ProfileListview] SetProfileFilter';
  constructor(public filter: string) {}
}

/**
 * Action dispatched to set page index
 */
export class SetProfilePageIndex {
  static type = '[ProfileListview] SetProfilePageIndex';
  constructor(public pageIndex: number) {}
}

/**
 * Action dispatched to set page size
 */
export class SetProfilePageSize {
  static type = '[ProfileListview] SetProfilePageSize';
  constructor(public pageSize: number) {}
}

/**
 * Action dispatched to set sort order
 */
export class SetProfileSort {
  static type = '[ProfileListview] SetProfileSort';
  constructor(public sort: SortObj) {}
}

/**
 * Action dispatched to reset the ProfileListView state to default
 */
export class ResetProfileListView {
  static type = '[ProfileListview] ResetProfileListView';
}
