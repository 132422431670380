import { State, Action, StateContext, Selector } from '@ngxs/store';

import * as DashboardActions from './dashboard-state.actions';
import { DashboardStateModel } from './dashboard-state.model';
import { Injectable } from '@angular/core';
import { Floor } from '../map/models/floor.model';
import { MapProfile } from './../map/models/map-profile.model';
import { Notification } from '../../../shared/models/notification.model';

/**
 * State class for dashboard data and settings
 */
@State<DashboardStateModel>({
  name: 'dashboard',
  defaults: {
    dashboardNotifications: [],
    mapProfiles: [],
    assetsCount: 0,
    residentsCount: 0,
    staffCount: 0,
    visitorsCount: 0,
    displayAssets: true,
    displayResidents: true,
    displayStaff: true,
    displayVisitors: true,
    displayNotifications: true,
    displayNotificationList: true,
    displayDashboardMap: true
  }
})
@Injectable()
export class DashboardState {

  /**
   * Get the list of dashboard notifications
   *
   * @returns DashboardState.dashboardNotifications
   */
  @Selector()
  static dashboardNotifications(state: DashboardStateModel): Notification[] {
    return state.dashboardNotifications;
  }

  /**
   * Get the number of notifications on the map
   *
   * @returns DashboardState.dashboardNotifications.length
   */
  @Selector()
  static dashboardNotificationsCount(state: DashboardStateModel): number {
    const floorId = state.floorData.floorId;
    const notifications = state.dashboardNotifications.filter(notif => notif.floorId === floorId);
    return notifications.length;
  }

  /**
   * Get the list of map profiles
   *
   * @returns DashboardState.mapProfiles
   */
  @Selector()
  static mapProfiles(state: DashboardStateModel): MapProfile[] {
    return state.mapProfiles;
  }

  /**
   * Get the number of assets available on the map
   *
   * @returns DashboardState.assetsCount
   */
  @Selector()
  static assetsCount(state: DashboardStateModel): number {
    return state.assetsCount;
  }

  /**
   * Get the number of residents available on the map
   *
   * @returns DashboardState.residentsCount
   */
  @Selector()
  static residentsCount(state: DashboardStateModel): number {
    return state.residentsCount;
  }

  /**
   * Get the number of staff available on the map
   *
   * @returns DashboardState.staffCount
   */
  @Selector()
  static staffCount(state: DashboardStateModel): number {
    return state.staffCount;
  }

  /**
   * Get the number of visitors available on the map
   *
   * @returns DashboardState.visitorsCount
   */
  @Selector()
  static visitorsCount(state: DashboardStateModel): number {
    return state.visitorsCount;
  }

  /**
   * Whether to display asset icons on the map
   *
   * @returns DashboardState.displayAssets
   */
  @Selector()
  static displayAssets(state: DashboardStateModel): boolean {
    return state.displayAssets;
  }

  /**
   * Whether to display resident icons on the map
   *
   * @returns DashboardState.displayResidents
   */
  @Selector()
  static displayResidents(state: DashboardStateModel): boolean {
    return state.displayResidents;
  }

  /**
   * Whether to display staff icons on the map
   *
   * @returns DashboardState.displayStaff
   */
  @Selector()
  static displayStaff(state: DashboardStateModel): boolean {
    return state.displayStaff;
  }

  /**
   * Whether to display visitor icons on the map
   *
   * @returns DashboardState.displayVisitors
   */
  @Selector()
  static displayVisitors(state: DashboardStateModel): boolean {
    return state.displayVisitors;
  }

  /**
   * Whether to display notification icons on the map
   *
   * @returns DashboardState.displayNotifications
   */
  @Selector()
  static displayNotifications(state: DashboardStateModel): boolean {
    return state.displayNotifications;
  }

  /**
   * Whether to display active notification list
   *
   * @returns DashboardState.displayNotificationList
   */
  @Selector()
  static displayNotificationList(state: DashboardStateModel): boolean {
    return state.displayNotificationList;
  }

  /**
   * Whether to display active dashboard map
   *
   * @returns DashboardState.displayDashboardMap
   */
  @Selector()
  static displayDashboardMap(state: DashboardStateModel): boolean {
    return state.displayDashboardMap;
  }

  /**
   * Get the floor
   *
   * @returns DashboardState.floorData
   */
  @Selector()
  static floorData(state: DashboardStateModel): Floor {
    return state.floorData;
  }

  /**
   * Get the ID of the notificaton to highlight
   *
   * @returns DashboardState.highlightedNotificationId
   */
  @Selector()
  static highlightedNotificationId(state: DashboardStateModel): string {
    return state.highlightedNotificationId;
  }

  /**
   * Get the ID of the notificaton to zoom to
   *
   * @returns DashboardState.zoomNotificationId
   */
  @Selector()
  static zoomNotificationId(state: DashboardStateModel): string {
    return state.zoomNotificationId;
  }

  /**
   * Action handler - add a dashboard notification to state
   */
  @Action(DashboardActions.AddDashboardNotification)
  onAddDashboardNotification(ctx: StateContext<DashboardStateModel>, action: DashboardActions.AddDashboardNotification) {
    const state = ctx.getState();
    const dashboardNotifications = [...state.dashboardNotifications];
    dashboardNotifications.push(action.notification);
    dashboardNotifications.sort(function(a, b) {
      if (a.priority === b.priority) {
        return b.time - a.time;
      }
      return a.priority - b.priority;
    });
    ctx.patchState({
      dashboardNotifications
    });
  }

  /**
   * Action handler - delete a dashboard notification from state
   */
  @Action(DashboardActions.DeleteDashboardNotification)
  onDeleteDashboardNotification(ctx: StateContext<DashboardStateModel>, action: DashboardActions.DeleteDashboardNotification) {
    const state = ctx.getState();
    const dashboardNotifications = [...state.dashboardNotifications];
    const index = dashboardNotifications.findIndex(x => x.id === action.id);
    dashboardNotifications.splice(index, 1);
    ctx.patchState({
      dashboardNotifications
    });
  }

  /**
   * Action handler - update a dashboard notification in state
   */
  @Action(DashboardActions.UpdateDashboardNotification)
  onUpdateDashboardNotification(ctx: StateContext<DashboardStateModel>, action: DashboardActions.UpdateDashboardNotification) {
    const state = ctx.getState();
    const dashboardNotifications = [...state.dashboardNotifications];
    const index = dashboardNotifications.findIndex(x => x.id === action.notification.id);
    dashboardNotifications[index] = action.notification;
    dashboardNotifications.sort(function(a, b) {
      if (a.priority === b.priority) {
        return b.time - a.time;
      }
      return a.priority - b.priority;
    });
    ctx.patchState({
      dashboardNotifications
    });
  }

  /**
   * Action handler - add or update a map profile in state
   */
  @Action(DashboardActions.AddUpdateMapProfile)
  onAddUpdateMapProfile(ctx: StateContext<DashboardStateModel>, action: DashboardActions.AddUpdateMapProfile) {
    const state = ctx.getState();
    const profiles = [...state.mapProfiles];
    const index = profiles.findIndex(x => x.id === action.profile.id);
    if (index >= 0) {
      profiles[index] = action.profile;
    } else {
      profiles.push(action.profile);
      if (action.profile.layer === 'resident') {
        ctx.dispatch(new DashboardActions.IncrementResidentsCount());
      } else if (action.profile.layer === 'staff') {
        ctx.dispatch(new DashboardActions.IncrementStaffCount());
      } else if (action.profile.layer === 'visitor') {
        ctx.dispatch(new DashboardActions.IncrementVisitorsCount());
      } else if (action.profile.layer === 'asset') {
        ctx.dispatch(new DashboardActions.IncrementAssetsCount());
      }
    }
    profiles.sort(function(a, b) {
      if (a.text.toLowerCase() < b.text.toLowerCase()) {
        return -1;
      }
      if (a.text.toLowerCase() > b.text.toLowerCase()) {
        return 1;
      }
      return 0;
    });
    if (!(this.profileListsEqual(profiles, state.mapProfiles))) {
      ctx.patchState({
        mapProfiles: profiles
      });
    }
  }

  private profileListsEqual(a, b) {
    if (a.length !== b.length) {
      return false;
    }
    for (let i = 0; i < a.length; ++i) {
      if (a[i].id !== b[i].id || a[i].text !== b[i].text) {
        return false;
      }
    }
    return true;
  }

    /**
   * Action handler - delete a map profile from state
   */
  @Action(DashboardActions.DeleteMapProfile)
  onDeleteMapProfile(ctx: StateContext<DashboardStateModel>, { payload }: DashboardActions.DeleteMapProfile) {
    const state = ctx.getState();
    const profiles = [...state.mapProfiles];
    const index = profiles.findIndex(x => x.id === payload.profileId);
    if (index >= 0) {
      profiles.splice(index, 1);

      if (payload.profileType === 'resident') {
        ctx.dispatch(new DashboardActions.DecrementResidentsCount());
      } else if (payload.profileType === 'staff') {
        ctx.dispatch(new DashboardActions.DecrementStaffCount());
      } else if (payload.profileType === 'visitor') {
        ctx.dispatch(new DashboardActions.DecrementVisitorsCount());
      } else if (payload.profileType === 'asset') {
        ctx.dispatch(new DashboardActions.DecrementAssetsCount());
      }

      ctx.patchState({
        mapProfiles: profiles
      });
    }

  }

  /**
   * Action handler - increase assetsCount by 1
   */
  @Action(DashboardActions.IncrementAssetsCount)
  onIncrementAssetsCount(ctx: StateContext<DashboardStateModel>) {
    const state = ctx.getState();
    let count = state.assetsCount;
    count++;
    ctx.patchState({
      assetsCount: count
    });
  }

  /**
   * Action handler - decrease assetsCount by 1
   */
  @Action(DashboardActions.DecrementAssetsCount)
  onDecrementAssetsCount(ctx: StateContext<DashboardStateModel>) {
    const state = ctx.getState();
    let count = state.assetsCount;
    count--;
    ctx.patchState({
      assetsCount: count
    });
  }

  /**
   * Action handler - increase residentsCount by 1
   */
  @Action(DashboardActions.IncrementResidentsCount)
  onIncrementResidentsCount(ctx: StateContext<DashboardStateModel>) {
    const state = ctx.getState();
    let count = state.residentsCount;
    count++;
    ctx.patchState({
      residentsCount: count
    });
  }

  /**
   * Action handler - decrease residentsCount by 1
   */
  @Action(DashboardActions.DecrementResidentsCount)
  onDecrementResidentsCount(ctx: StateContext<DashboardStateModel>) {
    const state = ctx.getState();
    let count = state.residentsCount;
    count--;
    ctx.patchState({
      residentsCount: count
    });
  }

  /**
   * Action handler - increase staffCount by 1
   */
  @Action(DashboardActions.IncrementStaffCount)
  onIncrementStaffCount(ctx: StateContext<DashboardStateModel>) {
    const state = ctx.getState();
    let count = state.staffCount;
    count++;
    ctx.patchState({
      staffCount: count
    });
  }

  /**
   * Action handler - decrease staffCount by 1
   */
  @Action(DashboardActions.DecrementStaffCount)
  onDecrementStaffCount(ctx: StateContext<DashboardStateModel>) {
    const state = ctx.getState();
    let count = state.staffCount;
    count--;
    ctx.patchState({
      staffCount: count
    });
  }

  /**
   * Action handler - increase visitorsCount by 1
   */
  @Action(DashboardActions.IncrementVisitorsCount)
  onIncrementVisitorsCount(ctx: StateContext<DashboardStateModel>) {
    const state = ctx.getState();
    let count = state.visitorsCount;
    count++;
    ctx.patchState({
      visitorsCount: count
    });
  }

  /**
   * Action handler - decrease visitorsCount by 1
   */
  @Action(DashboardActions.DecrementVisitorsCount)
  onDecrementVisitorsCount(ctx: StateContext<DashboardStateModel>) {
    const state = ctx.getState();
    let count = state.visitorsCount;
    count--;
    ctx.patchState({
      visitorsCount: count
    });
  }

  /**
   * Action handler - toggle whether assets should be
   *  displayed on the map
   */
  @Action(DashboardActions.ToggleDisplayAssets)
  onToggleDisplayAssets(ctx: StateContext<DashboardStateModel>) {
    const state = ctx.getState();
    let shouldDisplay = state.displayAssets;
    shouldDisplay = !shouldDisplay;
    ctx.patchState({
      displayAssets: shouldDisplay
    });
  }

  /**
   * Action handler - toggle whether residents should be
   *  displayed on the map
   */
  @Action(DashboardActions.ToggleDisplayResidents)
  onToggleDisplayResidents(ctx: StateContext<DashboardStateModel>) {
    const state = ctx.getState();
    let shouldDisplay = state.displayResidents;
    shouldDisplay = !shouldDisplay;
    ctx.patchState({
      displayResidents: shouldDisplay
    });
  }

  /**
   * Action handler - toggle whether staff should be
   *  displayed on the map
   */
  @Action(DashboardActions.ToggleDisplayStaff)
  onToggleDisplayStaff(ctx: StateContext<DashboardStateModel>) {
    const state = ctx.getState();
    let shouldDisplay = state.displayStaff;
    shouldDisplay = !shouldDisplay;
    ctx.patchState({
      displayStaff: shouldDisplay
    });
  }

  /**
   * Action handler - toggle whether visitors should be
   *  displayed on the map
   */
  @Action(DashboardActions.ToggleDisplayVisitors)
  onToggleDisplayVisitors(ctx: StateContext<DashboardStateModel>) {
    const state = ctx.getState();
    let shouldDisplay = state.displayVisitors;
    shouldDisplay = !shouldDisplay;
    ctx.patchState({
      displayVisitors: shouldDisplay
    });
  }

  /**
   * Action handler - toggle whether active notifications
   *  should be displayed on the map
   */
  @Action(DashboardActions.ToggleDisplayNotifications)
  onToggleDisplayNotifications(ctx: StateContext<DashboardStateModel>) {
    const state = ctx.getState();
    let shouldDisplay = state.displayNotifications;
    shouldDisplay = !shouldDisplay;
    ctx.patchState({
      displayNotifications: shouldDisplay
    });
  }

  @Action(DashboardActions.ToggleDisplayNotificationList)
  onToggleDisplayNotificationList(ctx: StateContext<DashboardStateModel>) {
    const state = ctx.getState();
    let shouldDisplay = state.displayNotificationList;
    shouldDisplay = !shouldDisplay;
    ctx.patchState({
      displayNotificationList: shouldDisplay
    });
  }

  /**
   * Action handler - toggle whether active notifications
   *  should be displayed on the map
   */
  @Action(DashboardActions.ToggleDisplayDashboardMap)
  onToggleDisplayDashboardMap(ctx: StateContext<DashboardStateModel>) {
    const state = ctx.getState();
    let shouldDisplay = state.displayDashboardMap;
    shouldDisplay = !shouldDisplay;
    ctx.patchState({
      displayDashboardMap: shouldDisplay
    });
  }

  /**
   * Action handler - set the selected floor data
   */
  @Action(DashboardActions.SetFloorData)
  onSetFloorData(ctx: StateContext<DashboardStateModel>, action: DashboardActions.SetFloorData) {
    ctx.patchState({
      floorData: action.floor
    });
  }

  /**
   * Action handler - hightlight a notification in the
   *  active notifications panel
   */
  @Action(DashboardActions.HighlightNotification)
  onHighlightNotification(ctx: StateContext<DashboardStateModel>, action: DashboardActions.HighlightNotification) {
    ctx.patchState({
      highlightedNotificationId: action.id
    });
  }

  /**
   * Action handler - zoom to a notification on the map
   */
  @Action(DashboardActions.ZoomToNotification)
  onZoomToNotification(ctx: StateContext<DashboardStateModel>, action: DashboardActions.ZoomToNotification) {
    ctx.patchState({
      zoomNotificationId: action.id
    });
  }

    /**
   * Action handler - reset everything in the dashboard state except hidden sections
   */
    @Action(DashboardActions.ResetMapDisplays)
    onResetMapDisplays(ctx: StateContext<DashboardStateModel>) {
      ctx.patchState({
        mapProfiles: [],
        residentsCount: 0,
        staffCount: 0,
        visitorsCount: 0,
        assetsCount: 0,
        displayResidents: true,
        displayStaff: true,
        displayVisitors: true,
        displayAssets: true,
        displayNotifications: true,
      });
    }
  

  /**
   * Action handler - reset the dashboard notifictions to empty
   */
  @Action(DashboardActions.ResetDashboardNotifications)
  onResetDashboardNotifications(ctx: StateContext<DashboardStateModel>) {
    ctx.patchState({
      dashboardNotifications: [],
    });
  }

  /**
   * Action handler - reset the dashboard state to default
   */
  @Action(DashboardActions.ResetDashboardState)
  onResetDashboardState(ctx: StateContext<DashboardStateModel>) {
    ctx.setState({
      dashboardNotifications: [],
      mapProfiles: [],
      residentsCount: 0,
      staffCount: 0,
      visitorsCount: 0,
      assetsCount: 0,
      displayResidents: true,
      displayStaff: true,
      displayVisitors: true,
      displayAssets: true,
      displayNotifications: true,
      displayNotificationList: true,
      displayDashboardMap: true,
    });
  }


}
