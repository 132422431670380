<mat-dialog-content>
  <h1 mat-dialog-title>Select New Profile Method</h1>

  <mat-radio-group class="example-radio-group" [(ngModel)]="selectedMethod">
    <mat-radio-button class="example-radio-button" *ngFor="let method of possibleMethods" [value]="method.value" (change)="radioChange($event)">
      {{ method.viewValue }}
    </mat-radio-button>
  </mat-radio-group>
</mat-dialog-content>

<form [formGroup]='form' (ngSubmit)='submit(form)'>

    <mat-dialog-content *ngIf="selectedMethod === 'import'">
      <mat-form-field class="example-full-width">
        <input type="text" placeholder="Select a patient to import" aria-label="Select a patient to import" matInput [formControl]="patientControl" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="display" (optionSelected)='patientSelect($event.option.value)'>
          <mat-option *ngFor="let patient of filteredPatients | async" [value]="patient">
            {{patient.name}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </mat-dialog-content>
    <div *ngIf="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>

    <mat-dialog-actions>
      <button mat-button type='submit'>Confirm</button>
      <button mat-button type='button' mat-dialog-close>Cancel</button>
    </mat-dialog-actions>
  </form>
