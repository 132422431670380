import { Floor } from '../map/models/floor.model';
import { MapProfile } from '../map/models/map-profile.model';
import { Notification } from '../../../shared/models/notification.model';

/**
 * Action dispatched to add a dashboard notification to state
 */
export class AddDashboardNotification {
  static type = '[Dashboard] AddDashboardNotification';
  constructor(public notification: Notification) {}
}

/**
 * Action dispatched to delete a dashboard notification from state
 */
export class DeleteDashboardNotification {
  static type = '[Dashboard] DeleteDashboardNotification';
  constructor(public id: string) {}
}

/**
 * Action dispatched to update a dashboard notification in state
 */
export class UpdateDashboardNotification {
  static type = '[Dashboard] UpdateDashboardNotification';
  constructor(public notification: Notification) {}
}

/**
 * Action dispatched to add or update a map profile in state
 */
export class AddUpdateMapProfile {
  static type = '[Dashboard] AddUpdateMapProfile';
  constructor(public profile: MapProfile) {}
}

/**
 * Action dispatched to delete a map profile from state
 */
export class DeleteMapProfile {
  static type = '[Dashboard] DeleteMapProfile';
  constructor(public payload: { profileId: string, profileType: string }) {}
}

/**
 * Action dispatched to increase assetsCount by 1
 */
export class IncrementAssetsCount {
  static type = '[Dashboard] IncrementAssetsCount';
}

/**
 * Action dispatched to decrease assetsCount by 1
 */
export class DecrementAssetsCount {
  static type = '[Dashboard] DecrementAssetsCount';
}

/**
 * Action dispatched to increase residentsCount by 1
 */
export class IncrementResidentsCount {
  static type = '[Dashboard] IncrementResidentsCount';
}

/**
 * Action dispatched to decrease residentsCount by 1
 */
export class DecrementResidentsCount {
  static type = '[Dashboard] DecrementResidentsCount';
}

/**
 * Action dispatched to increase staffCount by 1
 */
export class IncrementStaffCount {
  static type = '[Dashboard] IncrementStaffCount';
}

/**
 * Action dispatched to decrease staffCount by 1
 */
export class DecrementStaffCount {
  static type = '[Dashboard] DecrementStaffCount';
}

/**
 * Action dispatched to increase visitorsCount by 1
 */
export class IncrementVisitorsCount {
  static type = '[Dashboard] IncrementVisitorsCount';
}

/**
 * Action dispatched to decrease visitorsCount by 1
 */
export class DecrementVisitorsCount {
  static type = '[Dashboard] DecrementVisitorsCount';
}

/**
 * Action to toggle whether assets should be
 *  displayed on the map
 */
export class ToggleDisplayAssets {
  static type = '[Dashboard] ToggleDisplayAssets';
}

/**
 * Action to toggle whether residents should be
 *  displayed on the map
 */
export class ToggleDisplayResidents {
  static type = '[Dashboard] ToggleDisplayResidents';
}

/**
 * Action to toggle whether staff should be
 *  displayed on the map
 */
export class ToggleDisplayStaff {
  static type = '[Dashboard] ToggleDisplayStaff';
}

/**
 * Action to toggle whether visitors should be
 *  displayed on the map
 */
export class ToggleDisplayVisitors {
  static type = '[Dashboard] ToggleDisplayVisitors';
}

/**
 * Action to toggle whether active notifications
 *  should be displayed on the map
 */
export class ToggleDisplayNotifications {
  static type = '[Dashboard] ToggleDisplayNotifications';
}

/**
 * Action to toggle whether active notification list section
 *  should be displayed
 */
export class ToggleDisplayNotificationList {
  static type = '[Dashboard] ToggleDisplayNotificationList';
}

/**
 * Action to toggle whether dashboard map
 *  should be displayed
 */
export class ToggleDisplayDashboardMap {
  static type = '[Dashboard] ToggleDisplayDashboardMap';
}

/**
 * Action dispatched to set the selected floor data
 */
export class SetFloorData {
  static type = '[Dashboard] SetFloorData';
  constructor(public floor: Floor) {}
}

/**
 * Action to hightlight a notification in the
 *  active notifications panel
 */
export class HighlightNotification {
  static type = '[Dashboard] HighlightNotification';
  constructor(public id: string) {}
}

/**
 * Action to zoom to a notification on the map
 */
export class ZoomToNotification {
  static type = '[Dashboard] ZoomToNotification';
  constructor(public id: string) {}
}

/**
 * Action dispatched to reset the map display to default
 */
export class ResetMapDisplays {
  static type = '[Dashboard] ResetMapDisplays';
}

/**
 * Action dispatched to reset the dashboard notifications
 */
export class ResetDashboardNotifications {
  static type = '[Dashboard] ResetDashboardNotifications';
}

/**
 * Action dispatched to reset the dashboard state to default
 */
export class ResetDashboardState {
  static type = '[Dashboard] ResetDashboardState';
}
