const screenfull = require('screenfull');
import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, Select } from '@ngxs/store';

import { ConsoleState } from './../../shared/state/console/console.state';
import { Logout } from '../../shared/state/auth/auth-state.actions';
import { NotificationsService } from './../../shared/services/notifications.service';
import { Organization } from './../../shared/models/organization.model';
import {
  SetIsLoading,
  SetSelectedOrganization,
  SetSelectedUnits,
  SetSelectedVenue } from './../../shared/state/console/console-state.actions';
import { SettingsService } from '../../core/settings/settings.service';
import { Unit } from '../../shared/models/unit.model';
import { Venue } from '../../shared/models/venue.model';
import { Notification } from '../../shared/models/notification.model';
import { PermissionService } from '../..//shared/services/permission.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  // Observables on console state selectors
  @Select(ConsoleState.activeNotificationsCount) activeNotificationsCount$: Observable<number>;
  @Select(ConsoleState.activeNotificationsCodeWhite) activeNotificationsCodeWhite$: Observable<number>;
  @Select(ConsoleState.orgAccessList) organizations$: Observable<Organization[]>;
  @Select(ConsoleState.selectedOrganizationName) selectedOrganizationName$: Observable<string>;
  @Select(ConsoleState.venueList) venues$: Observable<Venue[]>;
  @Select(ConsoleState.selectedVenueName) selectedVenueName$: Observable<string>;
  @Select(ConsoleState.units) units$: Observable<Unit[]>;
  @Select(ConsoleState.selectedUnitNames) selectedUnitNames$: Observable<string[]>;

  @ViewChild('fsbutton', { static: true }) fsbutton; // the fullscreen button

  pendingSelectedUnitIds: string[] = [];
  pendingChanges = false;

  constructor(
    private notificationsService: NotificationsService,
    public settings: SettingsService,
    public permissionService: PermissionService,
    private store: Store) {}

  ngOnInit() {
    const ua = window.navigator.userAgent;
    if (ua.indexOf('MSIE ') > 0 || !!ua.match(/Trident.*rv\:11\./)) {
      // Not supported under IE
      this.fsbutton.nativeElement.style.display = 'none';
    }

    // Switch fullscreen icon indicator
    const el = this.fsbutton.nativeElement.firstElementChild;
    screenfull.on('change', () => {
      if (el) {
        el.className = screenfull.isFullscreen ? 'fa fa-compress' : 'fa fa-expand-arrows-alt';
      }
    });
  }

  toggleCollapsedSideabar() {
    this.settings.toggleSidebar();
  }

  isCollapsedText() {
    return this.settings.getLayoutSetting('isCollapsedText');
  }

  toggleFullScreen(event) {
    if (screenfull.enabled) {
      screenfull.toggle();
    }
  }

  async toggleUnitSelected(unit: Unit) {
    if (!this.pendingChanges) {
      this.pendingSelectedUnitIds = [...this.store.selectSnapshot(ConsoleState.selectedUnitIds)];
      this.pendingChanges = true;
    }
    
    if (!this.pendingSelectedUnitIds.includes(unit.unitId)) {
      this.pendingSelectedUnitIds.push(unit.unitId);
    } else {
      this.pendingSelectedUnitIds = this.pendingSelectedUnitIds.filter(p => p != unit.unitId);
    }
  }

  onResetSelectedUnits() {
    this.pendingSelectedUnitIds = [];
    this.pendingChanges = false;
  }

  onUpdateSelectedUnits() {
    console.log('here', this.pendingSelectedUnitIds.length);
    if (this.pendingSelectedUnitIds.length > 0) {
      this.store.dispatch(new SetSelectedUnits(this.pendingSelectedUnitIds));
      this.pendingSelectedUnitIds = [];
      this.pendingChanges = false;
    }
  }

  setSelectedVenue(venue: Venue) {
    // only dispatch action if organization has changed
    if (this.store.selectSnapshot(ConsoleState.selectedVenueId) !== venue.venueId) {
      this.store.dispatch(new SetIsLoading(true));
      this.store.dispatch(new SetSelectedVenue(venue.venueId));
      this.pendingSelectedUnitIds = [];
      this.pendingChanges = false;
    }
  }

  setSelectedOrganization(organization: Organization) {
    // only dispatch action if organization has changed
    if (this.store.selectSnapshot(ConsoleState.selectedOrganization) !== organization.id) {
      this.store.dispatch(new SetIsLoading(true));
      this.store.dispatch(new SetSelectedOrganization(organization.id));
      this.pendingSelectedUnitIds = [];
      this.pendingChanges = false;
    }
  }

  logout() {
    this.store.dispatch(new Logout());
  }
}
