import { Organization } from './../../models/organization.model';
import { Permissions } from '../../models/permissions.model';
import { Unit } from '../../models/unit.model';
import { Venue } from '../../models/venue.model';
import { Notification } from '../../models/notification.model';

/**
 * Action dispatched to set the is loading attribute
 */
export class SetIsLoading {
  static type = '[Console] SetIsLoading';
  constructor(public isLoading: boolean) {}
}

/**
 * Action dispatched to set if push notifications should be requested
 */
export class SetPushNotificationRequest {
  static type = '[Console] SetPushNotificationRequest';
  constructor(public isBrowserPushNotifications: boolean) {}
}

/**
 * Action dispatched to set the console state with user permissions
 */
export class GetPermissionsSuccess {
  static type = '[Console] GetPermissionsSuccess';
  constructor(public permissions: Permissions) {}
}

/**
 * Action dispatched to subscribe to organizations
 */
export class FetchOrganizations {
  static type = '[Console] FetchOrganizations';
}

/**
 * Action dispatched to set the list of organizations
 *  that a user may access
 */
export class SetOrganizations {
  static type = '[Console] SetOrganizations';
  constructor(public organizationList: Organization[]) {}
}

/**
 * Action dispatched to set the selected organization
 */
export class SetSelectedOrganization {
  static type = '[Console] SetSelectedOrganization';
  constructor(public organizationId: string) {}
}

/**
 * Action dispatched to set the list of venues
 *  that a user may access
 */
 export class SetVenues {
  static type = '[Console] SetVenues';
  constructor(public venueList: Venue[]) {}
}

/**
 * Action dispatched to set the selected venue
 */
export class SetSelectedVenue {
  static type = '[Console] SetSelectedVenue';
  constructor(public venueId: string) {}
}

/**
 * Action dispatched to set the list of units
 *  that a user may access
 */
export class SetUnits {
  static type = '[Console] SetUnits';
  constructor(public unitList: Unit[]) {}
}

/**
 * Action dispatched to set the selected unit
 */
export class SetSelectedUnits {
  static type = '[Console] SetSelectedUnits';
  constructor(public unitIds: string[]) {}
}

/**
 * Action dispatched to set the list of staff positions
 *  for the organization
 */
export class SetStaffPositions {
  static type = '[Console] SetStaffPositions';
  constructor(public staffPositionMap: Map<string, string>) {}
}

/**
 * Action dispatched to increase the number of
 *  active notifications by one
 */
export class IncrementActiveNotificationsCount {
  static type = '[Console] IncrementActiveNotificationsCount';
}

/**
 * Action dispatched to decrease the number of
 *  active notifications by one
 */
export class DecrementActiveNotificationsCount {
  static type = '[Console] DecrementActiveNotificationsCount';
}

/**
 * Action dispatched to increase the number of
 *  active notifications by one
 */
export class IncrementActiveNotificationsCodeWhite {
  static type = '[Console] IncrementActiveNotificationsCodeWhite';
}

/**
 * Action dispatched to decrease the number of
 *  active notifications by one
 */
export class DecrementActiveNotificationsCodeWhite {
  static type = '[Console] DecrementActiveNotificationsCodeWhite';
}

/**
 * Action update the latest added code white notifications
 */
export class UpdateAddedCodeWhiteNotifications {
  static type = '[Console] UpdateAddedCodeWhiteNotifications';
  constructor(public addedCodeWhiteNotifications: Notification[]) {}
}

/**
 * Action update active code white
 */
export class AddCodeWhiteNotifications {
  static type = '[Console] AddCodeWhiteNotifications';
  constructor(public codeWhiteNotifications: Notification) {}
}

/**
 * Action remove code white
 */
export class RemoveCodeWhiteNotifications {
  static type = '[Console] RemoveCodeWhiteNotifications';
  constructor(public codeWhiteNotifications: Notification) {}
}

/**
 * Action dispatched to set the selected floor
 */
export class SetSelectedFloor {
  static type = '[Console] SetSelectedFloor';
  constructor(public floorId: string) {}
}

/**
 * Action to set rotation of the map
 */
export class SetMapNorthDirection {
  static type = '[Dashboard] SetMapNorthDirection';
  constructor(public mapNorthDirection: string) {}
}

/**
 * Action dispatched to reset the console state to default
 */
export class ResetConsoleState {
  static type = '[Console] ResetConsoleState';
}
