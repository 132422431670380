import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';
import { Subscription } from 'rxjs';

import { Logout, AutoAuth} from '../../shared/state/auth/auth-state.actions';
import { LoginService } from '../../shared/services/login.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, OnDestroy {
  isInvalid: boolean;
  valForm: UntypedFormGroup;
  errorMessage = '';
  paramSubscription: Subscription;
  constructor(
    private loginService: LoginService,
    private route: ActivatedRoute,
    private store: Store
  ) {}

  async ngOnInit() {
    this.loginService.usePccAuth();
    this.paramSubscription  = this.route.queryParamMap.subscribe(async params => {
      const code = params.get('code');
      const state = params.get('state');
      const error = await this.loginService.handlePccAuthentication(code, state);
      if (!error) {
        this.store.dispatch([
          new AutoAuth()
        ]);
      } else {
        this.errorMessage = error;
        this.store.dispatch(new Logout);
      }
    });
  }

  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
  }

}
