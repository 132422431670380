import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { take } from 'rxjs/operators';

import * as ConsoleActions from './console-state.actions';
import { ConsoleService } from '../../services/console.service';
import { ConsoleStateModel } from './console-state.model';
import { FloorListItem } from '../../models/floor-list-item.model';
import { Organization } from './../../models/organization.model';
import { NotificationsService } from '../../services/notifications.service';
import { Unit } from '../../models/unit.model';
import { Venue } from './../../models/venue.model';
import { Notification } from '../../models/notification.model';
import { VenueFeatureConfig } from '../../models/venue-feature-config.model';

/**
 * State class for console permissions and selected values
 */
@Injectable()
@State<ConsoleStateModel>({
  name: 'console',
  defaults: {
    activeNotificationsCount: 0,
    activeNotificationsCodeWhite: 0,
    addedCodeWhiteNotifications: [],
    codeWhiteNotifications: [],
    isLoading: false,
    isBrowserPushNotifications: false,
    orgAccessList: [],
    units: [],
    venueList: [],
    pushNotificationError: null,
    mapNorthDirection: 'up'
  }
})
export class ConsoleState {

  /**
   * Get the number of active notification for the selected unit
   */
  @Selector()
  static activeNotificationsCount(state: ConsoleStateModel): number {
    return state.activeNotificationsCount;
  }

  /**
   * Get the number of active code white notification for the selected unit
   */
  @Selector()
  static activeNotificationsCodeWhite(state: ConsoleStateModel): number {
    return state.activeNotificationsCodeWhite;
  }

  /**
   * Get the latest active code white notification for the selected unit
   */
  @Selector()
  static addedCodeWhiteNotifications(state: ConsoleStateModel): Notification[] {
    return state.addedCodeWhiteNotifications;
  }

  /**
   * Get the active code white notification for the selected unit
   */
  @Selector()
  static codeWhiteNotifications(state: ConsoleStateModel): Notification[] {
    return state.codeWhiteNotifications;
  }

  /**
   * Get whether the console is in the loading state
   *
   * @returns ConsoleState.isLoading
   */
  @Selector()
  static isLoading(state: ConsoleStateModel): boolean {
    return state.isLoading;
  }

  /**
   * Get the organizations access list
   *
   * @returns ConsoleState.orgAccess
   */
  @Selector()
  static orgAccessList(state: ConsoleStateModel): Organization[] {
    return state.orgAccessList;
  }

  /**
   * Get the user permissions
   */
  @Selector()
  static permissions(state: ConsoleStateModel): string[] {
    return state.permissions;
  }

  /**
 * Does the user have rule-editor permissions
 *
 * @returns boolean
 */
  @Selector()
  static isRuleEditor(state: ConsoleStateModel): boolean {
    return state.permissions.includes('rule-editor');
  }
   
  /**
 * Does the user org use push notifications
 *
 * @returns boolean
 */
  @Selector()
  static isPushNotificationUser(state: ConsoleStateModel): boolean {
    return state.isBrowserPushNotifications;
  }
   
  /**
 * Did an error occur subscribing to push notifications
 *
 * @returns boolean
 */
  @Selector()
  static pushNotificationError(state: ConsoleStateModel): string {
    return state.pushNotificationError;
  }

  /**
 * Does the user have user-editor permissions
 *
 * @returns boolean
 */
  @Selector()
  static isUserEditor(state: ConsoleStateModel): boolean {
    return state.permissions.includes('user-editor');
  }
   

  /**
   * Get the role of the logged in user
   *
   * @returns ConsoleState.role
   */
  @Selector()
  static role(state: ConsoleStateModel): string {
    return state.role;
  }

  /**
   * Get the selected floor
   */
  @Selector()
  static selectedFloor(state: ConsoleStateModel): FloorListItem {
    return state.selectedFloor;
  }

  /**
   * Get the floor ID of the selected floor
   */
  @Selector()
  static selectedFloorId(state: ConsoleStateModel): string {
    return state.selectedFloor.floorId;
  }

  /**
   * Get the name of the selected floor
   */
  @Selector()
  static selectedFloorName(state: ConsoleStateModel): string {
    return state.selectedFloor.floorName;
  }

  /**
   * Get the selected organization ID
   *
   * @returns ConsoleState.selectedOrganization.id
   */
  @Selector()
  static selectedOrganization(state: ConsoleStateModel): string {
    return state.selectedOrganization.id;
  }

  /**
   * Get the name of the selected organization
   *
   * @returns ConsoleState.selectedOrganization.name
   */
   @Selector()
   static selectedOrganizationName(state: ConsoleStateModel): string {
     return state.selectedOrganization.name;
   }

  /**
   * Get the name of the selected organization
   *
   * @returns ConsoleState.selectedOrganization.name
   */
  @Selector()
  static selectedOrganizationEnablePermissions(state: ConsoleStateModel): string[] {
    return state.selectedOrganization.enablePermissions;
  }

  /**
   * Get the selected unit
   *
   * @returns ConsoleState.selectedUnit
   */
  @Selector()
  static selectedUnits(state: ConsoleStateModel): Unit[] {
    return state.selectedUnits;
  }

  /**
   * Get the selected unit
   *
   * @returns ConsoleState.selectedUnit
   */
  @Selector()
  static selectedAndSharedUnitIds(state: ConsoleStateModel): string[] {
    let units = state.selectedUnits.map(u => u.unitId);
    for (const unit of state.selectedUnits) {
      if (unit.includeShared) {
        units.push(...unit.includeShared);
      }
    }
    units = Array.from(new Set(units));
    return units;
  }

  /**
   * Get the unit ID of the selected unit
   *
   * @returns ConsoleState.selectedUnit.unitId
   */
  @Selector()
  static selectedUnitIds(state: ConsoleStateModel): string[] {
    return state.selectedUnits.map(u => u.unitId);
  }

  /**
   * Get the name of the selected unit
   *
   * @returns ConsoleState.selectedUnit.name
   */
  @Selector()
  static selectedUnitNames(state: ConsoleStateModel): string[] {
    return state.selectedUnits.map(u => u.name);
  }

  /**
   * Get the available floors for the selected unit
   *
   * @returns ConsoleState.selectedUnit.floors
   */
  @Selector()
  static selectedUnitFloors(state: ConsoleStateModel): FloorListItem[] {
    const floorArray = [];
    for (const u of state.selectedUnits) {
      floorArray.push(...u.floors.filter(f => !floorArray.find(a => a.floorId === f.floorId)));
    }
    return floorArray.sort((a, b) => a.floorNumber - b.floorNumber);
  }

  /**
   * Get the selected venue ID
   *
   * @returns ConsoleState.selectedVenue.venueId
   */
  @Selector()
  static selectedVenueId(state: ConsoleStateModel): string {
    return state.selectedVenue.venueId;
  }

  /**
   * Get the selected venue featureConfig if it exists
   *
   * @returns ConsoleState.selectedVenue.featureConfig
   */
  @Selector()
  static selectedVenueFeatureConfig(state: ConsoleStateModel): VenueFeatureConfig {
    return state.selectedVenue.featureConfig ? state.selectedVenue.featureConfig : {
      isAdlChartsEnabled: false,
      isIncidentReportsEnabled: false,
      isCodeWhiteEnabled: false,
      isMandatoryProximityClear: false,
      proximityClearExcludedPositions: [],
      isCodeLabeledBlue: false,
      isWearablePressRequired: false,
      wearablePressSeconds: null,
    };
  }

  /**
   * Get the selected venue PCC fac Id
   *
   * @returns ConsoleState.selectedVenue.facId
   */
  @Selector()
  static selectedPccFacId(state: ConsoleStateModel): number {
    return state.selectedVenue.pccFacId;
  }

  /**
   * Get the name of the selected venue
   *
   * @returns ConsoleState.selectedVenue.venueName
   */
  @Selector()
  static selectedVenueName(state: ConsoleStateModel): string {
    return state.selectedVenue.venueName;
  }

  /**
   * Get the list of units that are available to the logged in user
   *  that are associated with the selected organization
   *
   * @returns ConsoleState.units
   */
  @Selector()
  static units(state: ConsoleStateModel): Unit[] {
    return state.units;
  }

  /**
   * Get the location of the logged in user
   *
   * @returns ConsoleState.userLocation
   */
  @Selector()
  static userLocation(state: ConsoleStateModel): string {
    return state.userLocation;
  }
  /**
   * Get the location access of the logged in user
   *
   * @returns ConsoleState.userLocationAccess
   */
  @Selector()
  static userLocationAccess(state: ConsoleStateModel): string[] {
    return state.userLocationAccess;
  }

  /**
   * Get the organization of the logged in user
   *
   * @returns ConsoleState.userOrganization
   */
  @Selector()
  static userOrganization(state: ConsoleStateModel): string {
    return state.userOrganization;
  }

  /**
  * Get the list of venues available to the logged in user
  *
  * @returns ConsoleState.venueList
  */
  @Selector()
  static venueList(state: ConsoleStateModel): Venue[] {
    return state.venueList;
  }

  /**
   * Get the north direction for the map
   *
   * @returns DashboardState.mapNorthDirection
   */
  @Selector()
  static mapNorthDirection(state: ConsoleStateModel): string {
    return state.mapNorthDirection;
  }

    /**
  * Get the list of venues available to the logged in user
  *
  * @returns ConsoleState.staffPositionMap
  */
    @Selector()
    static staffPositions(state: ConsoleStateModel): Map<string,string> {
      return state.staffPositionMap;
    }

  constructor(
    private consoleService: ConsoleService,
    private afMessaging: AngularFireMessaging,
    private notificationsService: NotificationsService) {}

  /**
   * Action handler - set the is loading attribute
   */
  @Action(ConsoleActions.SetIsLoading)
  onSetIsLoading(ctx: StateContext<ConsoleStateModel>, { isLoading }: ConsoleActions.SetIsLoading) {
    ctx.patchState({
      isLoading: isLoading
    });
  }

  /**
   * Action handler - set the console state with user permissions
   */
  @Action(ConsoleActions.GetPermissionsSuccess)
  onGetPermissionsSuccess(ctx: StateContext<ConsoleStateModel>, { permissions }: ConsoleActions.GetPermissionsSuccess) {
    ctx.patchState({
      permissions: permissions.permissions,
      role: permissions.role,
      userLocation: permissions.userLocation,
      userLocationAccess: permissions.userLocationAccess,
      userOrganization: permissions.userOrganization
    });
  }

  /**
   * Action handler - set whether the org uses push notification
   */
  @Action(ConsoleActions.SetPushNotificationRequest)
  onSetPushNotificationRequest(ctx: StateContext<ConsoleStateModel>, { isBrowserPushNotifications }: ConsoleActions.SetPushNotificationRequest) {
    ctx.patchState({
      isBrowserPushNotifications: isBrowserPushNotifications
    });
  }

  /**
   * Action handler - subscribe to organizations
   */
  @Action(ConsoleActions.FetchOrganizations)
  onFetchOrganizations(ctx: StateContext<ConsoleStateModel>) {
    const state = ctx.getState();
    this.consoleService.fetchOrganizations(state.userLocation, state.userLocationAccess, state.userOrganization);
  }

  /**
   * Action handler - set the list of organizations
   * that a user may access
   */
  @Action(ConsoleActions.SetOrganizations)
  onSetOrganizations(ctx: StateContext<ConsoleStateModel>, action: ConsoleActions.SetOrganizations) {
    const state = ctx.getState();
    const lastSelectedOrg = localStorage.getItem('selectedOrganization');
    let selectedOrgId = state.userOrganization; // default to user organization

    for (const currentOrg of action.organizationList) {
      // if organization was stored in local storage, set as the selected organization
      if (lastSelectedOrg === currentOrg.id) {
        selectedOrgId = currentOrg.id;
      }
    }
    ctx.patchState({
      orgAccessList: action.organizationList
    });
    ctx.dispatch(new ConsoleActions.SetSelectedOrganization(selectedOrgId));
  }

  /**
   * Action handler - set the selected organization
   */
  @Action(ConsoleActions.SetSelectedOrganization)
  onSetSelectedOrganization(ctx: StateContext<ConsoleStateModel>, { organizationId }: ConsoleActions.SetSelectedOrganization ) {
    localStorage.setItem('selectedOrganization', organizationId);
    // find the organization in orgAccessList
    const state = ctx.getState();
    const orgs = state.orgAccessList;
    const index = orgs.findIndex(x => x.id === organizationId);

    // update state
    ctx.patchState({
      selectedFloor: null,
      selectedOrganization: orgs[index],
      selectedUnits: null,
      units: []
    });
    this.consoleService.fetchVenues(state.userLocation, state.userLocationAccess, organizationId);
    this.consoleService.fetchStaffPositions(organizationId);
  }

  /**
   * Action handler - set the list of venues
   * that a user may access
   */
   @Action(ConsoleActions.SetVenues)
   onSetVenues(ctx: StateContext<ConsoleStateModel>, action: ConsoleActions.SetVenues) {
     const state = ctx.getState();
     const lastSelectedVenue = localStorage.getItem('selectedVenue');
     let selectedVenueId;
     let isDefaultVenueSet = false;

     for (const currentVenue of action.venueList) {
       if (!isDefaultVenueSet) { // set the default selected venue to first in list
        selectedVenueId = currentVenue.venueId;
        isDefaultVenueSet = true;
       }
       // if venue was stored in local storage, set as the selected venue
       if (lastSelectedVenue === currentVenue.venueId) {
        selectedVenueId = currentVenue.venueId;
       }
     }
     ctx.patchState({
       venueList: action.venueList
     });
     ctx.dispatch(new ConsoleActions.SetSelectedVenue(selectedVenueId));
   }

   /**
   * Action handler - set the selected venue
   */
  @Action(ConsoleActions.SetSelectedVenue)
  onSetSelectedVenue(ctx: StateContext<ConsoleStateModel>, { venueId }: ConsoleActions.SetSelectedVenue ) {
    if (venueId) {
      // locate the selected venue object
      const state = ctx.getState();
      const currentVenue = state.selectedVenue;
      const venues = [...state.venueList];
      const index = venues.findIndex(x => x.venueId === venueId);
      const selectedVenue = venues[index];
      localStorage.setItem('selectedVenue', venueId);
      ctx.patchState({
        selectedVenue
      });
      if (currentVenue?.venueId != venueId) {
        this.consoleService.fetchUnits(state.userLocation, state.userLocationAccess, venueId, state.selectedOrganization.id);
      }
    }
  }

  /**
   * Action handler - set the list of units
   * that a user may access
   */
  @Action(ConsoleActions.SetUnits)
  async onSetUnits(ctx: StateContext<ConsoleStateModel>, action: ConsoleActions.SetUnits) {
    const state = ctx.getState();
    const lastSelectedUnits = localStorage.getItem('selectedUnits');
    const lastSelectedUnitIds = lastSelectedUnits ? JSON.parse(lastSelectedUnits) : [];
    const lastSelectedUnit = localStorage.getItem('selectedUnit');
    let isDefaultUnitSet = false;
    const selectedUnits = [];
    if (lastSelectedUnitIds.length > 0) {
      for (const currentUnit of action.unitList) {
        if (lastSelectedUnitIds.includes(currentUnit.unitId)) {
          selectedUnits.push(currentUnit.unitId)
        }
      }
    }

    if (selectedUnits.length < 1) {
      for (const currentUnit of action.unitList) {
        if (!isDefaultUnitSet) { // set the default selected unit to first in list
          selectedUnits.push(currentUnit.unitId);
          isDefaultUnitSet = true;
        }
        // if unit was stored in local storage, set as the selected unit
        if (lastSelectedUnit === currentUnit.unitId) {
          selectedUnits.push(currentUnit.unitId);
        }
      }
    }

    const selectedUnitObjs = action.unitList.filter(u => selectedUnits.includes(u.unitId))
    if (selectedUnits.length > 0) {
      const selectedUnit = action.unitList.find(u => u.unitId === selectedUnits[0]);
      const defaultFloor = selectedUnit.defaultFloorId ? selectedUnit.floors.find(floor => floor.floorId === selectedUnit.defaultFloorId) : null;
      const selectedFloor = this.processSelectedFloor(
        selectedUnitObjs, 
        defaultFloor ? defaultFloor.floorId : selectedUnit.floors[0].floorId
      );

      localStorage.setItem('selectedUnits', JSON.stringify(selectedUnits));

      ctx.patchState({
        units: action.unitList,
        activeNotificationsCount: 0,
        activeNotificationsCodeWhite: 0,
        addedCodeWhiteNotifications: [],
        codeWhiteNotifications: [],
        selectedFloor: selectedFloor,
        selectedUnits: selectedUnitObjs
      });
      await this.updatePushNotificationSubscription(ctx, state, selectedUnitObjs, state.selectedVenue.venueId);
    }
  }

  /**
   * Action handler - set the selected unit
   */
  @Action(ConsoleActions.SetSelectedUnits)
  async onSetSelectedUnits(ctx: StateContext<ConsoleStateModel>, { unitIds }: ConsoleActions.SetSelectedUnits ) {
    console.log('onSetSelectedUnits');
    const state = ctx.getState();
    const currentSelectedFloor = state.selectedFloor;
    const selectedUnits = [...state.units.filter(x => unitIds.includes(x.unitId))];
    const selectedUnit = selectedUnits[0];
    localStorage.setItem('selectedUnits', JSON.stringify(selectedUnits.map(u => u.unitId)));
    const floorArray = [];
    for (const u of selectedUnits) {
      floorArray.push(...u.floors);
    }
    let selectedFloor;
    if (false && currentSelectedFloor && floorArray.find(f => f.floorId === currentSelectedFloor.floorId)) {
      selectedFloor = currentSelectedFloor;
    } else {
      const defaultFloor = selectedUnit.defaultFloorId ? selectedUnit.floors.find(floor => floor.floorId === selectedUnit.defaultFloorId) : null;
      selectedFloor = this.processSelectedFloor(selectedUnits, defaultFloor ? defaultFloor.floorId : selectedUnit.floors[0].floorId);
    }

    ctx.patchState({
      activeNotificationsCount: 0,
      activeNotificationsCodeWhite: 0,
      addedCodeWhiteNotifications: [],
      codeWhiteNotifications: [],
      selectedFloor: selectedFloor,
      selectedUnits
    });
    await this.updatePushNotificationSubscription(ctx, state, selectedUnits, selectedUnit.venueId);
  }

  /**
 * Action handler - set the list of staff positions
 * for the organization
 */
  @Action(ConsoleActions.SetStaffPositions)
  onSetStaffPositions(ctx: StateContext<ConsoleStateModel>, action: ConsoleActions.SetStaffPositions) {
    const state = ctx.getState();
    ctx.patchState({
      staffPositionMap: action.staffPositionMap
    });
  }

  /**
   * Action handler - increase the number of active notifications by one
   */
  @Action(ConsoleActions.IncrementActiveNotificationsCount)
  onIncrementActiveNotificationsCount(ctx: StateContext<ConsoleStateModel>) {
    const state = ctx.getState();
    let count = state.activeNotificationsCount;
    count++;
    ctx.patchState({
      activeNotificationsCount: count
    });
  }

  /**
   * Action handler - decrease the number of active notifications by one
   */
  @Action(ConsoleActions.DecrementActiveNotificationsCount)
  onDecrementActiveNotificationsCount(ctx: StateContext<ConsoleStateModel>) {
    const state = ctx.getState();
    let count = state.activeNotificationsCount;
    count--;
    ctx.patchState({
      activeNotificationsCount: count
    });
  }

  /**
   * Action handler - increase the number of code white notifications by one
   */
  @Action(ConsoleActions.IncrementActiveNotificationsCodeWhite)
  onIncrementActiveNotificationsCodeWhite(ctx: StateContext<ConsoleStateModel>) {
    const state = ctx.getState();
    let count = state.activeNotificationsCodeWhite;
    count ++;
    ctx.patchState({
      activeNotificationsCodeWhite: count
    });
  }

  /**
   * Action handler - decrease the number of code white notifications by one
   */
  @Action(ConsoleActions.DecrementActiveNotificationsCodeWhite)
  onDecrementActiveNotificationsCodeWhite(ctx: StateContext<ConsoleStateModel>) {
    const state = ctx.getState();
    let count = state.activeNotificationsCodeWhite;
    count --;
    ctx.patchState({
      activeNotificationsCodeWhite: count
    });
  }

  /**
   * Action handler - set the code white notification that has been added
   */
  @Action(ConsoleActions.UpdateAddedCodeWhiteNotifications)
  onUpdateAddedCodeWhiteNotifications(ctx: StateContext<ConsoleStateModel>, { addedCodeWhiteNotifications }: ConsoleActions.UpdateAddedCodeWhiteNotifications) {
    ctx.patchState({
      addedCodeWhiteNotifications: addedCodeWhiteNotifications
    });
  }

  /**
   * Action handler - set the code white notification that are active
   */
  @Action(ConsoleActions.AddCodeWhiteNotifications)
  onAddCodeWhiteNotifications(ctx: StateContext<ConsoleStateModel>, { codeWhiteNotifications }: ConsoleActions.AddCodeWhiteNotifications) {
    const state = ctx.getState();
    let cwNotifications = [codeWhiteNotifications, ...state.codeWhiteNotifications];
    
    ctx.patchState({
      codeWhiteNotifications: cwNotifications
    });
  }

  /**
   * Action handler - set the code white notification that are active
   */
  @Action(ConsoleActions.RemoveCodeWhiteNotifications)
  onRemoveCodeWhiteNotifications(ctx: StateContext<ConsoleStateModel>, { codeWhiteNotifications }: ConsoleActions.RemoveCodeWhiteNotifications) {
    const state = ctx.getState();
    let cwNotifications = state.codeWhiteNotifications.filter(notification => notification.id != codeWhiteNotifications.id);
    
    ctx.patchState({
      codeWhiteNotifications: cwNotifications
    });
  }

  /**
   * Action handler - set the selected floor
   */
  @Action(ConsoleActions.SetSelectedFloor)
  onSetSelectedFloor(ctx: StateContext<ConsoleStateModel>, { floorId }: ConsoleActions.SetSelectedFloor ) {
    const state = ctx.getState();

    const selectedFloor = this.processSelectedFloor(state.selectedUnits, floorId);
    ctx.patchState({
      selectedFloor
    });
  }

  /**
   * Action handler - set the map north direction
   */
  @Action(ConsoleActions.SetMapNorthDirection)
  onSetMapNorthDirection(ctx: StateContext<ConsoleStateModel>, action: ConsoleActions.SetMapNorthDirection) {
    ctx.patchState({
      mapNorthDirection: action.mapNorthDirection
    });
  }

  /**
   * Action handler - reset the console state to default
   */
  @Action(ConsoleActions.ResetConsoleState)
  onResetConsoleState(ctx: StateContext<ConsoleStateModel>) {
    ctx.setState({
      activeNotificationsCount: 0,
      activeNotificationsCodeWhite: 0,
      addedCodeWhiteNotifications: [],
      codeWhiteNotifications: [],
      isLoading: false,
      orgAccessList: [],
      units: [],
      venueList: [],
      mapNorthDirection: 'up'
     });
  }

  async updatePushNotificationSubscription(ctx: StateContext<ConsoleStateModel>, state: ConsoleStateModel, selectedUnits: Unit[], venueId: string) {
    let notificationUnits = selectedUnits.map(u => u.unitId);
    for (const u of selectedUnits) {
      if (u.includeShared) {
        notificationUnits.push(...u.includeShared);
      }
    }
    const isHomeUnitNotifier = state.selectedVenue?.featureConfig?.isHomeUnitNotifier;
    notificationUnits = Array.from(new Set(notificationUnits));
    this.notificationsService.fetchActiveNotificationsCount(state.selectedOrganization.id, venueId, notificationUnits, isHomeUnitNotifier);
    // get the previous selected unit topic(s)
    const notificationSubscribed = localStorage.getItem('notificationUnitTopicsSubscription') === 'true';

    if (notificationSubscribed) {
      const organization = state.selectedOrganization.id;
      const token = await this.afMessaging.getToken.toPromise();

      if (token) {
        // get the previous selected unit topic(s)
        const subscibedTopics = JSON.parse(localStorage.getItem('notificationUnitTopics'));

        // get all units on this venue plus the recent unselected
        const currentSelectedUnits = state.units;
        let subscriptionUnits = [...subscibedTopics, ...currentSelectedUnits.map(u => u.unitId)];  
        for (const u of currentSelectedUnits) {
          if (u.includeShared) {
            subscriptionUnits.push(...u.includeShared);
          }
        }
        subscriptionUnits = Array.from(new Set(subscriptionUnits));

        // unsubscribe from all units
        await this.consoleService.unSubscribeToUnits(organization, token, subscriptionUnits);

        // get the latest selected units in case they have changed
        const currentStateUnits = ctx.getState().selectedUnits;
        let unitsToSubscribe = currentStateUnits.map(u => u.unitId);
        for (const cur of currentStateUnits) {
          if (cur.includeShared) {
            unitsToSubscribe.push(...cur.includeShared);
          }
        }
        
        unitsToSubscribe = Array.from(new Set(unitsToSubscribe));
        const res = await this.consoleService.subscribeToUnits(organization, token, unitsToSubscribe);
        if (!res) {
          // check for lingering topic subscriptions
          const latestCtx = ctx.getState().selectedUnits ?? [];
          let asyncCurrentUnits = latestCtx.map(u => u.unitId);
          for (const cur of latestCtx) {
            if (cur.includeShared) {
              asyncCurrentUnits.push(...cur.includeShared);
            }
          }
          const asyncUnsub = unitsToSubscribe.filter(u => !asyncCurrentUnits.includes(u));
          if (asyncUnsub.length) {
            this.consoleService.unSubscribeToUnits(organization, token, asyncUnsub);
            console.log('Unsubscribing from out of sync topics:', asyncUnsub);
          }

          // update the local storage
          localStorage.setItem('notificationUnitTopics', JSON.stringify(unitsToSubscribe));
          localStorage.setItem('notificationUnitTopicsSubscription', 'true');
          ctx.patchState({
            pushNotificationError: null
          });
        } else {
          ctx.patchState({
            pushNotificationError: 'Failed to subscribe to notifications'
          });
        }
      }
    } else {
      const unitTopics = JSON.parse(localStorage.getItem('notificationUnitTopics'));
      if (unitTopics && unitTopics.length > 0) {
        this.afMessaging.getToken
        .pipe(take(1)).subscribe(token => {
          if (token) {
            const organization = state.selectedOrganization.id;
            this.consoleService.unSubscribeToUnits(organization, token, unitTopics);
          }
        });
      }
    }
  }

  /**
   * 
   * @param selectedUnits Unit[] of selected units
   * @param floorId ID of selected floor
   * @returns 
   */
  processSelectedFloor(selectedUnits: Unit[], floorId: string) {
    const floorArray = [];
    for (const u of selectedUnits) {
      floorArray.push(...u.floors);
    }
    const selectedUnitFloors = floorArray.filter((f: FloorListItem) => f.floorId === floorId);
    const fullExtent = [...selectedUnitFloors[0].extent];
    for (const unitFloor of selectedUnitFloors) {
      const ext = unitFloor.extent;
      fullExtent[0] = ext[0] < fullExtent[0] ? ext[0] : fullExtent[0];
      fullExtent[1] = ext[1] > fullExtent[1] ? ext[1] : fullExtent[1];
      fullExtent[2] = ext[2] < fullExtent[2] ? ext[2] : fullExtent[2];
      fullExtent[3] = ext[3] > fullExtent[3] ? ext[3] : fullExtent[3];
    }
    console.log(fullExtent);
    
    const selectedFloor: FloorListItem = {...selectedUnitFloors[0], extent: fullExtent};
    return selectedFloor;
  }
}

