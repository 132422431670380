import { RuleHistory, RuleListItem } from "../../models/rule-list.model";

/**
 * Action dispatched to set the rule detail
 */
export class SetRuleDetail {
  static type = '[RuleDetail] SetRuleDetail';
  constructor(public ruleDetail: RuleListItem) {}
}

/**
 * Action dispatched to reset the rule detail state to default
 */
export class ResetRuleDetailState {
  static type = '[RuleDetail] ResetRuleDetailState';
}

/**
 * Action dispatched to set the rule history list
 */
 export class SetRuleHistoryList {
  static type = '[RuleDetail] SetRuleHistoryList';
  constructor(public ruleHistory: RuleHistory[]) {}
}

/**
 * Action dispatched to reset the rule list state to default
 */
export class ResetRuleHistoryListState {
  static type = '[RuleDetail] ResetRuleHistoryListState';
}

/**
 * Action dispatched to set the rule error message
 */
 export class SetRuleErrorMessage {
  static type = '[RuleDetail] SetRuleErrorMessage';
  constructor(public message: string) {}
}


/**
 * Action dispatched to set the rule update message
 */
 export class SetRuleUpdateMessage {
  static type = '[RuleDetail] SetRuleUpdateMessage';
  constructor(public message: string) {}
}

/**
 * Action dispatched to clear the rule error message
 */
 export class ClearRuleErrorMessage {
  static type = '[RuleDetail] ClearRuleErrorMessage';
}


/**
 * Action dispatched to clear the rule update message
 */
 export class ClearRuleUpdateMessage {
  static type = '[RuleDetail] ClearRuleUpdateMessage';
}
