import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-profile-confirm-proximity-clear-dialog',
  templateUrl: './profile-confirm-proximity-clear-dialog.component.html',
  styleUrls: ['./profile-confirm-proximity-clear-dialog.component.scss']
})
export class ProfileConfirmProximityDialogComponent implements OnInit {


  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private dialogRef: MatDialogRef<ProfileConfirmProximityDialogComponent>) {}

  ngOnInit() {
    
  }

  onConfirm(action) {
    this.dialogRef.close(action);
  }

}
