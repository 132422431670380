import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';

declare var $: any;

import { ConsoleState } from './../../shared/state/console/console.state';
import { MenuService } from '../../core/menu/menu.service';
import { SettingsService } from '../../core/settings/settings.service';
import { TrendAlertsService } from './../../shared/services/trend-alerts.service';
import { PermissionService } from './../../shared/services/permission.service';

const QUERY_ARRAY_LIMIT = 10;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit, OnDestroy {

  private permissionsSubscription: Subscription;
  private unitChangedSubscription: Subscription;
  private activeTrendAlertCountSubscription: Subscription[] = [];

  menuItems: Array<any>;
  activeTrendAlertsCount = 0;
  activeTrendAlertsCountArray = [];
  router: Router;
  sbclickEvent = 'click.sidebar-toggle';
  $doc: any = null;

  constructor(
    public menu: MenuService,
    public settings: SettingsService,
    public permissionService: PermissionService,
    public trendAlertsService: TrendAlertsService,
    public injector: Injector,
    private store: Store) {}

  ngOnInit() {
    // subscribe to permissions being set
    this.menuItems = [];

    this.unitChangedSubscription = this.store.select(ConsoleState.selectedUnits).subscribe(units => {
      this.activeTrendAlertsCountArray = [];
      this.activeTrendAlertCountSubscription.forEach(sub => sub.unsubscribe());
      this.activeTrendAlertCountSubscription = [];
      this.permissionsSubscription = this.store.select(ConsoleState.permissions).subscribe(permissions => {
        if (permissions) {
          this.menuItems = this.menu.getMenu();
        }
      });

      const organization = this.store.selectSnapshot(ConsoleState.selectedOrganization);
      if (!organization || !(units?.length > 0) || !this.permissionService.hasPermission(['trend-alert-viewer'])) return;
      for (let i=0; i<units.map(u => u.unitId).length; i+=QUERY_ARRAY_LIMIT) {
        const currentUnitIds = units.map(u => u.unitId).slice(i, i + QUERY_ARRAY_LIMIT);
        this.activeTrendAlertCountSubscription.push(this.trendAlertsService.fetchActiveTrendAlertCount(organization, units[0].venueId, currentUnitIds).subscribe((count: number) => {
          if (this.activeTrendAlertsCountArray.length > i) {
            this.activeTrendAlertsCountArray[i] = count;
          } else {
            this.activeTrendAlertsCountArray.push(count);
          }
          this.activeTrendAlertsCount = this.activeTrendAlertsCountArray.reduce((a, b) => a + b, 0);
          
        }, error => {
          console.error(error);
        }));
      }
    });

    this.router = this.injector.get(Router);
    this.router.events.subscribe(val => {
      // track last url in local storage for redirection
      const redirectUrl = (this.router.url==='/login') ? '/dashboard' : this.router.url;
      localStorage.setItem('redirectUrl', redirectUrl);
      // close any submenu opened when route changes
      this.removeFloatingNav();
      // scroll view to top
      window.scrollTo(0, 0);
      // close sidebar on route change
      this.settings.setLayoutSetting('asideToggled', false);
    });

    // enable sidebar autoclose from extenal clicks
    this.anyClickClose();
  }

  anyClickClose() {
    this.$doc = $(document).on(this.sbclickEvent, e => {
      if (!$(e.target).parents('.aside-container').length) {
        this.settings.setLayoutSetting('asideToggled', false);
      }
    });
  }

  ngOnDestroy() {
    if (this.$doc) {
      this.$doc.off(this.sbclickEvent);
    }
    if (this.permissionsSubscription && !this.permissionsSubscription.closed) {
      this.permissionsSubscription.unsubscribe();
    }
    if (this.unitChangedSubscription) {
      this.unitChangedSubscription.unsubscribe();
    }
    this.activeTrendAlertCountSubscription.forEach(sub => sub.unsubscribe());
    this.activeTrendAlertCountSubscription = [];
  }

  toggleSubmenuClick(event) {
    event.preventDefault();

    if (
      !this.isSidebarCollapsed() &&
      !this.isSidebarCollapsedText() &&
      !this.isEnabledHover()
    ) {
      const ul = $(event.currentTarget.nextElementSibling);

      // hide other submenus
      const parentNav = ul.parents('.sidebar-subnav');
      $('.sidebar-subnav').each((idx, el) => {
        const $el = $(el);
        // if element is not a parent or self ul
        if (el !== parentNav[0] && el !== ul[0]) {
          this.closeMenu($el);
        }
      });

      // abort if not UL to process
      if (!ul.length) {
        return;
      }

      // any child menu should start closed
      ul.find('.sidebar-subnav').each((idx, el) => {
        this.closeMenu($(el));
      });

      // toggle UL height
      const ulHeight = ul.css('height');
      if (ulHeight === 'auto' || parseInt(ulHeight, 10)) {
        this.closeMenu(ul);
      } else {
        // expand menu
        ul.on('transitionend', () => {
          ul.css('height', 'auto').off('transitionend');
        }).css('height', ul[0].scrollHeight);
        // add class to manage animation
        ul.addClass('opening');
      }
    }
  }

  // Close menu collapsing height
  closeMenu(elem) {
    elem.css('height', elem[0].scrollHeight); // set height
    elem.css('height', 0); // and move to zero to collapse
    elem.removeClass('opening');
  }

  toggleSubmenuHover(event) {
    const self = this;
    if (
      this.isSidebarCollapsed() ||
      this.isSidebarCollapsedText() ||
      this.isEnabledHover()
    ) {
      event.preventDefault();

      this.removeFloatingNav();

      const ul = $(event.currentTarget.nextElementSibling);
      const anchor = $(event.currentTarget);

      if (!ul.length) {
        return; // if not submenu return
      }

      const $aside = $('.aside-container');
      const $asideInner = $aside.children('.aside-inner'); // for top offset calculation
      const $sidebar = $asideInner.children('.sidebar');
      const mar = parseInt($asideInner.css('padding-top'), 0) + parseInt($aside.css('padding-top'), 0);
      const itemTop = anchor.parent().position().top + mar - $sidebar.scrollTop();

      const floatingNav = ul.clone().appendTo($aside);
      const vwHeight = document.body.clientHeight;

      // let itemTop = anchor.position().top || anchor.offset().top;

      floatingNav
        .removeClass('opening') // necesary for demo if switched between normal//collapsed mode
        .addClass('nav-floating')
        .css({
          position: this.settings.getLayoutSetting('isFixed')
            ? 'fixed'
            : 'absolute',
          top: itemTop,
          bottom:
            floatingNav.outerHeight(true) + itemTop > vwHeight ? 0 : 'auto'
        });

      floatingNav
        .on('mouseleave', () => {
          floatingNav.remove();
        })
        .find('a')
        .on('click', function(e) {
          e.preventDefault(); // prevents page reload on click
          // get the exact route path to navigate
          const routeTo = $(this).attr('route');
          if (routeTo) {
            self.router.navigate([routeTo]);
          }
        });

      this.listenForExternalClicks();
    }
  }

  listenForExternalClicks() {
    const $doc = $(document).on('click.sidebar', e => {
      if (!$(e.target).parents('.aside-container').length) {
        this.removeFloatingNav();
        $doc.off('click.sidebar');
      }
    });
  }

  removeFloatingNav() {
    $('.nav-floating').remove();
  }

  isSidebarCollapsed() {
    return this.settings.getLayoutSetting('isCollapsed');
  }
  isSidebarCollapsedText() {
    return this.settings.getLayoutSetting('isCollapsedText');
  }
  isEnabledHover() {
    return this.settings.getLayoutSetting('asideHover');
  }
}
