import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Injectable, OnDestroy } from '@angular/core';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';

import { Logout } from './../../shared/state/auth/auth-state.actions';
import { ProfileRule } from '../models/rules/profile-rule.model';
import { Rule } from './../models/rules/rule.model';
import { SetSavedRules } from '../../routes/profiles/profile-detail/state/profile-detail-state.actions';
import { EventType } from '../models/rules/enums/event-type.enum';
import { NotifyOnTime } from '../models/rules/enums/notify-on-time.enum';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Profile } from '../../routes/profiles/models/profile.model';
import { RuleHistory, RuleListItem } from '../../routes/rules/models/rule-list.model';
import { SetRuleList } from '../../routes/rules/rules-list/state/rule-list-state.actions';
import { SetRuleDetail, SetRuleHistoryList } from '../../routes/rules/rules-detail/state/rule-detail-state.actions';
import { ProximityRule } from '../models/rules/proximity-rule.model';
import { ConsoleState } from '../state/console/console.state';

const DELETED = 'deleted';
const REQUESTED = 'requested';
const QUERY_ARRAY_LIMIT = 10;

@Injectable()
export class RulesService implements OnDestroy {

  // Subscriptions to Actions being dispateched
  private logoutSubscription: Subscription;

  // Subscription used by profile rules component
  private profileRulesSubscription: Subscription;

  // Subscription used by profile list component
  private ruleListSubscription: Subscription;
  private ruleDetailSubscription: Subscription;
  private ruleHistoryListSubscription: Subscription;

  constructor(
    private actions: Actions,
    private http: HttpClient,
    private afs: AngularFirestore,
    private store: Store) {

    // set up subscription to Logout action
    this.logoutSubscription = this.actions.pipe(ofActionDispatched(Logout)).subscribe(() => {
      this.cancelProfileRulesSubscription();
      this.cancelRuleListSubscription();
      this.cancelRuleHistoryListSubscription();
      this.cancelRuleDetailSubscription();
    });
  }

  /**
   * Subscribe to rules for a profile
   *
   * @param organization The organization associated with the rules
   * @param venueId The ID of the venue
   * @param profileId The profile ID of the profile
   */
  fetchProfileRulesById(organization: string, venueId: string, profileId: string): void {
    const rulesUrl = 'organizations/' + organization + '/venues/' + venueId + '/rules';
    const rulesCollection = this.afs.collection<Rule>(rulesUrl, ref => ref.where('profileId', '==', profileId));
    this.profileRulesSubscription = rulesCollection.snapshotChanges()
    .pipe(map (rules => {
      // newer firebase ^7.21.0 allows the != operator so this filtering could be done above
      const filteredRules = rules.filter(rule => rule.payload.doc.data() && 
      !(!rule.payload.doc.data().hasPendingUpdates && rule.payload.doc.data().status == REQUESTED) &&
      rule.payload.doc.data().status != DELETED);
      return filteredRules.map(rule => {
        const data = rule.payload.doc.data() as ProfileRule;
        const id = rule.payload.doc.id;
        return {id, ...data};
      });
    })).subscribe( (rules: ProfileRule[]) => {
      // TODO: hide on floor rule from non super admins
      const isUserSuperAdmin = this.store.selectSnapshot(ConsoleState.role) === 'SuperAdmin';
      let showRules = rules.filter(r => r.eventType != EventType.ON_FLOOR || isUserSuperAdmin);
      this.store.dispatch(new SetSavedRules(showRules));
    }, error => {
      console.error(error);
    });
  }

  /**
   * Retrieve rules triggered by proximity to a profile
   *
   * @param organization The organization associated with the rules
   * @param venueId The ID of the venue
   * @param profileId The profile ID of the profile
   */
  async fetchProximityTriggerRules(organization: string, venueId: string, profileId: string): Promise<ProximityRule[]> {

    const profiles = await this.afs.collection<Profile>('organizations/' + organization + '/profiles', ref => ref.where('venueId', '==', venueId)).get().toPromise();
    const rulesUrl = 'organizations/' + organization + '/venues/' + venueId + '/rules';
    const rulesCollection = await this.afs.collection<Rule>(
      rulesUrl, ref => 
      ref.where('eventType', '==', 'Proximity')
        .where('properties.proximityTriggerId', '==', profileId)
    ).get().toPromise();
    const rules = rulesCollection.docs.map(rule => {
      const profile = profiles.docs.find(prof => prof.id === rule.data().profileId);
      let profileName: string;
      if (profile?.data().profileData?.firstName) {
       profileName = `${profile.data().profileData.firstName} ${profile.data().profileData.lastName}`;
      } else {
        profileName = profile?.data().label;
      }
      return {...rule.data() as ProximityRule, id: rule.id, profileDetailName: profileName}
    });
    
    return rules.filter(rule => rule.status != DELETED);
  }

  /**
   * Determine whether a specific category of rule exists for a profile
   *
   * @param organization The organization associated with the rules
   * @param venueId The ID of the venue
   * @param profileId The profile ID of the profile
   * @param eventCategory The event category of the rule
   */
  doesProfileRuleExistForCategory(organization: string, venueId: string, profileId: string, eventCategory: string) {
    return new Promise(async (resolve, reject) => {
      this.afs.collection<Rule>('organizations/' + organization + '/venues/' + venueId + '/rules',
        ref => ref.where('profileId', '==', profileId).where('eventCategory', '==', eventCategory))
      .get().subscribe(querySnapshot => {
        resolve(!querySnapshot.empty);
      }, error => {
        reject(error);
      });
    });
  }

  /**
   * Add a rule to firestore - promise resolves to the rule document ID
   *
   * @param organization The organization associated with the rules
   * @param venueId The ID of the venue
   * @param rule The rule to add
   * @param reason The reason for creating the rule - optional
   * @param requestorType The requestor
   */
  addRule(organization: string, venueId: string, rule: Rule, reason: string = null, requestorType: string = 'Staff'): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const rulesUrl = environment.apiUrl + '/organizations/' + organization + '/venues/' + venueId + '/rules';
        const data: {rule: Rule, reason?: string, requestorType?: string} = {
          rule: rule,
          requestorType: requestorType
        }
        if (reason) {
          data.reason = reason;
        }
        const res = await this.http.post<any>(rulesUrl, data).toPromise();
        if (res.ruleId) {
          resolve(res.ruleId);
        } else {
          reject('Rule could not be added');
        }
      } catch (error) {
        const message = error.error ? error.error.message : 'Encountered an error adding the rule';
        reject(message);
      }
    });
  }

    /**
   * Add a rule to firestore - promise resolves to the rule document ID
   *
   * @param organization The organization associated with the rules
   * @param venueId The ID of the venue
   * @param profileId The ID of the profile
   * @param rule The rule to add
   * @param reason The reason for creating the rule - optional
   * @param permission The type of system update
   * @param requestorType The requestor
   */
     systemAddRule(organization: string, venueId: string, profileId: string, rule: Rule, reason: string = null, permission: string = 'profile-editor', requestorType: string = 'System'): Promise<string> {
      return new Promise(async (resolve, reject) => {
        try {
          const rulesUrl = environment.apiUrl + '/organizations/' + organization + '/venues/' + venueId + '/rules/system/'+ permission + '/profile/' + profileId;
          const data: {rule: Rule, reason?: string, requestorType?: string} = {
            rule: rule,
            requestorType: requestorType
          }
          if (reason) {
            data.reason = reason;
          }
          const res = await this.http.post<any>(rulesUrl, data).toPromise();
          if (res.ruleId) {
            resolve(res.ruleId);
          } else {
            reject('Rule could not be added');
          }
        } catch (error) {
          const message = error.error ? error.error.message : 'Encountered an error adding the rule';
          reject(message);
        }
      });
    }
  

  /**
   * Update a rule in a firestore collection
   *
   * @param organization The organization associated with the rules
   * @param venueId The ID of the venue
   * @param docId The rule document ID
   * @param updates An object of properties and updated values
   * @param reason a string of the reason for updating - optional
   * @param requestorType a string of the requestor
   */
  updateRule(organization: string, venueId: string, docId: string, updates: {}, reason: string = null, requestorType: string = 'Staff'): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        const rulesUrl = environment.apiUrl + '/organizations/' + organization + '/venues/' + venueId + '/rules/id/' + docId;
        const data: {rule: any, reason?: string, requestorType?: string} = {
          rule: updates,
          requestorType: requestorType
        }
        if (reason) {
          data.reason = reason;
        }
        const res = await this.http.put<any>(rulesUrl, data).toPromise();
        if (res) {
          resolve();
        } else {
          reject('Rule could not be updated');
        }
      } catch (error) {
        const message = error.error ? error.error.message : 'Encountered an error updating the rule';
        reject(message);
      }
    });
  }

  /**
   * Update a rule in a firestore collection
   *
   * @param organization The organization associated with the rules
   * @param venueId The ID of the venue
   * @param docId The rule document ID
   * @param profileId The profile document ID
   * @param updates An object of properties and updated values
   * @param reason a string of the reason for updating - optional
   * @param permission a string of the permission type required to perform action
   * @param requestorType a string of the type of requestor
   */
   systemUpdateRule(organization: string, venueId: string, docId: string, profileId: string, updates: {}, reason: string = null, permission:string='profile-editor', requestorType: string = 'System'): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        const rulesUrl = environment.apiUrl + '/organizations/' + organization + '/venues/' + venueId + '/rules/system/'+ permission + '/profile/' + profileId + '/rule/' + docId;
        const data: {rule: any, reason?: string, requestorType?: string} = {
          rule: updates,
          requestorType: requestorType
        }
        if (reason) {
          data.reason = reason;
        }
        const res = await this.http.put<any>(rulesUrl, data).toPromise();
        if (res) {
          resolve();
        } else {
          reject('Rule could not be updated');
        }
      } catch (error) {
        const message = error.error ? error.error.message : 'Encountered an error updating the rule';
        reject(message);
      }
    });
  }

  /**
   * Delete a rule from a firestore collection
   *
   * @param organization The organization associated with the rules
   * @param venueId The ID of the venue
   * @param docId The rule document ID
   * @param reason The reason for deletion will be required
   */
  deleteRule(organization: string, venueId: string, docId: string, reason: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        const rulesUrl = environment.apiUrl + '/organizations/' + organization + '/venues/' + venueId + '/rules/id/' + docId;
        const options = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
          body: {
            reason: reason
          },
        };
        const res = await this.http.delete<any>(rulesUrl, options).toPromise();
        if (res) {
          resolve();
        } else {
          reject('Rule could not be deleted');
        }
      } catch (error) {
        reject('Encountered an error deleting the rule');
      }
    });
  }

  /**
   * Delete a rule from a firestore collection
   *
   * @param organization The organization associated with the rules
   * @param venueId The ID of the venue
   * @param docId The rule document ID
   * @param profileId The rule document ID
   * @param reason The reason for deletion will be required
   * @param permission The permission type required to perform this action
   */
   systemDeleteRule(organization: string, venueId: string, docId: string, profileId: string, reason: string = 'System Delete', permission : string = 'profile-editor', ): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        const rulesUrl = environment.apiUrl + '/organizations/' + organization + '/venues/' + venueId + '/rules/system/' + permission + '/profile/' + profileId + '/rule/' + docId;
        const options = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
          }),
          body: {
            reason: reason
          },
        };
        const res = await this.http.delete<any>(rulesUrl, options).toPromise();
        if (res) {
          resolve();
        } else {
          reject('Rule could not be deleted');
        }
      } catch (error) {
        reject('Encountered an error deleting the rule');
      }
    });
  }

  /**
   * Add a rule to firestore - promise resolves to the rule document ID
   *
   * @param organization The organization associated with the rules
   * @param venueId The ID of the venue
   * @param rule The rule to add
   * @param reason The reason for creating the rule - optional
   */
   requestAddRule(organization: string, venueId: string, rule: Rule, reason: string = null, requestorType: string = 'Staff'): Promise<string> {
    return new Promise(async (resolve, reject) => {
      try {
        const profileId = rule.profileId;
        const rulesUrl = environment.apiUrl + '/organizations/' + organization + '/venues/' + venueId + '/rules/request/profile/' + profileId;
        const data: {rule: Rule, reason?: string, requestorType?: string} = {
          rule: rule,
          requestorType: requestorType
        }
        if (reason) {
          data.reason = reason;
        }
        const res = await this.http.post<any>(rulesUrl, data).toPromise();
        if (res.ruleId) {
          resolve(res.ruleId);
        } else {
          reject('Rule could not be added');
        }
      } catch (error) {
        const message = error.error ? error.error.message : 'Encountered an error adding the rule';
        reject(message);
      }
    });
  }

  /**
   * Update a rule in a firestore collection
   *
   * @param organization The organization associated with the rules
   * @param venueId The ID of the venue
   * @param docId The rule document ID
   * @param updates An object of properties and updated values
   * @param profileId string of the profile ID
   * @param reason a string of the reason for updating - optional
   */
  requestUpdateRule(organization: string, venueId: string, docId: string, updates: {}, profileId: string, reason: string = null, requestorType: string = 'Staff'): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        const rulesUrl = environment.apiUrl + '/organizations/' + organization + '/venues/' + venueId + '/rules/request/profile/' + profileId + '/rule/' + docId;
        const data: {rule: any, reason?: string, requestorType?: string} = {
          rule: updates,
          requestorType: requestorType
        }
        if (reason) {
          data.reason = reason;
        }
        const res = await this.http.put<any>(rulesUrl, data).toPromise();
        if (res) {
          resolve();
        } else {
          reject('Rule could not be updated');
        }
      } catch (error) {
        const message = error.error ? error.error.message : 'Encountered an error updating the rule';
        reject(message);
      }
    });
  }

  /**
   * Delete a rule from a firestore collection
   *
   * @param organization The organization associated with the rules
   * @param venueId The ID of the venue
   * @param docId The rule document ID
   * @param profileId string of the profile ID
   * @param reason The reason for deletion will be required
   */
  requestDeleteRule(organization: string, venueId: string, docId: string, profileId: string, reason: string = 'Deleted before reason implementation'): Promise<void> {
    // TODO: remove the default reason when form is updated
    return new Promise(async (resolve, reject) => {
      try {
        // const rulesUrl = environment.apiUrl + '/organizations/' + organization + '/venues/' + venueId + '/rules/id/' + docId;
        const rulesUrl = environment.apiUrl + '/organizations/' + organization + '/venues/' + venueId + '/rules/request/profile/' + profileId + '/rule/' + docId;
        const data: {reason?: string, delete: boolean} = {
          delete: true
        }
        if (reason) {
          data.reason = reason;
        }
        const res = await this.http.put<any>(rulesUrl, data).toPromise();
        if (res) {
          resolve();
        } else {
          reject('Rule could not be deleted');
        }
      } catch (error) {
        reject('Encountered an error deleting the rule');
      }
    });
  }

  /**
   * Update the active field for all rules assigned to a profile where disableOnPass = true
   *
   * @param organization The organization associated with the rules
   * @param venueId The ID of the venue
   * @param profileId The profile ID of the profile
   * @param isActive Whether the rules should be set to active
   */
  setProfileRulesActive(organization: string, venueId: string, profileId: string, isActive: boolean): void {
    const rulesUrl = 'organizations/' + organization + '/venues/' + venueId + '/rules';
    this.afs.collection<Rule>(rulesUrl, ref => ref.where('profileId', '==', profileId)).get()
    .toPromise().then( rules => {
      rules.forEach( rule => {
        const id = rule.id;
        const profileRule: ProfileRule = {
          id, ...rule.data() as ProfileRule
        };
        if (profileRule.properties.disableOnPass && profileRule.status != DELETED) {
          this.systemUpdateRule(
            organization, 
            venueId, 
            profileRule.id, 
            profileId,
            { active: isActive }, 
            'System update in response to change in On Pass status');
        }
      });
    });
  }

  /**
   * Delete all rules assigned to a profile
   *
   * @param organization The organization associated with the rules
   * @param venueId The ID of the venue
   * @param profileId The profile ID of the profile
   */
  deleteProfileRules(organization: string, venueId: string, profileId: string) {
    const rulesUrl = 'organizations/' + organization + '/venues/' + venueId + '/rules';
    this.afs.collection<Rule>(rulesUrl, ref => ref.where('profileId', '==', profileId)).get()
    .toPromise().then( rules => {
      rules.forEach( rule => {
        if (rule.data() && rule.data().status != DELETED) {
          this.systemDeleteRule(organization, venueId, rule.id, profileId, 'System delete in response to deleted profile ' + profileId);
        }
      });
    });
  }

  // Update rules in response to a change in profile data

  /**
   * Update the name of the trigger profile for all proximity rules
   *
   * @param organization The organization associated with the rules
   * @param venueId The ID of the venue
   * @param profileId The unique profile identifier
   * @param newName The updated profile name
   */
  updateProximityTriggerName(organization: string, venueId: string, profileId: string, newName: string): Promise<void> {
    return new Promise( (resolve, reject) => {
      const rulesUrl = 'organizations/' + organization + '/venues/' + venueId + '/rules';
      this.afs.collection<Rule>(rulesUrl, ref => ref.where('properties.proximityTriggerId', '==', profileId)).get()
      .toPromise().then(rules => {
        rules.forEach( async (rule) => {
          const id = rule.id;
          const profileRule: ProfileRule = {
            id, ...rule.data() as ProfileRule
          };
          const properties = {
            properties: {
              proximityTriggerName: newName
            }
          };
          try {
            if (profileRule.status != DELETED) {
              this.systemUpdateRule(
                organization, 
                venueId, 
                profileRule.id, 
                profileId,
                properties, 
                'System update in response to proximity profile update');
            }
          } catch (error) {
            reject();
          }
        });
        resolve();
      });
    });
  }

  /**
   * Update the bed fields for all geofence rules assigned to a profile where updateBedOnChange = true
   *
   * @param organization The organization associated with the rules
   * @param venueId The ID of the venue
   * @param profileId The profile ID of the profile
   * @param bedId The ID of the bed geofence
   * @param bedName The name of the bed geofence
   */
  updateProfileBed(organization: string, venueId: string, profileId: string, bedId: string, bedName: string) {
    const rulesUrl = 'organizations/' + organization + '/venues/' + venueId + '/rules';
    this.afs.collection<Rule>(rulesUrl, ref => ref.where('profileId', '==', profileId)).get()
    .toPromise().then( rules => {
      rules.forEach( rule => {
        const id = rule.id;
        const profileRule: ProfileRule = {
          id, ...rule.data() as ProfileRule
        };
        if (profileRule.properties.updateBedOnChange && profileRule.eventType === EventType.GEOFENCE && profileRule.status != DELETED) {
          const properties = {
            properties: {
              triggerValue: bedId,
              geofenceIdName: bedName
            }
          };
          this.systemUpdateRule(
            organization, 
            venueId, 
            profileRule.id, 
            profileId,
            properties, 
            'System update in response to bed change');
        }
      });
    });
  }

  /**
   * Update the bathroom fields for all geofence rules assigned to a profile where updateBathroomOnChange = true
   *
   * @param organization The organization associated with the rules
   * @param venueId The ID of the venue
   * @param profileId The profile ID of the profile
   * @param bathroomId The ID of the bathroom geofence
   * @param bathroomName The name of the bathroom geofence
   */
  updateProfileBathroom(organization: string, venueId: string, profileId: string, bathroomId: string, bathroomName: string) {
    const rulesUrl = 'organizations/' + organization + '/venues/' + venueId + '/rules';
    this.afs.collection<Rule>(rulesUrl, ref => ref.where('profileId', '==', profileId)).get()
    .toPromise().then( rules => {
      rules.forEach( rule => {
        const id = rule.id;
        const profileRule: ProfileRule = {
          id, ...rule.data() as ProfileRule
        };
        if (profileRule.properties.updateBathroomOnChange && profileRule.eventType === EventType.GEOFENCE && profileRule.status != DELETED) {
          const properties = {
            properties: {
              triggerValue: bathroomId,
              geofenceIdName: bathroomName
            }
          };
          this.systemUpdateRule(
            organization, 
            venueId, 
            profileRule.id, 
            profileId,
            properties, 
            'System update in response to bathroom change');
        }
      });
    });
  }

  /**
   * Update the room fields for Not Own Room rules and all geofence rules
   *  assigned to a profile where updateRoomOnChange = true
   *
   * @param organization The organization associated with the rules
   * @param venueId The ID of the venue
   * @param profileId The profile ID of the profile
   * @param roomId The ID of the room geofence
   * @param roomName The name of the room geofence
   */
  updateProfileRoom(organization: string, venueId: string, profileId: string, roomId: string, roomName: string) {
    const rulesUrl = 'organizations/' + organization + '/venues/' + venueId + '/rules';
    this.afs.collection<Rule>(rulesUrl, ref => ref.where('profileId', '==', profileId)).get()
    .toPromise().then( rules => {
      rules.forEach( rule => {
        const id = rule.id;
        const profileRule: ProfileRule = {
          id, ...rule.data() as ProfileRule
        };
        if (profileRule.properties.updateRoomOnChange && profileRule.eventType === EventType.GEOFENCE && profileRule.status != DELETED) {
          const properties = {
            properties: {
              triggerValue: roomId,
              geofenceIdName: roomName
            }
          };
          this.systemUpdateRule(
            organization, 
            venueId, 
            profileRule.id, 
            profileId,
            properties, 
            'System update in response to room change');
        } else if (profileRule.properties.updateRoomOnChange && profileRule.eventType === EventType.NOT_OWN_ROOM && profileRule.status != DELETED) {
          const properties = {
            properties: {
              ownRoomId: roomId,
              ownRoomName: roomName
            }
          };
          this.systemUpdateRule(
            organization, 
            venueId, 
            profileRule.id, 
            profileId,
            properties, 
            'System update in response to room change');
        }
      });
    });
  }

  /**
   * Update the time fields for all rules assigned to a profile where updateTime is not 'Never'
   *
   * @param organization The organization associated with the rules
   * @param venueId The ID of the venue
   * @param profileId The profile ID of the profile
   * @param riseTime Average rise time for the profile
   * @param sleepTime Average sleep time for the profile
   */
  updateProfileTime(organization: string, venueId: string, profileId: string, riseTime: Date, sleepTime: Date) {
    const rulesUrl = 'organizations/' + organization + '/venues/' + venueId + '/rules';
    this.afs.collection<Rule>(rulesUrl, ref => ref.where('profileId', '==', profileId)).get()
    .toPromise().then( rules => {
      rules.forEach( rule => {
        const id = rule.id;
        const profileRule: ProfileRule = {
          id, ...rule.data() as ProfileRule
        };
        if (profileRule.properties.updateTime === NotifyOnTime.RISE_TO_SLEEP && profileRule.status != DELETED) {
          this.systemUpdateRule(
            organization, 
            venueId, 
            profileRule.id, 
            profileId,
            { startTime: riseTime.getTime(), endTime: sleepTime.getTime() }, 
            'System update in response to change in rise and sleep times');
        } else if (profileRule.properties.updateTime === NotifyOnTime.SLEEP_TO_RISE && profileRule.status != DELETED) {
          this.systemUpdateRule(
            organization, 
            venueId, 
            profileRule.id, 
            profileId,
            { startTime: sleepTime.getTime(), endTime: riseTime.getTime() }, 
            'System update in response to change in rise and sleep times');
        }
      });
    });
  }

  // Delete rules in response to a change in profile data

  /**
   * Delete all geofence rules assigned to a profile where updateBedOnChange = true
   *
   * @param organization The organization associated with the rules
   * @param venueId The ID of the venue
   * @param profileId The profile ID of the profile
   */
  deleteProfileBedRules(organization: string, venueId: string, profileId: string) {
    const rulesUrl = 'organizations/' + organization + '/venues/' + venueId + '/rules';
    this.afs.collection<Rule>(rulesUrl, ref => ref.where('profileId', '==', profileId)).get()
    .toPromise().then( rules => {
      rules.forEach( rule => {
        const id = rule.id;
        const profileRule: ProfileRule = {
          id, ...rule.data() as ProfileRule
        };
        if (profileRule.properties.updateBedOnChange && profileRule.eventType === EventType.GEOFENCE && profileRule.status != DELETED) {
          this.systemDeleteRule(organization, venueId, profileRule.id, profileId, 'System delete in response to removal of assigned bed');
        }
      });
    });
  }

  /**
   * Delete all geofence rules assigned to a profile where updateBathroomOnChange = true
   *
   * @param organization The organization associated with the rules
   * @param venueId The ID of the venue
   * @param profileId The profile ID of the profile
   */
  deleteProfileBathroomRules(organization: string, venueId: string, profileId: string) {
    const rulesUrl = 'organizations/' + organization + '/venues/' + venueId + '/rules';
    this.afs.collection<Rule>(rulesUrl, ref => ref.where('profileId', '==', profileId)).get()
    .toPromise().then( rules => {
      rules.forEach( rule => {
        const id = rule.id;
        const profileRule: ProfileRule = {
          id, ...rule.data() as ProfileRule
        };
        if (profileRule.properties.updateBathroomOnChange && profileRule.eventType === EventType.GEOFENCE && profileRule.status != DELETED) {
          this.systemDeleteRule(organization, venueId, profileRule.id, profileId, 'System delete in response to removal of assigned bathroom');
        }
      });
    });
  }

  /**
   * Delete all geofence rules assigned to a profile where updateRoomOnChange = true
   *
   * @param organization The organization associated with the rules
   * @param venueId The ID of the venue
   * @param profileId The profile ID of the profile
   */
  deleteProfileRoomRules(organization: string, venueId: string, profileId: string) {
    const rulesUrl = 'organizations/' + organization + '/venues/' + venueId + '/rules';
    this.afs.collection<Rule>(rulesUrl, ref => ref.where('profileId', '==', profileId)).get()
    .toPromise().then( rules => {
      rules.forEach( rule => {
        const id = rule.id;
        const profileRule: ProfileRule = {
          id, ...rule.data() as ProfileRule
        };
        if (profileRule.properties.updateRoomOnChange && profileRule.status != DELETED) {
          this.systemDeleteRule(organization, venueId, profileRule.id, profileId, 'System delete in response to removal of assigned room');
        }
      });
    });
  }

  /**
   * Delete all rules where the trigger is the profile specified
   *
   * @param organization The organization associated with the rules
   * @param venueId The ID of the venue
   * @param profileId The profile ID of the trigger profile
   */
  deleteProximityTriggerRules(organization: string, venueId: string, profileId: string) {
    const rulesUrl = 'organizations/' + organization + '/venues/' + venueId + '/rules';
    this.afs.collection<Rule>(rulesUrl, ref => ref.where('properties.proximityTriggerId', '==', profileId)).get()
    .toPromise().then( rules => {
      rules.forEach( rule => {
        if (rule.data() && rule.data().status != DELETED) {
          this.systemDeleteRule(organization, venueId, rule.id, profileId, 'System delete in response to removal of proximity profile ' + profileId);
        }
      });
    });
  }

  /**
   * Fetch all rules by venue
   * @param organization The organization associated with the rules
   * @param venueId The ID of the venue
   * @param unitIds The selected unit IDs
   * @param pending The pending status of documents (pending or all)
   */
   async fetchRulesList(organization: string, venueId: string, unitIds: string[], pending: string = 'pending') { 
    // TODO: upgrade firebase to get the isin operator
    const profiles = [];

    for (let i=0; i<unitIds.length; i+=QUERY_ARRAY_LIMIT) {
      const currentUnitIds = unitIds.slice(i, i + QUERY_ARRAY_LIMIT);
      const profilesCollection = await this.afs.collection<Profile>('organizations/' + organization + '/profiles',
      ref => ref
        .where('active', '==', true)
        .where('units', 'array-contains-any', currentUnitIds))
      .get().toPromise();

      for (const profile of profilesCollection.docs) {
        profiles.push({id: profile.id, ...profile.data()});
      }
    }

    const rulesUrl = 'organizations/' + organization + '/venues/' + venueId + '/rules';
    let rulesCollection: AngularFirestoreCollection<Rule>;
    if (pending === 'pending') {
      rulesCollection = this.afs.collection<Rule>(rulesUrl, ref => ref.where('hasPendingUpdates', '==', true));
    } else {
      rulesCollection = this.afs.collection<Rule>(rulesUrl);
    }
    
    this.ruleListSubscription = rulesCollection.snapshotChanges()
    .pipe(map (rules => {
      // newer firebase ^7.21.0 allows the != operator so this filtering could be done above
      const filteredRules = rules.filter(rule => rule.payload.doc.data() && 
      !(!rule.payload.doc.data().hasPendingUpdates && rule.payload.doc.data().status == REQUESTED));
      return filteredRules.map(rule => {
        const data = rule.payload.doc.data() as Rule;
        const id = rule.payload.doc.id;
        return {id, ...data};
      });
    })).subscribe( async (rules: Rule[]) => {
      // make the ruleList here
      const ruleslist: Array<RuleListItem> = [];
      for (const doc of rules) {
        let history = null;
        if (doc.hasPendingUpdates) {
          const historyRef = await this.afs.collection('organizations/' + organization + '/venues/' + venueId + '/ruleHistory', 
          ref => ref.where('ruleId', '==', doc.id)
          .orderBy('date', 'desc'))
          .get().toPromise();
          
          // Skip any system updates to get to the latest request
          const requestActions = ['CREATE_REQUEST', 'DELETE_REQUEST', 'UPDATE_REQUEST'];
          for (const hist of historyRef.docs) {
            if (requestActions.includes((hist.data() as any).action)) {
              history = {id: hist.id, ...(hist.data() as any)};
              break;
            }
          }
        }
        const ruleProfile = profiles.find(item => item.id === doc.profileId);
        // TODO: hide on floor rule from non super admins
        const isUserSuperAdmin = this.store.selectSnapshot(ConsoleState.role) === 'SuperAdmin';
        if (ruleProfile && (doc.eventType != EventType.ON_FLOOR || isUserSuperAdmin)) {
          // only fetch rules of active profiles within the selected unit
          ruleslist.push({rule: doc, profile: {id: doc.profileId, ...ruleProfile}, history: history});
        }
      }
      this.store.dispatch(new SetRuleList(ruleslist));
    }, error => {
      console.error(error);
    });
    
  }

  /**
   * Fetch all rules history by rule ID
   * @param organization The organization associated with the rules
   * @param venueId The ID of the venue
   * @param ruleId rule ID of history
   */
   async fetchRulesHistoryList(organization: string, venueId: string, ruleId: string) { 
    // TODO: upgrade firebase to get the isin operator
  
    const ruleHistoryCollection = await this.afs.collection<RuleHistory>('organizations/' + organization + '/venues/' + venueId + '/ruleHistory', 
    ref => ref.where('ruleId', '==', ruleId)
    .orderBy('date', 'desc'));
    
    this.ruleHistoryListSubscription = ruleHistoryCollection.snapshotChanges()
    .pipe(map (history => {
      // newer firebase ^7.21.0 allows the != operator so this filtering could be done above
      return history.map(hist => {
        const data = hist.payload.doc.data();
        const id = hist.payload.doc.id;
        return {id, ...data};
      });
    })).subscribe( async (ruleHistory: RuleHistory[]) => {
      this.store.dispatch(new SetRuleHistoryList(ruleHistory));
    }, error => {
      console.error(error);
    });
    
  }

  /**
   * Approve/Reject a ruleHistory request in a firestore collection
   *
   * @param organization The organization associated with the rules
   * @param venueId The ID of the venue
   * @param docId The rule document ID
   * @param requestReferenceId a string of the ruleHistory doc ID
   * @param approved a boolean of approval
   */
   async decideRule(organization: string, venueId: string, docId: string, requestReferenceId: string = null, approved: boolean, reason: string = null): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const rulesUrl = environment.apiUrl + '/organizations/' + organization + '/venues/' + venueId + '/rules/decide/' + docId;
        const data: {approved: boolean, requestReferenceId: string, reason?: string} = {
          approved: approved,
          requestReferenceId: requestReferenceId
        }
        if (reason) {
          data.reason = reason;
        }
        const res = await this.http.put<any>(rulesUrl, data).toPromise();
        if (res) {
          resolve(res);
        } else {
          reject('Encountered an error updating the rule status');
        }
      } catch (error) {
        const message = error.error ? error.error.message : 'Encountered an error updating the rule';
        reject(message);
      }
    });
  }

  /**
   * Subscribe to a rule document and update rule
   *  detail in state on change
   *
   * @param organization The selected organization
   * @param venueId The ID of the venue containing the selected unit
   * @param docId The firestore document identifier
   */
   fetchRuleDetail(organization: string, venueId: string, docId: string): void {
    const rulesUrl = 'organizations/' + organization + '/venues/' + venueId + '/rules';
    const rulesCollection = this.afs.collection<Rule>(rulesUrl).doc(docId);
    this.ruleDetailSubscription = rulesCollection.snapshotChanges()
    .pipe(map (rule => {
      if (rule.payload.data()) {
        const data = rule.payload.data() as Rule;
        const id = rule.payload.id;
        return {id, ...data};
      } else { // document does not exist, so return user to dashboard
        console.log('no rule exists');
        return null;
      }
    })).subscribe( async (rule: Rule) => {
      const profileRef = await this.afs.collection('organizations/' + organization + '/profiles')
        .doc(rule.profileId)
        .get().toPromise();

      const ruleListItem: RuleListItem = {
        rule: rule,
        profile: {id: profileRef.id, ...profileRef.data() as Profile} as Profile
      }
      this.store.dispatch(new SetRuleDetail(ruleListItem));
    }, error => {
      console.error(error);
    });
  }


  /**
   * Unsubscribe to changes to rules
   */
   cancelRuleListSubscription(): void {
    if (this.ruleListSubscription && !this.ruleListSubscription.closed) {
      this.ruleListSubscription.unsubscribe();
    }
  }

  /**
   * Unsubscribe to changes to rules detail
   */
   cancelRuleDetailSubscription(): void {
    if (this.ruleDetailSubscription && !this.ruleDetailSubscription.closed) {
      this.ruleDetailSubscription.unsubscribe();
    }
  }

  /**
   * Unsubscribe to changes to rules history
   */
   cancelRuleHistoryListSubscription(): void {
    if (this.ruleHistoryListSubscription && !this.ruleHistoryListSubscription.closed) {
      this.ruleHistoryListSubscription.unsubscribe();
    }
  }

  /**
   * Unsubscribe to changes to profile rules
   */
   cancelProfileRulesSubscription(): void {
    if (this.profileRulesSubscription && !this.profileRulesSubscription.closed) {
      this.profileRulesSubscription.unsubscribe();
    }
  }

  /**
   * Unsubscribe from all service subscriptions
   */
  ngOnDestroy(): void {
    this.cancelProfileRulesSubscription();
    this.cancelRuleListSubscription();
    this.cancelRuleDetailSubscription();
    this.cancelRuleHistoryListSubscription();
    if (this.logoutSubscription) {
      this.logoutSubscription.unsubscribe();
    }
  }
}
