import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Navigate } from '@ngxs/router-plugin';
import { Subscription } from 'rxjs';

import { AuthState } from './../../shared/state/auth/auth.state';
import { Login, LoginFailed } from '../../shared/state/auth/auth-state.actions';
import { LoginCredentials } from './login-credentials.model';
import { LoginFormService } from './login-form.service';
import { LoginService } from '../../shared/services/login.service';
import { PatientsService } from '../profiles/patients.service';
import { SettingsService } from '../../core/settings/settings.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { TranslatorService } from '../../core/translator/translator.service';

const TENERA_DOMAIN_SUFFIX = '@teneralogin.com';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [LoginFormService]
})
export class LoginComponent implements OnInit, OnDestroy {
  isInvalid: boolean;
  isOffline: boolean;
  isPcc = false;
  loginForm: UntypedFormGroup;
  private loginFailedSubscription: Subscription;

  constructor(
    private actions: Actions,
    private loginFormService: LoginFormService,
    private loginService: LoginService,
    private settings: SettingsService,
    private store: Store,
    private patientsService: PatientsService,
    private firebaseAuth: AngularFireAuth,
    private translator: TranslatorService) { }

  async ngOnInit() {
    // prevent users from accessing login page if logged in
    if (this.store.selectSnapshot(AuthState.token)) {
      this.store.dispatch(new Navigate(['dashboard']));
    }

    this.loginService.clearPccContext();

    // detect device language and use for default language
    await this.firebaseAuth.useDeviceLanguage();
    let language = await this.firebaseAuth.languageCode;
    if (!language) {
      language = 'en';
    } else if (language.indexOf('-') > 0) {
      language = language.split('-')[0];
    }
    this.translator.useLanguage(language);

    // set up subscription to LoginFailed action
    this.loginFailedSubscription = this.actions.pipe(ofActionDispatched(LoginFailed)).subscribe(() => {
      if (navigator.onLine) {
        this.isInvalid = true;
      } else {
        this.isOffline = true;
      }
      this.loginFormService.resetForm();
    });

    // initialize form
    this.loginForm = this.loginFormService.form;
  }

  /**
   * Attempt login if within permitted geographic regions
   *
   * @param credentials Username and password collected from the login form
   */
  async login(credentials: LoginCredentials) {
    try {
      // const isGeoAllowed = await this.loginService.isGeoAllowed();
      let fullEmail = credentials.username;
      if (!credentials.username?.includes('@')) {
        fullEmail = `${credentials.username}${TENERA_DOMAIN_SUFFIX}`;
      }
      const isGeoAllowed = true;
      if (isGeoAllowed) {
        this.store.dispatch(new Login( {username: fullEmail, password: credentials.password} ));
      }
    } catch (error) {
      if (navigator.onLine) {
        this.isInvalid = true;
      } else {
        this.isOffline = true;
      }
      this.loginFormService.resetForm();
    }
  }

  pccLogin() {
    const pcc = this.patientsService.getPccEnvironment();
    const url = pcc.url;
    const clientId = pcc.clientId;
    const responseType = pcc.responseType;
    const redirectUri = encodeURI(pcc.redirectUri);
    const state = this.generateStateCode();
    this.loginService.setState(state);
    window.location.href = url +
      '?scope=openid' +
      '&client_id=' + clientId +
      '&response_type=' + responseType +
      '&redirect_uri=' + redirectUri +
      '&state=' + state;
  }

  /**
   * Get an app setting value from the settings service
   *
   * @param name Name of the app setting to retrieve
   */
  getAppSetting(name: string) {
    return this.settings.getAppSetting(name);
  }

  private generateStateCode() {
    let code = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 8; i++) {
      code += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return code;
  }

  openPrivacyPolicyLink() {
    window.open("https://tenera.care/privacy-policy/");
  }

  ngOnDestroy() {
    if (this.loginFailedSubscription) {
      this.loginFailedSubscription.unsubscribe();
    }
  }

}
