<form [formGroup]='ruleForm' (ngSubmit)='submit()'>
  <h1 mat-dialog-title>{{ data.ruleMode }} Rule <span *ngIf="!(isRuleEditor$ | async)">Request</span></h1>

  <!-- START Create/Update Form -->
  <mat-dialog-content *ngIf="data.ruleMode != 'Delete'">
    <!-- START Event Type -->
    <div class="ruleSection">
      <h4>Event Type</h4>
      <mat-form-field class="full-width" appearance="outline">
        <mat-label>Select Event Type</mat-label>
        <mat-select formControlName="eventType" (selectionChange)="onEventTypeSelected()" required>
          <mat-option *ngFor="let eventType of eventTypes" [value]="eventType?.value">{{ eventType?.label }}</mat-option>
        </mat-select>
        <mat-error *ngIf="ruleForm.controls['eventType'].hasError('required') && (ruleForm.controls['eventType'].dirty || ruleForm.controls['eventType'].touched)">Please select an event type</mat-error>
      </mat-form-field>
    </div>
    <!-- END Event Type -->

    <!-- Dynamic fields based on Event Type -->
    <!-- START Triggers section -->
    <div class="ruleSection">
      <h4>Trigger</h4>

      <!-- START Geofence triggers -->
      <div *ngIf="ruleForm.controls['eventType'].value === 'Geofence' || ruleForm.controls['eventType'].value === 'Zone'">
        <section>
          <mat-radio-group class="rule-radio-group" formControlName="geofenceTrigger">
            <mat-radio-button class="rule-radio-button" value="Geofence Type" (change)="onTriggerTypeSelected('Geofence Type')">Zone Type</mat-radio-button>
            <mat-radio-button  class="rule-radio-button" value="Geofence ID" (change)="onTriggerTypeSelected('Geofence ID')">Zone Name</mat-radio-button>
          </mat-radio-group>
        </section>

        <mat-form-field appearance="outline" class="half-width left-side">
          <mat-label>Select Action</mat-label>
          <mat-select formControlName="action" (selectionChange)="onActionSelected()" required>
            <mat-option *ngFor="let action of actions" [value]="action">{{ action }}</mat-option>
          </mat-select>
          <mat-error *ngIf="ruleForm.controls['action'].hasError('required') && (ruleForm.controls['action'].dirty || ruleForm.controls['action'].touched)">Please select an action</mat-error>
        </mat-form-field>

        <!-- Dropdown for geofence types -->
        <mat-form-field appearance="outline" class="half-width right-side" *ngIf="ruleForm.controls['geofenceTrigger'].value === 'Geofence Type'">
          <mat-label>Select Zone Type</mat-label>
          <mat-select formControlName="triggerValue" (selectionChange)="onTriggerSelected()">
            <mat-option *ngFor="let geofenceType of geofenceTypes" [value]="geofenceType">{{ geofenceType }}</mat-option>
          </mat-select>
        </mat-form-field>
        <alert type="info" *ngIf="geofenceWarnText && ruleForm.controls['geofenceTrigger'].value === 'Geofence Type' && ruleForm.controls['triggerValue'].value">No {{ ruleForm.controls['triggerValue'].value }} zones are present in this venue</alert>

        <!-- Dropdown for geofence names -->
        <mat-form-field appearance="outline" class="half-width right-side" *ngIf="geofences && ruleForm.controls['geofenceTrigger'].value === 'Geofence ID' &&
            ruleForm.controls['action'].value !== 'Exit Assigned Bed' &&
            ruleForm.controls['action'].value !== 'Enter Assigned Bathroom'">
          <mat-label>Select Zone Name</mat-label>
          <mat-select formControlName="geofence">
            <mat-option value="" disabled class="option-header">{{unit.name}} - {{floorName}} Zones</mat-option>
            <ng-container *ngFor="let geofenceName of geofences"><mat-option *ngIf="geofenceName.unitId === unit.unitId" [value]="geofenceName.label">{{ geofenceName.label }}</mat-option></ng-container>
            <mat-option value="" disabled class="option-header"><ng-container *ngIf="(units$ | async).length > 1">{{floorName}} Zones in Other Units</ng-container></mat-option>
            <ng-container *ngFor="let geofenceName of geofences"><mat-option *ngIf="geofenceName.unitId != '*' && geofenceName.unitId != unit.unitId" [value]="geofenceName.label">{{ geofenceName.label }}</mat-option></ng-container>

            <!-- <mat-option *ngFor="let geofenceName of geofences" [value]="geofenceName.label">{{geofenceName.label}}</mat-option> -->
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="half-width" *ngIf="!geofences && ruleForm.controls['geofenceTrigger'].value === 'Geofence ID'">
          <input matInput readonly>
          <mat-label>Assign the profile to a floor to populate geofences</mat-label>
        </mat-form-field>

        <!-- Field for Exit Assigned Bed selection -->
        <mat-form-field appearance="outline" class="half-width right-side" *ngIf="ruleForm.controls['action'].value === 'Exit Assigned Bed'">
          <input matInput formControlName="geofence" readonly>
          <mat-label>Exit Bed Geofence</mat-label>
        </mat-form-field>

        <!-- Field for Enter Assigned Bathroom selection -->
        <mat-form-field appearance="outline" class="half-width right-side" *ngIf="ruleForm.controls['action'].value === 'Enter Assigned Bathroom'">
          <input matInput formControlName="geofence" readonly>
          <mat-label>Enter Assigned Bathroom</mat-label>
        </mat-form-field>
      </div>
      <!-- END Geofence triggers -->

      <!-- START Not Own Room triggers -->
      <div *ngIf="ruleForm.controls['eventType'].value === 'Not Own Room'">
        <mat-form-field appearance="outline" class="half-width">
          <input matInput formControlName="ownRoomName" readonly>
          <mat-label>Enter bedroom other than</mat-label>
        </mat-form-field>
      </div>
      <!-- END Not Own Room triggers -->

      <!-- START Proximity triggers -->
      <div *ngIf="ruleForm.controls['eventType'].value === 'Proximity'">
        <mat-form-field appearance="outline" class="half-width left-side">
          <mat-label>Distance from trigger profile in meters</mat-label>
          <mat-select formControlName="threshold">
            <mat-option *ngFor="let distance of proximityDistances" [value]="distance">{{ distance }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="half-width right-side" *ngIf="proximityNames">
          <mat-label>Select a trigger profile</mat-label>
          <mat-select formControlName="proximityTrigger">
            <ng-container *ngFor="let triggerName of proximityNames"><mat-option *ngIf="triggerName.unitId === '*'" [value]="triggerName.label">{{ triggerName.label }}</mat-option></ng-container>
            <mat-option value="" disabled class="option-header">{{unit.name}} Residents</mat-option>
            <ng-container *ngFor="let triggerName of proximityNames"><mat-option *ngIf="triggerName.unitId === unit.unitId" [value]="triggerName.label">{{ triggerName.label }}</mat-option></ng-container>
            <mat-option value="" disabled class="option-header"><ng-container *ngIf="(units$ | async).length > 1">Residents in Other Units</ng-container></mat-option>
            <ng-container *ngFor="let triggerName of proximityNames"><mat-option *ngIf="triggerName.unitId != '*' && triggerName.unitId != unit.unitId" [value]="triggerName.label">{{ triggerName.label }}</mat-option></ng-container>

          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="half-width" *ngIf="!proximityNames">
          <input matInput readonly>
          <mat-label>No options available</mat-label>
        </mat-form-field>
      </div>
      <!-- END Proximity triggers -->

      <!-- START On Floor triggers -->
      <div *ngIf="ruleForm.controls['eventType'].value === 'On Floor'">

      </div>
      
      <!-- START Geofence Proximity triggers -->
      <div *ngIf="ruleForm.controls['eventType'].value === 'Geofence' && ruleForm.controls['eventCategory'].value === 'Distance'">
        <mat-form-field appearance="outline" class="full-width left-side">
          <mat-label>Distance from trigger geofence in meters</mat-label>
          <mat-select formControlName="threshold">
            <mat-option *ngFor="let distance of proximityDistances" [value]="distance">{{ distance }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <!-- END Proximity triggers -->

    </div>
    <!-- END Triggers section -->

    <!-- START Enforcement -->
    <div class="ruleSection" *ngIf="(profileType$ | async).ruleSettings.showRuleEnforcement">
      <h4>Rule Enforcement</h4>
      <!-- Radio button group to specify when to apply rule -->
      <section>
        <mat-radio-group class="rule-radio-group" formControlName="ruleEnforcement">
          <mat-radio-button class="rule-radio-button" value="always">Always apply rule</mat-radio-button>
          <mat-radio-button  class="rule-radio-button" value="awake">Apply rule during waking hours</mat-radio-button>
          <mat-radio-button  class="rule-radio-button" value="asleep">Apply rule during sleeping hours</mat-radio-button>
        </mat-radio-group>
      </section>
    </div>
    <!-- END Enforcement -->

    <div class="request-history-info">
      <!-- START Reason -->
      <div class="ruleSection request-history-reason">
        <h4>{{ reasonLabel }}</h4>
        <section>
          <mat-form-field appearance="outline" class="full-width">
            <input matInput formControlName="reason" required>
            <mat-label>Reason</mat-label>
          <mat-error *ngIf="(ruleForm.controls['reason'].hasError('required') && (ruleForm.controls['reason'].dirty || ruleForm.controls['reason'].touched))">Please enter reason for rule</mat-error>
          </mat-form-field>
        </section>
      </div>
      <!-- END Reason -->


      <!-- START Requestor Type -->
      <div class="ruleSection">
        <h4>Requested By</h4>
        <mat-form-field class="full-width" appearance="outline">
          <mat-label>Select Requestor Type</mat-label>
          <mat-select formControlName="requestorType" required>
            <mat-option *ngFor="let requestor of requestorTypes" [value]="requestor.value">{{ requestor.label }}</mat-option>
          </mat-select>
          <mat-error *ngIf="(ruleForm.controls['requestorType'].hasError('required') && (ruleForm.controls['requestorType'].dirty || ruleForm.controls['requestorType'].touched))">Please select a requestor type</mat-error>
        </mat-form-field>
      </div>
      <!-- END Requestor Type -->
    </div>

  </mat-dialog-content>
  <!-- END Create/Update Form -->

  <!-- START Delete Form -->
  <mat-dialog-content *ngIf="data.ruleMode === 'Delete'">
    <!-- START Reason -->
    <div class="ruleSection">
      <h4>{{ reasonLabel }}</h4>
      <section>
        <mat-form-field appearance="outline" class="full-width">
          <input matInput formControlName="reason" required>
          <mat-label>Reason</mat-label>
          <mat-error *ngIf="(ruleForm.controls['reason'].hasError('required') && (ruleForm.controls['reason'].dirty || ruleForm.controls['reason'].touched))">Please enter reason for rule</mat-error>
        </mat-form-field>
      </section>
    </div>
    <!-- END Reason -->
  </mat-dialog-content>
  <!-- END Delete Form -->
  <hr>
  <!-- Alert element to display error messages -->
  <alert type="danger" class="text-center" dismissible="true" *ngIf="errorMessage">
    {{ errorMessage }}
  </alert>

  <mat-dialog-actions>
    <button mat-button type='submit' [disabled]="!isFormValid()">{{ submitButtonLabel }}</button>
    <button mat-button type='button' (click)="resetForm()" mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
</form>
