const Dashboard = {
  text: 'Dashboard',
  link: '/dashboard',
  icon: 'icon-speedometer',
  translate: 'sidebar.DASHBOARD',
  permission: ['dashboard-viewer']
};

const Notifications = {
  text: 'Notifications',
  link: '/notifications',
  icon: 'icon-bell',
  translate: 'sidebar.NOTIFICATIONS',
  permission: ['notification-viewer', 'notification-editor']
};

const Residents = {
  text: 'Residents',
  link: '/profiles/resident',
  icon: 'fa fa-user',
  translate: 'sidebar.RESIDENTS',
  permission: ['profile-viewer', 'profile-editor']
};

const Staff = {
  text: 'Staff',
  link: '/profiles/staff',
  icon: 'fa fa-user-md',
  translate: 'sidebar.STAFF',
  permission: ['profile-viewer', 'profile-editor']
};

const Visitors = {
  text: 'Visitors',
  link: '/profiles/visitor',
  icon: 'fa fa-user-plus',
  translate: 'sidebar.VISITORS',
  permission: ['profile-viewer', 'profile-editor']
};

const Assets = {
  text: 'Assets',
  link: '/profiles/asset',
  icon: 'fa fa-hdd',
  translate: 'sidebar.ASSETS',
  permission: ['profile-viewer', 'profile-editor']
};

const Fixtures = {
  text: 'Call Stations',
  link: '/profiles/fixture',
  icon: 'fa fa-pager',
  translate: 'sidebar.CALL_STATIONS',
  permission: ['venue-editor']
};

const Rules = {
  text: 'Notification Rules',
  link: '/rules',
  icon: 'fa fa-user-cog',
  translate: 'sidebar.RULES',
  permission: ['rule-viewer', 'rule-editor']
};

const Users = {
  text: 'Users',
  link: '/users',
  icon: 'fa fa-user-circle',
  translate: 'sidebar.USERS',
  permission: ['user-editor']
};

const Reports = {
  text: 'Reports',
  link: '/reports',
  icon: 'fa fa-chart-bar',
  translate: 'sidebar.REPORTS',
  permission: ['report-viewer']
};

const Trends = {
  text: 'Trend Alerts',
  link: '/trends',
  icon: 'fa fa-random',
  translate: 'sidebar.TRENDS',
  permission: ['trend-alert-viewer', 'trend-alert-editor'],
  alert: 5,
  label: "badge badge-danger"
};

const Account = {
  text: 'Account',
  link: '/account',
  icon: 'fa fa-edit',
  translate: 'sidebar.ACCOUNT',
  permission: ['any']
};

const Support = {
  text: 'Support',
  link: '/support',
  icon: 'fa fa-comments',
  translate: 'sidebar.SUPPORT',
  permission: ['any']
};

export const menu = [
  Dashboard,
  Notifications,
  Residents,
  Staff,
  Visitors,
  Assets,
  Fixtures,
  Rules,
  Users,
  Reports,
  Trends,
  Account,
  Support
];
