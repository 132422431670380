import { LayoutComponent } from '../layout/layout.component';

import { LoginComponent } from './login/login.component';
import { AuthComponent } from './auth/auth.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { AuthGuard } from '../shared/services/auth.guard';
import { PasswordRecoverComponent } from './password/recover/password-recover.component';
import { PasswordResetComponent } from './password/reset/password-reset.component';
import { PasswordChangeComponent } from './password/change/password-change.component';
import { Routes } from "@angular/router";

export const routes: Routes = [

  // Not lazy-loaded routes
  { path: 'login', component: LoginComponent },
  { path: 'authorize', component: AuthComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'password-change', component: PasswordChangeComponent },
  { path: 'password-recover', component: PasswordRecoverComponent },
  { path: 'password-reset', component: PasswordResetComponent },

  // Lazy-loaded routes
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
      { path: 'notifications', loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule) },
      { path: 'profiles', loadChildren: () => import('./profiles/profiles.module').then(m => m.ProfilesModule) },
      { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule) },
      { path: 'rules', loadChildren: () => import('./rules/rules.module').then(m => m.RulesModule) },
      { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule) },
      { path: 'trends', loadChildren: () => import('./trends/trends.module').then(m => m.TrendsModule) },
      { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
      { path: 'support', loadChildren: () => import('./support/support.module').then(m => m.SupportModule) },
    ]
  },

  // Not found
  { path: '**', redirectTo: 'dashboard' }

];
