import { Actions, Store } from '@ngxs/store';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { TrendAlert } from '../models/trend-alert.model';
import { environment } from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class TrendAlertsService {

  constructor(
    private actions: Actions,
    private afs: AngularFirestore,
    private http: HttpClient,
    private store: Store) {}

  /**
   * Subscribe to active trends for a unit
   *
   * @param organization The selected organization
   * @param venueId The ID of the venue containing the selected unit
   * @param unitIds The ID of the selected units
   */
  fetchActiveTrendAlertCount(organization: string, venueId: string, unitIds: string[]) {
    const trendsUrl = 'organizations/' + organization + '/venues/' + venueId + '/trendNotifications';
    const activeTrendsCollection = this.afs.collection<TrendAlert>(trendsUrl, ref => ref.where('clearedDate', '==', null).where('unitId', 'in', unitIds));
    return activeTrendsCollection.snapshotChanges().pipe(map(c => {
      return c.length;
    }));
  }

  /**
   * Subscribe to trends for a unit within a specified time
   *  interval and update trends list in state on change
   *
   * @param organization The selected organization
   * @param venueId The ID of the venue containing the selected unit
   * @param unitIds The IDs of the selected units
   * @param minTime The minimum time value to report
   */
    fetchTrendAlertList(organization: string, venueId: string, unitIds: string[], minTime: number = null) : Observable<TrendAlert[]> {
      const trendsUrl = 'organizations/' + organization + '/venues/' + venueId + '/trendNotifications';   
      let trendsCollection : AngularFirestoreCollection<TrendAlert>;
      if (minTime) {
        trendsCollection = this.afs.collection<TrendAlert>(trendsUrl, ref => ref.where('createdDate', '>=', new Date(minTime)).where('unitId', 'in', unitIds));
      } else {
        trendsCollection = this.afs.collection<TrendAlert>(trendsUrl, ref => ref.where('clearedDate', '==', null).where('unitId', 'in', unitIds));
      }
      return trendsCollection.snapshotChanges().pipe(map (trendAlertDocs => {
        return trendAlertDocs.map(trendAlertDoc => {
          const data = trendAlertDoc.payload.doc.data();
          const id = trendAlertDoc.payload.doc.id;
          return {id: id, ...data};
        });
      }));
    }
  

  /**
   * Subscribe to a trend document and update trend
   *  alert in state on change
   *
   * @param organization The selected organization
   * @param venueId The ID of the venue containing the selected unit
   * @param docId The firestore document identifier
   */
  fetchTrendAlert(organization: string, venueId: string, docId: string) {
    const trendsUrl = 'organizations/' + organization + '/venues/' + venueId + '/trendNotifications';
    const trendAlertDocument= this.afs.collection<TrendAlert>(trendsUrl).doc(docId);
    return trendAlertDocument.snapshotChanges().pipe(map (trend => {
      const data = trend.payload.data() as TrendAlert;
      const id = trend.payload.id;
      return {id, ...data};
    }));
  }

  /**
   * Update a trend alert in Firestore by setting the fields related to clearing
   *
   * @param organization The selected organization
   * @param venueId The ID of the venue containing the selected unit
   * @param trendAlert The updated trend alert
   */
  async clearTrendAlert(organization: string, venueId: string, trendAlertId: string, name: string) {
    try {
      const notificationsUrl = environment.apiUrl + '/organizations/' + organization + '/venues/' + venueId + '/trend-alerts/' + trendAlertId + '/clear';
      const data = { clearedBy: name };
      const res = await this.http.put<any>(notificationsUrl, data).toPromise();
      return null;
     } catch (error) {
       console.log(error);
     }
  }
}
