import { ProfileType } from './profile-type.model';
import { UntypedFormGroup } from '@angular/forms';
import { GeofenceAction } from '../../../../shared/models/rules/enums/geofence-action.enum';
import { GeofenceType } from '../../../../shared/models/rules/enums/geofence-type.enum';
import { DefaultRule } from './default-rule.model';
import { RuleSettings } from './rule-settings.model';
import { EventType } from '../../../../shared/models/rules/enums/event-type.enum';
import { FormLayout } from './form-layout.model';

export class VisitorProfileType extends ProfileType {
  private static instance: VisitorProfileType;

  readonly id = 'visitor';
  readonly name = 'Visitor';
  readonly namePlural = 'Visitors';
  readonly icon = 'user-plus';
  readonly listReturningOption = true;
  readonly checkForDuplicateName = true;
  readonly listColumns = ['photoUrl', 'firstName', 'lastName', 'reason', 'signout', 'latestTime', 'latestLocation', 'battery'];
  readonly secureColumns = [
    {
      column: 'signout',
      permissions: ['profile-editor']
    }
  ];
  readonly formLayout = <FormLayout>{
    basicProfile: ['photoUrl', 'firstName', 'lastName', 'mobile', 'reasonType', 'reason',
      'visitResident', 'covidScreenComplete', 'covidVaccine', 'temperature'],
    venueProfile: ['label', 'sensorId', 'notes'],
    locationProfile: ['locations']
  };
  readonly ruleSettings = <RuleSettings>{
    level: 3,
    showDisableOnPass: false,
    showRuleEnforcement: false,
    eventTypes: [
      EventType.PROXIMITY,
      EventType.NOT_OWN_ROOM,
      EventType.GEOFENCE,
      // TODO: remove?
      EventType.ON_FLOOR
    ],
    defaultEventType: EventType.PROXIMITY,
    defaultRules: [
      <DefaultRule>{eventType: EventType.GEOFENCE, geofenceAction: GeofenceAction.ENTER, geofenceType: GeofenceType.RESTRICTED, active: true},
      <DefaultRule>{eventType: EventType.GEOFENCE, geofenceAction: GeofenceAction.ENTER, geofenceType: GeofenceType.EXIT, active: true},
    ]
  };
  readonly downloadColumns = [
    'label', 'firstName', 'lastName', 'reason', 'latestTime', 'latestLocation', 'battery'
  ];

  private constructor() { super(); }

  public static getInstance(): VisitorProfileType {
    if (!VisitorProfileType.instance) {
      VisitorProfileType.instance = new VisitorProfileType();
    }
    return VisitorProfileType.instance;
  }

  mapLabel(form: UntypedFormGroup): string {
    const first = form.get('firstName').value;
    const last = form.get('lastName').value;
    return (first && last) ? first + ' ' + last.charAt(0) : null;
  }
}
