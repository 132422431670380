/**
 * Enumeration of possible rule event types
 */
export enum IncidentReportType {
  falls = 'Falls',
  foundOnFloor = 'Found on floor',
  injury = 'Injury - medical intervention',
  lostItems = 'Lost items',
  responsiveBehavior = 'Responsive behavior - (strike out, bite, slap, push, aggression, etc)',
  elopement = 'Elopement',
  other = 'Other'
}