import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Router } from '@angular/router';
import { State, Selector, StateContext, Action, NgxsOnInit } from '@ngxs/store';
import jwt_decode from "jwt-decode";

import * as AuthActions from './auth-state.actions';
import { AuthUtils } from './auth.utils';
import { AuthStateModel } from './auth-state.model';
import { FetchOrganizations, GetPermissionsSuccess, SetPushNotificationRequest, ResetConsoleState } from './../console/console-state.actions';
import { LoginService } from '../../services/login.service';
import { Permissions } from '../../models/permissions.model';
import { createGzip } from 'zlib';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

const API_URL_PREFIX = environment.apiUrl;

/**
 * State class for authentication details
 */
@Injectable()
@State<AuthStateModel>({
  name: 'auth'
})
@Injectable()
export class AuthState implements NgxsOnInit  {

  /**
   * Get the name of the logged in user
   *
   * @returns AuthState.name
   */
  @Selector()
  static accountName(state: AuthStateModel): string {
    return state.name;
  }

  /**
   * Get the JWT token
   *
   * @returns AuthState.token
   */
  @Selector()
  static token(state: AuthStateModel): string {
    return state.token;
  }

  /**
   * Get the Intercom hmac
   *
   * @returns AuthState.intercomToken
   */
  @Selector()
  static intercomToken(state: AuthStateModel): string {
    return state.intercomToken;
  }

  /**
   * Get the Firebase UID of the logged in user
   *
   * @returns AuthState.userId
   */
  @Selector()
  static userId(state: AuthStateModel): string {
    return state.userId;
  }

  constructor(
    private afs: AngularFirestore,
    private firebaseAuth: AngularFireAuth,
    private loginService: LoginService,
    private route: Router,
    private http: HttpClient) {}

  /**
   * Dispatch an AutoAuth Action on startup
   */
  ngxsOnInit(ctx: StateContext<AuthStateModel>) {
    ctx.dispatch(new AuthActions.AutoAuth());
  }

  /**
   * Action handler - dispatched on startup to determine whether
   *  a user is already logged in
   */
  @Action(AuthActions.AutoAuth)
  async autoAuth(ctx: StateContext<AuthStateModel>) {
    const authInformation = AuthUtils.getAuthData();
    if (!authInformation) { // no auth data stored
      ctx.dispatch(new AuthActions.LoginRedirect());
    } else {
      const expiresIn = authInformation.expirationDate.getTime() - new Date().getTime();
      const isGeoAllowed = true;
      // let isGeoAllowed;
      // try {
      //   isGeoAllowed = await this.loginService.isGeoAllowed();
      // } catch (error) {
      //   ctx.dispatch(new AuthActions.AutoAuthFailed());
      // }

      // only proceed if token has not expired AND user is accessing
      // the console from within a permitted country
      if (expiresIn > 0 && isGeoAllowed) {

        this.loginService.setAuthTimer(authInformation.expirationDate);
        ctx.dispatch(new AuthActions.AuthSuccess({
          token: authInformation.token,
          refreshToken: authInformation.refreshToken,
          expirationDate: authInformation.expirationDate,
          userId: authInformation.userId
        }));
      } else {
        ctx.dispatch(new AuthActions.AutoAuthFailed());
      }
    }
  }

  /**
   * Action handler - initiate console login
   */
  @Action(AuthActions.Login)
  async login(ctx: StateContext<AuthStateModel>, { payload }: AuthActions.Login) {
    try {
      const userData = await this.firebaseAuth.signInWithEmailAndPassword(payload.username, payload.password);
      const idTokenResult = await userData.user.getIdTokenResult(true);
      const token = idTokenResult.token;
      const userId = userData.user.uid;
      const refreshToken = userData.user.refreshToken;
      const expirationDate = this.loginService.getExpirationDateFromDate(idTokenResult.expirationTime);
      this.loginService.setAuthTimer(expirationDate);

      ctx.dispatch(new AuthActions.AuthSuccess({
        token: token,
        refreshToken: refreshToken,
        expirationDate: expirationDate,
        userId: userId
      }));
    } catch (error) {
      console.log(error);
      ctx.dispatch(new AuthActions.LoginFailed(error));
    }
  }

  /**
   * Action handler - initiate token refresh
   */
  @Action(AuthActions.Refresh)
  async refresh(ctx: StateContext<AuthStateModel>) {
    try {
      console.log('refreshing token...');
      const token = await (await this.firebaseAuth.currentUser).getIdToken(true);
      const expirationDate = this.loginService.getExpirationDateFromSeconds(3600);
      this.loginService.setAuthTimer(expirationDate);
      ctx.dispatch(new AuthActions.RefreshSuccess({
        token: token,
        expirationDate: expirationDate
      }));

    } catch (error) {
      console.log(error);
      ctx.dispatch([
        new AuthActions.Logout(),
        new AuthActions.RefreshFailed(error)
      ]);
    }
  }

  /**
   * Action handler - initiate console logout
   */
  @Action(AuthActions.Logout)
  async logout(ctx: StateContext<AuthStateModel>) {
    this.loginService.logout();
    AuthUtils.clearAuthData();
    ctx.setState({});
    ctx.dispatch([
      new ResetConsoleState(),
      new AuthActions.LoginRedirect()
    ]);
    await this.firebaseAuth.signOut();
  }


  // Events

  /**
   * Action handler - clean up after expired or
   * invalid data in local storage
   */
  @Action(AuthActions.AutoAuthFailed)
  async onAutoAuthFailed(ctx: StateContext<AuthStateModel>) {
    AuthUtils.clearAuthData(); // remove auth data from local storage
    // TODO this.loginService.clearConsoleStorage(); // remove redirect URL and selected venue
    await this.firebaseAuth.signOut(); // sign out of firebase
    ctx.dispatch(new AuthActions.LoginRedirect());
  }

  /**
   * Action handler - setup authorization context based on access token
   */
  @Action(AuthActions.AuthSuccess)
  async onAuthSuccess(ctx: StateContext<AuthStateModel>, { payload }: AuthActions.AuthSuccess) {

    // start idle timer
    this.loginService.startIdleTimer();
    // save auth data to local storage
    AuthUtils.saveAuthData(payload.token, payload.refreshToken, payload.expirationDate, payload.userId);
    const decodedToken = jwt_decode(payload.token) as {
        name: string, 
        organization: string, 
        location: string, 
        locationAccess: string[], 
        customPermissions: string[], 
        role: string, 
        email: string, 
      };
      
    const intercomToken = await this.loginService.getIntercomHmac(decodedToken.organization);
    // set auth state
    ctx.setState({
      token: payload.token,
      refreshToken: payload.refreshToken,
      userId: payload.userId,
      name: decodedToken.name,
      intercomToken: intercomToken
    });

    // fetch and set user persmissions
    const userLocation = decodedToken.location;
    const userOrganization = decodedToken.organization;
    const userLocationAccess = (decodedToken.locationAccess) ? decodedToken.locationAccess : [];
    try {
      const roleDoc = await this.afs.firestore.collection('organizations/' + userOrganization + '/roles').doc(decodedToken.role).get();
      const customPermissions = (decodedToken.customPermissions) ? decodedToken.customPermissions : [];
      const userPermissions = [...roleDoc.data().permissions, ...customPermissions];
      const permissions: Permissions = {
        permissions: userPermissions,
        role: decodedToken.role,
        userLocation,
        userLocationAccess,
        userOrganization
      } as Permissions;

      ctx.dispatch(new GetPermissionsSuccess(permissions)); // store permissions in ConsoleState
      ctx.dispatch(new FetchOrganizations()); // initiate populating organization access list
    } catch (error) {
      console.log(error);
      ctx.dispatch([
        new AuthActions.Logout(),
        new AuthActions.AuthFailed(error)]);
    }

    let redirectUrl = localStorage.getItem('redirectUrl');

    // if forced password change is enabked and password has expired then redirect
    try {
      const orgDoc = await this.afs.firestore.collection('organizations').doc(userOrganization).get();
      const passwordExpirationEnabled = orgDoc.data().forceNewPasswordMonths;
      if (passwordExpirationEnabled) {
        const passwordExpirationUrl = API_URL_PREFIX + '/organizations/' + userOrganization + '/users/passwordExpiration/' + payload.userId;
        const passwordExpirationDate = await this.http.get<string>(passwordExpirationUrl).toPromise();
        const now = new Date().getTime();
        if (passwordExpirationDate && now > new Date(passwordExpirationDate).getTime()) {
          redirectUrl = '/password-change';
        }
      }
      // get the org setting for push notifications
      const browserPushNotifications = orgDoc.data().featureConfig?.browserPushNotifications;
      if (browserPushNotifications) {
        ctx.dispatch(new SetPushNotificationRequest(true)); 
      } else {
        ctx.dispatch(new SetPushNotificationRequest(false)); 
      }
    } catch (error) {
      console.log(error);
    }

    // redirect after successful login
    if (this.route.url === '/login' || this.route.url.indexOf('/authorize') === 0) {
      if (!redirectUrl || redirectUrl === '/login')  {
        redirectUrl = '/dashboard';
      }
      ctx.dispatch(new Navigate([redirectUrl]));
    }
  }

  /**
   * Action handler - refresh authorization context based on access token
   */
  @Action(AuthActions.RefreshSuccess)
  async onRefreshSuccess(ctx: StateContext<AuthStateModel>, { payload }: AuthActions.RefreshSuccess) {
    AuthUtils.refreshAuthData(payload.token, payload.expirationDate); // update local storage
    ctx.setState({
      ...ctx.getState(),
      token: payload.token
    }); // update auth state
  }

  /**
   * Action handler - redirect the user to the console Login view
   */
  @Action(AuthActions.LoginRedirect)
  onLoginRedirect(ctx: StateContext<AuthStateModel>) {
    ctx.dispatch(new Navigate(['/login']));
  }


}
