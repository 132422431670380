<h1 mat-dialog-title>Duplicate Profile?</h1>
<div mat-dialog-content>
  <p *ngIf="data.existingProfiles.length === 1">A profile with this name exists, do you wish to sign the existing profile into this unit?</p>
  <p *ngIf="data.existingProfiles.length > 1">Profiles with this name exist, do you wish to sign an existing profile into this unit?</p>

  <div *ngFor="let existing of data.existingProfiles" class="profile-list-item">
    <div class="profile-info">
      <strong>Name:</strong> {{existing.profileData.firstName}} {{existing.profileData.lastName}}<br />
      <strong>Reason:</strong> {{existing.profileData.reason}}<br />
      <strong>Units:</strong> {{existing.units.join(', ')}}<br />
    </div>
    <div class="profile-button">
      <button mat-button [mat-dialog-close]="existing" class="btn btn-small float-right btn-info">Sign in {{existing.label}}</button>
    </div>
  </div>
  

</div>
<div mat-dialog-actions>
  <button mat-button (click)="onClickCancel()">Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Create New Profile</button>
</div>