import { ProfileType } from "./profile-type.model";
import { UntypedFormGroup } from "@angular/forms";
import { RuleSettings } from "./rule-settings.model";
import { EventType } from "../../../../shared/models/rules/enums/event-type.enum";
import { FormLayout } from "./form-layout.model";
import { GeofenceAction } from "../../../../shared/models/rules/enums/geofence-action.enum";
import { GeofenceType } from "../../../../shared/models/rules/enums/geofence-type.enum";
import { DefaultRule } from "./default-rule.model";

export class AssetProfileType extends ProfileType {
  private static instance: AssetProfileType;

  readonly id = 'asset';
  readonly name = 'Asset';
  readonly namePlural = 'Assets';
  readonly icon = 'hdd';
  readonly listReturningOption = false;
  readonly checkForDuplicateName = false;
  readonly listColumns = ['photoUrl', 'assetType', 'identifier', 'department', 'latestTime', 'latestLocation', 'battery'];
  readonly secureColumns = [];
  readonly formLayout = <FormLayout>{
    basicProfile: ['photoUrl', 'assetType', 'identifier', 'department', 'contactName', 'contactNumber', 'description'],
    venueProfile: ['label', 'sensorId', 'notes', 'floorId', 'floorNumber', 'roomId', 'roomName'],
    locationProfile: ['locations']
  };
  readonly ruleSettings = <RuleSettings>{
    level: 2,
    showDisableOnPass: false,
    showRuleEnforcement: false,
    eventTypes: [
      EventType.GEOFENCE,
      EventType.NOT_OWN_ROOM,
      EventType.PROXIMITY,
    ],
    defaultEventType: EventType.GEOFENCE,
    defaultRules: [
      <DefaultRule>{eventType: EventType.GEOFENCE, geofenceAction: GeofenceAction.ENTER, geofenceType: GeofenceType.EXIT, active: true},
    ]
  }
  readonly downloadColumns = [
    'label', 'assetType', 'identifier', 'department', 'latestTime', 'latestLocation', 'battery'
  ];

  private constructor(){super();}

  public static getInstance(): AssetProfileType {
    if (!AssetProfileType.instance) {
      AssetProfileType.instance = new AssetProfileType();
    }
    return AssetProfileType.instance;
  }

  mapLabel(form: UntypedFormGroup): string {
    const type = form.get('assetType').value;
    const id = form.get('identifier').value;
    return (type && id) ? type + ' ' + id : null;
  }
}
