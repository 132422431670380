<form [formGroup]='ruleForm' (ngSubmit)='submit()'>
  <h1 mat-dialog-title>Reject Rule Request</h1>

  <!-- START Rejection Form -->
  <mat-dialog-content>
    <!-- START Reason -->
    <div class="ruleSection">
      <h4>{{ reasonLabel }}</h4>
      <section>
        <mat-form-field appearance="outline" class="full-width">
          <input matInput formControlName="reason" required>
          <mat-label>Reason</mat-label>
          <mat-error *ngIf="(ruleForm.controls['reason'].hasError('required') && (ruleForm.controls['reason'].dirty || ruleForm.controls['reason'].touched))">Please enter reason for rejection</mat-error>
        </mat-form-field>
      </section>
    </div>
    <!-- END Reason -->
  </mat-dialog-content>
  <!-- END Rejection Form -->
  <hr>
  <!-- Alert element to display error messages -->
  <alert type="danger" class="text-center" dismissible="true" *ngIf="errorMessage">
    {{ errorMessage }}
  </alert>

  <mat-dialog-actions>
    <button mat-button type='submit' [disabled]="!isFormValid()">{{ submitButtonLabel }}</button>
    <button mat-button type='button' (click)="resetForm()" mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
</form>
