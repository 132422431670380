import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';

import * as ProfileListViewActions from './profile-listview-state.actions';
import { ListViewStateModel } from '../listview-state.model';

/**
 * State class for list views
 */
@Injectable()
@State<ListViewStateModel>({
  name: 'profileListview',
  defaults: {
    filter: '',
    pageIndex: 0,
    pageSize: 10,
    sort: { active: 'displayName', direction: 'asc' }
  }
})
export class ProfileListViewState {

  /**
   * Get the filter string applied to the profile list
   *
   * @returns ProfileListViewState.filter
   */
  @Selector()
  static filter(state: ListViewStateModel): string {
    return state.filter;
  }

  /**
   * Get the sort property applied to the profile list
   *
   * @returns ProfileListViewState.sort.active
   */
  @Selector()
  static sortActive(state: ListViewStateModel): string {
    return state.sort.active;
  }

  /**
   * Get the sort direction applied to the profile list
   *
   * @returns ProfileListViewState.sort.direction
   */
  @Selector()
  static sortDirection(state: ListViewStateModel): string {
    return state.sort.direction;
  }

  /**
   * Get the page index of the profile list
   *
   * @returns ProfileListViewState.pageIndex
   */
  @Selector()
  static pageIndex(state: ListViewStateModel): number {
    return state.pageIndex;
  }

  /**
   * Get the page size of the profile list
   *
   * @returns ProfileListViewState.pageIndex
   */
  @Selector()
  static pageSize(state: ListViewStateModel): number {
    return state.pageSize;
  }

  /**
   * Action handler - set filter
   */
  @Action(ProfileListViewActions.SetProfileFilter)
  onSetProfileFilter(ctx: StateContext<ListViewStateModel>, { filter }: ProfileListViewActions.SetProfileFilter) {
    ctx.patchState({ filter });
  }

  /**
   * Action handler - set page index
   */
  @Action(ProfileListViewActions.SetProfilePageIndex)
  onSetProfilePageIndex(ctx: StateContext<ListViewStateModel>, { pageIndex }: ProfileListViewActions.SetProfilePageIndex) {
    ctx.patchState({ pageIndex });
  }

  /**
   * Action handler - set page size
   */
  @Action(ProfileListViewActions.SetProfilePageSize)
  onSetProfilePageSize(ctx: StateContext<ListViewStateModel>, { pageSize }: ProfileListViewActions.SetProfilePageSize) {
    ctx.patchState({ pageSize });
  }

  /**
   * Action handler - set sort
   */
  @Action(ProfileListViewActions.SetProfileSort)
  onSetProfileSort(ctx: StateContext<ListViewStateModel>, { sort }: ProfileListViewActions.SetProfileSort) {
    ctx.patchState({ sort });
  }

  /**
   * Action handler - reset listview
   */
  @Action(ProfileListViewActions.ResetProfileListView)
  onResetProfileListView(ctx: StateContext<ListViewStateModel>) {
    ctx.setState({
      filter: '',
      pageIndex: 0,
      pageSize: 10,
      sort: { active: 'displayName', direction: 'asc' }
    });
  }

}
