import { Pipe } from "@angular/core";
import * as moment from "moment-timezone";
import { EventType } from "../models/rules/enums/event-type.enum";
import { GeofenceCategory } from "../models/rules/enums/geofence-category.enum";
import { GeofenceTrigger } from "../models/rules/enums/geofence-trigger.enum";
import { GeofenceRule } from "../models/rules/geofence-rule.model";
import { NotOwnRoomRule } from "../models/rules/not-own-room-rule.model";
import { ProximityRule } from "../models/rules/proximity-rule.model";
import { Rule } from "../models/rules/rule.model";


/**
 * Pipe to add spacing to camel case text
 * adopted from here https://stackoverflow.com/questions/48888648/split-camel-case-string-with-space-using-angularjs-filter
 */
@Pipe({
  name: 'humanize'
})
export class HumanizePipe {
  transform(value: string) {
  if ((typeof value) !== 'string') {
    return value;
  }
  value = value.split(/(?=[A-Z])/).join(' ');
  value = value[0].toUpperCase() + value.slice(1);
  return value;
  }
}


/**
 * Pipe to convert datetime to desired timezone and display timezone
 */
@Pipe({
  name: 'timezonePipe'
})
export class TimezonePipe {
  transform(value: number, format: string = 'HH:mm', timezone: string = null, showTz: boolean = false) {
  let dateTime: string;
  let timeformat = format;
    if ((typeof value) !== 'number') {
    return value;
  }
  
  if (showTz) {
    timeformat = format + ' zz';
  }
  
  if (!timezone) {
    dateTime = moment(value).format(timeformat);
  } else {
    dateTime = moment(value).tz(timezone).format(timeformat);
  }
  return dateTime;
  }
}


export class RulesUtils {

  /**
   * Get a human friendly description of a rule
   *
   * @param rule The rule used to genereate a description
   * @param updatedFields Updated fields that should replace the rule fields in the description
   */
   static getRuleDescription(rule: Rule, updatedFields: any = null): string {
    if (rule.eventType === EventType.NOT_OWN_ROOM) {
      if (updatedFields && updatedFields.properties && updatedFields.properties.ownRoomName) {
        return 'Enter Bedroom other than ' + (updatedFields as NotOwnRoomRule).properties.ownRoomName[1];
      }
      return 'Enter Bedroom other than ' + (rule as NotOwnRoomRule).properties.ownRoomName;
    } else if (rule.eventType === EventType.ON_FLOOR) {
      return 'On floor';
    } else if (rule.eventType === EventType.PROXIMITY) {
      let threshold = updatedFields && updatedFields.properties && updatedFields.properties.threshold ?
        (updatedFields as ProximityRule).properties.threshold[1] : (rule as ProximityRule).properties.threshold;
      let triggerName = updatedFields && updatedFields.properties && updatedFields.properties.proximityTriggerName ? 
        (updatedFields as ProximityRule).properties.proximityTriggerName[1] : (rule as ProximityRule).properties.proximityTriggerName;
      return 'Within ' + threshold + ' meters of ' + triggerName;
    } else if (rule.eventType === EventType.GEOFENCE) {
      const geofeneRule = rule as GeofenceRule;

      let eventCategory = geofeneRule.eventCategory;
      if (updatedFields && updatedFields.eventCategory) {
        eventCategory = updatedFields.eventCategory[1];
      }

      let geofenceTrigger = geofeneRule.properties.geofenceTrigger;
      if (updatedFields && updatedFields.properties && updatedFields.properties.geofenceTrigger) {
        geofenceTrigger = updatedFields.properties.geofenceTrigger[1];
      }

      let action = geofeneRule.action;
      if (updatedFields && updatedFields.action) {
        action = updatedFields.action[1];
      }
      let geofenceIdName = geofeneRule.properties.geofenceIdName;
      if (updatedFields && updatedFields.properties && updatedFields.properties.geofenceIdName) {
        geofenceIdName = updatedFields.properties.geofenceIdName[1];
      }
      let triggerValue = geofeneRule.properties.triggerValue;
      if (updatedFields && updatedFields.properties && updatedFields.properties.triggerValue) {
        triggerValue = updatedFields.properties.triggerValue[1];
      }

      if (eventCategory === GeofenceCategory.EXIT_ASSIGNED_BED) {
        return action + ' - ' + geofenceIdName;
      } else if (eventCategory === GeofenceCategory.ENTER_ASSIGNED_BATHROOM) {
        return action + ' - ' + geofenceIdName;
      } else if (geofenceTrigger === GeofenceTrigger.GEOFENCE_TYPE) {
        return action + ' ' + triggerValue + ' zone';
      } else if (geofenceTrigger === GeofenceTrigger.GEOFENCE_ID) {
        return action + ' ' + geofenceIdName + ' zone';
      }
    }
    return 'Invalid rule detected';
  }


}
