import { Store } from '@ngxs/store';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Navigate } from '@ngxs/router-plugin';
import jwt_decode from "jwt-decode";

import { AuthState } from './../../../shared/state/auth/auth.state';
import { PasswordReset } from './password-reset.model';
import { PasswordResetFormService } from './password-reset-form.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { SettingsService } from '../../../core/settings/settings.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Logout } from '../../../shared/state/auth/auth-state.actions';
import { AccountError } from '../../account/models/account-error.model';

const API_URL_PREFIX = environment.apiUrl;

@Component({
  selector: 'app-recover',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss'],
  providers: [PasswordResetFormService]
})
export class PasswordResetComponent implements OnInit {
  invalidCode = false;
  accountError: AccountError = null;
  passwordReset = false;
  resetForm: UntypedFormGroup;
  code = null;
  email = null;
  isLoading = false;

  constructor(
    private resetFormService: PasswordResetFormService,
    private route: ActivatedRoute,
    private store: Store,
    private settings: SettingsService,
    private http: HttpClient,
    private firebaseAuth: AngularFireAuth) { }

  async ngOnInit() {
    // initialize form
    this.resetForm = this.resetFormService.form;

    // prevent users from accessing recover page if logged in
    if (this.store.selectSnapshot(AuthState.token)) {
      this.store.dispatch(new Navigate(['dashboard']));
    }

    // make sure oobCode is valid
    this.route.queryParams.subscribe(async params => {
      this.code = params['oobCode'];
      try {
        this.email = await this.firebaseAuth.verifyPasswordResetCode(this.code);
      } catch(error) {
        console.log(error);
        this.invalidCode = true;
        this.accountError = { error: 'Invalid reset code' } as AccountError;
      }
    });
  }

  /**
   * Attempt recover if within permitted geographic regions
   *
   * @param credentials Username and password collected from the recover form
   */
  async reset(passwordReset: PasswordReset) {
    this.resetMessages();
    this.isLoading = true;

    if (passwordReset.newPassword1 !== passwordReset.newPassword2) {
      this.accountError = { error: 'New password fields must match' } as AccountError;
      this.isLoading = false;
      return;
    }

    try {
      // validate compliance with string password rules
      const validateUrl = API_URL_PREFIX + '/password/validate';
      const credentials = {
        email: this.email,
        password: passwordReset.newPassword1
      }
      await this.http.post<any>(validateUrl, credentials).toPromise();

      // reset the password
      await this.firebaseAuth.confirmPasswordReset(this.code, passwordReset.newPassword1);

      // log in with creds to get token for update password expiration api call
      const userData = await this.firebaseAuth.signInWithEmailAndPassword(this.email, passwordReset.newPassword1);
      const idTokenResult = await userData.user.getIdTokenResult(true);
      const userId = userData.user.uid;
      const decodedToken = jwt_decode(idTokenResult.token) as {
        name: string, 
        organization: string, 
        location: string, 
        locationAccess: string[], 
        role: string, 
        email: string, 
      };
      const organization = decodedToken.organization;
      const passwordExpirationUrl = API_URL_PREFIX + '/organizations/' + organization + '/users/passwordExpiration/' + userId;
      localStorage.setItem('token', idTokenResult.token);
      // call api to update password expiration
      await this.http.put(passwordExpirationUrl, {}).toPromise();
      // reset context
      localStorage.removeItem('token');
      await this.firebaseAuth.signOut();
      this.passwordReset = true;
    } catch (error) {
      this.accountError = { list: error.error.message.errors } as AccountError;
      this.resetFormService.resetForm();
    }
    this.isLoading = false;
  }

  resetMessages() {
    this.accountError = null;
    this.invalidCode = false;
    this.passwordReset = false;
  }

  /**
   * Get an app setting value from the settings service
   *
   * @param name Name of the app setting to retrieve
   */
  getAppSetting(name: string) {
    return this.settings.getAppSetting(name);
  }
}
