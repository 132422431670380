import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class RuleEditorFormService {
  public ruleForm: UntypedFormGroup;

  constructor() {
    this.ruleForm = new UntypedFormGroup({
      reason: new UntypedFormControl(null), // Reason string

    });
  }

  /**
   * Reset all form controls to default
   */
  resetForm(): void {
    this.ruleForm.reset();
  }

  /**
   * Reset fields that depend on event type
   */
  resetRuleParameters(): void {
    this.ruleForm.get('reason').setValue(null);
  }

  /**
   * Set form control values
   *
   */
   populateForm(): void {
    this.ruleForm.get('reason').setValue(null);

  }


  /**
   * Returns whether the form has captured the required fields
   *  for the selected rule type
   */
  isFormValid(): boolean {
    if (this.ruleForm.get('reason').value) {
      return true;
    }
    return false;
  }

}
