import { RuleListItem } from "../../models/rule-list.model";

/**
 * Action dispatched to set the rule list
 */
 export class SetRuleList {
  static type = '[RuleList] SetRuleList';
  constructor(public ruleList: RuleListItem[]) {}
}

/**
 * Action dispatched to reset the rule list state to default
 */
export class ResetRuleListState {
  static type = '[RuleList] ResetRuleListState';
}
