import { AbstractControl } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { debounceTime, map, take } from 'rxjs/operators';
import { Store } from '@ngxs/store';

export class SensorAvailableValidator {
  static sensorId(store: Store, afs: AngularFirestore, state: any, organization: string) {
    return (control: AbstractControl) => {

      const sensorId = control.value;
      // if no value is entered, validation passes
      if (!sensorId) {
        return new Promise((resolve) => {
          resolve(null);
        });
      } else if (store.selectSnapshot(state.mode) === 'Create') {
        // in Create mode return the error if this sensorId is found
        return afs.collection('organizations/' + organization + '/profiles',
          ref => ref.where('sensorId', '==', sensorId) ).valueChanges({ idField: 'id' })
          .pipe(
            debounceTime(500), // wait 500ms after the user stops typing before making the query
            take(1),
            map(arr => arr.length ? { 
              sensorAvailable: false, 
              sensorProfiles: arr.map((a: any) => {
                return a
              })
            } : null )
          );
      } else { // we are in Update mode
        // in Update mode return the error if the sensorId is found
        // and is not assigned to the current profile
        return afs.collection('organizations/' + organization + '/profiles',
          ref => ref.where('sensorId', '==', sensorId)).snapshotChanges()
          .pipe(
            debounceTime(500), // wait 500ms after the user stops typing before making the query
            take(1),
            map(arr => (arr.length && (arr[0].payload.doc.id !== (store.selectSnapshot(state.profileDetail) as any).id))
              ? { sensorAvailable: false, sensorProfiles: arr.map(a => {return {id: a.payload.doc.id, ...(a.payload.doc.data() as Object)}}) } : null )
          );
      }
    };
  }
}
