import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-visitor-sign-out-dialog',
  templateUrl: './visitor-sign-out-dialog.component.html',
  styleUrls: ['./visitor-sign-out-dialog.component.scss']
})
export class VisitorSignOutDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {title: string, message: string},
    public dialogRef: MatDialogRef<VisitorSignOutDialogComponent>) {}


  signOut() {
    this.dialogRef.close(true);
  }

}
