import { AuthState } from './auth/auth.state';
import { ConsoleState } from './console/console.state';
import { ProfileListViewState } from './listviews/profile/profile-listview.state';
import { UserListViewState } from './listviews/user/user-listview.state';
import { NcsChannelsState } from './ncsChannels/ncsChannels.state';
import { SensorsState } from './sensors/sensors.state';

export const States = [
  AuthState,
  ConsoleState,
  SensorsState,
  NcsChannelsState,
  UserListViewState,
  ProfileListViewState
];
