import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

import * as NotificationListActions from './notification-list-state.actions';
import { NotificationListItem } from '../../models/notification-list-item.model';
import { NotificationListStateModel } from './notification-list-state.model';

/**
 * State class for notification list
 */
@State<NotificationListStateModel>({
  name: 'notificationList',
  defaults: {
    notificationList: []
  }
})
@Injectable()
export class NotificationListState {

  /**
   * Get the list of notifications
   *
   * @returns NotificationListState.notificationList
   */
  @Selector()
  static notificationList(state: NotificationListStateModel): NotificationListItem[] {
    return state.notificationList;
  }

  /**
   * Action handler - add a notification to state
   */
  @Action(NotificationListActions.AddNotification)
  onAddNotification(ctx: StateContext<NotificationListStateModel>, action: NotificationListActions.AddNotification) {
    const state = ctx.getState();
    const notificationList = [...state.notificationList];
    notificationList.push(action.notification);
    notificationList.sort(function(a, b) {
      if (a.priority === b.priority) {
        return b.date - a.date;
      }
      return a.priority - b.priority;
    });
    ctx.patchState({
      notificationList
    });
  }

  /**
   * Action handler - delete a notification from state
   */
  @Action(NotificationListActions.DeleteNotification)
  onDeleteNotification(ctx: StateContext<NotificationListStateModel>, action: NotificationListActions.DeleteNotification) {
    const state = ctx.getState();
    const notificationList = [...state.notificationList];
    const index = notificationList.findIndex(x => x.id === action.id);
    notificationList.splice(index, 1);
    ctx.patchState({
      notificationList
    });
  }

  /**
   * Action handler - update a notification in state
   */
  @Action(NotificationListActions.UpdateNotification)
  onUpdateNotification(ctx: StateContext<NotificationListStateModel>, action: NotificationListActions.UpdateNotification) {
    const state = ctx.getState();
    const notificationList = [...state.notificationList];
    const index = notificationList.findIndex(x => x.id === action.notification.id);
    notificationList[index] = action.notification;
    notificationList.sort(function(a, b) {
      if (a.priority === b.priority) {
        return b.date - a.date;
      }
      return a.priority - b.priority;
    });
    ctx.patchState({
      notificationList
    });
  }

  /**
   * Action handler - reset the notification list state to default
   */
  @Action(NotificationListActions.ResetNotificationListState)
  onResetNotificationListState(ctx: StateContext<NotificationListStateModel>) {
    ctx.setState({
      notificationList: []
    });
  }


}
