import { Profile } from '../../models/profile.model';

// Events - Actions that result from an event

/**
 * Action dispatched to set the profile list
 */
export class SetProfileList {
  static type = '[ProfileList] SetProfileList';
  constructor(public profileList: Profile[]) {}
}

/**
 * Action dispatched to reset the profile list state to default
 */
export class ResetProfileListState {
  static type = '[ProfileList] ResetProfileListState';
}
