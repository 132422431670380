import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Injectable } from '@angular/core';

import { EventType } from '../../../../../shared/models/rules/enums/event-type.enum';
import { OnFloorAction } from '../../../../../shared/models/rules/enums/on-floor-action.enum';
import { OnFloorCategory } from '../../../../../shared/models/rules/enums/on-floor-category.enum';
import { GeofenceAction } from '../../../../../shared/models/rules/enums/geofence-action.enum';
import { GeofenceCategory } from '../../../../../shared/models/rules/enums/geofence-category.enum';
import { GeofenceRule } from '../../../../../shared/models/rules/geofence-rule.model';
import { NotifyOnTime } from '../../../../../shared/models/rules/enums/notify-on-time.enum';
import { NotOwnRoomAction } from '../../../../../shared/models/rules/enums/not-own-room-action.enum';
import { NotOwnRoomCategory } from '../../../../../shared/models/rules/enums/not-own-room-category.enum';
import { NotOwnRoomRule } from '../../../../../shared/models/rules/not-own-room-rule.model';
import { OptionItem } from '../../../../../shared/models/option-item.model';
import { ProximityAction } from '../../../../../shared/models/rules/enums/proximity-action.enum';
import { ProximityCategory } from '../../../../../shared/models/rules/enums/proximity-category.enum';
import { ProximityRule } from '../../../../../shared/models/rules/proximity-rule.model';
import { ProfileRule } from '../../../../../shared/models/rules/profile-rule.model';
import { ProfileRuleConstants } from '../../../../../shared/models/rules/profile-rule-constants';
import { ProfileType } from '../../../models/profile-type/profile-type.model';
import { OnFloorRule } from '../../../../../shared/models/rules/on-floor-rule.model';

const DEFAULT_DISTANCE_THRESHOLD = 2;

@Injectable()
export class RuleFormService {
  public ruleForm: UntypedFormGroup;

  constructor() {
    this.ruleForm = new UntypedFormGroup({
      eventType: new UntypedFormControl(null), // required for all rules
      action: new UntypedFormControl(null), // required for all rules
      eventCategory: new UntypedFormControl(null), // required for all rules
      active: new UntypedFormControl(null), // required for all rules
      disableOnPass: new UntypedFormControl(null), // required for all rules
      // radio button group - values ["always", "awake", "asleep"]
      ruleEnforcement: new UntypedFormControl(null), // required for all rules
      rate: new UntypedFormControl(null), // placeholder field not currently used but can enable rate limiting

      // parameter for fall rule and proximity
      threshold: new UntypedFormControl(null), // distance in meters

      // parameter for proximity rule
      proximityTrigger: new UntypedFormControl(null), // profile that triggers the proximity notification

      // parameters for not own room rule
      ownRoomId: new UntypedFormControl(null), // geofence ID of assigned room
      ownRoomName: new UntypedFormControl(null), // name of assigned room

      // parameters for geofence rule
      geofenceTrigger: new UntypedFormControl(null), // radio button group - values ["Geofence Type", "Geofence ID"]
      triggerValue: new UntypedFormControl(null), // "Geofence Type" enum value or "Geofence ID" geofence ID
      geofence: new UntypedFormControl(null), // Geofence if geofenceTrigger = Geofence ID

      // parameters for rule history
      reason: new UntypedFormControl(null), // Reason string
      requestorType: new UntypedFormControl(null), // requestor type string

    });
  }

  /**
   * Reset all form controls to default
   */
  resetForm(): void {
    this.ruleForm.reset();
  }

  /**
   * Reset fields that depend on event type
   */
  resetRuleParameters(): void {
    this.ruleForm.get('action').setValue(null);
    this.ruleForm.get('reason').setValue(null);
    this.ruleForm.get('requestorType').setValue(null);
    this.ruleForm.get('eventCategory').setValue(null);
    this.ruleForm.get('active').setValue(null);
    this.ruleForm.get('disableOnPass').setValue(null);
    this.ruleForm.get('ruleEnforcement').setValue(null);
    this.ruleForm.get('rate').setValue(null);

    this.ruleForm.get('threshold').setValue(null);
    this.ruleForm.get('proximityTrigger').setValue(null);
    this.ruleForm.get('ownRoomId').setValue(null);
    this.ruleForm.get('ownRoomName').setValue(null);
    this.ruleForm.get('geofenceTrigger').setValue(null);
    this.ruleForm.get('triggerValue').setValue(null);
    this.ruleForm.get('geofence').setValue(null);
  }

  /**
   * Set default Geofence rule fields
   */
  setupGeofenceRule(): void {
    this.ruleForm.get('action').setValue(null);
    this.ruleForm.get('active').setValue(true);
    this.ruleForm.get('disableOnPass').setValue(true);
    this.ruleForm.get('ruleEnforcement').setValue('always');
    this.ruleForm.get('geofenceTrigger').setValue('Geofence Type');
  }

  /**
   * Set default Not Own Room rule fields
   */
  setupNotOwnRoomRule(roomId: number, roomName: string): void {
    this.ruleForm.get('action').setValue(NotOwnRoomAction.ENTER);
    this.ruleForm.get('eventCategory').setValue(NotOwnRoomCategory.NOT_OWN_ROOM);
    this.ruleForm.get('active').setValue(true);
    this.ruleForm.get('disableOnPass').setValue(false);
    this.ruleForm.get('ruleEnforcement').setValue('always');
    this.ruleForm.get('ownRoomId').setValue(roomId);
    this.ruleForm.get('ownRoomName').setValue(roomName);
  }

  /**
   * Set default Proximity rule fields
   */
  setupProximityRule(profileType: ProfileType): void {
    this.ruleForm.get('action').setValue(ProximityAction.NEAR);
    this.ruleForm.get('eventCategory').setValue(ProximityCategory.DISTANCING);
    this.ruleForm.get('active').setValue(true);
    this.ruleForm.get('disableOnPass').setValue(false);
    this.ruleForm.get('ruleEnforcement').setValue('always');
    this.ruleForm.get('threshold').setValue(2); // set default to 2 meters
    this.ruleForm.get('rate').setValue(ProfileRuleConstants.ONCE_PER_5_MINUTES_RATE);
  }

  /**
   * Set default On Floor rule fields
   */
  setupOnFloorRule(profileType: ProfileType): void {
    this.ruleForm.get('eventCategory').setValue(OnFloorCategory.ON_FLOOR);
    this.ruleForm.get('action').setValue(OnFloorAction.ON_FLOOR);
    this.ruleForm.get('active').setValue(true);
    this.ruleForm.get('disableOnPass').setValue(false);
    this.ruleForm.get('ruleEnforcement').setValue('always');
  }

  /**
   * Reset the geofence fields
   */
  clearGeofence(): void {
    this.ruleForm.get('action').setValue(null);
    this.ruleForm.get('triggerValue').setValue(null);
    this.ruleForm.get('geofence').setValue(null);
  }

  /**
   * Set form control values with data from an existing rule
   *
   * @param rule The rule to display
   */
  populateFormFromRule(rule: ProfileRule): void {
    this.ruleForm.get('eventType').setValue(rule.eventType);
    this.ruleForm.get('action').setValue(rule.action);
    this.ruleForm.get('active').setValue(rule.active);
    this.ruleForm.get('rate').setValue((rule.rate) ? rule.rate : null);
    this.ruleForm.get('eventCategory').setValue(rule.eventCategory);
    this.ruleForm.get('disableOnPass').setValue(rule.properties.disableOnPass);
    this.ruleForm.get('ruleEnforcement').setValue(this.convertNotifyOnTimeToRuleEnforcement(rule.properties.updateTime));

    if (rule.eventType === EventType.NOT_OWN_ROOM) {
      this.ruleForm.get('ownRoomId').setValue((rule as NotOwnRoomRule).properties.ownRoomId);
      this.ruleForm.get('ownRoomName').setValue((rule as NotOwnRoomRule).properties.ownRoomName);
    } else if (rule.eventType === EventType.PROXIMITY) {
      this.ruleForm.get('threshold').setValue((rule as ProximityRule).properties.threshold);
      this.ruleForm.get('proximityTrigger').setValue((rule as ProximityRule).properties.proximityTriggerName);
    } else if (rule.eventType === EventType.GEOFENCE) {
      this.ruleForm.get('geofenceTrigger').setValue((rule as GeofenceRule).properties.geofenceTrigger);
      if ((rule as GeofenceRule).properties.geofenceTrigger.toString() === 'Geofence Type') {
        this.ruleForm.get('triggerValue').setValue((rule as GeofenceRule).properties.triggerValue);
      } else { // geofenceTrigger == 'Geofence ID'
        this.ruleForm.get('geofence').setValue((rule as GeofenceRule).properties.geofenceIdName);
      }
      if (rule.eventCategory === GeofenceCategory.DISTANCE) {
        this.ruleForm.get('threshold').setValue((rule as ProximityRule).properties.threshold);
      }
    }

    this.ruleForm.get('reason').setValue(null);
    this.ruleForm.get('requestorType').setValue(null);

  }


  /**
   * Set the eventCategory field corresponding to
   *  a selected geofence action
   *
   * @param action The selected geofence action
   */
  setEventCategoryFromAction(action: string): void {
    let category = null;
    switch (action) {
      case GeofenceAction.ENTER:
        category = GeofenceCategory.ZONE_ENTER;
        break;
      case GeofenceAction.EXIT:
        category = GeofenceCategory.ZONE_EXIT;
        break;
      case GeofenceAction.EXIT_ASSIGNED_BED:
        category = GeofenceCategory.EXIT_ASSIGNED_BED;
        break;
      case GeofenceAction.ENTER_ASSIGNED_BATHROOM:
        category = GeofenceCategory.ENTER_ASSIGNED_BATHROOM;
        break;
      case GeofenceAction.NEAR:
        category = GeofenceCategory.DISTANCE;
        break;
    }
    // set the default rule distance
    if (category === GeofenceCategory.DISTANCE) {
      this.ruleForm.get('threshold').setValue(DEFAULT_DISTANCE_THRESHOLD); 
    } else {
      this.ruleForm.get('threshold').setValue(null); 
    }
    this.ruleForm.get('eventCategory').setValue(category);
  }

  /**
   * Returns whether the form has captured the required fields
   *  for the selected rule type
   */
  isFormValid(): boolean {
    if (this.ruleForm.get('eventType').value && this.ruleForm.get('action').value &&
      this.ruleForm.get('eventCategory').value && this.ruleForm.get('ruleEnforcement').value) {

      // all required common fields are specified, so check rule specific fields
       if (this.ruleForm.get('eventType').value === EventType.NOT_OWN_ROOM
        && this.ruleForm.get('ownRoomId').value && this.ruleForm.get('ownRoomName').value) {
        return true;
      } else if (this.ruleForm.get('eventType').value === EventType.PROXIMITY && this.ruleForm.get('threshold').value
        && this.ruleForm.get('proximityTrigger').value) {
        return true;
      } else if (this.ruleForm.get('eventType').value === EventType.GEOFENCE && this.ruleForm.get('triggerValue').value !== null
        && this.ruleForm.get('geofenceTrigger').value === 'Geofence Type') {
        return true;
      } else if (this.ruleForm.get('eventType').value === EventType.GEOFENCE && this.ruleForm.get('geofence').value !== null
        && this.ruleForm.get('geofenceTrigger').value === 'Geofence ID') {
        return true;
      } else if (this.ruleForm.get('eventType').value === EventType.ON_FLOOR) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  /**
   * Return a GeofenceRule from form values
   */
  getFormAsGeofenceRule(geofences: OptionItem[], roomId: number, bedId: number, bathroomId: number): GeofenceRule {
    const geofenceTrigger = this.ruleForm.get('geofenceTrigger').value;
    let updateRoomOnChange = false;
    let updateBedOnChange = false;
    let updateBathroomOnChange = false;
    let triggerValue = null;
    let geofenceIdName = null;

    // if geofenceTrigger == "Geofence Type"
    // type will be in the triggerValue form control
    if (geofenceTrigger === 'Geofence Type') {
      triggerValue = this.ruleForm.get('triggerValue').value;
    } else { // geofenceTrigger == "Geofence ID"
      // get the ID of the trigger geofence from the geofences array
      geofenceIdName = this.ruleForm.get('geofence').value;
      const index = geofences.findIndex(x => x.label === geofenceIdName);
      triggerValue = geofences[index].value;
      // set update flags if this geofence is the profile room or profile bed
      if (triggerValue === roomId) {
        updateRoomOnChange = true;
      } else if (triggerValue === bedId) {
        updateBedOnChange = true;
      } else if (triggerValue === bathroomId) {
        updateBathroomOnChange = true;
      }
    }

    let properties;

    if (this.ruleForm.get('eventCategory').value === GeofenceCategory.DISTANCE) {
      properties = {
        disableOnPass: this.ruleForm.get('disableOnPass').value,
        updateRoomOnChange: updateRoomOnChange,
        updateBedOnChange: updateBedOnChange,
        updateBathroomOnChange: updateBathroomOnChange,
        updateTime: this.convertRuleEnforcementToNotifyOnTime(),
        geofenceTrigger: geofenceTrigger,
        triggerValue: triggerValue,
        geofenceIdName: geofenceIdName,
        threshold: this.ruleForm.get('threshold').value
      }
    } else {
      properties = {
        disableOnPass: this.ruleForm.get('disableOnPass').value,
        updateRoomOnChange: updateRoomOnChange,
        updateBedOnChange: updateBedOnChange,
        updateBathroomOnChange: updateBathroomOnChange,
        updateTime: this.convertRuleEnforcementToNotifyOnTime(),
        geofenceTrigger: geofenceTrigger,
        triggerValue: triggerValue,
        geofenceIdName: geofenceIdName
      }
    }

    return {
      action: this.ruleForm.get('action').value,
      active: this.ruleForm.get('active').value,
      eventCategory: this.ruleForm.get('eventCategory').value,
      eventType: this.ruleForm.get('eventType').value,
      rate: this.ruleForm.get('rate').value,
      properties: properties
    };
  }

  /**
   * Return a NotOwnRoomRule from form values
   */
  getFormAsNotOwnRoomRule(): NotOwnRoomRule {
    return {
      action: this.ruleForm.get('action').value,
      active: this.ruleForm.get('active').value,
      eventCategory: this.ruleForm.get('eventCategory').value,
      eventType: this.ruleForm.get('eventType').value,
      rate: this.ruleForm.get('rate').value,
      properties: {
        disableOnPass: this.ruleForm.get('disableOnPass').value,
        updateRoomOnChange: true,
        updateBedOnChange: false,
        updateBathroomOnChange: false,
        updateTime: this.convertRuleEnforcementToNotifyOnTime(),
        ownRoomId: this.ruleForm.get('ownRoomId').value,
        ownRoomName: this.ruleForm.get('ownRoomName').value
      }
    };
  }

  /**
   * Return a ProximityRule from form values
   */
  getFormAsProximityRule(proximityNames: OptionItem[]): ProximityRule {
    // get the ID of the trigger profile from the proximityNames array
    const index = proximityNames.findIndex(x => x.label === this.ruleForm.get('proximityTrigger').value);
    const proximityTriggerID = proximityNames[index].value;
    return {
      action: this.ruleForm.get('action').value,
      active: this.ruleForm.get('active').value,
      eventCategory: this.ruleForm.get('eventCategory').value,
      eventType: this.ruleForm.get('eventType').value,
      rate: this.ruleForm.get('rate').value,
      properties: {
        disableOnPass: this.ruleForm.get('disableOnPass').value,
        updateRoomOnChange: false,
        updateBedOnChange: false,
        updateBathroomOnChange: false,
        updateTime: this.convertRuleEnforcementToNotifyOnTime(),
        proximityTriggerId: proximityTriggerID,
        proximityTriggerName: this.ruleForm.get('proximityTrigger').value,
        threshold: this.ruleForm.get('threshold').value
      }
    };
  }

  /**
   * Return a On Floor from form values
   */
  getFormAsOnFloorRule(): OnFloorRule {
    return {
      action: this.ruleForm.get('action').value,
      active: this.ruleForm.get('active').value,
      eventCategory: this.ruleForm.get('eventCategory').value,
      eventType: this.ruleForm.get('eventType').value,
      rate: this.ruleForm.get('rate').value,
      properties: {
        disableOnPass: this.ruleForm.get('disableOnPass').value,
        updateRoomOnChange: false,
        updateBedOnChange: false,
        updateBathroomOnChange: false,
        updateTime: this.convertRuleEnforcementToNotifyOnTime(),
      }
    };
  }


  /**
   * Convert the form ruleEnforcement selection to a NotifyOnTime enum
   */
  convertRuleEnforcementToNotifyOnTime(): NotifyOnTime {
    const ruleEnforcement = this.ruleForm.get('ruleEnforcement').value;
    if (ruleEnforcement === 'always') {
      return NotifyOnTime.NEVER;
    } else if (ruleEnforcement === 'awake') {
      return NotifyOnTime.RISE_TO_SLEEP;
    } else if (ruleEnforcement === 'asleep') {
      return NotifyOnTime.SLEEP_TO_RISE;
    }
  }

  /**
   * Convert the a NotifyOnTime enum to a string
   */
  convertNotifyOnTimeToRuleEnforcement(notifyOnTime: NotifyOnTime): string {
    if (notifyOnTime === NotifyOnTime.NEVER) {
      return 'always';
    } else if (notifyOnTime === NotifyOnTime.RISE_TO_SLEEP) {
      return 'awake';
    } else if (notifyOnTime === NotifyOnTime.SLEEP_TO_RISE) {
      return 'asleep';
    }
  }
}
