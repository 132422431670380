import { OnInit, Directive, Input, ElementRef, Renderer2, HostListener } from '@angular/core';
declare var $: any;

@Directive({
    selector: 'scrollable'
})
export class ScrollableDirective implements OnInit {

    timer = null;

    @HostListener('mouseover')
    onMouseOver() {
        clearTimeout(this.timer);
        let scrollbar = 'auto';
        // only chromium browsers support overlay
        if (navigator.vendor.match(/google/i)) {
            scrollbar = 'overlay';
        }
        this.renderer.setStyle(this.element.nativeElement, 'overflow-y', scrollbar);
        this.renderer.setStyle(this.element.nativeElement, 'overflow-x', scrollbar);
    }

    @HostListener('mouseout')
    onMouseOut() {
        let scrollbar =  'hidden';
        // safari looks bad when reverting to hidden
        if (navigator.vendor.match(/apple/i)) {
            scrollbar = 'auto';
        }
        this.timer = setTimeout(()=>{
            this.renderer.setStyle(this.element.nativeElement, 'overflow-y', scrollbar);
            this.renderer.setStyle(this.element.nativeElement, 'overflow-x', scrollbar);
            this.renderer.setStyle(this.element.nativeElement, 'width', '100%');
        }, 500);
    }

    @HostListener('touchstart', ['$event'])
    @HostListener('mousedown')
    onMouseDown() {
        clearTimeout(this.timer);
        let scrollbar = 'auto';
        // only chromium browsers support overlay
        if (navigator.vendor.match(/google/i)) {
            scrollbar = 'overlay';
        }
        this.renderer.setStyle(this.element.nativeElement, 'overflow-y', scrollbar);
        this.renderer.setStyle(this.element.nativeElement, 'overflow-x', scrollbar);
    }

    @Input() height: number;
    defaultHeight = 250;

    constructor(public element: ElementRef, private renderer: Renderer2) { }

    ngOnInit() {
        this.renderer.setStyle(this.element.nativeElement, 'overflow-y', 'hidden');
        this.renderer.setStyle(this.element.nativeElement, 'overflow-x', 'hidden');
        this.renderer.setStyle(this.element.nativeElement, 'width', 'auto');
        this.renderer.setStyle(this.element.nativeElement, 'height', (this.height || this.defaultHeight));
    }
}
