import { ProfileType } from "./profile-type.model";
import { UntypedFormGroup } from "@angular/forms";
import { RuleSettings } from "./rule-settings.model";
import { EventType } from "../../../../shared/models/rules/enums/event-type.enum";
import { FormLayout } from "./form-layout.model";

export class StaffProfileType extends ProfileType {
  private static instance: StaffProfileType;

  readonly id = 'staff';
  readonly name = 'Staff';
  readonly namePlural = 'Staff';
  readonly icon = 'user-md';
  readonly listReturningOption = false;
  readonly checkForDuplicateName = true;
  readonly listColumns = ['photoUrl', 'firstName', 'lastName', 'position', 'user', 'latestTime', 'latestLocation', 'battery'];
  readonly secureColumns = [];  
  readonly formLayout = <FormLayout>{
    basicProfile: ['photoUrl', 'firstName', 'lastName', 'position', 'employeeId', 'email', 'mobile', 'proximityClear'],
    venueProfile: ['label', 'sensorId', 'notes', 'floorId', 'floorNumber'],
    locationProfile: ['locations', 'locationAccess']
  };
  readonly ruleSettings = <RuleSettings>{
    level: 1,
    showDisableOnPass: false,
    showRuleEnforcement: false,
    eventTypes: [
      EventType.GEOFENCE,
      EventType.PROXIMITY,
      EventType.ON_FLOOR
    ],
    defaultEventType: EventType.GEOFENCE,
    defaultRules: []
  }
  readonly downloadColumns = [
    'label', 'firstName', 'lastName', 'position', 'latestTime', 'latestLocation', 'battery'
  ];

  private constructor(){super();}

  public static getInstance(): StaffProfileType {
    if (!StaffProfileType.instance) {
      StaffProfileType.instance = new StaffProfileType();
    }
    return StaffProfileType.instance;
  }

  mapLabel(form: UntypedFormGroup): string {
    const first = form.get('firstName').value;
    const last = form.get('lastName').value;
    return (first && last) ? first + ' ' + last.charAt(0) : null;
  }
}
