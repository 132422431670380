<!-- START Top Navbar-->
<nav class="navbar topnavbar" role="navigation">
  <!-- START Left navbar-->
  <ul class="navbar-nav mr-auto flex-row">
    <li class="nav-item">
      <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
      <a class="nav-link d-none d-md-block d-lg-block d-xl-block" matTooltip="{{ 'header.MENU' | translate }}" trigger-resize=""
          (click)="toggleCollapsedSideabar()" *ngIf="!isCollapsedText()">
        <em class="fas fa-bars"></em>
      </a>
      <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
      <a class="nav-link sidebar-toggle d-md-none"
          (click)="settings.toggleLayoutSetting('asideToggled'); $event.stopPropagation()">
        <em class="fas fa-bars"></em>
      </a>
    </li>
    <li>
      <a class="navbar-brand d-none d-md-block" routerLink="/">
        <div class="brand-logo">Tenera Care</div>
      </a>
    </li>
  </ul>
  <!-- END Left navbar-->

  <!-- START Right Navbar-->
  <ul class="navbar-nav flex-row">
    <!-- START Organization menu-->
    <ng-container *ngIf="(organizations$ | async) && (organizations$ | async).length > 1">
      <li class="nav-item dropdown dropdown-list" dropdown>
        <a class="nav-link dropdown-toggle" matTooltip="{{ 'header.ORGANIZATION' | translate }}" dropdownToggle>
          <span class="selected-location">{{ selectedOrganizationName$ | async }}</span>
        </a>
        <!-- START Organization Dropdown menu-->
        <div *dropdownMenu class="dropdown-menu dropdown-menu-right animated flipInX">
          <div class="dropdown-item">
            <div class="list-group">
              <!-- START Organization list item-->
              <div class="list-group-item list-group-item-action" *ngFor="let organization of organizations$ | async"
                  (click)="setSelectedOrganization(organization)">
                <div class="media">
                  <div class="media-body">
                    <p class="m-0">{{ organization.name }}</p>
                  </div>
                  <div class="align-self-start mr-2">
                    <em *ngIf="(selectedOrganizationName$ | async) === organization.name" class="fa fa-check text-success"></em>
                  </div>
                </div>
              </div>
              <!-- END Organization list item-->
            </div>
          </div>
        </div>
        <!-- END Organization Dropdown menu-->
      </li>
    </ng-container>
    <!-- END Organization menu-->

    <!-- START Venue menu-->
    <ng-container *ngIf="(venues$ | async) && (venues$ | async).length > 1">
      <li class="nav-item dropdown dropdown-list" dropdown>
        <a class="nav-link dropdown-toggle" matTooltip="{{ 'header.VENUE' | translate }}" dropdownToggle>
          <span class="selected-location">{{ selectedVenueName$ | async }}</span>
        </a>
        <!-- START Venue Dropdown menu-->
        <div *dropdownMenu class="dropdown-menu dropdown-menu-right animated flipInX">
          <div class="dropdown-item">
            <div class="list-group">
              <!-- START Venue list item-->
              <div class="list-group-item list-group-item-action" *ngFor="let venue of venues$ | async"
                  (click)="setSelectedVenue(venue)">
                <div class="media">
                  <div class="media-body">
                    <p class="m-0">{{ venue.venueName }}</p>
                  </div>
                  <div class="align-self-start mr-2">
                    <em *ngIf="(selectedVenueName$ | async) === venue.venueName" class="fa fa-check text-success"></em>
                  </div>
                </div>
              </div>
              <!-- END Venue list item-->
            </div>
          </div>
        </div>
        <!-- END Venue Dropdown menu-->
      </li>
    </ng-container>
    <!-- END Venue menu-->

    <!-- START Unit menu-->
    <ng-container *ngIf="(units$ | async) && (units$ | async).length > 1">
      <li class="nav-item dropdown dropdown-list unit-list" dropdown>
        <a class="nav-link dropdown-toggle" matTooltip="{{ 'header.LOCATION' | translate }}" dropdownToggle>
          <span class="selected-location" *ngIf="(selectedUnitNames$ | async).length < 3">{{ (selectedUnitNames$ | async).slice(0, 2)?.join(', ') }}</span>
          <span class="selected-location" *ngIf="(selectedUnitNames$ | async).length >= 3">{{(selectedUnitNames$ | async).length}} units selected</span>
        </a>
        <!-- START Unit Dropdown menu-->
        <div *dropdownMenu class="dropdown-menu dropdown-menu-right animated flipInX" (click)="$event.stopPropagation()">
          <div class="dropdown-item">
            <div class="list-group">
              <!-- START Unit list item-->

              <div class="list-group-item list-group-item-action" *ngFor="let unit of units$ | async"
                  (click)="toggleUnitSelected(unit)"
                  [class.pending]="this.pendingSelectedUnitIds?.includes(unit.unitId)">
                <div class="media">
 
                  <div class="align-self-start mr-2">
                    <mat-checkbox [checked]="this.pendingSelectedUnitIds.includes(unit.unitId) || ((selectedUnitNames$ | async)?.includes(unit.name) && !this.pendingChanges)"></mat-checkbox>
                  </div>
                  <div class="media-body">
                    <p class="m-0">{{ unit.name }}</p>
                  </div>

                </div>
              </div>
              <!-- END Unit list item-->
              <div class="list-group-item list-group-item-action unit-change-buttons">
                <button class="btn btn-lg btn-info" 
                (click)="onUpdateSelectedUnits()" 
                [disabled]="pendingSelectedUnitIds.length === 0">
                  <span>Update Units</span>
                </button> 
                <button class="btn btn-lg btn-secondary"  
                (click)="onResetSelectedUnits()" 
                [disabled]="!this.pendingChanges">
                  <span>Reset</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- END Unit Dropdown menu-->
      </li>
    </ng-container>
    <ng-container *ngIf="(units$ | async) && (units$ | async).length === 1">
      <li class="nav-item">
        <a class="nav-link nav-link-cursor" matTooltip="{{ 'header.LOCATION' | translate }}" dropdown dropdownToggle>
          <span class="selected-location">{{ (selectedUnitNames$ | async)?.join(', ') }}</span>
        </a>
      </li>
    </ng-container>
    <!-- END Unit menu-->

    <!-- Notifications -->
    <li class="nav-item dropdown" *hasPermission="['notification-viewer']">
      <a class="nav-link" routerLink="/notifications" matTooltip="{{ 'header.NOTIFICATIONS' | translate }}">
        <em class="icon-bell"></em> <span *ngIf="(activeNotificationsCount$ | async) > 0" class="badge badge-danger" [ngStyle]="{'animation': ((activeNotificationsCodeWhite$ | async) > 0) ? 'badgeAnim 1s infinite' : 'unset'}">{{ activeNotificationsCount$ | async }}</span>
      </a>
    </li>

    <!-- Fullscreen (only desktops)-->
    <li class="nav-item d-none d-md-block">
      <a class="nav-link" #fsbutton matTooltip="{{ 'header.FULLSCREEN' | translate }}" (click)="toggleFullScreen($event)">
        <em class="fa fa-expand-arrows-alt"></em>
      </a>
    </li>

    <!-- Logout -->
    <li class="nav-item">
      <a class="nav-link" matTooltip="{{ 'header.SIGNOUT' | translate }}" (click)="logout()">
        <em class="fa fa-sign-out-alt"></em>
      </a>
    </li>
  </ul>
  <!-- END Right Navbar-->
</nav>
<!-- END Top Navbar-->
