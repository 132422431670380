/**
 * Enumeration of possible rule property values when GeofenceTrigger = GEOFENCE_TYPE
 */
export enum GeofenceType {
  BATHROOM = 'Bathroom',
  BATH_TUB = 'Bath Tub',
  BED = 'Bed',
  BEDROOM = 'Bedroom',
  CHAIR = 'Chair',
  COMMON = 'Common',
  COMMUNITY_DOOR = 'Community Door',
  EXIT = 'Exit',
  OUTSIDE = 'Outside',
  REFRIGERATOR = 'Refrigerator',
  RESTRICTED = 'Restricted',
  SINK = 'Sink',
  STOVE = 'Stove',
  TOILET = 'Toilet',
  TV_CHAIR = 'TV Chair',
  UNIT = 'Unit',
  LIVING_ROOM = 'Living Room',
  KITCHEN = 'Kitchen'
}