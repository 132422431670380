<form [formGroup]='clearNotificationForm' (ngSubmit)='submit(clearNotificationForm)'>
  <h1 mat-dialog-title>Clear Notification</h1>

  <mat-dialog-content class="dialog-content-padding">
    <mat-form-field appearance="outline">
        <mat-label>Please select a reason for clearing</mat-label>
        <mat-select formControlName="reason" required>
          <mat-option *ngFor="let reason of clearedReasons" [value]="reason">
            {{reason}}
          </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <textarea matInput formControlName="memo" rows="5"></textarea>
          <mat-label>Additional information</mat-label>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button type='submit' [disabled]="!clearNotificationForm.valid" *ngIf="!data.isWearablePressRequired">Clear
    </button>
    <button 
      mat-button (click)="onClearAttempt($event)"
      [disabled]="!clearNotificationForm.valid" 
      *ngIf="data.isWearablePressRequired"
    >
      <span *ngIf="!(timeRemaining$ | async) || (timeRemaining$ | async) <= 0">Clear</span>
      <span *ngIf="(timeRemaining$ | async) > 0">Press Wearable to Clear Call {{timeRemaining$ | async | date:'mm:ss.S'}}</span>
    </button>
    <button mat-button type='button' mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
</form>