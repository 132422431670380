import { NotificationListItem } from '../../models/notification-list-item.model';

// Events - Actions that result from an event

/**
 * Action dispatched to add a notification to state
 */
export class AddNotification {
  static type = '[NotificationList] AddNotification';
  constructor(public notification: NotificationListItem) {}
}

/**
 * Action dispatched to delete a notification from state
 */
export class DeleteNotification {
  static type = '[NotificationList] DeleteNotification';
  constructor(public id: string) {}
}

/**
 * Action dispatched to update a notification in state
 */
export class UpdateNotification {
  static type = '[NotificationList] UpdateNotification';
  constructor(public notification: NotificationListItem) {}
}

/**
 * Action dispatched to reset the notification list state to default
 */
export class ResetNotificationListState {
  static type = '[NotificationList] ResetNotificationListState';
}
