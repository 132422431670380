import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { PasswordChange } from './password-change.model';
import { PasswordChangeFormService } from './password-change-form.service';
import { AccountService } from '../../account/account.service';
import { Account } from '../../account/models/account.model';
import { AccountError } from '../../account/models/account-error.model';
import { SettingsService } from '../../../core/settings/settings.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss'],
  providers: [PasswordChangeFormService]
})
export class PasswordChangeComponent implements OnInit {
  accountError: AccountError = null;
  success: boolean;
  changeForm: UntypedFormGroup;

  constructor(
    private settings: SettingsService,
    private changeFormService: PasswordChangeFormService,
    private accountService: AccountService) { }

  ngOnInit() {
    this.changeForm = this.changeFormService.form;
  }

  /**
   * Update the user's password with the newly entered one
   *
   * @param passwordChange Password collected from the change form
   */
  async change(passwordChange: PasswordChange) {
    this.resetMessages();
    const account = passwordChange as Account;
    const accountError = await this.accountService.updateAccountPassword(account);
    this.resetMessages();
    if (accountError) {
      this.accountError = accountError;
    } else {
      this.success = true;
    }
  }

  /**
   * Reset the form state
   */
  resetMessages() {
    this.accountError = null;
    this.success = false;
  }

  /**
   * Get an app setting value from the settings service
   *
   * @param name Name of the app setting to retrieve
   */
  getAppSetting(name: string) {
    return this.settings.getAppSetting(name);
  }
}
