import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { RulesService } from '../../../shared/services/rules.service';
import { Rule } from '../../../shared/models/rules/rule.model';
import { ConsoleState } from '../../../shared/state/console/console.state';
import { Profile } from '../../profiles/models/profile.model';
import { RuleEditorFormService } from './rule-editor-form.service';
import * as RuleDetailActions from '../rules-detail/state/rule-detail-state.actions';

@Component({
  selector: 'app-rule-editor-detail-dialog',
  templateUrl: './rule-editor-detail-dialog.component.html',
  styleUrls: ['./rule-editor-detail-dialog.component.scss'],
  providers: [RuleEditorFormService]
})
export class RuleEditorDetailDialogComponent implements OnInit {
  ruleForm: UntypedFormGroup;
  errorMessage: string;

  submitButtonLabel = 'Reject Request';
  reasonLabel: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {rule: Rule, history: any,
      profile: Profile},
    private ruleFormService: RuleEditorFormService,
    private rulesService: RulesService,
    private store: Store,
    private dialogRef: MatDialogRef<RuleEditorDetailDialogComponent>) { }

  ngOnInit() {
    if (this.data.history.action === 'CREATE_REQUEST') {
      this.reasonLabel = 'Reason for rejection of new rule for ' + this.data.profile.label;
    } else if (this.data.history.action === 'UPDATE_REQUEST') {
      this.reasonLabel = 'Reason for rejection of rule update for ' + this.data.profile.label;
    } else {
      this.reasonLabel = 'Reason for rejection';
    }
    this.ruleForm = this.ruleFormService.ruleForm;
    this.ruleFormService.populateForm();
  }


  /**
   * Check form values according to eventType selected
   */
  isFormValid(): boolean {
    if (!this.ruleForm.get('reason').value) {
      return false;
    }
    return this.ruleFormService.isFormValid();
  }

  /**
   * Set all form values to their default
   */
  resetForm(): void {
    this.ruleFormService.resetForm();
  }

  /**
   * Create a new rule or update an existing rule
   */
  async submit() {

    const reason = this.ruleForm.get('reason').value;
    const organization = this.store.selectSnapshot(ConsoleState.selectedOrganization);
    const selectedVenue = this.store.selectSnapshot(ConsoleState.selectedVenueId);
    const ruleId = this.data.rule.id;
    const historyId = this.data.history.id;
    try {
      await this.rulesService.decideRule(organization, selectedVenue, ruleId, historyId, false, reason);
      this.store.dispatch(new RuleDetailActions.SetRuleUpdateMessage('Rule decision successful'));
    } catch (error) {
      this.store.dispatch(new RuleDetailActions.SetRuleErrorMessage('Error submitting rule decision'));
    }

    this.dialogRef.close();
    this.resetForm();
  }
}
