import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import * as moment from 'moment-timezone';

import { ConsoleState } from '../../../shared/state/console/console.state';
import { ProfileDetailState } from './state/profile-detail.state';
import { ProfileRuleConstants } from '../../../shared/models/rules/profile-rule-constants';
import { SensorAvailableValidator } from '../../../shared/validators/sensor-available.validator';
import { ProfileType } from '../models/profile-type/profile-type.model';
import { Profile } from '../models/profile.model';
import { AuthState } from '../../../shared/state/auth/auth.state';
import { CallStationClearMethod } from '../models/enums/call-station-clear-method.enum';

@Injectable()
export class ProfileFormService {
  public profileForm: UntypedFormGroup;

  constructor(private store: Store, private afs: AngularFirestore) {}

  createForm(profileType: ProfileType) {
    const fieldOptions = {
      // basic profile fields
      firstName: new UntypedFormControl(null, {validators: [Validators.required]}),
      middleName: new UntypedFormControl(null),
      lastName: new UntypedFormControl(null, {validators: [Validators.required]}),
      gender: new UntypedFormControl(null, {validators: [Validators.required]}),
      position: new UntypedFormControl(null, {validators: [Validators.required]}),
      residentId: new UntypedFormControl(null),
      employeeId: new UntypedFormControl(null),
      email: new UntypedFormControl(null),
      mobile: new UntypedFormControl(null),
      proximityClear: new UntypedFormControl(false),
      reasonType: new UntypedFormControl(null, {validators: [Validators.required]}),
      reason: new UntypedFormControl(null),
      visitResident: new UntypedFormControl(null),
      covidScreenComplete: new UntypedFormControl(false),
      covidVaccine: new UntypedFormControl(false),
      temperature: new UntypedFormControl(null),
      assetType: new UntypedFormControl(null, {validators: [Validators.required]}),
      fixtureType: new UntypedFormControl(null, {validators: [Validators.required]}),
      identifier: new UntypedFormControl(null, {validators: [Validators.required]}),
      department: new UntypedFormControl(null, {validators: [Validators.required]}),
      contactName: new UntypedFormControl(null, {validators: [Validators.required]}),
      contactNumber: new UntypedFormControl(null, {validators: [Validators.required]}),
      xCoordinate: new UntypedFormControl(null, {validators: [Validators.required]}),
      yCoordinate: new UntypedFormControl(null, {validators: [Validators.required]}),
      zCoordinate: new UntypedFormControl(null, {validators: [Validators.required]}),
      description: new UntypedFormControl(null),
      // venue profile fields
      label: new UntypedFormControl(null, {validators: [Validators.required]}),
      sensorId: new UntypedFormControl(
        null, // no default value
        null, // no non-async validators
        SensorAvailableValidator.sensorId(this.store, this.afs, ProfileDetailState,
          this.store.selectSnapshot(ConsoleState.selectedOrganization))
        ),
      floorId: new UntypedFormControl(null),
      floorNumber: new UntypedFormControl(null),
      roomId: new UntypedFormControl(null), // geofence ID
      roomName: new UntypedFormControl(null),
      bedId: new UntypedFormControl(null), // geofence ID
      bedName: new UntypedFormControl(null),
      bathroomId: new UntypedFormControl(null), // geofence ID
      bathroomName: new UntypedFormControl(null),
      assignedUnit: new UntypedFormControl({value: null, disabled: true}),
      // activity profile fields
      onpassstatus: new UntypedFormControl('NOT ON PASS', {validators: [Validators.required]}),
      riseHour: new UntypedFormControl(6, {validators: [Validators.required]}),
      riseMeridian: new UntypedFormControl('AM', {validators: [Validators.required]}),
      riseMinute: new UntypedFormControl(30, {validators: [Validators.required]}),
      sleepHour: new UntypedFormControl(11, {validators: [Validators.required]}),
      sleepMeridian: new UntypedFormControl('PM', {validators: [Validators.required]}),
      sleepMinute: new UntypedFormControl(0, {validators: [Validators.required]}),
      notes: new UntypedFormControl(null),
      isSelfClearing: new UntypedFormControl(null),
      activeHighOrLow: new UntypedFormControl(null),
      beaconId: new UntypedFormControl(null),
      channelId: new UntypedFormControl(null),
      hardwareType: new UntypedFormControl(null),
      hardwareClearingMethod: new UntypedFormControl(CallStationClearMethod.NONE),
      clearSensorId: new UntypedFormControl(null, null, SensorAvailableValidator.sensorId(this.store, this.afs, ProfileDetailState,
        this.store.selectSnapshot(ConsoleState.selectedOrganization))),
      clearBeaconId: new UntypedFormControl(null),
      clearChannelId: new UntypedFormControl(null),

      // linked user location access
      locationAccess: new UntypedFormControl(null),
    };

    // remove fields that don't apply to this profile type
    for (const field in fieldOptions) {
      if (!profileType.hasFormField(field)) {
        delete fieldOptions[field];
      }
    }
    
    this.profileForm = new UntypedFormGroup(fieldOptions);
  }

  /**
   * Reset all form controls to default
   */
  resetForm(): void {
    this.profileForm.reset();
    this.resetField('onpassstatus', 'NOT ON PASS');
    this.resetField('riseHour', 6);
    this.resetField('riseMeridian', 'AM');
    this.resetField('riseMinute', 30);
    this.resetField('sleepHour', 11);
    this.resetField('sleepMeridian', 'PM');
    this.resetField('sleepMinute', 0);
  }

  resetField(field, value) {
    if (this.profileForm.get(field)) {
      this.profileForm.get(field).setValue(value);
    }
  }

  /**
   * Get the custom profile form fields for the org 
   *
   * @param org The organization id
   */
  async getCustomFields(org: string) : Promise<string[]> {
    const orgDoc = await this.afs.firestore.collection('organizations').doc(org).get();
    const orgData = orgDoc.data();
    return (orgData && orgData.customProfileFields) ? orgData.customProfileFields : [];
  } 

  /**
   * Set form control values with data from an existing profile profile
   *
   * @param profile The profile profile to display
   */
  populateFormWithProfile(profile: Profile): void {
    for (const field in profile) {
      if (this.profileForm.get(field) && field !== 'profileData') {
        this.profileForm.get(field).setValue(profile[field]);
      }
    }
    for (const field in profile.profileData) {
      if (this.profileForm.get(field)) {
        this.profileForm.get(field).setValue(profile.profileData[field]);
      }
    }
  }

  /**
   * Programatically mark a form control as dirty
   *
   * @param controlName The name of the control to set as dirty
   */
  setControlDirty(controlName: string): void {
    this.profileForm.get(controlName).markAsDirty();
  }

  /**
   * Set the selected reason
   *
   * @param reason The reason for being at the facility
   */
  setReason(reason: string): void {
    this.setFormValue('reason', reason);
  }

  setLocationAccess(locationAccess: string[]) {
    this.setFormValue('locationAccess', locationAccess);
  }

  getLocationAccess() {
    return this.getFormValue('locationAccess');
  }

  /**
   * Set the selected resisent being visited
   *
   * @param visitResident The resident being visited
   */
  setVisitResident(visitResident: string): void {
    this.setFormValue('visitResident', visitResident);
  }

  /**
   * Set the selected floor ID
   *
   * @param floorId The ID of the selected floor
   */
  setFloorId(floorId: string): void {
    this.setFormValue('floorId', floorId);
  }

  /**
   * Set the selected floor ID
   *
   * @param floorId The ID of the selected floor
   */
  setFloorNumber(floorNumber: number): void {
    this.setFormValue('floorNumber', floorNumber);
  }

  /**
   * Set the identifier of a room geofence
   *
   * @param roomId The geofence identifier for the selected room
   */
  setRoomId(roomId: string): void {
    this.setFormValue('roomId', roomId);
  }

  /**
   * Set the name of a room geofence
   *
   * @param roomName The name of the selected bedroom
   */
  setRoomName(roomName: string): void {
    this.setFormValue('roomName', roomName);
  }

  /**
   * Set the identifier of a bed geofence
   *
   * @param bedId The geofence identifier for the selected bed
   */
  setBedId(bedId: string): void {
    this.setFormValue('bedId', bedId);
  }

  /**
   * Set the name of a bed geofence
   *
   * @param bedName The name the selected bed
   */
  setBedName(bedName: string): void {
    this.setFormValue('bedName', bedName);
  }

  /**
   * Set the identifier of a bathroom geofence
   *
   * @param bathroomId The geofence identifier for the selected bathroom
   */
  setBathroomId(bathroomId: string): void {
    this.setFormValue('bathroomId', bathroomId);
  }

  /**
   * Set the name of a bathroom geofence
   *
   * @param bathroomName The name the selected bathroom
   */
  setBathroomName(bathroomName: string): void {
    this.setFormValue('bathroomName', bathroomName);
  }

  /**
   * Set the name of a bathroom geofence
   *
   * @param assignedUnit The ID the selected bathroom
   */
  setAssignedUnit(assignedUnit: string): void {
    this.setFormValue('assignedUnit', assignedUnit);
  }

  /**
   * Get the selected floor ID
   */
  getFloorId(): string {
    return this.getFormValue('floorId');
  }

  /**
   * Get the selected room ID
   */
  getRoomId(): number {
    return this.getFormValue('roomId');
  }

  /**
   * Get the selected room name
   */
  getRoomName(): string {
    return this.getFormValue('roomName');
  }

  /**
   * Get the selected bed ID
   */
  getBedId(): number {
    return this.getFormValue('bedId');
  }

  /**
   * Get the selected bed name
   */
  getBedName(): string {
    return this.getFormValue('bedName');
  }

  /**
   * Get the selected bathroom ID
   */
  getBathroomId(): number {
    return this.getFormValue('bathroomId');
  }

  /**
   * Get the selected bathroom name
   */
  getBathroomName(): string {
    return this.getFormValue('bathroomName');
  }

  /**
   * Get the selected unit ID
   */
  getAssignedUnit(): string {
    return this.getFormValue('assignedUnit');
  }

  /**
   * Safely get the form value
   * @param fieldName the field name to get
   */
  getFormValue(fieldName: string): any {
    return (this.profileForm.get(fieldName)) ?
      this.profileForm.get(fieldName).value :
      null;
  }

  /**
   * Safely set the form value
   * @param fieldName the field name to set
   * @param fieldValue the value to which the field name will be set
   */
  setFormValue(fieldName: string, fieldValue: any): any {
    if (this.profileForm.get(fieldName)) {
      this.profileForm.get(fieldName).setValue(fieldValue);
    }
  }

  /**
   * Create a new Profile Profile using form data
   * @param profileType The ProfileType to create, used to determine dynamic profile data fields
   * @param selectedUnits The units to which the profile should be assigned
   */
  createProfile(profileType: ProfileType, selectedUnits: string[]): Profile {
    const battery = this.profileForm.get('sensorId').value ? 'Awaiting Status Update' : 'No Device';
    const profile = {
      active: true,
      battery: battery, // TODO set from sensorId instead of assignement above
      label: this.profileForm.get('label').value,
      notes: this.profileForm.get('notes').value,
      photoUrl: null,
      profileData: {},
      profileType: profileType.id,
      profileStatus: 'enabled',
      sensorId: this.profileForm.get('sensorId').value,
      units: selectedUnits,
      venueId: this.store.selectSnapshot(ConsoleState.selectedVenueId)
    };
    if (profileType.isFixture && this.profileForm.get('fixtureType').value === 'Nurse Call Device') {
      this.profileForm.get('sensorId').setValue(null);
      profile.sensorId = null;
    } else if (profileType.isFixture) {
      this.profileForm.get('isSelfClearing').setValue(null);
      this.profileForm.get('beaconId').setValue(null);
      this.profileForm.get('channelId').setValue(null);
      this.profileForm.get('activeHighOrLow').setValue(null);
      this.profileForm.get('hardwareType').setValue(null);
    }
    if (profileType.isFixture) {
      this.setHardwareCancelMethod();
    }
    profileType.getAllFields().forEach(field => {
      if (!profile.hasOwnProperty(field)) {
        let value = null;
        if (this.profileForm.get(field) && (this.profileForm.get(field).value || this.profileForm.get(field).value === 0 ||
          this.profileForm.get(field).value === false)) {
            value = this.profileForm.get(field).value;
        }
        profile.profileData[field] = value;
        if (field === 'covidVaccine') {
          profile.profileData['covidVaccineUpdatedByUserId'] = this.store.selectSnapshot(AuthState.userId);
          profile.profileData['covidVaccineUpdatedDate'] = new Date().toISOString();
        }
        // prevent this from being added to profile data
        if (field === 'assignedUnit' || field === 'hardwareClearingMethod' || field === 'coordinateMap') {
          profile.profileData[field] = undefined;
        }
      }
    });
    if (!profileType.isFixture) {
      profile.profileData['isSelfClearing'] = undefined,
      profile.profileData['clearSensorId'] = undefined,
      profile.profileData['clearBeaconId'] = undefined,
      profile.profileData['clearChannelId'] = undefined
    }
    return profile;
  }

  /**
   * determine the clear method for a fixture
   * @param profile 
   */
  getHardwareClearingMethod(profile: Profile) {
    if (profile?.profileData?.clearSensorId) {
      this.profileForm.get('hardwareClearingMethod').setValue(CallStationClearMethod.SENSOR);
    } else if (profile?.profileData?.clearBeaconId) {
      this.profileForm.get('hardwareClearingMethod').setValue(CallStationClearMethod.CHANNEL);
    } else if (profile?.profileData?.isSelfClearing) {
      this.profileForm.get('hardwareClearingMethod').setValue(CallStationClearMethod.SELF_CLEAR_LATCH);
    } else {
      this.profileForm.get('hardwareClearingMethod').setValue(CallStationClearMethod.NONE);
    }
  }

  setHardwareClearingFieldRequirements(methodValue: CallStationClearMethod) {
    switch (methodValue) {
      case CallStationClearMethod.SENSOR:
        this.profileForm.get('clearBeaconId')?.clearValidators();
        this.profileForm.get('clearChannelId')?.clearValidators();
        this.profileForm.get('clearSensorId')?.setValidators(Validators.required);
        break;
      case CallStationClearMethod.CHANNEL: 
        this.profileForm.get('clearSensorId')?.clearValidators();
        this.profileForm.get('clearBeaconId')?.setValidators(Validators.required);
        this.profileForm.get('clearChannelId')?.setValidators(Validators.required);
        break;
      case CallStationClearMethod.NONE:
      case CallStationClearMethod.SELF_CLEAR_LATCH:
      default:
        this.profileForm.get('clearBeaconId')?.clearValidators();
        this.profileForm.get('clearChannelId')?.clearValidators();
        this.profileForm.get('clearSensorId')?.clearValidators();
        break;
    }
    this.profileForm.get('clearBeaconId')?.updateValueAndValidity();
    this.profileForm.get('clearChannelId')?.updateValueAndValidity();
    this.profileForm.get('clearSensorId')?.updateValueAndValidity();
  }

  /**
   * Return the rise time specified in the form to a Date
   */
  getRiseTimeAsDate(): Date {
    return this.convertTimeToDate(
      this.profileForm.get('riseHour').value,
      this.profileForm.get('riseMinute').value,
      this.profileForm.get('riseMeridian').value
    );
  }

  /**
   * Return the sleep time specified in the form to a Date
   */
  getSleepTimeAsDate(): Date {
    return this.convertTimeToDate(
      this.profileForm.get('sleepHour').value,
      this.profileForm.get('sleepMinute').value,
      this.profileForm.get('sleepMeridian').value
    );
  }

  /**
   * Method to convert a time represented by hours, minutes and meridian
   *  to a Date object
   *
   * @param hours The number of hours to set
   * @param minutes The number of minutes to set
   * @param meridian The meridian to set
   */
  private convertTimeToDate(hours: number, minutes: number, meridian: string): Date {
    const venueList = this.store.selectSnapshot(ConsoleState.venueList);
    const venue = venueList.find(item => item.venueId === this.store.selectSnapshot(ConsoleState.selectedVenueId));
    const timezone = venue.timezone;
    // if after 1 PM, add 12 hours
    if (meridian === 'PM' && hours < 12) {
      hours += 12;
    }
    // if between midnight and 1 am, hour is 0
    if (hours === 12 && meridian === 'AM') {
      hours = 0;
    }
    let timeMoment: moment.Moment;
    if (timezone) {
      timeMoment = moment('1970-01-01 00:00').tz(timezone, true)
    } else {
      timeMoment = moment('1970-01-01 00:00');
    }
    
    timeMoment.add((hours * ProfileRuleConstants.SECONDS_PER_HOUR) + (minutes * ProfileRuleConstants.SECONDS_PER_MINUTE), 'seconds');
    const time = timeMoment.toDate();
    return time;
  }

  setHardwareCancelMethod() {
    const cancelMethod = this.profileForm.get('hardwareClearingMethod').value;
      switch (cancelMethod) {
        case CallStationClearMethod.SELF_CLEAR_LATCH: 
          this.profileForm.get('isSelfClearing').setValue(true);
          this.profileForm.get('clearSensorId').setValue(null);
          this.profileForm.get('clearBeaconId').setValue(null);
          this.profileForm.get('clearChannelId').setValue(null);
          break;
        case CallStationClearMethod.SENSOR: 
          this.profileForm.get('isSelfClearing').setValue(false);
          this.profileForm.get('clearBeaconId').setValue(null);
          this.profileForm.get('clearChannelId').setValue(null);
        break;
        case CallStationClearMethod.CHANNEL: 
          this.profileForm.get('isSelfClearing').setValue(false);
          this.profileForm.get('clearSensorId').setValue(null);
          break;
        case CallStationClearMethod.NONE:
        default:
          this.profileForm.get('isSelfClearing').setValue(false);
          this.profileForm.get('clearSensorId').setValue(null);
          this.profileForm.get('clearBeaconId').setValue(null);
          this.profileForm.get('clearChannelId').setValue(null);
          break;
      }
  }


}
