import { EventType } from '../../../../shared/models/rules/enums/event-type.enum';
import { GeofenceAction } from '../../../../shared/models/rules/enums/geofence-action.enum';
import { GeofenceCategory } from '../../../../shared/models/rules/enums/geofence-category.enum';
import { GeofenceRule } from '../../../../shared/models/rules/geofence-rule.model';
import { GeofenceTrigger } from '../../../../shared/models/rules/enums/geofence-trigger.enum';
import { GeofenceType } from '../../../../shared/models/rules/enums/geofence-type.enum';
import { NotifyOnTime } from '../../../../shared/models/rules/enums/notify-on-time.enum';
import { NotOwnRoomAction } from '../../../../shared/models/rules/enums/not-own-room-action.enum';
import { NotOwnRoomCategory } from '../../../../shared/models/rules/enums/not-own-room-category.enum';
import { NotOwnRoomRule } from '../../../../shared/models/rules/not-own-room-rule.model';
import { ProfileRule } from '../../../../shared/models/rules/profile-rule.model';
import { ProfileRuleConstants } from '../../../../shared/models/rules/profile-rule-constants';
import { ProximityRule } from '../../../../shared/models/rules/proximity-rule.model';
import { ProfileType } from '../../models/profile-type/profile-type.model';
import { ProximityAction } from '../../../../shared/models/rules/enums/proximity-action.enum';
import { ProximityCategory } from '../../../../shared/models/rules/enums/proximity-category.enum';
import { DefaultRule } from '../../models/profile-type/default-rule.model';
import { OnFloorAction } from '../../../../shared/models/rules/enums/on-floor-action.enum';
import { OnFloorCategory } from '../../../../shared/models/rules/enums/on-floor-category.enum';
import { OnFloorRule } from '../../../../shared/models/rules/on-floor-rule.model';

export class ProfileRuleUtils {

  /**
   * Static function to return an array of possible proximity distance options
   */
  static getProximityDistancesInMeters(): number[] {
    return [2, 4, 6, 8, 10];
  }

  /**
   * Static function to return an array of possible rule requestor types
   */
  static getRuleRequestorTypes(): Array<{value: string, label: string}> {
    return [
      {value: 'Family Member', label: 'Family Member'},
      {value: 'Staff', label: 'Staff'},
      {value: 'Resident', label: 'Resident'},
      {value: 'Medical Professional', label: 'Medical Professional'},
    ];
  }

  /**
  * Static function to return an enum class as an array of values
  */
  static getEnumsAsArray(enumList: any): string[] {
   const optionArray: string[] = [];
   for (const n in enumList) {
     if (typeof enumList[n] === 'string') {
       optionArray.push(enumList[n]);
     }
   }
   return optionArray;
 }

 /**
   * Static function to return a set of default rules for a profile
   * 
   * @param profileType: The profile's profile type
   */
  static getDefaultRules(profileType: ProfileType): ProfileRule[] {
    const rules: ProfileRule[] = [];
    const defaults = profileType.ruleSettings.defaultRules;
    const level = profileType.ruleSettings.level;
    let defaultRule: DefaultRule; 

    // prioximity
    defaultRule = defaults.find(({eventType}) =>
      eventType === EventType.PROXIMITY);
    if (defaultRule) {
      const DISTANCE_THRESHOLD_IN_METERS = 2;
      rules.push(this.createProximityRule(ProximityAction.NEAR, defaultRule.active, null, ProximityCategory.DISTANCING, level, null,
        ProfileRuleConstants.ONCE_PER_5_MINUTES_RATE, false, false, false, NotifyOnTime.NEVER,
        DISTANCE_THRESHOLD_IN_METERS, 'ALL_RESIDENTS_STAFF_VISITORS', '* All Residents / Staff / Visitors *', null));
    }

    // on floor
    defaultRule = defaults.find(({eventType}) =>
      eventType === EventType.ON_FLOOR);
    if (defaultRule) {
      rules.push(this.createOnFloorRule(OnFloorAction.ON_FLOOR, defaultRule.active, null, OnFloorCategory.ON_FLOOR, level, null,
        null, false, false, false, NotifyOnTime.NEVER, null, false));
    }

    // enter restricted area
    defaultRule = defaults.find(({eventType, geofenceAction, geofenceType}) =>
      eventType === EventType.GEOFENCE &&
      geofenceAction === GeofenceAction.ENTER &&
      geofenceType === GeofenceType.RESTRICTED);
    if (defaultRule) {
      rules.push(this.createGeofenceRule(GeofenceAction.ENTER, defaultRule.active, null, GeofenceCategory.ZONE_ENTER, level, null,
        ProfileRuleConstants.ONCE_PER_MINUTE_RATE, false, false, false, NotifyOnTime.NEVER, GeofenceTrigger.GEOFENCE_TYPE,
        GeofenceType.RESTRICTED, null, null));
    }

    // enter exit area
    defaultRule = defaults.find(({eventType, geofenceAction, geofenceType}) =>
      eventType === EventType.GEOFENCE &&
      geofenceAction === GeofenceAction.ENTER &&
      geofenceType === GeofenceType.EXIT);
    if (defaultRule) {
      rules.push(this.createGeofenceRule(GeofenceAction.ENTER, defaultRule.active, null, GeofenceCategory.ZONE_ENTER, level, null,
        ProfileRuleConstants.ONCE_PER_5_MINUTES_RATE, false, false, false, NotifyOnTime.NEVER, GeofenceTrigger.GEOFENCE_TYPE,
        GeofenceType.EXIT, null, null));
    }

    // exit any bathroom
    defaultRule = defaults.find(({eventType, geofenceAction, geofenceType}) =>
      eventType === EventType.GEOFENCE &&
      geofenceAction === GeofenceAction.EXIT &&
      geofenceType === GeofenceType.BATHROOM);
    if (defaultRule) {
      rules.push(this.createGeofenceRule(GeofenceAction.EXIT, defaultRule.active, null, GeofenceCategory.ZONE_EXIT, level, null,
        ProfileRuleConstants.ONCE_PER_5_MINUTES_RATE, false, false, false, NotifyOnTime.NEVER, GeofenceTrigger.GEOFENCE_TYPE,
        GeofenceType.BATHROOM, null, null));
    }

    return rules;
  }


  /**
   * Static function to return an Exit Assigned Bed rule
   *
   * @param assignedBedId The ID of the bed geofence
   * @param assignedBedName The name of the bed geofence
   * @param startTime The desired rule start time
   * @param endTime The desired rule end time
   * @param profileId The profile profile ID to which this rule applies
   * @param profileType: The profile's profile type 
   */
  static getExitAssignedBedRule(assignedBedId: string, assignedBedName: string, startTime: number, endTime: number, profileId: string, profileType: ProfileType):
   GeofenceRule {
    const level = profileType.ruleSettings.level;
    return this.createGeofenceRule(GeofenceAction.EXIT_ASSIGNED_BED, false, endTime, GeofenceCategory.EXIT_ASSIGNED_BED, level, profileId,
      ProfileRuleConstants.ONCE_PER_5_MINUTES_RATE, false, true, false, NotifyOnTime.SLEEP_TO_RISE, GeofenceTrigger.GEOFENCE_ID,
      assignedBedId, assignedBedName, startTime);
  }

  /**
   * Static function to return an Exit Assigned Bathroom rule
   *
   * @param assignedBathroomId The ID of the bed geofence
   * @param assignedBathroomName The name of the bed geofence
   * @param profileId The profile profile ID to which this rule applies
   * @param profileType: The profile's profile type 
   */
  static getEnterAssignedBathroomRule(assignedBathroomId: string, assignedBathroomName: string, profileId: string, profileType: ProfileType):
   GeofenceRule {
    const level = profileType.ruleSettings.level;
    return this.createGeofenceRule(GeofenceAction.ENTER_ASSIGNED_BATHROOM, false, null, GeofenceCategory.ENTER_ASSIGNED_BATHROOM, level, profileId,
      ProfileRuleConstants.ONCE_PER_5_MINUTES_RATE, false, false, true, NotifyOnTime.NEVER, GeofenceTrigger.GEOFENCE_ID,
      assignedBathroomId, assignedBathroomName, null);
  }


  /**
   * Static function to return a Not Own Room rule
   *
   * @param active Whether the room should be activated upon creation
   * @param roomId The ID of the room geofence
   * @param roomName The name of the room geofence
   * @param profileId The profile profile ID to which this rule applies
   * @param level The rule severity level
   */
  static getNotOwnRoomRule(active: boolean, roomId: string, roomName: string, profileId: string, level: number): NotOwnRoomRule {
    return this.createNotOwnRoomRule(NotOwnRoomAction.ENTER, active, null, NotOwnRoomCategory.NOT_OWN_ROOM, level, profileId,
      ProfileRuleConstants.ONCE_PER_MINUTE_RATE, false, false, false, NotifyOnTime.NEVER, roomId, roomName, null);
  }

 /**
   * Static function to create a Geofence Rule
   */
  static createGeofenceRule(
    action: string, active: boolean, endTime: number, eventCategory: string, level: number, profileId: string,
    rate: number, updateRoomOnChange: boolean, updateBedOnChange: boolean, updateBathroomOnChange: boolean,
    updateTime: NotifyOnTime, geofenceTrigger: GeofenceTrigger, triggerValue: string, geofenceIdName: string,
    startTime: number): GeofenceRule {
      const eventType = EventType.GEOFENCE;
      const properties = {
        updateRoomOnChange: updateRoomOnChange,
        updateBedOnChange: updateBedOnChange,
        updateBathroomOnChange: updateBathroomOnChange,
        updateTime: updateTime,
        geofenceTrigger: geofenceTrigger,
        triggerValue: triggerValue,
        geofenceIdName: geofenceIdName
      };

      return this.createProfileRule(action, active, endTime, eventCategory, eventType, level, profileId, rate,
        properties, startTime) as GeofenceRule;
  }

 /**
   * Static function to create a Not Own Room Rule
   */
  static createNotOwnRoomRule(
    action: string, active: boolean, endTime: number, eventCategory: string, level: number, profileId: string,
    rate: number, disableOnPass: boolean, updateBedOnChange: boolean, updateBathroomOnChange: boolean, updateTime: NotifyOnTime, ownRoomId: string,
    ownRoomName: string, startTime: number): NotOwnRoomRule {
      const eventType = EventType.NOT_OWN_ROOM;
      const properties = {
        disableOnPass: disableOnPass,
        updateRoomOnChange: true,
        updateBedOnChange: updateBedOnChange,
        updateBathroomOnChange: updateBathroomOnChange,
        updateTime: updateTime,
        ownRoomId: ownRoomId,
        ownRoomName: ownRoomName
      };

      return this.createProfileRule(action, active, endTime, eventCategory, eventType, level, profileId, rate,
        properties, startTime) as NotOwnRoomRule;
  }

 /**
   * Static function to create a Proximity Rule
   */
  static createProximityRule(
    action: string, active: boolean, endTime: number, eventCategory: string, level: number, profileId: string,
    rate: number, updateRoomOnChange: boolean, updateBedOnChange: boolean, updateBathroomOnChange: boolean, updateTime: NotifyOnTime,
    threshold: number, proximityTriggerId: string, proximityTriggerName: string, startTime: number): ProximityRule {
      const eventType = EventType.PROXIMITY;
      const properties = {
        updateRoomOnChange: updateRoomOnChange,
        updateBedOnChange: updateBedOnChange,
        updateBathroomOnChange: updateBathroomOnChange,
        updateTime: updateTime,
        threshold: threshold,
        proximityTriggerId: proximityTriggerId,
        proximityTriggerName: proximityTriggerName
      };

      return this.createProfileRule(action, active, endTime, eventCategory, eventType, level, profileId, rate,
        properties, startTime) as ProximityRule;
  }

 /**
   * Static function to create a On Floor Rule
   */
  static createOnFloorRule(
    action: string, active: boolean, endTime: number, eventCategory: string, level: number, profileId: string,
    rate: number, updateRoomOnChange: boolean, updateBedOnChange: boolean, updateBathroomOnChange: boolean, updateTime: NotifyOnTime,
     startTime: number, disableOnPass: boolean): OnFloorRule {
      const eventType = EventType.PROXIMITY;
      const properties = {
        disableOnPass: disableOnPass,
        updateRoomOnChange: updateRoomOnChange,
        updateBedOnChange: updateBedOnChange,
        updateBathroomOnChange: updateBathroomOnChange,
        updateTime: updateTime
      };

      return this.createProfileRule(action, active, endTime, eventCategory, eventType, level, profileId, rate,
        properties, startTime) as OnFloorRule;
  }

 /**
   * Static function to create a Profile Rule
   */
  static createProfileRule(action: string, active: boolean, endTime: number, eventCategory: string,
    eventType: EventType, level: number, profileId: string, rate: number, properties: { 
      updateRoomOnChange: boolean, 
      updateBedOnChange: boolean, 
      updateBathroomOnChange: boolean, 
      updateTime: NotifyOnTime
    }, 
      startTime: number): ProfileRule {
      return {
        action: action,
        active: active,
        endTime: endTime,
        eventCategory: eventCategory,
        eventType: eventType,
        level: level,
        profileId: profileId,
        rate: rate,
        properties: properties,
        startTime: startTime
      };
  }

}
