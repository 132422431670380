<div class="code-white-bar" *ngIf="codeWhiteAssistance">
  <div class="code-white-container col-md-9 col-lg-8 col-xl-7">
    <button *ngIf="totalNotifications > 1" (click)="onPrevNotification()" class="code-white-arrow"><i class="fas fa-angle-left"></i></button>
    <i class="fas fa-bell"></i>
    <div class="code-wite-box">
      <h3 class="code-white-header">
        <span *ngIf="!(selectedVenueFeatureConfig$ | async)?.isCodeLabeledBlue">Code White Alert</span>
        <span *ngIf="(selectedVenueFeatureConfig$ | async)?.isCodeLabeledBlue">Code Blue Alert</span>
        <span *ngIf="(activeNotificationsCodeWhite$ | async) > 1"> ({{(notificationIdx + 1)}} of {{(activeNotificationsCodeWhite$ | async)}})</span></h3>
      
      <div>
        
        <div class="code-wite-item">
          <p class="mb-0 code-white-subject">
            <strong>{{ notification.subject }}</strong>
          </p>
          <p class="m-0 code-white-message">{{ notification.message }}</p>
          <button (click)="onViewNotification(notification.id)" class="btn btn-danger code-white-view">VIEW</button>
        </div>
        
      </div>
    </div>
    <button *ngIf="totalNotifications > 1" (click)="onNextNotification()" class="code-white-arrow"><i class="fas fa-angle-right"></i></button>
  </div>
</div>