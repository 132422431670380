import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-disable-dialog',
  templateUrl: './confirm-disable-dialog.component.html',
  styleUrls: ['./confirm-disable-dialog.component.scss']
})
export class ConfirmDisableDialogComponent implements OnInit {

  isDisabled = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private dialogRef: MatDialogRef<ConfirmDisableDialogComponent>) {}

  ngOnInit() {
    this.isDisabled = this.data.user.disabled;
  }

  onConfirm(action) {
    this.dialogRef.close(action);
  }

}
