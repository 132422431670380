import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { Store } from '@ngxs/store';

import { NcsChannelsService } from '../../services/ncsChannel.service';
import { NcsChannel } from '../../models/ncsChannel.model';
import { NcsChannelsState } from '../../state/ncsChannels/ncsChannels.state';
import { ResetNcsChannelsState } from '../../state/ncsChannels/ncsChannels-state.actions';
import { ConsoleState } from '../../state/console/console.state';


@Component({
  selector: 'app-auto-assign-fixture-dialog',
  templateUrl: './auto-assign-fixture-dialog.component.html',
  styleUrls: ['./auto-assign-fixture-dialog.component.scss']
})
export class AutoAssignFixtureDialogComponent implements OnInit, OnDestroy {

  // references used in MatTable
  dataSource = new MatTableDataSource<NcsChannel>();
  displayedColumns: string[];

  // data subscriptions
  private activatedFixtureListSubscription: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {title: string, message: string, showClearingDevices: boolean},
    private dialogRef: MatDialogRef<AutoAssignFixtureDialogComponent>,
    private ncsChannelsService: NcsChannelsService,
    private store: Store,) {}


  async ngOnInit() {
    if (this.data.showClearingDevices) {
      this.displayedColumns = ['beaconId', 'channelId', 'clearProfileIds', 'reset'];
    } else {
      this.displayedColumns = ['beaconId', 'channelId', 'reset'];
    }
    this.activatedFixtureListSubscription = this.store.select(NcsChannelsState.activatedNcsChannels)
    .subscribe((ncsChannelsList: NcsChannel[]) => {
      this.dataSource.data = ncsChannelsList;
    });
  }

  /**
   * Reset the sensor data
   *
   * @param sensorId The ID of the selected sensor
   */
  resetChannel(element: NcsChannel) {
    this.ncsChannelsService.resetNcsChannel(
      this.store.selectSnapshot(ConsoleState.selectedOrganization),
      this.store.selectSnapshot(ConsoleState.selectedVenueId),
      element.beaconId,
      element.channelId,
    )
  }

  /**
   * Highlight the ncschannel ID if the ncschannel button
   * was pressed within last 30 seconds
   *
   * @param activationTime The notification priority
   */
  setNcsChannelStyle(activationTime: number) {
    let styles = {};
    const currentTime = new Date().getTime();
    if (currentTime - activationTime < 30000) { // 30000 milliseconds = 30 seconds
      styles = { 'background-color': 'magenta', 'color': 'white'  };
    }
    return styles;
  }

  /**
   * Respond to a ncschannel being selected in the dialog
   *
   * @param ncschannelId The ID of the selected ncschannel
   */
  onSelect(ncschannelId) {
    const selectedChannel = this.dataSource.data.find(data => data.id === ncschannelId);
    this.dialogRef.close(selectedChannel);
  }

  ngOnDestroy() {
    if (this.activatedFixtureListSubscription) {
      this.activatedFixtureListSubscription.unsubscribe();
    }
    this.store.dispatch(new ResetNcsChannelsState());
  }


}
