import { ProfileType } from "./profile-type.model";
import { FormGroup } from "@angular/forms";
import { RuleSettings } from "./rule-settings.model";
import { FormLayout } from "./form-layout.model";


export class FixtureProfileType extends ProfileType {
  private static instance: FixtureProfileType;

  readonly id = 'fixture';
  readonly name = 'Call Station';
  readonly namePlural = 'Call Stations';
  readonly isFixture = true;
  readonly icon = 'pager';
  readonly listReturningOption = false;
  readonly checkForDuplicateName = false;
  readonly listColumns = ['photoUrl', 'fixtureType', 'hardwareType', 'identifier', 'label', 'roomName', 'deviceLabel', 'selfClear', 'units'];
  readonly secureColumns = [];
  readonly formLayout = <FormLayout>{
    basicProfile: ['photoUrl', 'fixtureType', 'identifier'],
    fixtureClearSensor: ['isSelfClearing', 'clearSensorId', 'hardwareClearingMethod'],
    fixtureClearChannel: ['isSelfClearing', 'clearBeaconId', 'clearChannelId', 'hardwareClearingMethod'],
    venueProfile: ['label', 'notes', 'floorId', 'floorNumber', 'xCoordinate', 'yCoordinate', 'zCoordinate', 'roomId', 'roomName', 'assignedUnit', 'coordinateMap'],
    fixtureNurseCall: ['beaconId', 'channelId', 'activeHighOrLow', 'hardwareType'],
    fixtureSensor: ['sensorId',]
  };
  readonly ruleSettings = <RuleSettings>{
    level: 2,
    showDisableOnPass: false,
    showRuleEnforcement: false,
    eventTypes: [
    ],
    defaultEventType: null,
    defaultRules: [
    ]
  }

  readonly downloadColumns = [
    'label', 'fixtureType', 'hardwareType', 'identifier', 'x', 'y', 'z', 'roomName', 'sensorId', 'beaconId', 'channelId', 'battery'
  ];

  private constructor(){super();}

  public static getInstance(): FixtureProfileType {
    if (!FixtureProfileType.instance) {
      FixtureProfileType.instance = new FixtureProfileType();
    }
    return FixtureProfileType.instance;
  }

  mapLabel(form: FormGroup): string {
    const type = form.get('hardwareType').value ?? 'call station';
    const roomName = form.get('roomName').value;
    return (type && roomName) ? roomName + ' ' + type : null;
  }
}
