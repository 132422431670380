import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Profile } from '../../models/profile.model';

@Component({
  selector: 'app-duplicate-dialog',
  templateUrl: './profile-duplicate-dialog.component.html',
  styleUrls: ['./profile-duplicate-dialog.component.scss'],
})
export class ProfileDuplicateDialogComponent implements OnInit {


  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    existingProfiles: Array<Profile>
  },
    private dialogRef: MatDialogRef<ProfileDuplicateDialogComponent>) { }

  ngOnInit() {
    
  }

  onClickCancel() {
    this.dialogRef.close();
  }

}
