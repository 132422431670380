import { Notification } from '../../../../shared/models/notification.model';

// Events - Actions that result from an event

/**
 * Action dispatched to set the notification detail
 */
export class SetNotificationDetail {
  static type = '[NotificationDetail] SetNotificationDetail';
  constructor(public notificationDetail: Notification) {}
}

/**
 * Action dispatched to reset the notification detail state to default
 */
export class ResetNotificationDetailState {
  static type = '[NotificationDetail] ResetNotificationDetailState';
}
