import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Store, Select } from '@ngxs/store';
import { Router } from '@angular/router';

import { ConsoleState } from './../../shared/state/console/console.state';
import { Notification } from '../../shared/models/notification.model';
import { VenueFeatureConfig } from '../../shared/models/venue-feature-config.model';

@Component({
  selector: 'app-code-white-bar',
  templateUrl: './code-white-bar.component.html',
  styleUrls: ['./code-white-bar.component.scss']
})
export class CodeWhiteBarComponent implements OnInit, OnDestroy {

  // Observables on console state selectors
  @Select(ConsoleState.codeWhiteNotifications) codeWhiteNotifications$: Observable<Notification[]>;
  @Select(ConsoleState.activeNotificationsCodeWhite) activeNotificationsCodeWhite$: Observable<number>;
  @Select(ConsoleState.selectedVenueFeatureConfig) selectedVenueFeatureConfig$: Observable<VenueFeatureConfig>;


  codeWhiteSubscription: Subscription;
  codeWhiteAssistance: boolean;
  notification: Notification;
  activeNotifications: Notification[];
  notificationIdx: number = 0;
  totalNotifications: number = 0;

  constructor(private store: Store, private router: Router) {}

  ngOnInit() {
  
    this.codeWhiteSubscription = this.store.select(ConsoleState.codeWhiteNotifications).subscribe(codeWhites => {
      this.notificationIdx = 0;
      if (codeWhites && codeWhites.length > 0) {
        this.codeWhiteAssistance = (codeWhites.filter(code => !code.clearedTime).length > 0);
        if (this.codeWhiteAssistance) {
          this.activeNotifications = codeWhites.filter(code => !code.clearedTime);
          this.totalNotifications = this.activeNotifications.length;
          this.notification = this.activeNotifications[0];
        } else {
          this.notification = null;
          this.totalNotifications = 0;
        }
      } else {
        this.codeWhiteAssistance = false;
        this.notification = null;
        this.totalNotifications = 0;
      }
    })
  }

  onViewNotification(notificationId: string) {
    const detailUrl = '/notifications/detail/' + notificationId;
    this.router.navigate([detailUrl]);
  }

  onPrevNotification() {
    this.notificationIdx = this.notificationIdx > 0 ? this.notificationIdx - 1 : this.activeNotifications.length - 1;
    this.notification = this.activeNotifications[this.notificationIdx];
  }

  onNextNotification() {
    this.notificationIdx = this.notificationIdx < this.activeNotifications.length - 1 ? this.notificationIdx + 1 : 0;
    this.notification = this.activeNotifications[this.notificationIdx];
  }

  ngOnDestroy(): void {
    if (this.codeWhiteSubscription) {
      this.codeWhiteSubscription.unsubscribe();
    }
  }
}
