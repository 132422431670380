import { Store } from '@ngxs/store';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Navigate } from '@ngxs/router-plugin';

import { AuthState } from './../../../shared/state/auth/auth.state';
import { PasswordRecoverCredentials } from './password-recover-credentials.model';
import { PasswordRecoverFormService } from './password-recover-form.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { SettingsService } from '../../../core/settings/settings.service';

@Component({
  selector: 'app-recover',
  templateUrl: './password-recover.component.html',
  styleUrls: ['./password-recover.component.scss'],
  providers: [PasswordRecoverFormService]
})
export class PasswordRecoverComponent implements OnInit {
  isInvalid: boolean;
  emailSent = false;
  recoverForm: UntypedFormGroup;

  constructor(
    private recoverFormService: PasswordRecoverFormService,
    private store: Store,
    private settings: SettingsService,
    private firebaseAuth: AngularFireAuth) { }

  ngOnInit() {
    // prevent users from accessing recover page if logged in
    if (this.store.selectSnapshot(AuthState.token)) {
      this.store.dispatch(new Navigate(['dashboard']));
    }

    // initialize form
    this.recoverForm = this.recoverFormService.form;
  }

  /**
   * Attempt recover if within permitted geographic regions
   *
   * @param credentials Username and password collected from the recover form
   */
  async recover(credentials: PasswordRecoverCredentials) {
    this.isInvalid = false;
    this.emailSent = false;
    
    try {
      if (credentials.email.toLocaleLowerCase().includes('tclogin.com')) {
        this.isInvalid = true;
        this.emailSent = false;
      } else {
        await this.firebaseAuth.sendPasswordResetEmail(credentials.email);
        this.emailSent = true;
      }
      
    } catch (error) {
      this.isInvalid = true;
      this.recoverFormService.resetForm();
    }
  }

  /**
   * Get an app setting value from the settings service
   *
   * @param name Name of the app setting to retrieve
   */
  getAppSetting(name: string) {
    return this.settings.getAppSetting(name);
  }
}
