import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsModule } from '@ngxs/store';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { UserIdleModule } from 'angular-user-idle';

import { AppComponent } from './app.component';
import { AuthInterceptor } from './shared/services/auth.interceptor';
import { AutoAssignSensorDialogComponent } from './shared/components/auto-assign-sensor-dialog/auto-assign-sensor-dialog.component';
import { ClearNotificationDialogComponent } from './routes/dashboard/clear-notification-dialog/clear-notification-dialog.component';
import { ConsoleService } from './shared/services/console.service';
import { CoreModule } from './core/core.module';
import { DeleteConfirmDialogComponent } from './shared/components/delete-confirm-dialog/delete-confirm-dialog.component';
import { environment } from '../environments/environment';
import { ImportPatientDialogComponent } from './routes/profiles/import-patient-dialog/import-patient-dialog.component';
import { LayoutModule } from './layout/layout.module';
import { LoginService } from './shared/services/login.service';
import { MessageDialogComponent } from './shared/components/message-dialog/message-dialog.component';
import { ProfileRuleDetailDialogComponent
} from './routes/profiles/profile-detail/profile-rules/profile-rule-detail-dialog/profile-rule-detail-dialog.component';
import { RoutesModule } from './routes/routes.module';
import { SharedModule } from './shared/shared.module';
import { States } from './shared/state/app.state';
import { UnsavedChangesDialogComponent } from './shared/components/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { PermissionService } from './shared/services/permission.service';
import { RuleEditorDetailDialogComponent } from './routes/rules/rule-editor-detail-dialog/rule-editor-detail-dialog.component';
import { VisitorSignOutDialogComponent } from './shared/components/visitor-sign-out-dialog/visitor-sign-out-dialog.component';
import { ProfileDuplicateDialogComponent } from './routes/profiles/profile-detail/profile-duplicate-dialog/profile-duplicate-dialog.component';
import { ConfirmDisableDialogComponent } from './shared/components/confirm-disable-dialog/confirm-disable-dialog.component';
import { AccountService } from './routes/account/account.service';
import { ConfirmArchiveDialogComponent } from './routes/dashboard/confirm-archive-dialog/confirm-archive-dialog.component';
import { ProfileConfirmProximityDialogComponent } from './routes/profiles/profile-detail/profile-confirm-proximity-clear-dialog/profile-confirm-proximity-clear-dialog.component';
import { AutoAssignFixtureDialogComponent } from './shared/components/auto-assign-fixture-dialog/auto-assign-fixture-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ChangeConfirmDialogComponent } from './shared/components/change-confirm-dialog/change-confirm-dialog.component';

const hostname = window.location.hostname;
const subdomain = (hostname === 'localhost') ? 'dev' : hostname.split('.')[0];
const firebase = environment.firebase;

// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    ClearNotificationDialogComponent,
    ProfileRuleDetailDialogComponent,
    ProfileDuplicateDialogComponent,
    RuleEditorDetailDialogComponent,
    ImportPatientDialogComponent,
    VisitorSignOutDialogComponent,
    ConfirmArchiveDialogComponent,
    ProfileConfirmProximityDialogComponent,
    UnsavedChangesDialogComponent,
    MessageDialogComponent,
    DeleteConfirmDialogComponent,
    ConfirmDisableDialogComponent,
    ChangeConfirmDialogComponent,
    AutoAssignSensorDialogComponent,
    AutoAssignFixtureDialogComponent
  ],
  imports: [
    MatDialogModule,
    HttpClientModule,
    BrowserAnimationsModule, // required for ng2-tag-input
    CoreModule,
    LayoutModule,
    SharedModule.forRoot(),
    RoutesModule,
    AngularFireModule.initializeApp(firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireMessagingModule,
    NgxsModule.forRoot(States, { 
      developmentMode: !environment.production,
      compatibility: { strictContentSecurityPolicy: true }
    }),
    NgxsRouterPluginModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    UserIdleModule.forRoot({idle: environment.idleTimeout, timeout: 4, ping: 1})
  ],
  providers: [
    LoginService,
    ConsoleService,
    PermissionService,
    AccountService,
    { provide: SETTINGS, useValue: { merge: true } },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
