import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { UserIdleService } from 'angular-user-idle';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthUtils } from '../state/auth/auth.utils';

import { environment } from '../../../environments/environment';
import { Logout, Refresh } from '../state/auth/auth-state.actions';

const IP_INFO_URL = environment.ipInfoUrl;
const API_URL_PREFIX = environment.apiUrl;
const CANADA = 'CA';
const UNITED_STATES = 'US';
const REFRESH_BUFFER_SECONDS = 10;

@Injectable()
export class LoginService {
  private tokenTimer: any;

  constructor(
    private http: HttpClient,
    private store: Store,
    private firebaseAuth: AngularFireAuth,
    private userIdle: UserIdleService) { }

  /**
   * Determine whether the request is
   * coming from Canada or the United States
   */
  isGeoAllowed() {
    return this.http.get<{country: string}>(IP_INFO_URL).pipe(map(response => {
      return response.country === CANADA || response.country === UNITED_STATES;
    })).toPromise();
  }

  /**
   * Determine whether user is authenticated through PCC
   */
  usePccAuth() {
    localStorage.setItem('isPccAuth', 'true');
  }

  isPccAuth() {
    return (localStorage.getItem('isPccAuth') && localStorage.getItem('isPccAuth') === 'true');
  }

  /**
   * Force refresh when JWT token expires
   *
   * @param expirationDate The time at which to run a refresh
   */
  setAuthTimer(expirationDate: Date) {
    const duration = (expirationDate.getTime() - (new Date()).getTime()) / 1000;
    console.log('setting timeout to: ' + duration);
    this.tokenTimer = setTimeout( () => {
      if (this.isPccAuth()) {
        this.handlePccRefresh();
      } else {
        this.store.dispatch(new Refresh());
      }
    }, duration * 1000); // convert to milliseconds
  }

  /**
   * Start tracking idle time to force logout after
   * long period of inactivity based on the "idleTimeout"
   * environment variable
   */
  startIdleTimer() {
    // Start watching for user inactivity.
    this.userIdle.startWatching();
    // Start watching when user idle is starting.
    const counterSubscription = this.userIdle.onTimerStart().subscribe(count => console.log('Timing out... ' + count));
    // Start watch when time is up.
    const timeoutSubscription = this.userIdle.onTimeout().subscribe(() => {
      console.log('Inactive for too long. Timed out...');
      counterSubscription.unsubscribe();
      this.userIdle.stopWatching();
      timeoutSubscription.unsubscribe();
      this.store.dispatch(new Logout());
    });
  }

  /**
   * Perform logout cleanup tasks
   */
  logout() {
    // clear the token expiration timer
    if (this.tokenTimer) {
      clearTimeout(this.tokenTimer);
    }

    // revoke tokens from auth services
    let revokeUri = '/authorize/revoke';
    if (this.isPccAuth()) {
      revokeUri = '/pointclickcare/authorize/revoke' ;
    }
    const revokeEndpoint = environment.apiUrl + revokeUri;
    this.http.get<any>(revokeEndpoint).subscribe();

    // clear local storage
    this.clearStorage();
  }

  /**
   * State used for 3-legged oauth
   */
  setState(state) {
    localStorage.setItem('state', state);
  }

  clearState() {
    localStorage.removeItem('state');
  }

  getState() {
    return localStorage.getItem('state');
  }

  /**
   * Change date into expected date format and subtract latency buffer
   */
  getExpirationDateFromDate(date: any) {
    return new Date((new Date(date)).getTime() - REFRESH_BUFFER_SECONDS * 1000);
  }

  /**
   * Change seconds into expected date format and subtract latency buffer
   */
  getExpirationDateFromSeconds(seconds: number) {
    return new Date((new Date()).getTime() + (seconds - REFRESH_BUFFER_SECONDS) * 1000);
  }

  /**
   * Complete PCC 3-legged oauth process through API
   */
  async handlePccAuthentication(code, state) {
    try {
      if (!code || !state) {
        return 'missing codes';
      }
      if (state !== this.getState()) {
        return 'invalid state code';
      }
      this.clearState();

      const authEndpoint = environment.apiUrl + '/pointclickcare/authorize?code=' + code;
      // call api to convert PCC token into Firebase Custom Token
      const res = await this.http.get<any>(authEndpoint).toPromise();
      // get the id token from user data and set in local storage
      await this.loginWithCustomToken(res.token);
      return '';
    } catch (error) {
      return error.error.message;
    }
  }

  /**
   * Refresh PCC's token through API
   */
  async handlePccRefresh() {
    const authData = AuthUtils.getAuthData();
    const refreshEndpoint = environment.apiUrl + '/pointclickcare/authorize/refresh';
    const data = {
      refreshToken: authData.refreshToken
    };
    console.log('refreshing pcc token...');
    const res = await this.http.post<any>(refreshEndpoint, data).toPromise();
    // login and update refresh timer
    const expirationDate = await this.loginWithCustomToken(res.token);
    this.setAuthTimer(expirationDate);
  }

  private async loginWithCustomToken(token) {
    // login to Firebase with Custom Token and get user data
    const userData = await this.firebaseAuth.signInWithCustomToken(token);
    // get the id token from user data and set in local storage
    const idTokenResult = await userData.user.getIdTokenResult(true);
    const expirationDate = this.getExpirationDateFromDate(idTokenResult.expirationTime);

    // update local storage context
    localStorage.setItem('token', idTokenResult.token);
    localStorage.setItem('refreshToken', userData.user.refreshToken);
    localStorage.setItem('expiration', expirationDate.toISOString());
    localStorage.setItem('userId', userData.user.uid);
    localStorage.setItem('isPccAuth', 'true');

    return expirationDate;
  }

  clearStorage() {
    localStorage.removeItem('redirectUrl');
    localStorage.removeItem('selectedOrganization');
    localStorage.removeItem('selectedUnit');
    this.clearPccContext();
  }

  clearPccContext() {
    localStorage.removeItem('state');
  }

    /**
   * Retrieve an hmac for intercom id for this user
   * 
   * @param organization The selected organization
   */
     async getIntercomHmac(organization:string) {
      const supportUrl = API_URL_PREFIX + '/organizations/' + organization + '/users/intercom/hmac';
      try {
        const response = await this.http.get<any>(supportUrl).toPromise();
        return response.hmac;
      } catch (error) {
        console.log(error);
        return null;
      }
    }
}
