import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { Store } from '@ngxs/store';

import { ConsoleState } from '../../../shared/state/console/console.state';
import { NewProfileMethod } from './new-profile-method.model';
import { PatientsService } from '../patients.service';
import { ProfilesService } from '../profiles.service';

export interface Patient {
  name: string;
  id: string;
}

@Component({
  selector: 'app-import-patient-dialog',
  templateUrl: './import-patient-dialog.component.html',
  styleUrls: ['./import-patient-dialog.component.scss']
})
export class ImportPatientDialogComponent implements OnInit {
  form: UntypedFormGroup;
  errorMessage: string;
  selectedMethod: string;
  possibleMethods: NewProfileMethod[] = [
    {value: 'manual', viewValue: 'Create new profile manually'},
    {value: 'import', viewValue: 'Import patient from PointClickCare'}];
  patientControl = new UntypedFormControl();
  patients: Patient[] = [];
  filteredPatients: Observable<Patient[]>;

  constructor(
    private dialogRef: MatDialogRef<ImportPatientDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private patientsService: PatientsService,
    private profilesService: ProfilesService,
    private store: Store) {
      this.selectedMethod = 'manual';
  }

  async ngOnInit() {
    const orgId = this.store.selectSnapshot(ConsoleState.selectedOrganization);
    const pccFacId = this.store.selectSnapshot(ConsoleState.selectedPccFacId);
    try {
      const patientList = await this.patientsService.getPatientList(orgId, pccFacId);
      const patients = patientList.patients;
      patients.forEach(patient => {
        this.patients.push({name: patient.patientName, id: patient.patientId});
      });
    } catch (error) {
      this.errorMessage = 'An error occurred retrieving patients';
      console.log('Error fetching patients', error.message);
    }

    this.form = this.formBuilder.group({
      patientId: null
    });

    this.filteredPatients = this.patientControl.valueChanges.pipe(
        startWith<string | Patient>(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this.filter(name) : this.patients.slice())
      );
  }

  patientSelect(patient?: Patient) {
    if (patient) {
      this.errorMessage = '';
      const organization = this.store.selectSnapshot(ConsoleState.selectedOrganization);
      this.profilesService.fetchProfileByCustomId(organization, 'pccPatientId', patient.id)
        .subscribe(result => {
          if (result.size === 1) {
            this.errorMessage = 'This patient has already been imported';
          } else {
            this.form.setValue({patientId: patient.id});
          }
        }, error => {
          this.errorMessage = 'An error occurred when checking patient records';
        });
      }
  }

  display(patient?: Patient): string | undefined {
    return (patient) ? patient.name : undefined;
  }

  filter(name: string): Patient[] {
    const filterValue = name.toLowerCase();
    return this.patients.filter(option => (
      option.name.toLowerCase().indexOf(filterValue) === 0 ||
      option.name.toLowerCase().indexOf(', ' + filterValue) > -1 ||
      option.name.toLowerCase().indexOf('(' + filterValue) > -1
    ));
  }

  radioChange(event: MatRadioChange) {
    if (event.value === 'manual') {
      this.errorMessage = '';
    } else if (event.value === 'import') {
      this.form.reset();
    }
  }

  submit(form) {
    if (this.selectedMethod === 'import' && form.value.patientId === null ||
        this.selectedMethod === 'import' && this.errorMessage) {
      this.errorMessage = 'You must select a valid patient to import';
    } else if (this.selectedMethod === 'manual') {
      this.dialogRef.close(this.selectedMethod);
    } else {
      this.dialogRef.close(form.value);
    }
  }
}
