import { Injectable } from '@angular/core';

import { Account } from './models/account.model';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AccountError } from './models/account-error.model';
import { ConsoleState } from '../../shared/state/console/console.state';
import { Store } from '@ngxs/store';

const API_URL_PREFIX = environment.apiUrl;

@Injectable()
export class AccountService {

  constructor(private firebaseAuth: AngularFireAuth, private http: HttpClient, private store: Store) {}

  async getMyAccount(): Promise<Account> {
    const user = await this.firebaseAuth.currentUser;
    return {
      currentPassword: null,
      email: user.email,
      displayName: user.displayName,
      phone: user.phoneNumber,
      newPassword1: null,
      newPassword2: null,
      profileId: (await user.getIdTokenResult()).claims.profileId
    } as Account;
  }

  async verifyCredentials(account: Account) {
    const user = await this.firebaseAuth.currentUser;
    let error = '';
    if (!user) {
      error = 'Please login in order to update';
    } else if (!account.currentPassword) {
      error = 'Current password is required to update account information';
    } else {
      try {
        await this.firebaseAuth.signInWithEmailAndPassword(user.email, account.currentPassword);
      } catch (e) {
        error = 'Current password is incorrect';
      }
    }
    return error;
  }

  async validateNewPasswordStrength(account: Account) {
    let errors = [];
    const user = await this.firebaseAuth.currentUser;

    try {
      const validateUrl = API_URL_PREFIX + '/password/validate';
      const credentials = {
        email: user.email,
        password: account.newPassword1
      }
      await this.http.post<any>(validateUrl, credentials).toPromise();
    } catch (error) {
      console.log(error);
      errors = error.error.message.errors;
    }
    return errors;
  }

  async updateAccountPassword(account: Account) : Promise<AccountError> {
    const user = await this.firebaseAuth.currentUser;
    let error = null;

    // validate input
    if (!account.currentPassword) {
      error = 'Current password is required to change password';
    } else if (!account.newPassword1 || !account.newPassword2) {
      error = 'New password fields cannot be blank';
    } else if (account.newPassword1 !== account.newPassword2) {
      error = 'New password fields must match';
    } else if (account.currentPassword === account.newPassword1) {
      error = 'New password must be different than current password';
    }
    if (error) {
      return { error } as AccountError;
    }

    // validate current password
    error = await this.verifyCredentials(account);
    if (error) {
      return { error } as AccountError;
    }
  
    // check password strength
    const errorList = await this.validateNewPasswordStrength(account);
    if (errorList && errorList.length > 0) {
      return {
        error: 'Password not strong enough',
        list: errorList
      } as AccountError;
    }

    // attempt update
    try {
      await user.updatePassword(account.newPassword1);

      const organization = this.store.selectSnapshot(ConsoleState.selectedOrganization);
      const passwordExpirationUrl = API_URL_PREFIX + '/organizations/' + organization + '/users/passwordExpiration/' + user.uid;
      await this.http.put(passwordExpirationUrl, {}).toPromise();
        
    } catch(e) {
      error = 'Error updating pasword';
      return { error } as AccountError;
    }

    return null;
  }

  async updateAccountProfile(account: Account) : Promise<AccountError> {
    let error = await this.verifyCredentials(account);
    if (error) {
      return { error } as AccountError;
    }

    const user = await this.firebaseAuth.currentUser;

    try {
      // update display name
      if (user.displayName !== account.displayName) {
        await user.updateProfile({
          displayName: account.displayName,
          photoURL: null
        });
      }

      // update email
      if (user.email !== account.email) {
        await user.updateEmail(account.email);
        await user.sendEmailVerification();
      }

      // update phone
      if (user.phoneNumber !== account.phone) {
        // this.firebaseAuth.signInWithPhoneNumber()
        // await user.updatePhoneNumber();
      }

    } catch(e) {
      error = 'Error updating Account Detail';
      return { error } as AccountError;
    }
  
    return null;
  }

}
