import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ClearNotificationFormService } from './clear-notification-form.service';
import { Store } from '@ngxs/store';
import { Subscription, timer } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';
import { DashboardState } from '../state/dashboard.state';
import { NotificationsService } from '../../../shared/services/notifications.service';
import { ConsoleState } from '../../../shared/state/console/console.state';
import { AuthState } from '../../../shared/state/auth/auth.state';

const emptyClear = 'None';

@Component({
  selector: 'app-clear-notification-dialog',
  templateUrl: './clear-notification-dialog.component.html',
  styleUrls: ['./clear-notification-dialog.component.scss'],
  providers: [ClearNotificationFormService]
})
export class ClearNotificationDialogComponent implements OnInit, OnDestroy {

  clearNotificationForm: UntypedFormGroup;
  clearedReasons: string[];
  timeRemaining$ = null; 
  timerInitialized = false;
  countDownTime = -1;
  clearAttemptTime = null;

  notificationSubscription?: Subscription;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    notificationId: string,
    isEmptyClearAllowed: boolean,
    isWearablePressRequired: boolean,
    wearablePressSeconds?: number,
    isOnFloorType?: boolean,
  }, 
  private clearNotificationFormService: ClearNotificationFormService,
  private store: Store,
  private snackBar: MatSnackBar,
  private notificationsService: NotificationsService,
    private dialogRef: MatDialogRef<ClearNotificationDialogComponent>) { }

  ngOnInit() {
    this.clearNotificationForm = this.clearNotificationFormService.form;
    this.clearedReasons = this.clearNotificationFormService.clearedReasons;
    if (this.data.isEmptyClearAllowed && !this.data.isOnFloorType) {
      this.clearedReasons.unshift(emptyClear);
      this.clearNotificationForm.get('reason').setValue(emptyClear);
    }
    if (this.data.isOnFloorType) {
      this.clearedReasons = this.clearNotificationFormService.onFloorClearReasons;
    }
    if (this.data.isWearablePressRequired && this.data.wearablePressSeconds) {
      this.notificationSubscription = this.store.select(DashboardState.dashboardNotifications).subscribe((notifications) => {
        const notification = notifications.find(n => n.id === this.data.notificationId);
        if (notification) {
          if (notification.clearAttemptTime) {
            this.countDownTime = (this.data.wearablePressSeconds * 1000) + notification.clearAttemptTime - Date.now();
            this.clearAttemptTime = notification.clearAttemptTime;
          }

          if (this.countDownTime > 0 && !this.timerInitialized) {
            this.timerInitialized = true;
            this.timeRemaining$ = timer(0, 1000).pipe(
              map(count => { 
                const countValue = (this.data.wearablePressSeconds * 1000) + this.clearAttemptTime - Date.now();
                if (countValue <= 0) {
                  this.timerInitialized = false;
                }
                return countValue;
              }),
              takeWhile(n => {return n >= -1000}),
            );
          } else if (this.countDownTime <= 0 && this.timerInitialized) {
            this.timerInitialized = false;
          }
        } else if (this.clearAttemptTime) {
          this.dialogRef.close();
        }
      });
    }
  }

  submit(form: UntypedFormGroup) {
    this.dialogRef.close(form.value);
  }

  async onClearAttempt(event) {
    event.preventDefault();
    const name = this.store.selectSnapshot(AuthState.accountName);
    const error = await this.notificationsService.clearNotification(
    this.store.selectSnapshot(ConsoleState.selectedOrganization), 
    this.store.selectSnapshot(ConsoleState.selectedVenueId), 
    this.data.notificationId, 
    this.clearNotificationForm.get('reason')?.value, 
    this.clearNotificationForm.get('memo')?.value, 
    name);
      if (error) {
        const msg = error.error?.message ?? 'Failed to clear notification';
        this.snackBar.open(msg, 'Okay', {
          duration: 6000,
          panelClass: ['error-snackbar']
        });
      }
  }

  ngOnDestroy() {
    this.notificationSubscription?.unsubscribe();
  }

}
