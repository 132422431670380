// Commands - Actions related to triggering something to happen

/**
 * Action dispatched on startup to determine whether a user is already logged in
 */
export class AutoAuth {
  static type = '[Auth] AutoAuth';
}

/**
 * Action dispatched to initiate console login
 */
export class Login {
  static type = '[Auth] Login';
  constructor(public payload: { username: string, password: string }) {}
}

/**
 * Action dispatched to initiate console logout
 */
export class Logout {
  static type = '[Auth] Logout';
}

/**
 * Action dispatched to initiate refresh of authorization token
 */
export class Refresh {
  static type = '[Auth] Refresh';
}

// Events - Actions that result from an event

/**
 * Action dispatched to clean up after expired or
 *  invalid data in local storage
 */
export class AutoAuthFailed {
  static type = '[Auth] AutoAuthFailed';
}

/**
 * Action dispatched to to setup authorization context
 */
export class AuthSuccess {
  static type = '[Auth] AuthSuccess';
  constructor(public payload: { token: string, refreshToken: string, expirationDate: Date, userId: string }) {}
}

/**
 * Action dispatched to indicate that an auth attempt (manual or auto) was not successful
 */
export class AuthFailed {
  static type = '[Auth] AuthFailed';
  constructor(public error: string) {}
}

/**
 * Action dispatched to indicate that a login attempt was not successful
 */
export class LoginFailed {
  static type = '[Auth] LoginFailed';
  constructor(public error: string) {}
}

/**
 * Action dispatched to refresh access token
 */
export class RefreshSuccess {
  static type = '[Auth] RefreshSuccess';
  constructor(public payload: { token: string, expirationDate: Date }) {}
}

/**
 * Action dispatched to indicate that a token refresh was not successful
 */
export class RefreshFailed {
  static type = '[Auth] RefreshFailed';
  constructor(public error: string) {}
}

/**
 * Action dispatched to redirect the user to the console Login view
 */
export class LoginRedirect {
  static type = '[Auth] LoginRedirect';
}
