/**
 * Enumeration of possible rule property values when GeofenceTrigger = GEOFENCE_TYPE
 */
export enum VisitReason {
  VISITING_RESIDENT = 'Visiting Resident',
  CONTRACTOR = 'Contractor',
  DELIVERY = 'Delivery',
  ENTERTAINER = 'Entertainer',
  HEALTH_WELLNESS_BEAUTY = 'Health, Wellness and Beauty Services',
  MAINTENANCE = 'Maintenance',
  VOLUNTEER = 'Volunteer',
  OTHER = 'Other'
}
