<!-- START Code White Bar -->
<app-code-white-bar></app-code-white-bar>
<!-- END Code White Bar -->
<div [class]="wrapperClass">
  <!-- top navbar-->
  <app-header class="topnavbar-wrapper" #appHeader></app-header>
  <!-- sidebar-->
  <app-sidebar [class]="asideContainer"></app-sidebar>
  <!-- Main section-->
  <section [class]="sectionContainerClass">
    <!-- Page content-->
    <div class="content-wrapper">
      <router-outlet></router-outlet>
    </div>
  </section>
  <!-- Page footer-->
  <footer class="footer-container" app-footer></footer>
</div>
