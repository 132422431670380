import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UntypedFormGroup } from '@angular/forms';

import { environment } from '../../../environments/environment';
import { Profile } from './models/profile.model';
import { Patient } from './models/patient.model';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({providedIn: 'root'})
export class PatientsService {
  private patient: Patient;
  private PATIENT_LIST_URL: string;
  private PATIENT_URL: string;

  constructor(
    private http: HttpClient,
    private afs: AngularFirestore
  ) {
    this.PATIENT_LIST_URL = environment.apiUrl + '/pointclickcare/patients/facility/';
    this.PATIENT_URL = environment.apiUrl + '/pointclickcare/patients/patient/';
  }

  async getPatientList(orgId: string, pccFacId: number): Promise<{patients: any[]}> {
    return await this.http.get<{patients: any[]}>(this.PATIENT_LIST_URL + pccFacId).toPromise();
  }

  async selectPatient(patientId: number) {
    try {
      const patientProfile = await this.http.get<{patient: Patient}>(this.PATIENT_URL + patientId).toPromise();
      this.patient = patientProfile.patient;
    } catch(error) {
      console.log('Error fetching patient', error.message);
    }
  }

  clearSelectedPatient() {
    this.patient = null;
  }

  isPatientSelected() {
    return (this.patient) ? true : false;
  }

  isPccUser() {
    return (localStorage.getItem('isPccAuth') && localStorage.getItem('isPccAuth') === 'true');
  }

  getPatient() {
    return this.patient;
  }

  getPccEnvironment() {
    return (environment.pcc) ? environment.pcc : null;
  }

  setupForm(profileForm: UntypedFormGroup) {
    if (this.isPatientSelected()) {
      let gender = this.patient.gender;
      if (this.patient.gender === 'MALE') {
        gender = 'Male';
      } else if (this.patient.gender === 'FEMALE') {
        gender = 'Female';
      }
      profileForm.get('firstName').setValue(this.patient.firstName);
      profileForm.get('middleName').setValue(this.patient.middleName);
      profileForm.get('lastName').setValue(this.patient.lastName);
      profileForm.get('gender').setValue(gender);
      profileForm.get('floorId').setValue('MainFloor');
      profileForm.get('floorNumber').setValue(1);
      profileForm.get('roomId').setValue((this.patient.roomId) ? this.patient.roomId : null);
      profileForm.get('bedId').setValue((this.patient.bedId) ? this.patient.bedId : null);
      profileForm.get('label').setValue(this.patient.firstName + ' ' + this.patient.lastName.substr(0, 1));
    }
  }

  prepareForSave(profileProfile: Profile) {
    if (this.isPatientSelected()) {
      const patient = this.getPatient();
      profileProfile.profileData.pccPatientId = patient.patientId;
      profileProfile.profileData.pccFacId = patient.facId;
      profileProfile.profileData.pccOrgId = patient.orgId;
      profileProfile.profileData.pccOrgUuid = patient.orgUuid;
    }
  }
}
