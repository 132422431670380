// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  idleTimeout: 60 * 60 * 24 * 7,
  // apiUrl: 'http://localhost:3000/api',
  apiUrl: 'https://api-dev-us.tenera.care/api',
  // apiUrl: 'https://tenera-prod-ca.appspot.com/api',
  ipInfoUrl: 'https://ipinfo.io?token=05ca6b3b4b3688',
  firebase: {
    apiKey: 'AIzaSyC-QEZOuoOY_I2Bihi5hTuAD3pcvlJCFqM',
    authDomain: 'tenera-dev-us.firebaseapp.com',
    databaseURL: 'https://tenera-dev-us.firebaseio.com',
    projectId: 'tenera-dev-us',
    storageBucket: 'tenera-dev-us.appspot.com',
    messagingSenderId: '300263629496',
    appId: 'TBD'
  },
  pcc: {
    url: 'https://connect.pointclickcare.com/auth/login',
    clientId: 'dPGNaA7meahFVWOI13XGHWtV2xMZHCTN',
    responseType: 'code',
    redirectUri: 'https://tenera-dev-us.web.app/authorize',
    defaultFacilityId: 12
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.