import { Adl } from '../../../../shared/models/adl.model';
import { ProfileChart } from '../../models/profile-chart.model';

// Events - Actions that result from an event

/**
 * Action dispatched to set the adls
 */
export class SetAdls {
  static type = '[ProfileChart] SetAdls';
  constructor(public adls: Adl[]) {}
}

/**
 * Action dispatched to set the adl chart
 */
export class SetProfileChart {
  static type = '[ProfileChart] SetProfileChart';
  constructor(public chart: ProfileChart) {}
}

/**
 * Action dispatched to set the chart as read only
 */
export class SetIsChartReadOnly {
  static type = '[ProfileChart] SetIsChartReadOnly';
  constructor(public isChartReadOnly: boolean) {}
}

/**
 * Action dispatched to reset
 */
export class ResetProfileChart {
  static type = '[ProfileChart] ResetProfileChart';
}
