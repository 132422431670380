
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { AuthState } from '../state/auth/auth.state';
import { LoginRedirect } from '../state/auth/auth-state.actions';

@Injectable()
export class AuthGuard  {

  constructor(private store: Store) {}
​
  canActivate() {
    const token = this.store.selectSnapshot(AuthState.token);
    if (!token) {
      this.store.dispatch(new LoginRedirect());
    }
    return true;
  }
​
}
