<div class="wrapper">
  <div class="block-center mt-4 wd-xl">
    <h2 id="brand" class="text-center">Tenera Care</h2>
    <!-- START card-->
    <div class="card card-flat">
      <div class="card-body">
        <p class="text-center py-2">RECOVER PASSWORD</p>
        <div *ngIf="isInvalid" class="alert alert-danger text-center">
          The email address is not registered or is invalid.
        </div>

        <alert *ngIf="emailSent" type="success" class="text-center">
          An email has been sent with instruictions on resetting your password.
        </alert>

        <form *ngIf="!emailSent" [formGroup]="recoverForm" class="form-validate mb-3" role="form" name="recoverForm" novalidate="" (submit)="recover(recoverForm.value)">
          <div class="form-group">
            <div class="input-group with-focus">
              <input class="form-control border-right-0" type="text" placeholder="Email" autocomplete="off" formControlName="email" />
              <div class="input-group-append">
                <span class="input-group-text text-muted bg-transparent border-left-0">
                  <em class="fa fa-envelope"></em>
                </span>
              </div>
            </div>
            <div class="text-danger text-center"
              *ngIf="recoverForm.controls['email'].hasError('required') && (recoverForm.controls['email'].dirty || recoverForm.controls['email'].touched)">
              This field is required
            </div>
          </div>
          <button class="btn btn-block btn-primary btn-lg mt-3" type="submit" [disabled]="!recoverForm.valid">
            Send Email
          </button>
        </form>

        <div class="login"><a [routerLink]="'/login'">Return to Login</a></div>
      </div>
    </div>
    <!-- END card-->
    <div class="p-3 text-center credit">
      <span>&copy;</span> <span>{{ getAppSetting("year") }}</span>
      <span> - </span> <span>{{ getAppSetting("name") }}</span>
    </div>
    <div class="privacy"><a [routerLink]="'/privacy'">Privacy Policy</a></div>
  </div>
</div>
