import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { ProfileUpdateItem } from '../../models/profile-update-item.model';
import * as ProfileDetailActions from './profile-detail-state.actions';
import { EventType } from '../../../../shared/models/rules/enums/event-type.enum';
import { NotifyOnTime } from '../../../../shared/models/rules/enums/notify-on-time.enum';
import { Rule } from '../../../../shared/models/rules/rule.model';
import { Profile } from '../../models/profile.model';
import { ProfileType } from '../../models/profile-type/profile-type.model';
import { Unit } from '../../../../shared/models/unit.model';
import { ProfileDetailStateModel } from './profile-detail-state.model';
import { Floor } from '../../../../routes/dashboard/map/models/floor.model';
import { Geofence } from '../../../../shared/models/geofence.model';

/**
 * State class for profile detail
 */
@State<ProfileDetailStateModel>({
  name: 'profileDetail',
  defaults: {
    isBedChanged: false,
    isBathroomChanged: false,
    isNameChanged: false,
    isOnpassChanged: false,
    isRoomChanged: false,
    isTimeChanged: false,
    isNewSensorAssigned: false,
    isUnitChanged: false,
    assignedUnit: null,
    selectedUnitGeofenceId: null,
    isReadOnly: false,
    mode: 'Create',
    profileType: null,
    removeMapIcon: false,
    profileUpdates: [],
    savedRules: [],
    unsavedRules: [],
    firmwareVersion: null,
    floorData: null,
    coordinates: null,
    fixturePendingCoordinates: null,
    floorGeofences: null,
    selectedRoomId: null,
    profileDetail: null,
  }
})
@Injectable()
export class ProfileDetailState {

  /**
   * Get whether bed will change if the profile is updated
   *
   * @returns ProfileDetailStateModel.isBedChanged
   */
  @Selector()
  static isBedChanged(state: ProfileDetailStateModel): boolean {
    return state.isBedChanged;
  }

  /**
   * Get whether bathroom will change if the profile is updated
   *
   * @returns ProfileDetailStateModel.isBathroomChanged
   */
  @Selector()
  static isBathroomChanged(state: ProfileDetailStateModel): boolean {
    return state.isBathroomChanged;
  }

  /**
   * Get whether name will change if the profile is updated
   *
   * @returns ProfileDetailStateModel.isNameChanged
   */
  @Selector()
  static isNameChanged(state: ProfileDetailStateModel): boolean {
    return state.isNameChanged;
  }

  /**
   * Get whether onpassstatus will change if the profile is updated
   *
   * @returns ProfileDetailStateModel.isOnpassChanged
   */
  @Selector()
  static isOnpassChanged(state: ProfileDetailStateModel): boolean {
    return state.isOnpassChanged;
  }

  /**
   * Get whether there are pending profile updates
   *
   * @returns True if profileUpdates is not empty, false otherwise
   */
  @Selector()
  static isProfileUpdated(state: ProfileDetailStateModel): boolean {
    return state.profileUpdates.length > 0;
  }

  /**
   * Get whether room will change if the profile is updated
   *
   * @returns ProfileDetailStateModel.isRoomChanged
   */
  @Selector()
  static isRoomChanged(state: ProfileDetailStateModel): boolean {
    return state.isRoomChanged;
  }

  /**
   * Get whether a new sensor has been assigned
   *
   * @returns ProfileDetailStateModel.isNewSensorAssigned
   */
  @Selector()
  static isNewSensorAssigned(state: ProfileDetailStateModel): boolean {
    return state.isNewSensorAssigned;
  }

  /**
   * Get whether unit will change if the resident profile is updated
   *
   * @returns ProfileDetailStateModel.isUnitChanged
   */
  @Selector()
  static isUnitChanged(state: ProfileDetailStateModel): boolean {
    return state.isUnitChanged;
  }

  /**
   * Get new unit ID when resident is moved
   *
   * @returns ProfileDetailStateModel.assingedUnit
   */
  @Selector()
  static assingedUnit(state: ProfileDetailStateModel): Unit {
    return state.assignedUnit;
  }

  /**
   * Get firmware version of sensor
   *
   * @returns ProfileDetailStateModel.assingedUnit
   */
  @Selector()
  static firmwareVersion(state: ProfileDetailStateModel): string {
    return state.firmwareVersion;
  }

  /**
   * Get the assigned unit geofence ID for rule updates
   *
   * @returns ProfileDetailStateModel.selectedUnitGeofenceId
   */
  @Selector()
  static selectedUnitGeofenceId(state: ProfileDetailStateModel): string {
    return state.selectedUnitGeofenceId;
  }
  /**
   * Get whether rise or sleep time will change if the profile is updated
   *
   * @returns ProfileDetailStateModel.isTimeChanged
   */
  @Selector()
  static isTimeChanged(state: ProfileDetailStateModel): boolean {
    return state.isTimeChanged;
  }

  /**
   * Get whether profile detail is read only
   *
   * @returns ProfileDetailStateModel.isReadOnly
   */
  @Selector()
  static isReadOnly(state: ProfileDetailStateModel): boolean {
    return state.isReadOnly;
  }

  /**
   * Get whether profile detail includes a user ID
   *
   * @returns ProfileDetailStateModel.isReadOnly
   */
  @Selector()
  static linkedUserId(state: ProfileDetailStateModel): string {
    return state.profileDetail?.profileData?.userId;
  }

  /**
   * Get the profile type
   *
   * @returns ProfileDetailStateModel.type
   */
  @Selector()
  static profileType(state: ProfileDetailStateModel): ProfileType {
    return state.profileType;
  }

  /**
   * Get the mode
   *
   * @returns ProfileDetailStateModel.mode
   */
  @Selector()
  static mode(state: ProfileDetailStateModel): string {
    return state.mode;
  }

  /**
   * Get the photo
   *
   * @returns ProfileDetailStateModel.photo
   */
  @Selector()
  static photo(state: ProfileDetailStateModel): string {
    return state.photo;
  }

  /**
   * Get the photo error message
   *
   * @returns ProfileDetailStateModel.photoErrorMessage
   */
  @Selector()
  static photoErrorMessage(state: ProfileDetailStateModel): string {
    return state.photoErrorMessage;
  }

  /**
   * Get the photo file type
   *
   * @returns ProfileDetailStateModel.photoFileType
   */
  @Selector()
  static photoFileType(state: ProfileDetailStateModel): string {
    return state.photoFileType;
  }

  /**
   * Get the profile detail
   *
   * @returns ProfileDetailStateModel.profileDetail
   */
  @Selector()
  static profileDetail(state: ProfileDetailStateModel): Profile {
    return state.profileDetail;
  }

  /**
   * Get the profile error message
   *
   * @returns ProfileDetailStateModel.profileErrorMessage
   */
  @Selector()
  static profileErrorMessage(state: ProfileDetailStateModel): string {
    return state.profileErrorMessage;
  }

  /**
   * Get the profile error message
   *
   * @returns ProfileDetailStateModel.profileRuleErrorMessage
   */
  @Selector()
  static profileRuleErrorMessage(state: ProfileDetailStateModel): string {
    return state.profileRuleErrorMessage;
  }

  /**
   * Get the profile update message
   *
   * @returns ProfileDetailStateModel.profileUpdateMessage
   */
  @Selector()
  static profileUpdateMessage(state: ProfileDetailStateModel): string {
    return state.profileUpdateMessage;
  }
  /**
   * Get the profile update message
   *
   * @returns ProfileDetailStateModel.profileUpdateMessage
   */
  @Selector()
  static profileRuleUpdateMessage(state: ProfileDetailStateModel): string {
    return state.profileRuleUpdateMessage;
  }

  /**
   * Get the profile updates
   *
   * @returns ProfileDetailStateModel.profileUpdates
   */
  @Selector()
  static profileUpdates(state: ProfileDetailStateModel): ProfileUpdateItem[] {
    return state.profileUpdates;
  }

  /**
   * Get whether the position icon should be
   *  removed from the map if the profile is updated
   *
   * @returns ProfileDetailStateModel.removeMapIcon
   */
  @Selector()
  static removeMapIcon(state: ProfileDetailStateModel): boolean {
    return state.removeMapIcon;
  }

  /**
   * Get the list of saved rules
   *
   * @returns ProfileDetailStateModel.savedRules
   */
  @Selector()
  static savedRules(state: ProfileDetailStateModel): Rule[] {
    return state.savedRules;
  }

  /**
   * Get the list of unsaved rules
   *
   * @returns ProfileDetailStateModel.unsavedRules
   */
  @Selector()
  static unsavedRules(state: ProfileDetailStateModel): Rule[] {
    return state.unsavedRules;
  }

  /**
   * Get the floor data
   *
   * @returns ProfileDetailStateModel.floorData
   */
  @Selector()
  static floorData(state: ProfileDetailStateModel): Floor {
    return state.floorData;
  }

  /**
   * Get the fixture position coordinates
   *
   * @returns ProfileDetailStateModel.coordinates
   */
  @Selector()
  static coordinates(state: ProfileDetailStateModel): number[] {
    return state.coordinates;
  }

  /**
   * Get the pending fixture position coordinates
   *
   * @returns ProfileDetailStateModel.fixturePendingCoordinates
   */
  @Selector()
  static fixturePendingCoordinates(state: ProfileDetailStateModel): number[] {
    return state.fixturePendingCoordinates;
  }

  /**
   * Get the floor geofences for fixtures
   *
   * @returns ProfileDetailStateModel.floorGeofences
   */
  @Selector()
  static floorGeofences(state: ProfileDetailStateModel): Geofence[] {
    return state.floorGeofences;
  }

  /**
   * Get the room ID for fixtures
   *
   * @returns ProfileDetailStateModel.selectedRoomId
   */
  @Selector()
  static selectedRoomId(state: ProfileDetailStateModel): string {
    return state.selectedRoomId;
  }

  // Actions handlers

  /**
   * Action handler - clear the photo
   */
  @Action(ProfileDetailActions.ClearPhoto)
  onClearPhoto(ctx: StateContext<ProfileDetailStateModel>) {
    ctx.patchState({
      photo: null
    });
  }

  /**
   * Action handler - clear the photo error message
   */
  @Action(ProfileDetailActions.ClearPhotoErrorMessage)
  onClearPhotoErrorMessage(ctx: StateContext<ProfileDetailStateModel>) {
    ctx.patchState({
      photoErrorMessage: null
    });
  }

  /**
   * Action handler - clear the photo file type
   */
  @Action(ProfileDetailActions.ClearPhotoFileType)
  onClearPhotoFileType(ctx: StateContext<ProfileDetailStateModel>) {
    ctx.patchState({
      photoFileType: null
    });
  }

  /**
   * Action handler - clear the profile error message
   */
  @Action(ProfileDetailActions.ClearProfileErrorMessage)
  onClearProfileErrorMessage(ctx: StateContext<ProfileDetailStateModel>) {
    ctx.patchState({
      profileErrorMessage: null
    });
  }

  /**
   * Action handler - clear the profile error message
   */
  @Action(ProfileDetailActions.ClearProfileRuleErrorMessage)
  onClearProfileRuleErrorMessage(ctx: StateContext<ProfileDetailStateModel>) {
    ctx.patchState({
      profileRuleErrorMessage: null
    });
  }

  /**
   * Action handler - clear the profile error message
   */
  @Action(ProfileDetailActions.SetFirmwareVersion)
  onSetFirmwareVersion(ctx: StateContext<ProfileDetailStateModel>, action: ProfileDetailActions.SetFirmwareVersion) {
    ctx.patchState({
      firmwareVersion: action.firmwareVersion
    });
  }

  /**
   * Action handler - clear the profile update message
   */
  @Action(ProfileDetailActions.ClearProfileUpdateMessage)
  onClearProfileUpdateMessage(ctx: StateContext<ProfileDetailStateModel>) {
    ctx.patchState({
      profileUpdateMessage: null
    });
  }
  /**
   * Action handler - clear the profile update message
   */
  @Action(ProfileDetailActions.ClearProfileRuleUpdateMessage)
  onClearProfileRuleUpdateMessage(ctx: StateContext<ProfileDetailStateModel>) {
    ctx.patchState({
      profileRuleUpdateMessage: null
    });
  }

  /**
    * Action handler - set the type
    */
  @Action(ProfileDetailActions.SetProfileType)
  onSetProfileType(ctx: StateContext<ProfileDetailStateModel>, action: ProfileDetailActions.SetProfileType) {
    ctx.patchState({
      profileType: action.profileType
    });
  }

  /**
    * Action handler - set the floor data
    */
  @Action(ProfileDetailActions.SetFloorData)
  onSetFloorData(ctx: StateContext<ProfileDetailStateModel>, action: ProfileDetailActions.SetFloorData) {
    ctx.patchState({
      floorData: action.floorData
    });
  }

  /**
    * Action handler - set the fixture position coords
    */
  @Action(ProfileDetailActions.SetCoordinates)
  onSetCoordinates(ctx: StateContext<ProfileDetailStateModel>, action: ProfileDetailActions.SetCoordinates) {
    ctx.patchState({
      coordinates: action.coordinates
    });
  }

  /**
    * Action handler - set if the fixture position is pending
    */
  @Action(ProfileDetailActions.SetFixturePendingCoordinates)
  onSetFixturePendingCoordinates(ctx: StateContext<ProfileDetailStateModel>, action: ProfileDetailActions.SetFixturePendingCoordinates) {
    ctx.patchState({
      fixturePendingCoordinates: action.fixturePendingCoordinates
    });
  }

  /**
    * Action handler - set fixture geofences
    */
  @Action(ProfileDetailActions.SetFloorGeofences)
  onSetFloorGeofences(ctx: StateContext<ProfileDetailStateModel>, action: ProfileDetailActions.SetFloorGeofences) {
    ctx.patchState({
      floorGeofences: action.floorGeofences
    });
  }

  /**
    * Action handler - set fixture selected room
    */
  @Action(ProfileDetailActions.SetSelectedRoomId)
  onSetSelectedRoomId(ctx: StateContext<ProfileDetailStateModel>, action: ProfileDetailActions.SetSelectedRoomId) {
    ctx.patchState({
      selectedRoomId: action.selectedRoomId
    });
  }

  /**
   * Action handler - set the mode
   */
  @Action(ProfileDetailActions.SetMode)
  onSetMode(ctx: StateContext<ProfileDetailStateModel>, action: ProfileDetailActions.SetMode) {
    ctx.patchState({
      mode: action.mode
    });
  }

  /**
   * Action handler - set the photo
   */
  @Action(ProfileDetailActions.SetPhoto)
  onSetPhoto(ctx: StateContext<ProfileDetailStateModel>, action: ProfileDetailActions.SetPhoto) {
    ctx.patchState({
      photo: action.photo
    });
  }

  /**
   * Action handler - set the photo error message
   */
  @Action(ProfileDetailActions.SetPhotoErrorMessage)
  onSetPhotoErrorMessage(ctx: StateContext<ProfileDetailStateModel>, action: ProfileDetailActions.SetPhotoErrorMessage) {
    ctx.patchState({
      photoErrorMessage: action.message
    });
  }

  /**
   * Action handler - set the photo file type
   */
  @Action(ProfileDetailActions.SetPhotoFileType)
  onSetPhotoFileType(ctx: StateContext<ProfileDetailStateModel>, action: ProfileDetailActions.SetPhotoFileType) {
    ctx.patchState({
      photoFileType: action.fileType
    });
  }

  /**
   * Action handler - set the profile error message
   */
  @Action(ProfileDetailActions.SetProfileErrorMessage)
  onSetProfileErrorMessage(ctx: StateContext<ProfileDetailStateModel>, action: ProfileDetailActions.SetProfileErrorMessage) {
    ctx.patchState({
      profileErrorMessage: action.message
    });
  }

  /**
   * Action handler - set the profile error message
   */
  @Action(ProfileDetailActions.SetProfileRuleErrorMessage)
  onSetProfileRuleErrorMessage(ctx: StateContext<ProfileDetailStateModel>, action: ProfileDetailActions.SetProfileRuleErrorMessage) {
    ctx.patchState({
      profileRuleErrorMessage: action.message
    });
  }

  /**
   * Action handler - set the profile update message
   */
  @Action(ProfileDetailActions.SetProfileUpdateMessage)
  onSetProfileUpdateMessage(ctx: StateContext<ProfileDetailStateModel>, action: ProfileDetailActions.SetProfileErrorMessage) {
    ctx.patchState({
      profileUpdateMessage: action.message
    });
  }

  /**
   * Action handler - set the profile update message
   */
  @Action(ProfileDetailActions.SetProfileRuleUpdateMessage)
  onSetProfileRuleUpdateMessage(ctx: StateContext<ProfileDetailStateModel>, action: ProfileDetailActions.SetProfileRuleErrorMessage) {
    ctx.patchState({
      profileRuleUpdateMessage: action.message
    });
  }

  /**
   * Action handler - add a pending profile update
   */
  @Action(ProfileDetailActions.AddProfileUpdateItem)
  onAddProfileUpdateItem(ctx: StateContext<ProfileDetailStateModel>, action: ProfileDetailActions.AddProfileUpdateItem) {
    // state is immutable, so make a copy
    const state = ctx.getState();
    const pendingUpdates = [...state.profileUpdates];
    // remove an update with this key if it already exists
    const index = pendingUpdates.findIndex(x => x.key === action.profileUpdateItem.key);
    if (index >= 0) {
      pendingUpdates.splice(index, 1);
    }
    // verify that this value is different than the stored value
    // profile data fields are nested, so need to be tested separately
    if (action.profileUpdateItem.isProfileData) {
      if (state.profileDetail.profileData[action.profileUpdateItem.key] !== action.profileUpdateItem.value) {
        // add incoming update to array
        pendingUpdates.push(action.profileUpdateItem);
      }
    } else { // field is not nested in the profileData object
      if (state.profileDetail[action.profileUpdateItem.key] !== action.profileUpdateItem.value) {
        // add incoming update to array
        pendingUpdates.push(action.profileUpdateItem);
      }
    }
    // patch the new array into state
    ctx.patchState({
      profileUpdates: pendingUpdates
    });
  }

  /**
   * Action handler - clear pending updates
   */
  @Action(ProfileDetailActions.ClearPendingUpdates)
  onClearPendingUpdates(ctx: StateContext<ProfileDetailStateModel>) {
    ctx.patchState({
      isNameChanged: false,
      isRoomChanged: false,
      isBedChanged: false,
      isOnpassChanged: false,
      isNewSensorAssigned: false,
      isTimeChanged: false,
      removeMapIcon: false,
      profileUpdates: [],
      unsavedRules: []
    });
  }

  /**
   * Action handler - set removeMapIcon
   */
  @Action(ProfileDetailActions.SetRemoveMapIcon)
  onSetRemoveMapIcon(ctx: StateContext<ProfileDetailStateModel>,
      action: ProfileDetailActions.SetRemoveMapIcon) {
    ctx.patchState({
      removeMapIcon: action.removeMapIcon
    });
  }

  /**
   * Action handler - set isNameChanged
   */
  @Action(ProfileDetailActions.SetIsNameChanged)
  onSetIsNameChanged(ctx: StateContext<ProfileDetailStateModel>,
      action: ProfileDetailActions.SetIsNameChanged) {
    ctx.patchState({
      isNameChanged: action.isNameChanged
    });
  }

  /**
   * Action handler - set isRoomChanged
   */
  @Action(ProfileDetailActions.SetIsRoomChanged)
  onSetIsRoomChanged(ctx: StateContext<ProfileDetailStateModel>,
      action: ProfileDetailActions.SetIsRoomChanged) {
    ctx.patchState({
      isRoomChanged: action.isRoomChanged
    });
  }

  /**
   * Action handler - set isBedChanged
   */
  @Action(ProfileDetailActions.SetIsBedChanged)
  onSetIsBedChanged(ctx: StateContext<ProfileDetailStateModel>,
      action: ProfileDetailActions.SetIsBedChanged) {
    ctx.patchState({
      isBedChanged: action.isBedChanged
    });
  }

  /**
   * Action handler - set isBathroomChanged
   */
  @Action(ProfileDetailActions.SetIsBathroomChanged)
  onSetIsBathroomChanged(ctx: StateContext<ProfileDetailStateModel>,
      action: ProfileDetailActions.SetIsBathroomChanged) {
    ctx.patchState({
      isBathroomChanged: action.isBathroomChanged
    });
  }

  /**
   * Action handler - set SetIsUnitChanged
   */
  @Action(ProfileDetailActions.SetIsUnitChanged)
  onSetIsUnitChanged(ctx: StateContext<ProfileDetailStateModel>,
      action: ProfileDetailActions.SetIsUnitChanged) {
    ctx.patchState({
      isUnitChanged: action.isUnitChanged
    });
  }

  /**
   * Action handler - set Assigned Unit
   */
  @Action(ProfileDetailActions.SetAssignedUnit)
  onSetAssignedUnit(ctx: StateContext<ProfileDetailStateModel>,
      action: ProfileDetailActions.SetAssignedUnit) {
    ctx.patchState({
      assignedUnit: action.assignedUnit
    });
  }
  

  /**
   * Action handler - set selectedUnitGeofenceId
   */
  @Action(ProfileDetailActions.SetSelectedUnitGeofenceId)
  onSetSelectedUnitGeofenceId(ctx: StateContext<ProfileDetailStateModel>,
      action: ProfileDetailActions.SetSelectedUnitGeofenceId) {
    ctx.patchState({
      selectedUnitGeofenceId: action.selectedUnitGeofenceId
    });
  }
  

  /**
   * Action handler - set isOnpassChanged
   */
  @Action(ProfileDetailActions.SetIsOnpassChanged)
  onSetIsOnpassChanged(ctx: StateContext<ProfileDetailStateModel>,
      action: ProfileDetailActions.SetIsOnpassChanged) {
    ctx.patchState({
      isOnpassChanged: action.isOnpassChanged
    });
  }

  /**
   * Action handler - set isNewSensorAssigned
   */
  @Action(ProfileDetailActions.SetIsNewSensorAssigned)
  onSetIsNewSensorAssigned(ctx: StateContext<ProfileDetailStateModel>,
      action: ProfileDetailActions.SetIsNewSensorAssigned) {
    ctx.patchState({
      isNewSensorAssigned: action.isNewSensorAssigned
    });
  }

  /**
   * Action handler - set isTimeChanged
   */
  @Action(ProfileDetailActions.SetIsTimeChanged)
  onSetIsTimeChanged(ctx: StateContext<ProfileDetailStateModel>, action: ProfileDetailActions.SetIsTimeChanged) {
    ctx.patchState({
      isTimeChanged: action.isTimeChanged
    });
  }

  /**
   * Action handler - set isReadOnly to view profile detail readonly
   */
  @Action(ProfileDetailActions.SetIsReadOnly)
  onSetIsReadOnly(ctx: StateContext<ProfileDetailStateModel>, action: ProfileDetailActions.SetIsReadOnly) {
    ctx.patchState({
      isReadOnly: action.isReadOnly
    });
  }

  /**
   * Action handler - set the profile detail
   */
  @Action(ProfileDetailActions.SetProfileDetail)
  onSetProfileDetail(ctx: StateContext<ProfileDetailStateModel>, action: ProfileDetailActions.SetProfileDetail) {
    ctx.patchState({
      profileDetail: action.profileDetail
    });
  }

  /**
   * Action handler - set the saved rules
   */
  @Action(ProfileDetailActions.SetSavedRules)
  onSetSavedRules(ctx: StateContext<ProfileDetailStateModel>, action: ProfileDetailActions.SetSavedRules) {
    ctx.patchState({
      savedRules: action.rules
    });
  }

  /**
   * Action handler - set the unsaved rules
   */
  @Action(ProfileDetailActions.SetUnsavedRules)
  onSetUnsavedRules(ctx: StateContext<ProfileDetailStateModel>, action: ProfileDetailActions.SetUnsavedRules) {
    ctx.patchState({
      unsavedRules: action.rules
    });
  }

  /**
   * Action handler - add a rule to unsaved rules
   */
  @Action(ProfileDetailActions.AddUnsavedRule)
  onAddUnsavedRule(ctx: StateContext<ProfileDetailStateModel>, action: ProfileDetailActions.AddUnsavedRule) {
    const state = ctx.getState();
    ctx.patchState({
      unsavedRules: [
        ...state.unsavedRules,
        action.rule,
      ]
    });
  }

  /**
   * Action handler - update the room geofence in all
   *  unsaved rules where updateRoomOnChange = true
   */
  @Action(ProfileDetailActions.UpdateRoomInUnsavedRules)
  onUpdateRoomInUnsavedRules(ctx: StateContext<ProfileDetailStateModel>, action: ProfileDetailActions.UpdateRoomInUnsavedRules) {
    const unsavedRules = [...ctx.getState().unsavedRules];
    for (let i = 0; i < unsavedRules.length; i++) {
      const currentRule = unsavedRules[i];
      if (currentRule.properties.updateRoomOnChange && currentRule.eventType === EventType.GEOFENCE) {
        const updatedRule = {
          ...currentRule,
          properties: {
            ...currentRule.properties,
            triggerValue: action.payload.roomId,
            geofenceIdName: action.payload.roomName
          }
        };
        unsavedRules[i] = updatedRule;
      } else if (currentRule.properties.updateRoomOnChange && currentRule.eventType === EventType.NOT_OWN_ROOM) {
        const updatedRule = {
          ...currentRule,
          properties: {
            ...currentRule.properties,
            ownRoomId: action.payload.roomId,
            ownRoomName: action.payload.roomName
          }
        };
        unsavedRules[i] = updatedRule;
      }
    }
    ctx.patchState({
      unsavedRules: unsavedRules
    });
  }

  /**
   * Action handler - delete all unsaved
   *  rules where updateRoomOnChange = true
   */
  @Action(ProfileDetailActions.DeleteUnsavedRoomRules)
  onDeleteUnsavedRoomRules(ctx: StateContext<ProfileDetailStateModel>) {
    const unsavedRules = [...ctx.getState().unsavedRules];
    const itemsToRemove: number[] = [];

    for (let ruleIndex = 0; ruleIndex < unsavedRules.length; ruleIndex++) {
      const currentRule = unsavedRules[ruleIndex];
      if (currentRule.properties.updateRoomOnChange) {
        itemsToRemove.push(ruleIndex);
      }
    }
    for (let removeIndex = itemsToRemove.length - 1; removeIndex >= 0; removeIndex--) {
      unsavedRules.splice(itemsToRemove[removeIndex], 1);
    }
    ctx.patchState({
      unsavedRules: unsavedRules
    });
  }

  /**
   * Action handler - update the bed geofence in all unsaved
   *  Geofence rules where updateBedOnChange = true
   */
  @Action(ProfileDetailActions.UpdateBedInUnsavedRules)
  onUpdateBedInUnsavedRules(ctx: StateContext<ProfileDetailStateModel>, action: ProfileDetailActions.UpdateBedInUnsavedRules) {
    const unsavedRules = [...ctx.getState().unsavedRules];
    for (let i = 0; i < unsavedRules.length; i++) {
      const currentRule = unsavedRules[i];
      if (currentRule.properties.updateBedOnChange) {
        const updatedRule = {
          ...currentRule,
          properties: {
            ...currentRule.properties,
            ...action.payload
          }
        };
        unsavedRules[i] = updatedRule;
      }
    }
    ctx.patchState({
      unsavedRules: unsavedRules
    });
  }

  /**
   * Action handler - delete all unsaved
   *  rules where updateBedOnChange = true
   */
  @Action(ProfileDetailActions.DeleteUnsavedBedRules)
  onDeleteUnsavedBedRules(ctx: StateContext<ProfileDetailStateModel>) {
    const unsavedRules = [...ctx.getState().unsavedRules];
    const itemsToRemove: number[] = [];

    for (let ruleIndex = 0; ruleIndex < unsavedRules.length; ruleIndex++) {
      const currentRule = unsavedRules[ruleIndex];
      if (currentRule.properties.updateBedOnChange) {
        itemsToRemove.push(ruleIndex);
      }
    }
    for (let removeIndex = itemsToRemove.length - 1; removeIndex >= 0; removeIndex--) {
      unsavedRules.splice(itemsToRemove[removeIndex], 1);
    }
    ctx.patchState({
      unsavedRules: unsavedRules
    });
  }

  /**
   * Action handler - update the bathroom geofence in all unsaved
   *  Geofence rules where updateBathroomOnChange = true
   */
  @Action(ProfileDetailActions.UpdateBathroomInUnsavedRules)
  onUpdateBathroomInUnsavedRules(ctx: StateContext<ProfileDetailStateModel>, action: ProfileDetailActions.UpdateBathroomInUnsavedRules) {
    const unsavedRules = [...ctx.getState().unsavedRules];
    for (let i = 0; i < unsavedRules.length; i++) {
      const currentRule = unsavedRules[i];
      if (currentRule.properties.updateBathroomOnChange) {
        const updatedRule = {
          ...currentRule,
          properties: {
            ...currentRule.properties,
            ...action.payload
          }
        };
        unsavedRules[i] = updatedRule;
      }
    }
    ctx.patchState({
      unsavedRules: unsavedRules
    });
  }

  /**
   * Action handler - delete all unsaved
   *  rules where updateBathroomOnChange = true
   */
  @Action(ProfileDetailActions.DeleteUnsavedBathroomRules)
  onDeleteUnsavedBathroomRules(ctx: StateContext<ProfileDetailStateModel>) {
    const unsavedRules = [...ctx.getState().unsavedRules];
    const itemsToRemove: number[] = [];

    for (let ruleIndex = 0; ruleIndex < unsavedRules.length; ruleIndex++) {
      const currentRule = unsavedRules[ruleIndex];
      if (currentRule.properties.updateBathroomOnChange) {
        itemsToRemove.push(ruleIndex);
      }
    }
    for (let removeIndex = itemsToRemove.length - 1; removeIndex >= 0; removeIndex--) {
      unsavedRules.splice(itemsToRemove[removeIndex], 1);
    }
    ctx.patchState({
      unsavedRules: unsavedRules
    });
  }

  /**
   * Action handler - update rule start and end time in all
   *  newRules rules where updateTime is not set the NEVER
   */
  @Action(ProfileDetailActions.UpdateTimeInUnsavedRules)
  onUpdateTimeInUnsavedRules(ctx: StateContext<ProfileDetailStateModel>, action: ProfileDetailActions.UpdateTimeInUnsavedRules) {
    const unsavedRules = [...ctx.getState().unsavedRules];
    for (let i = 0; i < unsavedRules.length; i++) {
      const currentRule = unsavedRules[i];
      if (currentRule.properties.updateTime === NotifyOnTime.RISE_TO_SLEEP) {
        const updatedRule = {
          ...currentRule,
          startTime: action.payload.riseTime,
          endTime: action.payload.sleepTime
        };
        unsavedRules[i] = updatedRule;
      } else if (currentRule.properties.updateTime === NotifyOnTime.SLEEP_TO_RISE) {
        const updatedRule = {
          ...currentRule,
          startTime: action.payload.sleepTime,
          endTime: action.payload.riseTime
        };
        unsavedRules[i] = updatedRule;
      }
    }
    ctx.patchState({
      unsavedRules: unsavedRules
    });
  }

  /**
   * Action handler - update all unsaved
 *  rules with the specified properties
   */
  @Action(ProfileDetailActions.UpdateAllUnsavedRules)
  onUpdateAllUnsavedRules(ctx: StateContext<ProfileDetailStateModel>, action: ProfileDetailActions.UpdateAllUnsavedRules) {
    const unsavedRules = [...ctx.getState().unsavedRules];
    for (let i = 0; i < unsavedRules.length; i++) {
      const currentRule = unsavedRules[i];
      const updatedRule = {
        ...currentRule,
        ...action.updatedProperties
      };
      unsavedRules[i] = updatedRule;
    }
    ctx.patchState({
      unsavedRules: unsavedRules
    });
  }

  /**
   * Action handler - update a rule in unsaved rules
   */
  @Action(ProfileDetailActions.UpdateUnsavedRule)
  onUpdateUnsavedRule(ctx: StateContext<ProfileDetailStateModel>, action: ProfileDetailActions.UpdateUnsavedRule) {
    const unsavedRules = [...ctx.getState().unsavedRules];
    const rule = unsavedRules[action.payload.index];
    const updatedRule = {
      ...rule,
      ...action.payload.updatedProperties
    };
    unsavedRules[action.payload.index] = updatedRule;
    ctx.patchState({
      unsavedRules: unsavedRules
    });
  }

  /**
   * Action handler - remove a rule from unsaved rules
   */
  @Action(ProfileDetailActions.DeleteUnsavedRule)
  onDeleteUnsavedRule(ctx: StateContext<ProfileDetailStateModel>, action: ProfileDetailActions.DeleteUnsavedRule) {
    const unsavedRules = [...ctx.getState().unsavedRules];
    unsavedRules.splice(action.index, 1);
    ctx.patchState({
      unsavedRules: unsavedRules
    });
  }

  /**
   * Action handler - reset the profiles state to default
   */
  @Action(ProfileDetailActions.ResetProfileDetailState)
  onResetProfileDetailState(ctx: StateContext<ProfileDetailStateModel>) {
    ctx.setState({
      isBedChanged: false,
      isBathroomChanged: false,
      isNameChanged: false,
      isOnpassChanged: false,
      isRoomChanged: false,
      isTimeChanged: false,
      isNewSensorAssigned: false,
      isUnitChanged: false,
      assignedUnit: null,
      selectedUnitGeofenceId: null,
      isReadOnly: false,
      mode: 'Create',
      profileType: null,
      removeMapIcon: false,
      profileUpdates: [],
      savedRules: [],
      unsavedRules: [],
      floorData: null,
      coordinates: null,
      fixturePendingCoordinates: null,
      floorGeofences: null,
      selectedRoomId: null,
      profileDetail: null
    });
  }


}
