import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-archive-dialog',
  templateUrl: './confirm-archive-dialog.component.html',
  styleUrls: ['./confirm-archive-dialog.component.scss']
})
export class ConfirmArchiveDialogComponent implements OnInit {


  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private dialogRef: MatDialogRef<ConfirmArchiveDialogComponent>) {}

  ngOnInit() {
    
  }

  onConfirm(action) {
    this.dialogRef.close(action);
  }

}
