import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { UserIdleService } from 'angular-user-idle';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthUtils } from '../state/auth/auth.utils';

import { environment } from '../../../environments/environment';
import { Logout, Refresh } from '../state/auth/auth-state.actions';
import { ConsoleState } from '../state/console/console.state';

const IP_INFO_URL = environment.ipInfoUrl;
const CANADA = 'CA';
const UNITED_STATES = 'US';
const REFRESH_BUFFER_SECONDS = 10;

@Injectable()
export class PermissionService {
  private tokenTimer: any;

  constructor (
    private store: Store
  ) { }

  hasPermission(requiredPermissions: string[]) {
    let permissions = this.store.selectSnapshot(ConsoleState.permissions);
    for (const checkPermission of requiredPermissions) {
      if (permissions?.find(x => x.toUpperCase() === checkPermission.toUpperCase())) {
        return true;
      }
    }
    return false;
  }
}
