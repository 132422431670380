/**
 * Enumeration of possible actions for the Geofence event type
 */
export enum GeofenceAction {
  ENTER = 'Enter',
  EXIT = 'Exit',
  EXIT_ASSIGNED_BED = 'Exit Assigned Bed',
  ENTER_ASSIGNED_BATHROOM = 'Enter Assigned Bathroom',
  NEAR = 'Near'
}
