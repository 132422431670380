import { ProfileType } from './profile-type.model';
import { UntypedFormGroup } from '@angular/forms';
import { GeofenceAction } from '../../../../shared/models/rules/enums/geofence-action.enum';
import { GeofenceType } from '../../../../shared/models/rules/enums/geofence-type.enum';
import { DefaultRule } from './default-rule.model';
import { RuleSettings } from './rule-settings.model';
import { EventType } from '../../../../shared/models/rules/enums/event-type.enum';
import { FormLayout } from './form-layout.model';

export class ResidentProfileType extends ProfileType {
  private static instance: ResidentProfileType;

  readonly id = 'resident';
  readonly name = 'Resident';
  readonly namePlural = 'Residents';
  readonly icon = 'user';
  readonly listReturningOption = false;
  readonly checkForDuplicateName = false;
  readonly downloadColumns = [
    'label', 'firstName', 'lastName', 'roomName', 'onpassstatus', 'latestTime', 'latestLocation', 'battery'
  ];
  readonly listColumns = [
    'photoUrl', 'firstName', 'lastName', 'roomName', 'onpassstatus', 'adlChart', 'latestTime', 'latestLocation', 'battery'
  ];
  readonly secureColumns = [
    {
      column: 'onpassstatus',
      permissions: ['profile-editor']
    }
  ];
  readonly formLayout = <FormLayout>{
    basicProfile: ['photoUrl', 'firstName', 'middleName', 'lastName', 'residentId', 'gender'],
    venueProfile: ['label', 'sensorId', 'notes', 'floorId', 'floorNumber', 'roomId', 'roomName', 'bedId', 'bedName', 'bathroomId', 'bathroomName', 'assignedUnit'],
    activityProfile: ['onpassstatus', 'riseHour', 'riseMeridian', 'riseMinute', 'sleepHour', 'sleepMeridian', 'sleepMinute'],
    adlExclusions: ['chartExclusions']
  };
  readonly ruleSettings = <RuleSettings>{
    level: 2,
    showDisableOnPass: true,
    showRuleEnforcement: true,
    eventTypes: [
      EventType.GEOFENCE,
      EventType.NOT_OWN_ROOM,
      EventType.PROXIMITY,
      EventType.ON_FLOOR
    ],
    defaultEventType: EventType.GEOFENCE,
    defaultRules: [
      <DefaultRule>{eventType: EventType.GEOFENCE, geofenceAction: GeofenceAction.ENTER, geofenceType: GeofenceType.RESTRICTED, active: false}
    ]
  };

  private constructor() { super(); }

  public static getInstance(): ResidentProfileType {
    if (!ResidentProfileType.instance) {
      ResidentProfileType.instance = new ResidentProfileType();
    }
    return ResidentProfileType.instance;
  }

  mapLabel(form: UntypedFormGroup): string {
    const first = form.get('firstName').value;
    const last = form.get('lastName').value;
    return (first && last) ? first + ' ' + last.charAt(0) : null;
  }
}
