import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { ConsoleState } from '../shared/state/console/console.state';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy, AfterViewInit {

  codeWhiteSubscription: Subscription;
  selectedUnitNamesSubscription: Subscription;
  wrapperClass: string = 'wrapper';
  codeWhiteAssistance: boolean = false;
  sectionContainerClass = 'section-container';
  asideContainer = 'aside-container';

  @Select(ConsoleState.codeWhiteNotifications) codeWhiteNotifications$: Observable<Notification[]>;

  constructor(private store: Store, private myElement: ElementRef) {}

  ngOnInit() {
    this.codeWhiteSubscription = this.store.select(ConsoleState.codeWhiteNotifications).subscribe(codeWhites => {
      if (codeWhites && codeWhites.length > 0) {
        this.codeWhiteAssistance = (codeWhites.filter(code => !code.clearedTime).length > 0);
        this.wrapperClass = this.codeWhiteAssistance ? 'wrapper move-wrapper' : 'wrapper';
      } else {
        this.codeWhiteAssistance = false;
        this.wrapperClass = 'wrapper';
      }
    })
  }

  ngAfterViewInit() {
    this.selectedUnitNamesSubscription = this.store.select(ConsoleState.selectedUnitNames).subscribe(unitNames => {
      this.adjustContentPadding();
    })
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
      this.adjustContentPadding();
  }

  adjustContentPadding() {
    const normalHeight = 65;
    const appHeaderHeight = this.myElement?.nativeElement.querySelector('app-header').getBoundingClientRect().height ?? normalHeight;
    if (appHeaderHeight > 150) {
      this.sectionContainerClass = 'section-container move-section-container-more';
      this.asideContainer = 'aside-container move-section-container-more';
    } else if (appHeaderHeight > normalHeight) {
      this.sectionContainerClass = 'section-container move-section-container';
      this.asideContainer = 'aside-container move-section-container';
    } else {
      this.sectionContainerClass = 'section-container';
      this.asideContainer = 'aside-container';
    }
  }

  ngOnDestroy(): void {
    if (this.codeWhiteSubscription) {
      this.codeWhiteSubscription.unsubscribe();
    }
    if (this.selectedUnitNamesSubscription) {
      this.selectedUnitNamesSubscription.unsubscribe();
    }
  }
}
