import { ProfileIncidentReport } from '../../models/profile-incident-report.model';

// Events - Actions that result from an event

/**
 * Action dispatched to add an incident report
 */
export class SetProfileIncidentReport {
  static type = '[ProfileIncidentReport] SetProfileIncidentReport';
  constructor(public incidentReports: ProfileIncidentReport[]) {}
}

/**
 * Action dispatched to add an incident report
 */
export class SetIsReportReadOnly {
  static type = '[ProfileIncidentReport] SetIsReportReadOnly';
  constructor(public isReportReadOnly: boolean) {}
}

/**
 * Action dispatched to reset
 */
export class ResetProfileIncidentReport {
  static type = '[ProfileIncidentReport] ResetProfileIncidentReport';
}
