import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Injectable()
export class LoginFormService {
  public form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      'username': [null, Validators.required],
      'password': [null, Validators.required]
    });
  }

  resetForm() {
    this.form.reset();
  }


}
