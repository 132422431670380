<div class="wrapper">
  <div class="block-center mt-4 wd-xl">
    <h2 id="brand" class="text-center">Tenera Care</h2>
    <!-- START card-->
    <div class="card card-flat">
      <div class="card-body">
        <p class="text-center py-2">CONSOLE LOGIN</p>
        <div *ngIf="isInvalid" class="alert alert-danger text-center">Invalid username and/or password.</div>
        <div *ngIf="isOffline" class="alert alert-danger text-center">Not connected to the internet.</div>
        <form *ngIf="loginForm" [formGroup]="loginForm" class="form-validate mb-3" role="form" name="loginForm" novalidate="" (submit)="login(loginForm.value)">
          <div class="form-group">
            <div class="input-group with-focus">
              <input class="form-control border-right-0" id="email" type="text" name="email" 
                placeholder="Email / Username" autocomplete="off" formControlName="username" />
              <div class="input-group-append">
                <span class="input-group-text text-muted bg-transparent border-left-0">
                  <em class="fa fa-envelope"></em>
                </span>
              </div>
            </div>
            <div class="text-danger text-center"
              *ngIf="loginForm.controls['username'].hasError('required') && (loginForm.controls['username'].dirty || loginForm.controls['username'].touched)">
              This field is required
            </div>
          </div>

          <div class="form-group">
            <div class="input-group with-focus">
              <input class="form-control border-right-0" id="password" type="password" name="password"
                placeholder="Password" formControlName="password"/>
              <div class="input-group-append">
                <span class="input-group-text text-muted bg-transparent border-left-0">
                  <em class="fa fa-lock"></em>
                </span>
              </div>
            </div>
            <div
              class="text-danger text-center"
              *ngIf="loginForm.controls['password'].hasError('required') && (loginForm.controls['password'].dirty || loginForm.controls['password'].touched)">
              This field is required
            </div>
          </div>
          <button class="btn btn-block btn-primary btn-lg mt-3" type="submit" [disabled]="!loginForm.valid">
            Login
          </button>
          <div>
            <div class="login-or">- or -</div>
            <div class="btn-pcc-login" type="button" (click)="pccLogin()"></div>
          </div>
          <div class="clearfix recover">
            <div>
              <a class="text-muted forgot-password" [routerLink]="'/password-recover'">Forgot your password?</a>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- END card-->
    <div class="p-3 text-center credit">
      <span>&copy;</span> <span>{{ getAppSetting("year") }}</span>
      <span> - </span> <span>{{ getAppSetting("name") }}</span>
    </div>
    <div class="privacy"><a (click)="openPrivacyPolicyLink()">Privacy Policy</a></div>
  </div>
</div>
