<div class="wrapper">
  <div class="block-center mt-4 wd-custom">
    <h2 id="brand" class="text-center">Tenera Care</h2>
    <!-- START card-->
    <div class="card card-flat">
      <div class="card-body">
        
        <p class="text-center py-2 form-title">PASSWORD UPDATE REQUIRED</p>
        <p class="text-center py-2" *ngIf="!success">Periodic password changes are required for security purposes.<br/>Please update your password below.</p>    

        <alert *ngIf="accountError" type="danger" class="text-left">
          <div *ngIf="accountError.list">
            Password must:
            <ul>
              <ng-container *ngFor="let error of accountError.list">
                <li>{{error}}</li>
              </ng-container>    
            </ul>
          </div>
          <div *ngIf="!accountError.list">
            {{accountError.error}}
          </div>
        </alert>

        <alert *ngIf="success" type="success" class="text-center">
          Thank you password has been updated.
        </alert>
        <div *ngIf="success" type="success" class="text-center">
          <a [routerLink]="'/dashboard'">Click here to continue.</a> 
        </div>

        <form *ngIf="!success" [formGroup]="changeForm" class="form-validate mb-3" role="form" name="changeForm" novalidate="" (submit)="change(changeForm.value)">
          <div class="form-group">
            <div class="input-group with-focus">
              <input class="form-control" type="password" placeholder="Enter Current Password" autocomplete="off" formControlName="currentPassword" (keypress)="resetMessages()"/>
            </div>
            <div class="text-danger text-center"
              *ngIf="changeForm.controls['currentPassword'].hasError('required') && (changeForm.controls['currentPassword'].dirty || changeForm.controls['currentPassword'].touched)">
              This field is required
            </div>
          </div>
          <div class="form-group">
            <div class="input-group with-focus">
              <input class="form-control" type="password" placeholder="Enter New Password" autocomplete="off" formControlName="newPassword1" (keypress)="resetMessages()"/>
            </div>
            <div class="text-danger text-center"
              *ngIf="changeForm.controls['newPassword1'].hasError('required') && (changeForm.controls['newPassword1'].dirty || changeForm.controls['newPassword1'].touched)">
              This field is required
            </div>
          </div>
          <div class="form-group">
            <div class="input-group with-focus">
              <input class="form-control" type="password" placeholder="Re-enter New Password" autocomplete="off" formControlName="newPassword2" (keypress)="resetMessages()"/>
            </div>
            <div class="text-danger text-center"
              *ngIf="changeForm.controls['newPassword2'].hasError('required') && (changeForm.controls['newPassword2'].dirty || changeForm.controls['newPassword2'].touched)">
              This field is required
            </div>
          </div>
          <button class="btn btn-block btn-primary btn-lg mt-3" type="submit" [disabled]="!changeForm.valid">
            Update Password
          </button>
        </form>

      </div>
    </div>
    <!-- END card-->
    <div class="p-3 text-center credit">
      <span>&copy;</span> <span>{{ getAppSetting("year") }}</span>
      <span> - </span> <span>{{ getAppSetting("name") }}</span>
    </div>
    <div class="privacy"><a [routerLink]="'/privacy'">Privacy Policy</a></div>
  </div>
</div>
