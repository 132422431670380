import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Injectable()
export class ClearNotificationFormService {

  public form: UntypedFormGroup;
  public clearedReasons = [
    'ADL assist',
    'Found on floor',
    'Medical assistance',
    'Outdoor activity',
    'Self assisted',
    'Staff assist',
    'Staff emergency',
    'Test'
  ];

  public onFloorClearReasons = [
    'Hard fall',
    'Soft fall/slide',
    'Fell but self-recovered',
    'No fall occurred'
  ];

  constructor(private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      'memo': [null],
      'reason': [null, Validators.required]
    });
  }

  resetForm() {
    this.form.reset();
  }


}
