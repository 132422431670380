import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Injectable()
export class PasswordRecoverFormService {
  public form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      'email': [null, Validators.email]
    });
  }

  resetForm() {
    this.form.reset();
  }
}
