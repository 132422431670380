import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { Howl } from 'howler';
import { HttpClient } from '@angular/common/http';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { Subscription } from 'rxjs';

import { FloorListItem } from '../models/floor-list-item.model';
import { Logout } from '../state/auth/auth-state.actions';
import { Organization } from './../models/organization.model';
import { SetOrganizations, SetStaffPositions, SetUnits, SetVenues } from './../state/console/console-state.actions';
import { Unit } from './../models/unit.model';
import { Venue } from '../models/venue.model';
import { environment } from '../../../environments/environment';


const GLOBAL_ORG = 'Tenera';
const API_URL_PREFIX = environment.apiUrl;
const SOUND_FILE_BRUYERE = '/assets/sound/bruyere_notification.wav';
const SOUND_FILE_DEFAULT = '/assets/sound/tenera_alert.wav';
const SOUND_FILE_CODE_WHITE = '/assets/sound/code_white.wav';

@Injectable()
export class ConsoleService implements OnDestroy {

  constructor(
    private readonly afs: AngularFirestore,
    private http: HttpClient,
    private afMessaging: AngularFireMessaging,
    private store: Store) {}

    messagingSubscription: Subscription;

  /**
   * Query organizations and dispatch the organizations
   *  that the user may access
   *
   * @param userLocation The location assigned to the user
   * @param userLocationAccess A list of locations that the user may access
   * @param userOrganization The organization that the user belongs to
   */
  fetchOrganizations(userLocation: string, userLocationAccess: string[], userOrganization: string) {
    this.afs.collection('organizations').get().subscribe(async querySnapshot => {
      const orgAccessList: Organization[] = [];
      for (const currentDoc of querySnapshot.docs) {
        const orgData = currentDoc.data() as Organization;

        let orgLocationDoc;
        try { // get the access list for the organization from the location document
          orgLocationDoc = await this.getOrganizationLocation(currentDoc.id);
        } catch (error) {
          console.log('Firestore error getting organization location: ' + error);
        }

        if (orgLocationDoc) { // a location document was found
          const orgLocation = orgLocationDoc.data();
          const organization = {
            id: currentDoc.id,
            name: orgLocation.name,
            enablePermissions: (orgData.enablePermissions) ? orgData.enablePermissions : []
          };
          if (userOrganization === currentDoc.id || orgLocation.access.includes(userLocation)
            || orgLocation.access.some((r: string) => userLocationAccess.includes(r))) { // user has access

              // filter out if no units
              const locationsUrl = 'organizations/' + currentDoc.id + '/locations';
              const unitLocations = await this.afs.collection<any>(locationsUrl, ref => ref.where('type', '==', 'unit')).get().toPromise();

              if (unitLocations && unitLocations.docs.length > 0) {
                orgAccessList.push(organization);
              }
          } else if (userOrganization === GLOBAL_ORG) {
            // if user is Tenera, include all orgs that have access items
            const locDocs = await this.afs.firestore.collection('organizations/' + currentDoc.id + '/locations').get();
            if (locDocs.docs.find(doc => userLocationAccess.includes(doc.id))) {
              // filter out if no units
              const locationsUrl = 'organizations/' + currentDoc.id + '/locations';
              const unitLocations = await this.afs.collection<any>(locationsUrl, ref => ref.where('type', '==', 'unit')).get().toPromise();

              if (unitLocations && unitLocations.docs.length > 0) {
                orgAccessList.push(organization);
              }
            }
          }
        }
      }

      this.store.dispatch(new SetOrganizations(orgAccessList));
    }, error => {
      console.error('Error fetching organizations: ' + error);
      this.store.dispatch(new Logout());
    });
  }

  /**
   * Get an organization location document
   *
   * @param organizationId The organization identifier
   */
  getOrganizationLocation(organizationId: string) {
    return this.afs.firestore.collection('organizations/' + organizationId + '/locations').doc(organizationId).get();
  }

  /**
   * Query venues for an organization and dispatch the venues
   *  that the user may access
   *
   * @param userLocation The location assigned to the user
   * @param userLocationAccess A list of locations that the user may access
   * @param organization The selected organization
   */
   async fetchVenues(userLocation: string, userLocationAccess: string[], organization: string) {
    const locationsUrl = 'organizations/' + organization + '/locations';
    const venuesUrl = 'organizations/' + organization + '/venues';
    const venueDocs = await this.afs.collection<any>(venuesUrl)
    .get()
    .toPromise();
    const activeVenues = [];
    for (const doc of venueDocs.docs) {
      if (doc.data() && doc.data().status != 'deleted' && !doc.data().deletedDate) {
        const pccFacId = (doc.data().pccFacId) ? doc.data().pccFacId : null;
        activeVenues.push({venueId: doc.id, timezone: doc.data().timezone, featureConfig: doc.data().featureConfig, pccFacId: pccFacId});
      }
    }
    const unitLocations = await this.afs.collection<any>(locationsUrl, ref => ref.where('type', '==', 'unit')).get().toPromise();

    const venueLocations = this.afs.collection<any>(locationsUrl, ref => ref.where('type', '==', 'venue'));
    venueLocations.get().subscribe(async querySnapshot => {
      const venueList: Venue[] = [];

      for (const currentDoc of querySnapshot.docs) {
        const venueData = currentDoc.data();
        if (venueData.access.includes(userLocation) ||
          venueData.access.some((r: string) => userLocationAccess.includes(r))) { // user has access
            const units = unitLocations.docs ? unitLocations.docs.filter(item => item.data().parent === currentDoc.id) : [];
            // don't show venues that have been deleted
            const activeVenue = activeVenues.find(item => item.venueId === currentDoc.id);
            const pccFacId = (activeVenue?.pccFacId) ? activeVenue.pccFacId : null;
            if (activeVenue && units.length > 0) {
              venueList.push({venueId: currentDoc.id, venueName: venueData.name, timezone: activeVenue.timezone, featureConfig: activeVenue.featureConfig, pccFacId: pccFacId });
            }
        }
      }

      this.store.dispatch(new SetVenues(venueList.sort((a, b) => 
        (a.venueName > b.venueName) ? 1 : ((b.venueName > a.venueName) ? -1 : 0)
      )));
    }, error => {
      console.error('Error fetching venues: ' + error);
      this.store.dispatch(new Logout());
    });
  }

  /**
   * Query units for an organization and dispatch the units
   *  that the user may access
   *
   * @param userLocation The location assigned to the user
   * @param userLocationAccess A list of locations that the user may access
   * @param venueId The selected venue ID
   * @param organization The selected organization ID
   */
  fetchUnits(userLocation: string, userLocationAccess: string[], venueId: string, organization: string) {
    const locationsUrl = 'organizations/' + organization + '/locations';
    const unitLocations = this.afs.collection<any>(locationsUrl,
      ref => ref.where('type', '==', 'unit').where('parent', '==', venueId));
    unitLocations.get().subscribe(async querySnapshot => {
      const unitList: Unit[] = [];

      for (const currentDoc of querySnapshot.docs) {
        const unitData = currentDoc.data();
        if (unitData.access.includes(userLocation) ||
          unitData.access.some((r: string) => userLocationAccess.includes(r))) { // user has access
            let unitDoc;
            try { // get the unit document to access list of floors
              unitDoc = await this.getUnitDocument(organization, unitData.parent, currentDoc.id);
            } catch (error) {
              console.log('Firestore error getting unit document: ' + error);
            }

            if (unitDoc && !unitDoc.data().isShared) {
              let includeShared = unitDoc.data().includeShared;
              let defaultFloorId = unitDoc.data().defaultFloorId;
              let featureConfig = unitDoc.data().featureConfig;
              let floors: FloorListItem[] = unitDoc.data().floors;

              // if this unit pull in shared units, make sure the floors are included
              if (includeShared) {
                for (const sharedUnit of includeShared) {
                  let sharedUnitDoc;
                  try { // get the shared unit document to access list of floors
                    sharedUnitDoc = await this.getUnitDocument(organization, unitData.parent, sharedUnit);
                  } catch (error) {
                    console.log('Firestore error getting unit document: ' + error);
                  }
                  // for each floor in the shared unit, add to unit floors array if not already present
                  if (sharedUnitDoc.data().floors) {
                    for (const currentFloor of sharedUnitDoc.data().floors) {
                      const floorIndex = floors.findIndex(x => x.floorId === currentFloor.floorId);
                      if (floorIndex === -1) {
                        floors.push(currentFloor);
                      }
                    }
                  }
                }
              }

              // sort the floors by floor number
              floors.sort((a,b) => (a.floorNumber > b.floorNumber) ? 1 : ((b.floorNumber > a.floorNumber) ? -1 : 0));

              const unit = {
                floors,
                includeShared: includeShared ? includeShared : null,
                name: unitData.name,
                unitId: currentDoc.id,
                venueId: unitData.parent,
                defaultFloorId: defaultFloorId,
                featureConfig: {
                  isEmptyClearAllowed: featureConfig?.isEmptyClearAllowed ?? false,
                  isOnFloorEnabled: featureConfig?.isOnFloorEnabled ?? false,
                }
              };
              unitList.push(unit);
            }
        }
      }
      
      this.store.dispatch(new SetUnits(unitList.sort((a, b) => 
        (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
      )));
    }, error => {
      console.error('Error fetching units: ' + error);
      this.store.dispatch(new Logout());
    });
  }

  /**
   * Get a unit document
   *
   * @param organizationId The organization identifier
   * @param venueId The ID of the selected venue
   * @param unitId The ID of the unit document to retrieve
   */
  getUnitDocument(organizationId: string, venueId: string, unitId: string) {
    return this.afs.firestore.collection('organizations/' + organizationId + '/venues/' + venueId + '/units').doc(unitId).get();
  }

  /**
   * Subscribe to specified unit push notifications
   * @param organizationId org id
   * @param token notification browser token
   * @param units all unit ids to subscribe to
   * @returns null if successful, string if unsuccessful
   */
  async subscribeToUnits(organizationId: string, token: string, units: string[]) {
    try {
      const subscribeUrl = API_URL_PREFIX + '/organizations/' + organizationId + '/users/topics/subscribe';
      const body = {
        units: units,
        token: token
      }
      await this.http.post<any>(subscribeUrl, body).toPromise();
      return null;
    } catch (error) {
      console.log(error);
      return error.error && error.error.message ? error.error.message.errors : 'Error subscribing';
    }
  }

  /**
   * Unsubscribe from push notification units
   * @param organizationId org id
   * @param token notification browser token
   * @param units all unit ids to subscribe to
   * @returns null if successful, string if unsuccessful
   */
  async unSubscribeToUnits(organizationId: string, token: string, units: string[]) {
    try {
      const subscribeUrl = API_URL_PREFIX + '/organizations/' + organizationId + '/users/topics/unsubscribe';
      const body = {
        units: units,
        token: token
      }
      await this.http.post<any>(subscribeUrl, body).toPromise();
      return null;
    } catch (error) {
      console.log(error);
      return error.error && error.error.message ? error.error.message.errors : 'Error unsubscribing';
    }
  }

  /**
   * Query staff positions for an organization and dispatch 
   *
   * @param organization The selected organization
   */
  async fetchStaffPositions(organization: string) {
    const positionsCollection = this.afs.collection<{name: string}>('organizations/' + organization + '/positions');
    const positionList = await positionsCollection.get().toPromise();
    const positionMap = new Map();
    positionList.docs.map(position => {
      positionMap.set(position.id, position.data().name);
    });
    this.store.dispatch(new SetStaffPositions(positionMap));
  }

  /**
   * Subscribe to foreground messages - push notifications when user is viewing page
   */
  async listenForMessages() {
    if (await this.afMessaging.getToken.toPromise()) {
      this.messagingSubscription = this.afMessaging.messages
        .subscribe((message) => { 
          if (message.notification) {
            const notification = new Notification(`${message.notification.title}`, 
            { ...message.fcmOptions, 
              icon: '/assets/icons/favicon-96x96.png', 
            body: message.notification.body, 
            silent: true }
            ); 

            // don't play these sounds for code white
            if (message?.data?.priority && parseInt(message?.data?.priority) > 0) {
              let audio = new Howl({
                src: [SOUND_FILE_DEFAULT],
                // src: message?.data?.orgId === 'Bruyere' ? [SOUND_FILE_BRUYERE] : [SOUND_FILE_DEFAULT],
                autoplay: false,
                onloaderror: (error) => {console.log('load error', error)},
                onplayerror: (error) => {console.log('play error', error)},
              });
  
              // play the sound
              audio.play();
    
              // if the sound doesn't play within 20 seconds, stop it
              setTimeout(() => {
                if (audio) {
                  audio.stop();
                }
              }, 20000);
            } else if (message?.data?.priority 
              && parseInt(message?.data?.priority) == 0 
              && message.notification?.body?.includes('unaccepted')
            ) {
              let audio = new Howl({
                src: [SOUND_FILE_CODE_WHITE],
                autoplay: false,
                onloaderror: (error) => {console.log('load error', error)},
                onplayerror: (error) => {console.log('play error', error)},
              });
  
              // play the sound
              audio.play();
    
              // if the sound doesn't play within 20 seconds, stop it
              setTimeout(() => {
                if (audio) {
                  audio.stop();
                }
              }, 20000);
            }
          }
        });
    }
  }

  /**
   * Unsubscribe from the foreground message service
   */
  cancelListenForMessages() {
    if (this.messagingSubscription) {
      this.messagingSubscription.unsubscribe();
    }
  }

  /**
   * Unsubscribe from all service subscriptions
   */
  ngOnDestroy(): void {
    this.cancelListenForMessages();
  }

}