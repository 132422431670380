/**
 * Enumeration of possible categories for the Geofence event type
 */
export enum GeofenceCategory {
  ZONE_ENTER = 'Zone Enter',
  ZONE_EXIT = 'Zone Exit',
  EXIT_ASSIGNED_BED = 'Exit Bed',
  ENTER_ASSIGNED_BATHROOM = 'Enter Bathroom',
  DISTANCE = 'Distance'
}
