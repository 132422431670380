import { GlobalConstants } from '../global-constants';
import { NumericOptionItem } from '../../shared/models/numeric-option-item.model';
import { ProfileRuleConstants } from '../../shared/models/rules/profile-rule-constants';
import { OptionItem } from '../../shared/models/option-item.model';
import { OnPassOptionItem } from './models/profile-on-pass-option.model';
import { Pipe, PipeTransform } from '@angular/core';
import { IncidentReportType } from '../../shared/models/rules/enums/incident-report-type.enum';
import { CallStationClearMethod } from './models/enums/call-station-clear-method.enum';


@Pipe({ name: "incidentReportTypesPipe" })
export class IncidentReportTypesPipe implements PipeTransform {
  transform(typeValue: string) {
    return ProfileUtils.getIncidentReportTypeOptions().find(item => item.value === typeValue) ? 
      ProfileUtils.getIncidentReportTypeOptions().find(item => item.value === typeValue).label.split(' - ')[0] : 
      '';
  }
}

export class ProfileUtils {

  /**
   * Static function to return the file type of accepted images
   *
   * @param arr The Uint8Array used data to validate file type
   */
  static getFileType(arr: Uint8Array): string {
    let header = '';
    let fileType: string;
    for (let i = 0; i < arr.length; i++) {
      header += arr[i].toString(16);
    }
    switch (header) {
      case '89504e47':
        fileType = 'image/png';
        break;
      case 'ffd8ffe0':
      case 'ffd8ffe1':
      case 'ffd8ffe2':
      case 'ffd8ffe3':
      case 'ffd8ffe8':
        fileType = 'image/jpeg';
        break;
      default:
        break;
    }
    return fileType;
  }

  // Option item functions

  /**
   * Static function to get an array of OptionItem objects
   *  to use in a Gender select element
   */
  static getGenderOptions(): OptionItem[] {
    return [
      { value: 'Female', label: 'Female' },
      { value: 'Male', label: 'Male' }
    ];
  }


  /**
   * Static function to get an array of NumericOptionItem objects
   *  to use in an Hour select element
   */
  static getHourOptions(): NumericOptionItem[] {
    return [
      { value: 1, label: '1' },
      { value: 2, label: '2' },
      { value: 3, label: '3' },
      { value: 4, label: '4' },
      { value: 5, label: '5' },
      { value: 6, label: '6' },
      { value: 7, label: '7' },
      { value: 8, label: '8' },
      { value: 9, label: '9' },
      { value: 10, label: '10' },
      { value: 11, label: '11' },
      { value: 12, label: '12' }
    ];
  }


  /**
   * Static function to get an array of OptionItem objects
   *  to use in a Meridian select element
   */
  static getMeridianOptions(): OptionItem[] {
    return [
      { value: GlobalConstants.AM_MERIDIAN, label: GlobalConstants.AM_MERIDIAN },
      { value: GlobalConstants.PM_MERIDIAN, label: GlobalConstants.PM_MERIDIAN }
    ];
  }


  /**
   * Static function to get an array of NumericOptionItem objects
   *  to use in a Minute select element
   */
  static getMinuteOptions(): NumericOptionItem[] {
    return [
      { value: 0, label: '00' },
      { value: 15, label: '15' },
      { value: 30, label: '30' },
      { value: 45, label: '45' }
    ];
  }

  /**
   * Static function to get an array of Options for nurse call station active status
   */
  static getActiveStatusOptions(): OptionItem[] {
    return [
      { value: 'high', label: 'High' },
      { value: 'low', label: 'Low' }
    ];
  }

  /**
   * Static function to get an array of hardware clearing options for fixtures
   */
  static getHardwareClearingMethods(): OptionItem[] {
    return [
      { value: CallStationClearMethod.NONE, label: 'None' },
      { value: CallStationClearMethod.SELF_CLEAR_LATCH, label: 'Self Clearing Latch' },
      { value: CallStationClearMethod.SENSOR, label: 'Button' },
      { value: CallStationClearMethod.CHANNEL, label: 'Beacon/Channel' },
    ];
  }


  /**
   * Static function to get an array of OptionItem objects to use
   *  in an On Pass Status select element
   */
  static getStatusOptions(): OnPassOptionItem[] {
    return [
      {value: 'NOT ON PASS', label: 'NOT ON PASS', icon: 'person_off', color: '#696969'},
      {value: 'ON PASS - Out With Family', label: 'Out With Family', icon: 'supervisor_account', color: '#2C78AF'},
      {value: 'ON PASS - Organized Event', label: 'Organized Event', icon: 'language', color: '#e1b004'},
      {value: 'ON PASS - Group Activity', label: 'Group Activity', icon: 'groups', color: '#Cb43B1'},
      {value: 'ON PASS - Extended Leave', label: 'Extended Leave', icon: 'meeting_room', color: '#DB7600'},
      {value: 'ON PASS - Appointment', label: 'Appointment', icon: 'calendar_today', color: '#602E92'},
      {value: 'ON PASS - 1:1 Walk', label: '1:1 Walk', icon: 'directions_walk', color: '#4AA029'},
      {value: 'ON PASS - Hospital', label: 'Hospital', icon: 'local_hospital', color: '#b92e2e'},
      {value: 'ON PASS - Other', label: 'Other', icon: 'help_center', color: '#4b4b4b', outline: true}
    ];
  }

  /**
   * Method to convert a time represented by hours, minutes and meridian
   *  to a Date object
   *
   * @param hours The number of hours to set
   * @param minutes The number of minutes to set
   * @param meridian The meridian to set
   */
  static convertTimeToDate(hours: number, minutes: number, meridian: string): Date {
    // if after 1 PM, add 12 hours
    if (meridian === 'PM' && hours < 12) {
      hours += 12;
    }
    // if between midnight and 1 am, hour is 0
    if (hours === 12 && meridian === 'AM') {
      hours = 0;
    }

    const time = new Date(1970, 0, 1);
    time.setSeconds((hours * ProfileRuleConstants.SECONDS_PER_HOUR) + (minutes * ProfileRuleConstants.SECONDS_PER_MINUTE));
    return time;
  }

    /**
   * Static function to get an array of OptionItem objects
   *  to use in the incident report type select element
   */
  static getIncidentReportTypeOptions(): OptionItem[] {
    return [
      { value: 'falls', label: IncidentReportType.falls },
      { value: 'foundOnFloor', label: IncidentReportType.foundOnFloor },
      { value: 'injury', label: IncidentReportType.injury },
      { value: 'lostItems', label: IncidentReportType.lostItems },
      { value: 'responsiveBehavior', label: IncidentReportType.responsiveBehavior },
      { value: 'elopement', label: IncidentReportType.elopement },
      { value: 'other', label: IncidentReportType.other },
    ];
  }

  /**
   * Static function to get an array of OptionItem objects
   *  to use in the call station profiles
   */
  static getFixtureTypeOptions(): OptionItem[] {
    return [
      { value: 'Nurse Call Device', label: 'Nurse Call Device' },
      { value: 'Sensor Device', label: 'Sensor Device' },
    ];
  }

  /**
   * Static function to get an array of OptionItem objects
   *  to use in the call station profile data
   */
  static getHardwareTypeOptions(): OptionItem[] {
    return [
      { value: 'Pull-Cord', label: 'Pull-Cord' },
      { value: 'Accessibility Device', label: 'Accessibility Device' },
      { value: 'Wall-Button', label: 'Wall-Button' },
      { value: 'Pressure Pad', label: 'Pressure Pad' },
    ];
  }
}
